import {Injectable, Injector} from '@angular/core'
import {AbbreviatedCoordinateData} from '../model/coordinate/abbreviatedCoordinateData.type'
import {ComponentCoordinateConfig, componentSizeConfig} from '../model/coordinate/componentCoordinateConfig.type'
import {Profile, ProfilMasse} from '../../class'
import {ConstructionComponent} from '../model/component/construction/constructionComponent'
import {ConstructionComponentType} from '../model/component/construction/construction-component-type.enum'
import {Tuer} from '../model/component/construction/tuer'
import {Seitenteil} from '../model/component/construction/seitenteil'
import {ParameterService} from './parameter/parameter.service'
import {ConfiguratorConfigurationModel} from '../model/configuratorConfigurationModel'
import {ConfiguratedDoor} from '../model/configuratedDoor'
import {ComponentSelectionService} from './componentSelectionService'
import {isSimpleConstruction, SimpleConstruction} from '../model/component/other/construction'
import {ConstructionDimensions} from '../model/component/construction/construction-dimensions'

@Injectable()
export class ConstructionDimensionService {
  constructor(
    private injector: Injector,
    private parameterService: ParameterService
  ) {
  }

  static calcCords(
    profile: ProfilMasse,
    componentWidth: number,
    componentHeight: number,
    constructionComponent: ConstructionComponent,
    components: ConstructionComponent[],
    construction?: SimpleConstruction
  ): ComponentCoordinateConfig {
    const sheet: componentSizeConfig = {
      outside: {
        width: null,
        height: null
      },
      inside: {
        width: null,
        height: null
      }
    }
    const body: componentSizeConfig = {
      outside: {
        width: null,
        height: null
      },
      inside: {
        width: null,
        height: null
      }
    }
    if (constructionComponent.objectType === ConstructionComponentType.Fanlight) {
      sheet.outside.width = componentWidth - (2 * profile.BlendrahmenAussenSeite)
      sheet.outside.height = componentHeight - profile.BlendrahmenAussenOben
      sheet.inside.width = componentWidth - (2 * profile.BlendrahmenInnenSeite)
      sheet.inside.height = componentHeight - profile.BlendrahmenInnenOben
    } else if (constructionComponent.objectType === ConstructionComponentType.SidePanel) {
      sheet.outside.width = componentWidth - profile.BlendrahmenAussenSeite
      sheet.outside.height = componentHeight - profile.BlendrahmenAussenOben - profile.BlendrahmenAussenUnten
      sheet.inside.width = componentWidth - profile.BlendrahmenInnenSeite
      sheet.inside.height = componentHeight - profile.BlendrahmenInnenOben - profile.BlendrahmenInnenUnten
    } else if (constructionComponent.objectType === ConstructionComponentType.Door) {
      sheet.outside.width = componentWidth - (2 * profile.BlendrahmenAussenSeite)
      sheet.outside.height = componentHeight - profile.BlendrahmenAussenOben
      sheet.inside.width = componentWidth - (2 * profile.BlendrahmenInnenSeite)
      sheet.inside.height = componentHeight - profile.BlendrahmenInnenOben
    }
    switch (construction ?? constructionComponent.konstruktion) {
      case SimpleConstruction.Einsatz:
        if (constructionComponent.objectType === ConstructionComponentType.Door) {
          body.outside.width = sheet.outside.width - (2 * profile.FluegelrahmenAussenSeite)
          body.outside.height = sheet.outside.height - profile.FluegelrahmenAussenOben - profile.FluegelrahmenAussenUnten
        } else {
          body.outside.width = sheet.outside.width
          body.outside.height = sheet.outside.height
        }
        break
      case SimpleConstruction.AufsatzEinseitig:
        body.outside.width = sheet.outside.width
        body.outside.height = sheet.outside.height
        break
      case SimpleConstruction.AufsatzBeidseitig:
        body.outside.width = sheet.outside.width
        body.outside.height = sheet.outside.height
        break
      default:
        console.error('Unknown SimpleConstruction: ', constructionComponent.konstruktion)
    }


    const doorsWithEinsatzExist: boolean = components.some((c: ConstructionComponent): boolean =>
      c.objectType === ConstructionComponentType.Door && c.konstruktion === SimpleConstruction.Einsatz
    )

    if (constructionComponent.objectType === ConstructionComponentType.SidePanel && doorsWithEinsatzExist) {
      body.outside.height = body.outside.height - profile.FluegelrahmenAussenOben
    }

    return {
      sheet: {
        inside: {
          startPoint: [0, 0],
          endPoint: [sheet.inside.width, sheet.inside.height]
        },
        outside: {
          startPoint: [0, 0],
          endPoint: [sheet.outside.width, sheet.outside.height]
        }
      },
      body: {
        startPoint: [profile.FluegelrahmenAussenSeite, profile.FluegelrahmenAussenUnten],
        endPoint: [profile.FluegelrahmenAussenSeite + body.outside.width, profile.FluegelrahmenAussenUnten + body.outside.height]
      }
    }
  }

  /**
   * TTK version
   * @param component
   * @param construction
   * @param profile
   */
  calculateConstructionDimensions(
    component: ConstructionComponent,
    construction?: number,
    profile?: Profile
  ): ConstructionDimensions
  /**
   * Xpert version
   * @param construction
   */
  calculateConstructionDimensions(
    construction?: number,
  ): ConstructionDimensions
  calculateConstructionDimensions(
    componentOrConstruction?: ConstructionComponent | number,
    construction?: number,
    profile?: Profile
  ): ConstructionDimensions {
    if (typeof this.parameterService.model?.bxa !== 'undefined') {
      const construct = typeof componentOrConstruction === 'number'
        ? componentOrConstruction
        : componentOrConstruction?.konstruktion ?? this.selectedComponent.konstruktion
      return ConstructionDimensions.fromParameterModel(this.parameterService.model, construct)
    }
    if (!(componentOrConstruction instanceof ConstructionComponent)) {
      throw new Error('Either a parameter model must be set or a component')
    }
    const self = this.constructor as typeof ConstructionDimensionService
    const component: ConstructionComponent = componentOrConstruction
    construction ??= component.konstruktion
    profile ??= this.configuratedDoor.profile
    const typedConstruction = isSimpleConstruction(construction) ? construction : SimpleConstruction.AufsatzEinseitig
    const componentProfilDimension =
      component instanceof Tuer
        ? profile.doorDimension
        : component instanceof Seitenteil
          ? profile.sidepanelDimension
          : profile.skylightDimension
    return ConstructionDimensions.fromParameterModel(
      self.getAbbreviatedCoordinatesFromConfig(
        self.calcCords(
          componentProfilDimension,
          component.breite,
          component.hoehe,
          component,
          this.configuratedDoor.Components,
          typedConstruction
        )
      ),
      construction
    )
  }

  static getAbbreviatedCoordinatesFromConfig(config: ComponentCoordinateConfig): AbbreviatedCoordinateData {
    return {
      sixa: config.sheet.inside.startPoint[0],
      siya: config.sheet.inside.startPoint[1],
      sixb: config.sheet.inside.endPoint[0],
      siyb: config.sheet.inside.endPoint[1],
      soxa: config.sheet.outside.startPoint[0],
      soya: config.sheet.outside.startPoint[1],
      soxb: config.sheet.outside.endPoint[0],
      soyb: config.sheet.outside.endPoint[1],
      bxa: config.body.startPoint[0],
      bya: config.body.startPoint[1],
      bxb: config.body.endPoint[0],
      byb: config.body.endPoint[1],
      /* vixa: config.view.inside.startPoint[0],
                        viya: config.view.inside.startPoint[1],
                        vixb: config.view.inside.endPoint[0],
                        viyb: config.view.inside.endPoint[1],
                        voxa: config.view.outside.startPoint[0],
                        voya: config.view.outside.startPoint[1],
                        voxb: config.view.outside.endPoint[0],
                        voyb: config.view.outside.endPoint[1],*/
    }
  }

  static getCoordinatesFromAbbreviatedData(abbreviated: AbbreviatedCoordinateData): ComponentCoordinateConfig {
    return {
      body: {
        startPoint: [abbreviated.bxa, abbreviated.bya],
        endPoint: [abbreviated.bxb, abbreviated.byb]
      },
      sheet: {
        inside: {
          startPoint: [abbreviated.sixa, abbreviated.siya],
          endPoint: [abbreviated.sixb, abbreviated.siyb]
        },
        outside: {
          startPoint: [abbreviated.soxa, abbreviated.soya],
          endPoint: [abbreviated.soxb, abbreviated.soyb]
        },
      },
      /* view: {
                          inside: {
                            startPoint: [abbreviated.vixa, abbreviated.viya],
                            endPoint: [abbreviated.vixb, abbreviated.viyb]
                          },
                          outside: {
                            startPoint: [abbreviated.voxa, abbreviated.voya],
                            endPoint: [abbreviated.voxb, abbreviated.voyb]
                          },
                        }*/
    }
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.injector.get(ConfiguratorConfigurationModel)?.configuratedDoor
  }

  get selectedComponent(): ConstructionComponent {
    return this.injector.get(ComponentSelectionService)?.selectedComponent
  }
}
