import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog'
import {TranslateService} from '../../translate'
import {DialogueModalComponent, DialogueModalData} from './dialogue-modal/dialogue-modal.component'
import {Inject, Injectable, INJECTOR, Injector} from '@angular/core'
import {MassblattModalComponent, MassblattModalData} from './massblatt-modal/massblatt-modal.component'
import {Konstruktion} from '../../class'
import {MasseModalComponent, MasseModalComponentData} from './masse-modal/masse-modal.component'
import {Tuer} from '../../classes/model/component/construction/tuer'
import {Seitenteil} from '../../classes/model/component/construction/seitenteil'
import {Oberlicht} from '../../classes/model/component/construction/oberlicht'
import {KonstruktionModalComponent} from './konstruktion-modal/konstruktion-modal.component'
import {KonstruktionsauswahlModalComponent} from './konstruktionsauswahl-modal/konstruktionsauswahl-modal.component'
import {ImageModalComponent, ImageModalData} from './image-modal/image-modal.component'
import {
  GlassModalComponent,
  GlassModalData,
  GlassModalMode,
  HasGlassOrMotiveChanged
} from './glass-modal/glass-modal.component'
import {MehrpreisModalComponent, MehrpreisModalData} from './mehrpreis-modal/mehrpreis-modal.component'
import {SprossenModalComponent, SprossenModalData} from './sprossen-modal/sprossen-modal.component'
import {Sprossen} from '../../classes/model/component/glassaufbau/sprossen'
import {NotesModalComponent, NotesModalData} from './notes-modal/notes-modal.component'
import {SupportModalComponent} from './support-modal/support-modal.component'
import {Zubehoer} from '../../classes/model/component/extras/zubehoer/zubehoer'
import {MehrpreisCategory} from '../../classes/model/component/extras/mehrpreis/fbsMehrpreis'
import {Massblatt} from '../../classes/model/component/extras/massblatt'
import {HausfrontModalComponent} from './hausfront-modal/hausfront-modal.component'
import {Hausfront} from '../../classes/model/component/hausfront/hausfront'
import {ShareModalComponent, ShareModalData} from './share-modal/share-modal.component'
import {ZubehoerAddonEntry} from '../../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import {LoadingModalComponent} from './loading-modal/loading-modal.component'
import {LoadConfigRequest, LoadDoorIdModalComponent} from './load-door-id-modal/load-door-id-modal.component'
import {UrlParameterModalComponent} from './url-parameter-modal/url-parameter-modal.component'
import {PrivacyModalComponent} from './privacy-modal/privacy-modal.component'
import {GlasaufbauPositionShort} from '../../types'
import {ConstructionChooserModalComponent} from './construction-chooser-modal/construction-chooser-modal.component'
import {SimpleConstruction} from '../../classes/model/component/other/construction'
import {ConstructionDimensions} from '../../classes/model/component/construction/construction-dimensions'
import {KalkulationModalComponent} from './kalkulation-modal/kalkulation-modal.component'

const TRANSLATION_KEY = {
  DEFAULT_MILIEU_TITLE: 'ModalService.DefaultHausfront.Title',
  DEFAULT_MILIEU_MESSAGE: 'ModalService.DefaultHausfront.Message',
  DEFAULT_MILIEU_YES: 'ModalService.DefaultHausfront.Yes',
  DEFAULT_MILIEU_NO: 'ModalService.DefaultHausfront.No',
  CONFIGURATOR_RESET_TITLE: 'ModalService.ConfiguratorReset.Title',
  CONFIGURATOR_RESET_MESSAGE: 'ModalService.ConfiguratorReset.Message',
  CONFIGURATOR_RESET_YES: 'ModalService.ConfiguratorReset.Yes',
  CONFIGURATOR_RESET_NO: 'ModalService.ConfiguratorReset.No',
  DINCHANGED_TITLE: 'ModalService.DinChanged.Title',
  DINCHANGED_MESSAGE: 'ModalService.DinChanged.Message',
  DINCHANGED_YES: 'ModalService.DinChanged.Yes',
  DINCHANGED_NO: 'ModalService.DinChanged.No',
  MAT_SYSTEM_CHANGED_TITLE: 'ModalService.MatSystemChanged.Title',
  MAT_SYSTEM_CHANGED_MESSAGE: 'ModalService.MatSystemChanged.Message',
  MAT_SYSTEM_CHANGED_YES: 'ModalService.MatSystemChanged.Yes',
  MAT_SYSTEM_CHANGED_NO: 'ModalService.MatSystemChanged.No',
  MAT_FILLING_CHANGED_TITLE: 'ModalService.MatFillingChanged.Title',
  MAT_FILLING_CHANGED_MESSAGE: 'ModalService.MatFillingChanged.Message',
  MAT_FILLING_CHANGED_YES: 'ModalService.MatFillingChanged.Yes',
  MAT_FILLING_CHANGED_NO: 'ModalService.MatFillingChanged.No',
  SHOWIMAGE_CLOSE: 'ModalService.ShowImage.Close',
  MEASURESCHANGED_TITLE: 'ModalService.MeasuresChanged.Title',
  MEASURESCHANGED_MESSAGE: 'ModalService.MeasuresChanged.Message',
  MEASURESCHANGED_YES: 'ModalService.MeasuresChanged.Yes',
  MEASURESCHANGED_NO: 'ModalService.MeasuresChanged.No',
  CHOOSE_MAT_TITLE: 'ModalService.ChooseMaterial.Title',
  CHOOSE_MAT_MESSAGE: 'ModalService.ChooseMaterial.Message',
  CHOOSE_MAT_KU: 'ModalService.ChooseMaterial.Ku',
  CHOOSE_MAT_ALU: 'ModalService.ChooseMaterial.Alu'
}

@Injectable()
export class ModalService {
  constructor(
    @Inject(INJECTOR) private injector: Injector,
    private translate: TranslateService,
    private matModalService: MatDialog
  ) {
  }

  showChooseDefaultHausfrontModal(): MatDialogRef<DialogueModalComponent, boolean> {
    return this.showDialogueModal({
      title: this.translate.translate(TRANSLATION_KEY.DEFAULT_MILIEU_TITLE),
      message: this.translate.translate(TRANSLATION_KEY.DEFAULT_MILIEU_MESSAGE),
      nostring: this.translate.translate(TRANSLATION_KEY.DEFAULT_MILIEU_NO),
      yesstring: this.translate.translate(TRANSLATION_KEY.DEFAULT_MILIEU_YES)
    })
  }

  showConfiguratorResetModal(): MatDialogRef<DialogueModalComponent, boolean> {
    return this.showDialogueModal({
      title: this.translate.translate(TRANSLATION_KEY.CONFIGURATOR_RESET_TITLE),
      message: this.translate.translate(TRANSLATION_KEY.CONFIGURATOR_RESET_MESSAGE),
      nostring: this.translate.translate(TRANSLATION_KEY.CONFIGURATOR_RESET_NO),
      yesstring: this.translate.translate(TRANSLATION_KEY.CONFIGURATOR_RESET_YES)
    })
  }

  showConstructionChooserModal(): MatDialogRef<ConstructionChooserModalComponent, SimpleConstruction> {
    return this.matModalService.open<ConstructionChooserModalComponent, void, SimpleConstruction>(
      ConstructionChooserModalComponent,
      {
        injector: this.injector,
        width: '80vw',
        disableClose: true
      }
    )
  }

  private showDialogueModal(
    data: DialogueModalData,
    config?: MatDialogConfig<DialogueModalData>
  ): MatDialogRef<DialogueModalComponent, boolean> {
    return this.matModalService.open<DialogueModalComponent, DialogueModalData, boolean>(
      DialogueModalComponent,
      {
        injector: this.injector,
        width: '450px',
        data,
        ...config
      })
  }

  showDinChangedModal(): MatDialogRef<DialogueModalComponent, boolean> {
    return this.showDialogueModal({
      title: this.translate.translate(TRANSLATION_KEY.DINCHANGED_TITLE),
      message: this.translate.translate(TRANSLATION_KEY.DINCHANGED_MESSAGE),
      nostring: this.translate.translate(TRANSLATION_KEY.DINCHANGED_NO),
      yesstring: this.translate.translate(TRANSLATION_KEY.DINCHANGED_YES)
    })
  }

  showFuellungMaterialChangeModal(): MatDialogRef<DialogueModalComponent, boolean> {
    return this.showDialogueModal({
      title: this.translate.translate(TRANSLATION_KEY.MAT_FILLING_CHANGED_TITLE),
      message: this.translate.translate(TRANSLATION_KEY.MAT_FILLING_CHANGED_MESSAGE),
      nostring: this.translate.translate(TRANSLATION_KEY.MAT_FILLING_CHANGED_NO),
      yesstring: this.translate.translate(TRANSLATION_KEY.MAT_FILLING_CHANGED_YES)
    })
  }

  showGlassModal(position: GlasaufbauPositionShort, mode: GlassModalMode): MatDialogRef<GlassModalComponent, HasGlassOrMotiveChanged> {
    return this.matModalService.open<GlassModalComponent, GlassModalData, HasGlassOrMotiveChanged>(
      GlassModalComponent,
      {
        injector: this.injector,
        width: '80vw',
        data: {
          mode,
          position
        }
      })
  }

  showHausfrontModal(hausfront?: Hausfront): MatDialogRef<HausfrontModalComponent> {
    return this.matModalService.open<HausfrontModalComponent, Hausfront>(
      HausfrontModalComponent,
      {
        injector: this.injector,
        width: '90vw',
        // height: '90vh',
        data: hausfront
      })
  }

  showImageModal(title: string, url: string, config?: MatDialogConfig<ImageModalData>): MatDialogRef<ImageModalComponent, void> {
    return this.matModalService.open<ImageModalComponent, ImageModalData, void>(ImageModalComponent, {
      injector: this.injector,
      data: {
        title,
        imageUrl: url,
        nostring: this.translate.translate(TRANSLATION_KEY.SHOWIMAGE_CLOSE)
      },
      ...config
    })
  }

  showInitialLoadingModal(config?: MatDialogConfig<string>): MatDialogRef<LoadingModalComponent, void> {
    return this.matModalService.open<LoadingModalComponent, string, void>(LoadingModalComponent, {
      autoFocus: true,
      backdropClass: 'loading-backdrop',
      closeOnNavigation: false,
      delayFocusTrap: false,
      disableClose: true,
      hasBackdrop: true,
      injector: this.injector,
      ...config
    })
  }

  showKalkulationModal(): MatDialogRef<KalkulationModalComponent, void> {
    return this.matModalService.open<KalkulationModalComponent, void, void>(KalkulationModalComponent, {
      injector: this.injector
    })
  }

  showKonstruktionModal(konstruktion: Konstruktion): MatDialogRef<KonstruktionModalComponent, void> {
    return this.matModalService.open<KonstruktionModalComponent, Konstruktion, void>(KonstruktionModalComponent, {
      injector: this.injector,
      data: konstruktion
    })
  }

  showKonstruktionsauswahlModal(): MatDialogRef<KonstruktionsauswahlModalComponent, void> {
    return this.matModalService.open<KonstruktionsauswahlModalComponent, never, void>(KonstruktionsauswahlModalComponent, {
      injector: this.injector
    })
  }

  showLoadDoorIdModal(): MatDialogRef<LoadDoorIdModalComponent, LoadConfigRequest> {
    return this.matModalService.open(LoadDoorIdModalComponent, {
      injector: this.injector
    })
  }

  showMassblattModal(massblaetter: Massblatt[], addon: ZubehoerAddonEntry): MatDialogRef<MassblattModalComponent, Massblatt> {
    return this.matModalService.open<MassblattModalComponent, MassblattModalData, Massblatt>(MassblattModalComponent, {
      injector: this.injector,
      data: {
        addon,
        massblaetter
      }
    })
  }

  showMasseChangeModal(): MatDialogRef<DialogueModalComponent, boolean> {
    return this.showDialogueModal({
      title: this.translate.translate(TRANSLATION_KEY.MEASURESCHANGED_TITLE),
      message: this.translate.translate(TRANSLATION_KEY.MEASURESCHANGED_MESSAGE),
      nostring: this.translate.translate(TRANSLATION_KEY.MEASURESCHANGED_NO),
      yesstring: this.translate.translate(TRANSLATION_KEY.MEASURESCHANGED_YES)
    }, {
      width: '450px'
    })
  }

  showMasseModal(mode: 'display'): MatDialogRef<MasseModalComponent, void>
  showMasseModal(mode: 'input', construction: SimpleConstruction): MatDialogRef<MasseModalComponent, ConstructionDimensions>
  showMasseModal(
    mode: 'display' | 'input',
    construction?: SimpleConstruction
  ): MatDialogRef<MasseModalComponent, ConstructionDimensions | void> {
    return this.matModalService.open<MasseModalComponent, MasseModalComponentData, ConstructionDimensions>(MasseModalComponent, {
      injector: this.injector,
      data: {
        mode,
        construction
      },
      disableClose: mode === 'input'
    })
  }

  showMaterialChangedModal(modalConfig?: MatDialogConfig<DialogueModalData>): MatDialogRef<DialogueModalComponent, boolean> {
    return this.showDialogueModal({
      title: this.translate.translate(TRANSLATION_KEY.MAT_SYSTEM_CHANGED_TITLE),
      message: this.translate.translate(TRANSLATION_KEY.MAT_SYSTEM_CHANGED_MESSAGE),
      nostring: this.translate.translate(TRANSLATION_KEY.MAT_SYSTEM_CHANGED_NO),
      yesstring: this.translate.translate(TRANSLATION_KEY.MAT_SYSTEM_CHANGED_YES)
    }, modalConfig)
  }

  showMaterialChoiceModal(): MatDialogRef<DialogueModalComponent, boolean> {
    return this.showDialogueModal({
      title: this.translate.translate(TRANSLATION_KEY.CHOOSE_MAT_TITLE),
      message: this.translate.translate(TRANSLATION_KEY.CHOOSE_MAT_MESSAGE),
      nostring: this.translate.translate(TRANSLATION_KEY.CHOOSE_MAT_ALU),
      yesstring: this.translate.translate(TRANSLATION_KEY.CHOOSE_MAT_KU)
    })
  }

  showMehrpreisModal(category: MehrpreisCategory): MatDialogRef<MehrpreisModalComponent, Zubehoer | undefined> {
    return this.matModalService.open<MehrpreisModalComponent, MehrpreisModalData, Zubehoer | undefined>(
      MehrpreisModalComponent,
      {
        injector: this.injector,
        minWidth: '80vw',
        maxWidth: '80vw',
        data: {
          category
        }
      }
    )
  }

  showNotesModal(notes: string): MatDialogRef<NotesModalComponent, string> {
    return this.matModalService.open<NotesModalComponent, NotesModalData, string>(NotesModalComponent, {
      injector: this.injector,
      data: notes
    })
  }

  showPrivacyModal(privacyUrl: string): MatDialogRef<PrivacyModalComponent, false | undefined> {
    return this.matModalService.open<PrivacyModalComponent, string, false | undefined>(PrivacyModalComponent, {
      injector: this.injector,
      data: privacyUrl
    })
  }

  showShareModal(data: ShareModalData): MatDialogRef<ShareModalComponent, void> {
    return this.matModalService.open<ShareModalComponent, ShareModalData, void>(
      ShareModalComponent,
      {
        injector: this.injector,
        width: '80vw',
        data
      })
  }

  showSprossenModal(element: Tuer | Seitenteil | Oberlicht): MatDialogRef<SprossenModalComponent, Sprossen | null | false> {
    return this.matModalService.open<SprossenModalComponent, SprossenModalData, Sprossen | null | false>(
      SprossenModalComponent,
      {
        injector: this.injector,
        width: '80vw',
        data: {
          element
        }
      }
    )
  }

  showSupportModal(): MatDialogRef<SupportModalComponent, void> {
    return this.matModalService.open<SupportModalComponent, never, void>(SupportModalComponent, {
      injector: this.injector
    })
  }

  showUrlParameterModal(): MatDialogRef<UrlParameterModalComponent> {
    return this.matModalService.open(UrlParameterModalComponent)
  }
}
