import {ModelConfiguration} from './modelConfiguration'

import {Material} from '../material'

export class InitialModelConfiguration implements ModelConfiguration {
  din: string
  konstruktion: number
  material: Material
  tuertyp: string


  constructor(din: string = 'L', material: Material = Material.Alu, tuertyp: string = 'HT', konstruktion: number = 0) {
    this.din = din
    this.material = material
    this.tuertyp = tuertyp
    this.konstruktion = konstruktion
  }
}
