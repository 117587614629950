import {EventEmitter} from '@angular/core'
import {Observable, Observer, PartialObserver, Subscription} from 'rxjs'
import {ThenableSubscriber} from './thenable-subscriber'

// Should be considered Promise<unknown> | unknown at runtime
type SecretlyAPromise = void

/**
 * This is a dirty hack. It relies heavily on implementation details of rxjs as well as parts of the non-public api.
 * May break with any rxjs update.
 * Will probably NOT work with any `Observer` that has more than a few simple functions. Therefore, things like
 * rxjs pipe or operators will most likely not work.
 */
export default class ThenableEventEmitter<T> extends EventEmitter<T> {
  emit(value: T): Promise<void> {
    return this.next(value)
  }
  emitThenable(value: T): Promise<void> {
    return this.next(value)
  }
  next(value: T): Promise<void> {
    return !this.closed
      ? Promise.all(this.observers.map((observer: Observer<T>): SecretlyAPromise => observer.next(value)))
        .then((): void => void 0)
      : new Promise((resolve): void => {
        super.next(value)
        resolve()
      })
  }

  subscribe(
    observerOrNext?: PartialObserver<T> | ((value: T) => SecretlyAPromise | Promise<unknown>),
    error?: (error: unknown) => void,
    complete?: () => void
  ): Subscription {
    const subscriber = new ThenableSubscriber({
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      next: (value?: T): SecretlyAPromise | Promise<unknown> =>
        typeof observerOrNext === 'function' ? observerOrNext(value) : observerOrNext?.next(value),
      error: (err?: unknown): void => { if (error) {error(err)} },
      complete: (): void  => { if (complete) {complete()} }
    })
    return unboundObservableSubscribe.call(this, subscriber) as ReturnType<Observable<T>['subscribe']>
  }
}

const unboundObservableSubscribe:
<T>(this: ThenableEventEmitter<T>, observer: Observer<T>) => ReturnType<Observable<T>['subscribe']>
  // eslint-disable-next-line @typescript-eslint/unbound-method
  = (new Observable()).subscribe
