import {Component, Input} from '@angular/core'
import {Catalog} from '../../../class'
import {CatalogueService} from '../../../classes/service/catalogueService'
import {NGXLogger} from 'ngx-logger'
import {ModelMenuComponent} from '../model-menu/model-menu.component'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'

const TRANSLATION_KEY = {
  TITLE: 'CatalogMenuComponent.Title'
}

@Component({
  selector: 'configurator-catalogue-menu',
  styleUrls: ['./catalogue-menu.component.scss'],
  templateUrl: './catalogue-menu.component.html'
})
export class CatalogueMenuComponent {
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  // @ViewChild(BaseMenuComponent) private baseMenu: BaseMenuComponent
  @Input() lazyImageLoaded: (object: unknown) => void
  @Input() modelMenu: ModelMenuComponent

  constructor(
    private _catalogueService: CatalogueService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private logger: NGXLogger,
    protected readonly navigatorService: NavigatorService
  ) {
    this.configuratorConfigurationModel.newConfiguratedDoorInitialized.subscribe({
      next: (): void => {
        const model = this.configuratorConfigurationModel.selectedComponent.model
        const catalogue = this.catalogueService.catalogues
          .find((c: Catalog): boolean => c.KatalogId === model.KatalogId)
        if (catalogue) {
          this.onSelectCatalogInternal(catalogue)
        }
      }
    })
  }

  onSearchInCatalogs(): void {
    this.logger.trace('onsearchInCatalogs')
    this.navigatorService.activateMenu(NavigationMenuEntryKey.Model)
    this.modelMenu.focusModelSearchInput()
  }

  onSelectCatalog(catalog: Catalog): void {
    this.logger.trace('onSelectCatalog')
    this.modelMenu.modelTerm = ''
    if (!catalog) {
      return
    }
    this._catalogueService.selectedCatalog = catalog.KatalogId
    this.navigatorService.activateMenu(NavigationMenuEntryKey.Model)
    this.modelMenu.selectedCatalogue = this._catalogueService.selectedCatalog
  }

  onSelectCatalogInternal(catalog: Catalog): void {
    this._catalogueService.selectedCatalog = catalog.KatalogId
    this.modelMenu.selectedCatalogue = this._catalogueService.selectedCatalog
  }

  get catalogueService(): CatalogueService {
    return this._catalogueService
  }

  get selectedCatalogue(): number | null {
    return this._catalogueService.selectedCatalog
  }
}
