import {Component, ElementRef, HostListener, Input} from '@angular/core'

const TRANSLATION_KEY = {
  CONFIGURATOR_FRONTOPTIONS_FULLSCREEN_ZOOMIN: 'FullscreenComponent.ZoomIn',
  CONFIGURATOR_FRONTOPTIONS_FULLSCREEN_ZOOMOUT: 'FullscreenComponent.ZoomOut'
} as const

@Component({
  selector: 'configurator-fullscreen-toggle, [configurator-fullscreen-toggle]',
  templateUrl: './fullscreen-toggle.component.html',
  styleUrls: ['./fullscreen-toggle.component.scss']
})
export class FullscreenToggleComponent {
  protected readonly document = document
  @Input({required: true, alias: 'configurator-fullscreen-toggle'}) fullscreenElement: Element
  @Input({required: true}) isMobile: boolean

  protected readonly TRANSLATION_KEY = TRANSLATION_KEY

  constructor(hostElement: ElementRef<HTMLElement>) {
    if (!document?.fullscreenEnabled) {
      hostElement.nativeElement?.style?.setProperty('display', 'none', 'important')
    }
  }



  @HostListener('click')
  onAction(): void {
    if (document?.fullscreenElement) {
      void document?.exitFullscreen()
    } else {
      void this.fullscreenElement?.requestFullscreen()
    }

    setTimeout((): void => {
      window.dispatchEvent(new Event('resize'))
    }, 100)
  }
}
