import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

export type PrivacyModalData = string
const TRANSLATION_KEY = {
  TITLE: 'PrivacyModal.Title',
  CLOSE: 'PrivacyModal.Close',
  MORE_INFO: 'PrivacyModal.MoreInfo'
} as const

@Component({
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss']
})
export class PrivacyModalComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY

  constructor(
    @Inject(MAT_DIALOG_DATA) public privacyUrl: PrivacyModalData,
    public dialogRef: MatDialogRef<PrivacyModalComponent, string>
  ) {
  }

  onDismiss(): void {
    this.dialogRef.close()
  }
}
