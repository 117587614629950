import {ConstructionComponent, ConstructionComponentFactory} from './constructionComponent'
import {GlasaufbauFactory} from '../glassaufbau/glasaufbau'
import {Injectable} from '@angular/core'
import {ConstructionComponentType} from './construction-component-type.enum'

export class Seitenteil extends ConstructionComponent {
  constructor(data: Partial<ConstructionComponent>, glasaufbauFactory: GlasaufbauFactory) {
    super(data, glasaufbauFactory)
    this._objectType = ConstructionComponentType.SidePanel
  }
}

@Injectable()
export class SeitenteilFactory extends ConstructionComponentFactory<Seitenteil> {
  constructor(glasaufbauFactory: GlasaufbauFactory) {
    super(glasaufbauFactory, Seitenteil)
  }
}


