import {FactoryUtil} from './util/factoryUtil'
import {
  ConfiguratedDeckschicht,
  ConfiguratedDienstleistung,
  ConfiguratedFluegelrahmen,
  ConfiguratedMassblatt,
  ConfiguratedMehrpreis,
  ConfiguratedOption,
  Konstruktion
} from '../class'
import {Din, InsideOutsideArray, InsideOutsideObject} from '../types'
import {Fluegelrahmen} from './model/component/frame/fluegelrahmen'
import {Paket} from './model/component/other/paket'
import {isMaterial, Material} from './model/material'
import {AdhesiveSystem} from './api/model/adhesive/adhesive-system.interface'
import {ModelConfigurationGlasaufbau} from './api/model/configuration/glass/model-configuration-glasaufbau.interface'
import {ConstructionDimensions} from './model/component/construction/construction-dimensions'
import {ModelConfigurationComponent} from './api/model/configuration/component/model-configuration-component.interface'
import {ModelConfigurationSubassembly} from './api/model/configuration/addons/model-configuration-subassembly.interface'
import {ModelMehrpreis} from './api/model/addons/model-mehrpreis.interface'
import {ModelDienstleistung} from './api/model/addons/model-dienstleistung.interface'
import {ModelDienstleistungMassblatt} from './api/model/addons/model-dienstleistung-massblatt.interface'

const DEFAULT_KLEBESYSTEM_ID = 2

export class ConfigurationComponent {
  Breite: number
  Deckschichten: InsideOutsideArray<Partial<ConfiguratedDeckschicht>> = {
    Inside: [],
    Outside: [],
  }
  Din: Din
  Fluegelrahmen: InsideOutsideArray<ConfiguratedFluegelrahmen> = {
    Inside: [],
    Outside: [],
  }
  // Blendrahmen: null | InsideOutsideObject<ConfiguratedBlendrahmen>
  Glasaufbau: ModelConfigurationGlasaufbau
  Hoehe: number
  Hoehenverteilung: number
  KatalogId: number
  Klebeset: boolean = false
  Klebesystem: number
  Konstruktion: number
  KonstruktionsMasse: ConstructionDimensions
  KonstruktionsVariante: number
  Material: Material
  Mehrpreise: ConfiguratedMehrpreis[] = []
  ModelId: number
  Oeffnungsart: string
  Optionen: InsideOutsideArray<ConfiguratedOption> = {
    Inside: [],
    Outside: [],
  }
  Querfries: number
  // Rahmen: any
  Schallschutz: undefined | number
  Sicherheit: undefined | number
  Staerke: number
  Typ: string
  Waermeschutz: undefined | number
  Zmass: number

  constructor(data: Partial<ConfigurationComponent | ModelConfigurationComponent>) {
    this.Breite = FactoryUtil.getNumberFromProperty(data, 'Breite')
    this.Deckschichten = {
      Inside: data?.Deckschichten?.Inside
        ?.map(({
          Id,
          HasBeschichtung,
          Beschichtung,
          Bezeichnung,
          IsLackMoeglich,
          IsPulverMoeglich,
          IsFolieMoeglich,
          IsDesignOberflaecheMoeglich,
          BeschichtungAnpassen,
          OberflaechenTyp
        }: ConfiguratedDeckschicht | ModelConfigurationSubassembly): Partial<ConfiguratedDeckschicht> => ({
          Id,
          Typ: 'fuellung',
          HasBeschichtung,
          Beschichtung,
          Bezeichnung,
          IsLackMoeglich,
          IsPulverMoeglich,
          IsFolieMoeglich,
          IsDesignOberflaecheMoeglich,
          BeschichtungAnpassen,
          OberflaechenTyp
        }))
        ?? [],
      Outside: data?.Deckschichten?.Outside
        ?.map(({Id,
          HasBeschichtung,
          Beschichtung,
          Bezeichnung,
          IsLackMoeglich,
          IsPulverMoeglich,
          IsFolieMoeglich,
          IsDesignOberflaecheMoeglich,
          BeschichtungAnpassen,
          OberflaechenTyp
        }: ConfiguratedDeckschicht | ModelConfigurationSubassembly): Partial<ConfiguratedDeckschicht> => ({
          Id,
          Typ: 'fuellung',
          HasBeschichtung,
          Beschichtung,
          Bezeichnung,
          IsLackMoeglich,
          IsPulverMoeglich,
          IsFolieMoeglich,
          IsDesignOberflaecheMoeglich,
          BeschichtungAnpassen,
          OberflaechenTyp
        }))
        ?? [],
    }
    this.Din = data?.Din
    this.Fluegelrahmen = {
      Inside: data?.Fluegelrahmen?.Inside
        ?.map(
          (
            {
              Id,
              HasBeschichtung,
              Beschichtung
            }: ConfiguratedFluegelrahmen | ModelConfigurationSubassembly
          ): ConfiguratedFluegelrahmen => ({
            Id,
            Typ: 'fluegelrahmen',
            HasBeschichtung,
            Beschichtung
          }))
        ?? [],
      Outside: data?.Fluegelrahmen?.Outside
        ?.map(
          (
            {
              Id,
              HasBeschichtung,
              Beschichtung
            }: ConfiguratedFluegelrahmen | ModelConfigurationSubassembly
          ): ConfiguratedFluegelrahmen => ({
            Id,
            Typ: 'fluegelrahmen',
            HasBeschichtung,
            Beschichtung
          }))
        ?? [],
    }
    this.Glasaufbau = data?.Glasaufbau
    this.Hoehe = FactoryUtil.getNumberFromProperty(data, 'Hoehe')
    this.Hoehenverteilung = data && data.Hoehenverteilung || 0
    this.KatalogId = data?.KatalogId
    this.Klebeset = data?.Klebeset
    this.Klebesystem = FactoryUtil.getNumberFromProperty(data, 'Klebesystem')
    this.Konstruktion = FactoryUtil.getNumberFromProperty(data, 'Konstruktion')
    this.KonstruktionsMasse = new ConstructionDimensions(data?.KonstruktionsMasse)
    this.KonstruktionsVariante = FactoryUtil.getNumberFromProperty(data, 'KonstruktionsVariante')
    this.Material = isMaterial(data?.Material) ? data.Material : Material.Alu
    this.Mehrpreise = data?.Mehrpreise?.map((m: ConfiguratedMehrpreis | ModelMehrpreis): ConfiguratedMehrpreis => ({
      Dienstleistungen: m.Dienstleistungen
        ?.map(({Id, Massblaetter, Typ}: ConfiguratedDienstleistung | ModelDienstleistung): ConfiguratedDienstleistung => ({
          Id,
          Massblaetter: Massblaetter?.map((b: ConfiguratedMassblatt | ModelDienstleistungMassblatt): ConfiguratedMassblatt => ({
            Id: b.Id,
            ScriptId: 'ScriptId' in b ? b.ScriptId : -1,
            Values: 'Values' in b ? b.Values : undefined
          })),
          Typ
        })),
      Typ: m.Typ,
      Id: m.Id,
      HasDienstleistungen: m.Dienstleistungen?.length > 0,
      HasZubehoer: 'Zubehoer' in m && m.Zubehoer?.length > 0,
      Zubehoer: 'Zubehoer' in m && m.Zubehoer?.length > 0 ? m.Zubehoer : [],
      ScriptId: -1
    }))
    this.ModelId = FactoryUtil.getNumberFromProperty(data, 'ModelId')
    this.Oeffnungsart = data?.Oeffnungsart
    this.Optionen = {
      Inside: data?.Optionen?.Inside
        ?.map((
          {Beschichtung, HasBeschichtung, Id, Typ}: ConfiguratedOption | ModelConfigurationSubassembly
        ): ConfiguratedOption => ({Beschichtung, HasBeschichtung, Id, Typ})),
      Outside: data?.Optionen?.Outside
        ?.map((
          {Beschichtung, HasBeschichtung, Id, Typ}: ConfiguratedOption | ModelConfigurationSubassembly
        ): ConfiguratedOption => ({Beschichtung, HasBeschichtung, Id, Typ})),
    }
    this.Querfries = data && data.Querfries || 0
    this.Schallschutz = data?.Schallschutz
    this.Sicherheit = data?.Sicherheit
    this.Staerke = FactoryUtil.getNumberFromProperty(data, 'Staerke')
    this.Typ = data?.Typ
    this.Waermeschutz = data?.Waermeschutz // FactoryUtil.getNumberFromProperty(data, 'Waermeschutz')
    this.Zmass = FactoryUtil.getNumberFromProperty(data, 'Zmass')
  }

  resolveFluegelrahmen(fluegelrahmenStorage: Fluegelrahmen[]): InsideOutsideObject<null | Fluegelrahmen> {
    return {
      Inside: this.Fluegelrahmen
        ? fluegelrahmenStorage.find((f): boolean => f.IsInnen && f.Id === this.Fluegelrahmen.Inside?.[0].Id) ?? null
        : fluegelrahmenStorage.find((f): boolean => f.IsInnen),
      Outside: this.Fluegelrahmen
        ? fluegelrahmenStorage.find((f): boolean => f.IsAussen && f.Id === this.Fluegelrahmen.Outside?.[0].Id) ?? null
        : fluegelrahmenStorage.find((f): boolean => f.IsAussen),
    }
  }

  resolveKlebesystem(klebesystemStorage: AdhesiveSystem[], defaultKlebesystem: null | number | AdhesiveSystem = null): AdhesiveSystem {
    const klebesystem = klebesystemStorage.find((k): boolean => k.Id === this.Klebesystem)
    if (klebesystem) {
      return klebesystem
    } else {
      if (defaultKlebesystem === null) {
        defaultKlebesystem = DEFAULT_KLEBESYSTEM_ID
      }
      if (typeof defaultKlebesystem === 'number') {
        return klebesystemStorage.find((k): boolean => k.Id === defaultKlebesystem)
      } else {
        return defaultKlebesystem
      }
    }
  }

  resolveKonstruktionsVariante(
    konstruktionsStorage: Konstruktion[],
    defaultKonstruktion: null | number | Konstruktion = null
  ): Konstruktion {
    const konstruktion = konstruktionsStorage.find((k): boolean => k.Id === this.KonstruktionsVariante)
    if (konstruktion) {
      return konstruktion
    } else {
      if (defaultKonstruktion === null) {
        // return konstruktionsStorage.find(() => true)
        return null
      } else if (defaultKonstruktion instanceof Konstruktion) {
        return defaultKonstruktion
      } else {
        return konstruktionsStorage.find((k): boolean => k.Id === defaultKonstruktion)
      }
    }
  }

  private resolvePaket(paketId: number, paketStorage: Paket[], defaultPaket: null | number | Paket = null): null | Paket {
    const paket = paketStorage.find((k): boolean => k.Id === paketId)
    if (paket) {
      return paket
    } else {
      if (defaultPaket === null) {
        return null
      } else if (defaultPaket instanceof Paket) {
        return defaultPaket
      } else {
        return paketStorage.find((k): boolean => k.Id === defaultPaket)
      }
    }
  }

  resolveSchallschutz(paketStorage: Paket[], defaultPaket: null | number | Paket = null): null | Paket {
    return this.resolvePaket(this.Schallschutz, paketStorage, defaultPaket)
  }

  resolveSicherheit(paketStorage: Paket[], defaultPaket: null | number | Paket = null): null | Paket {
    return this.resolvePaket(this.Sicherheit, paketStorage, defaultPaket)
  }

  resolveWaermeschutz(paketStorage: Paket[], defaultPaket: null | number | Paket = null): null | Paket {
    return this.resolvePaket(this.Waermeschutz, paketStorage, defaultPaket)
  }
}
