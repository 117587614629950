import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

export type NotesModalData = string
const TRANSLATION_KEY = {
  MODAL_TITLE: 'NotesModal.Title',
  DISMISS: 'NotesModal.Dismiss',
  SAVE: 'NotesModal.Save'
} as const

@Component({
  templateUrl: './notes-modal.component.html',
  styleUrls: ['./notes-modal.component.scss']
})
export class NotesModalComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  public notes: NotesModalData

  constructor(@Inject(MAT_DIALOG_DATA) notes: NotesModalData) {
    this.notes = notes ?? ''
  }
}
