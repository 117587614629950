import {Component, EventEmitter, HostListener, isDevMode, OnInit, Output, ViewChild} from '@angular/core'
import {HausfrontDoorPosition} from '../class'
import {HttpService} from '../http.service'
import {TranslateService} from '../translate'
import {environment} from '../../environments/environment'
import {ComponentModel} from '../classes/model/component/model/component-model'
import {ConstructionComponent} from '../classes/model/component/construction/constructionComponent'
import * as constEnumModule from '../types'
import {ConfiguratorMode, Din, SideType} from '../types'
import {NGXLogger} from 'ngx-logger'
import {ConfiguratedDoor} from '../classes/model/configuratedDoor'
import {CatalogueService} from '../classes/service/catalogueService'
import {ModelMenuComponent} from './menu/model-menu/model-menu.component'
import {StringUtil} from '../classes/util/stringUtil'
import {Settings} from '../classes/service/settings/settings'
import {ParameterService} from '../classes/service/parameter/parameter.service'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'
import {FBS_MODEL_PROVIDER_TOKEN} from '../classes/model/dataProvider/fbsModel/fbsModel.type'
import {ParameterModelConfiguration} from '../classes/model/modelConfiguration/parameterModelConfiguration'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {ModalService} from './modal/modal.service'
import TrackingService from '../classes/service/tracking.service'
import {BetterMatRadioChange} from '../batter-mat-radio-group.directive'
import {DinUtil} from '../classes/util/dinUtil'
import {ToastrService} from 'ngx-toastr'
import {GrundformService} from '../classes/service/grundform.service'
import {ProfileService} from '../classes/service/profile.service'
import {HausfrontMenuComponent} from './menu/hausfront-menu/hausfront-menu.component'
import {ConfiguratorModeService} from '../classes/service/configuratorMode.service'
import {ConfiguratedDoorDto} from '../classes/api/configuratedDoor.dto'
import {isMaterial, Material} from '../classes/model/material'
import {ShareAPIPolyfillOptions} from 'share-api-polyfill'
import {ComponentSelectionService} from '../classes/service/componentSelectionService'
import {WorkflowService} from '../workflow/workflow.service'
import {WorkflowSubscriber} from '../workflow/workflow.types'
import {ViewService} from '../classes/service/view.service'
import {Parameter} from '../classes/service/parameter/parameter'
import * as ConstructionComponentTypeEnumModule
  from '../classes/model/component/construction/construction-component-type.enum'
import {
  ConstructionComponentType,
  isConstructionComponentType
} from '../classes/model/component/construction/construction-component-type.enum'
import {FbsModelTyp} from '../classes/model/component/model/component-model-type.enum'
import {SettingsService} from '../classes/service/settings/settings.service'
import MemoListService from '../classes/service/memo-list/memo-list.service'
import {LoadRequestIdentifier} from './components/load-configuration-button/load-configuration-button.component'
import {CookieConsentService} from '../cookie-consent'
import {
  SpacerThicknessExceededError,
  SpacerThicknessUndercutError
} from '../classes/model/component/glassaufbau/spacer-thickness.error'
import {ConfiguratorInitializerService, LoadModule, LoadService} from './load'
import {ColorsMenuComponent} from './menu/colors-menu/colors-menu.component'
import {MehrpreisMenuComponent} from './menu/mehrpreis-menu/mehrpreis-menu.component'
import {NavigatorService} from '../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../classes/service/navigation/navigation-menu-entry'
import {EventBusService} from '../classes/service/eventBus/eventBus'
import {EventBusBase} from '../classes/service/eventBus/eventBusBase'
import {CompactComponentModel} from '../classes/api/model/compact-component-model.interface'
import {GrundformTupel} from '../classes/model/component/other/grundform'
import {BusEvent} from '../classes/service/eventBus/eventTypes'


const TRANSLATION_KEY = {
  CONFIGURATOR_FRONTOPTIONS_ADDMEMO: 'ConfiguratorComponent.FrontOptions.AddMemo',
  CONFIGURATOR_FRONTOPTIONS_CHARACTERISTICS: 'ConfiguratorComponent.FrontOptions.Charasteristics',
  CONFIGURATOR_FRONTOPTIONS_MULTIVISION: 'ConfiguratorComponent.FrontOptions.Multivision',
  CONFIGURATOR_FRONTOPTIONS_PRICE: 'ConfiguratorComponent.FrontOptions.Price',
  TOAST_ERROR_CONFIGURATION_NOT_FOUND_TITLE: 'ConfiguratorComponent.Toast.Error.ConfigurationNotFound.Title',
  TOAST_ERROR_CONFIGURATION_NOT_FOUND_MESSAGE: 'ConfiguratorComponent.Toast.Error.ConfigurationNotFound.Message',
  TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_TITLE: 'ConfiguratorComponent.Toast.Error.ConfigurationLoadUnknown.Title',
  TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_MESSAGE: 'ConfiguratorComponent.Toast.Error.ConfigurationLoadUnknown.Message',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Title',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Message.Prefix',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Message.Suffix',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Title',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Message.Prefix',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Message.Suffix',
  TOAST_ERROR_GLASS_SPACER_UNKNOWN_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUnknown.Title',
  TOAST_ERROR_GLASS_SPACER_UNKNOWN_MESSAGE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUnknown.Message',
  TOAST_ERROR_LOADING_MODEL_MESSAGE: 'ConfiguratorComponent.Toast.Error.LoadingModel.Message',
  TOAST_ERROR_LOADING_MODEL_TITLE: 'ConfiguratorComponent.Toast.Error.LoadingModel.Title',
  TOAST_WARNING_ZVALUE_TOO_LARGE_TITLE: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Title',
  TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Message.Prefix',
  TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Message.Suffix',
  TOAST_WARNING_ZVALUE_TOO_SMALL_TITLE: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Title',
  TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Message.Prefix',
  TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Message.Suffix',
  LOADING_PLEASE_WAIT: 'ConfiguratorComponent.Loading.PleaseWait',

  VIEW_OUTSIDE: 'ConfiguratorComponent.View.Outside',
  VIEW_INSIDE: 'ConfiguratorComponent.View.Inside',
  DOWNLOAD_DOOR_IMAGE: 'ConfiguratorComponent.Door.DownloadImage',
  HINT_MEASURES_NOT_OBLIGING: 'ConfiguratorComponent.Hint.MeasuresNotObliging',
  FOOTER_ITEMS_VERSION: 'ConfiguratorComponent.Footer.Version',

  BUTTON_VIEWSWITCH_OUTSIDE: 'ConfiguratorComponent.Button.ViewSwitch.Outside',
  BUTTON_VIEWSWITCH_INSIDE: 'ConfiguratorComponent.Button.ViewSwitch.Inside',

  HEADER_TTK_SETTINGS: 'ConfiguratorComponent.Header.TTK.Settings',
} as const

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss'],
  providers: [
    CatalogueService,
    TrackingService,
    {provide: FBS_MODEL_PROVIDER_TOKEN, useExisting: ConfiguratorDataModel},
    ...LoadModule.provide()
  ]
})
export class ConfiguratorComponent extends EventBusBase implements OnInit {
  protected ConfiguratorMode = ConfiguratorMode
  protected readonly ConstructionComponentType: typeof ConstructionComponentType =
    (ConstructionComponentTypeEnumModule as {
      ConstructionComponentType: Required<typeof ConstructionComponentType>
    })?.ConstructionComponentType
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly SideType: typeof SideType = (constEnumModule as {
    SideType: Required<typeof SideType>
  })?.SideType
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @ViewChild(ColorsMenuComponent) protected colorsMenuComponent: ColorsMenuComponent
  protected doorPosition: HausfrontDoorPosition | null = null
  @ViewChild(HausfrontMenuComponent) protected hausfrontMenuComponent: HausfrontMenuComponent
  protected readonly isDevMode = isDevMode
  protected isMobile = false
  // if the current device is mobile
  protected loading = true
  @ViewChild(MehrpreisMenuComponent) protected mehrpreisMenuComponent: MehrpreisMenuComponent
  @ViewChild(ModelMenuComponent) protected modelMenuComponent: ModelMenuComponent
  @Output() readonly ngInit: EventEmitter<void>
  protected result_inside = ''
  protected result_outside = ''
  private screenHeight = 0
  private screenWidth = 0
  protected showCharacteristics: boolean = false
  public showNextToggle = ''
  protected version: string = environment.version

  constructor(
    private httpService: HttpService,
    private _translate: TranslateService,
    private toastrService: ToastrService,
    private logger: NGXLogger,
    protected modalService: ModalService,
    private catalogueService: CatalogueService,
    private grundformService: GrundformService,
    private viewService: ViewService,
    private _configuratorModeService: ConfiguratorModeService,
    protected _componentSelection: ComponentSelectionService,
    private profileService: ProfileService,
    protected navigatorService: NavigatorService,
    private parameterService: ParameterService,
    private trackingService: TrackingService,
    private _configuratorDataModel: ConfiguratorDataModel,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private workflowService: WorkflowService,
    private settingsService: SettingsService,
    protected readonly memoListService: MemoListService,
    protected cookieConsentService: CookieConsentService,
    private readonly configuratorInitializeHelper: ConfiguratorInitializerService,
    private readonly loadService: LoadService,
    eventBus: EventBusService
  ) {
    super(eventBus)
    this.logger.trace('ConfiguratorComponent::constructor')
    this.isMobile = window.innerWidth <= 767
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight
    this.ngInit = new EventEmitter<void>()
    if (this.configuratorMode === ConfiguratorMode.FBS) {
      this.cookieConsentService.showConsentBanner = false
      // this.cookieConsentService.addConsent('necessary')
      // this.cookieConsentService.addConsent('tracking')
    }
    this.configuratorConfigurationModel.zMassChanged.subscribe(
      (): void => this.onZmassChange()
    )
    this.configuratorConfigurationModel.colorChanged.subscribe(() => void this.getCompleteDoor())

    this.configuratorConfigurationModel.afterZubehoerChanged.subscribe(() => void this.getCompleteDoor())
    this.configuratorConfigurationModel.securityPackageChange.subscribe(() => void this.getCompleteDoor())
  }

  changeDoorView(view: SideType): void {
    this.changeDoorViewInternal(view)
    void this.getCompleteDoor()
  }

  changeDoorViewInternal(view: SideType): void {
    this.logger.trace('changeDoorViewInternal')
    // reverse the door and seitenteil array - as they have to be mirrored
    if (this.view !== view) {
      this.configuratedDoor.Components.reverse()
    }
    this.configuratedDoor.innenansicht = view !== SideType.Outside
    this.view = view
  }

  changeMaterialTuersystem(changeEvent: BetterMatRadioChange): void {
    this.modalService.showConfiguratorResetModal()
      .afterClosed()
      .subscribe((result): void => {
        if (result && isMaterial(changeEvent.value)) {
          this.configuratorConfigurationModel.material = changeEvent.value
        } else {
          changeEvent.cancel()
        }
      })
  }

  changeView(newSide: SideType): void {
    if (this.view === newSide) {
      return
    }
    this.view = newSide
    void this.onViewChange()
  }

  clearFbsKonstruktion(): boolean {
    return delete this._componentSelection.selectedComponent.fbsKonstruktion
  }

  // checks if all configuratedDoor elements' models have been loaded
  configuratedModelsLoaded(): ComponentModel {
    return this._componentSelection.selectedComponent && this._componentSelection.selectedComponent.model
  }

  generateElement(): ConfiguratedDoorDto {
    return this.configuratorConfigurationModel.configuratedDoorDto
  }

  // collect current configuration and post it to the backend
  getCompleteDoor(): Promise<void> {
    this.logger.trace('getCompleteDoor')
    let resolve: () => void
    let reject: (reason?: unknown) => void
    const promise: Promise<void> = new Promise<void>((resolve1, reject1): void => {
      resolve = resolve1
      reject = reject1
    }).catch((error): Promise<void> => {
      this.result_outside = ''
      this.result_inside = ''
      return Promise.reject(error)
    })
    let elem: ConfiguratedDoorDto
    try {
      elem = this.generateElement()
    } catch (error) {
      this.logger.error(error)
      reject(error)
    }
    if (elem) {
      this.loading = true
      this.httpService.postImagesV2(elem).subscribe({
        next: (result): void => {
          this.logger.trace('downloadDoor')
          this.result_inside = result.Images?.Innen ?? ''
          this.result_outside = result.Images?.Aussen ?? ''
          this.configuratedDoor.Images = {
            Inside: result.Images?.Innen ?? '',
            Outside: result.Images?.Aussen ?? ''
          }
          this.loading = false
          if (result.TuerId !== '') {
            this.parameterService.setTuerId(result.TuerId)
          }
        }, error: (error): void => {
          this.logger.error(error)
          this.loading = false
          reject(error)
        }, complete: (): void => {
          this.hausfrontMenuComponent?.updateDoorPosition()
          resolve()
          // this.setDoorPosition();
          // this.resetDownload();
        }
      })
    }
    return promise
  }

  getDeckschichtStaerken(): Promise<void> {
    return this.configuratorConfigurationModel.updateDeckschichtStaerke()
  }

  getDin(): Din | undefined {
    return this._componentSelection.selectedComponent.dinfuellung as Din || DinUtil.fromString(this.configuratedDoor?.Grundform?.DinElement)
  }

  getModel(
    modelId: string,
    componentType: ConstructionComponentType,
    material?: Material | null,
    loadRequestIdentifier?: LoadRequestIdentifier
  ): Promise<ComponentModel> {
    this.logger.trace('getModel')
    this.loading = true
    return this.configuratorDataModel
      .loadModel(
        modelId,
        componentType,
        material ?? this._componentSelection.selectedComponent.material ?? this.modell.material ?? Material.Kunststoff,
        this.parameter.profil ?? this.configuratedDoor.profile.Beschreibung,
        this._componentSelection.selectedComponent.konstruktion,
        this.modell,
        this._componentSelection.selectedComponent.oeffnungsart,
        this.getDin()
            ?? (this._componentSelection.selectedComponent.oeffnungsart === 'innen'
              ? DinUtil.fromString(this.modell.din)
              : DinUtil.fromString(this.modell.din) as number === 0 ? 1 : 0),
        loadRequestIdentifier
      )
  }

  hideAndReloadConsent(): void {
    this.cookieConsentService.showConsentBanner = false
    window.location.reload()
  }

  lazyImageLoaded(object: {
    loaded?: boolean
  }): void {
    object.loaded = true
  }

  async loadComponentByIndex(loadRequestIdentifier: LoadRequestIdentifier): Promise<void> {
    return this.loadService.loadComponentOfDoorFromLoadedConfig(loadRequestIdentifier)
  }

  async loadDoorById(doorId: string): Promise<void> {
    this.loading = true
    try {
      await this.loadService.loadDoorById(doorId)
      await this.getCompleteDoor()
    } finally {
      this.loading = false
    }
  }

  ngOnInit(): void {
    this.logger.trace('ngOnInit')
    this.loading = true
    this.ngInit.emit()
    window.ttc = this
    this.subscribeToEvents()
    const loadingModal = this._configuratorModeService.mode === ConfiguratorMode.TTK
      ? this.modalService.showInitialLoadingModal()
      : undefined
    loadingModal?.componentInstance.setMessage('Lade Einstellungen...')
    const workflowSubscriber: WorkflowSubscriber[] = []
    loadingModal?.componentInstance.setMessage('Lade Kataloge und Modelle...')
    const preloading = [
      this._configuratorDataModel.preload(),
      this.catalogueService.load(),
      this.grundformService.load(),
      this.profileService.load(),
      this.workflowService.load().then((subs: WorkflowSubscriber[]): void => {
        workflowSubscriber.push(...subs)
      }),
      this.configuratorInitializeHelper.loadConfig(),
      this.configuratorInitializeHelper.construction()
    ]

    void Promise.all(preloading)
      .then((): Promise<void> => this.configuratorInitializeHelper.resetConfiguratedDoor())
      .then((): void => workflowSubscriber.forEach((sub): void =>
        void sub.subscribe(this.configuratorConfigurationModel, this._configuratorDataModel, this.parameterService, this.toastrService))
      ).then((): Promise<void> | void =>
        this.configuratorInitializeHelper.initialLoadDoor()
          .then((): Promise<void> => this.getCompleteDoor())
          .catch(async (error): Promise<void> => {
            this.logger.error(error)
            this.toastrService.error(
              this._translate.translate(
                TRANSLATION_KEY.TOAST_ERROR_LOADING_MODEL_MESSAGE
              ),
              this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_LOADING_MODEL_TITLE)
            )
            await this.resetConfiguratedDoor()
          })
      ).catch((error): void => {
        this.logger.error(error)
      })
      .then((): void => {
        this.loading = false
      })
      .then(() => void this.trackingService.track())
      .then(() => void loadingModal?.close())
      .then(() => void this.ngInit.complete())
  }

  onGrundformChange(): void {
    void this.loadService.loadModelsAfterGrundformChange()
    this.selectFirstDoor()
  }

  onMaterialChange(materialChange: BetterMatRadioChange): void {
    // event.preventDefault()
    const oldValue = materialChange.previous
    const newValue = materialChange.value as unknown
    if (newValue === oldValue || !isMaterial(newValue)) {
      return
    }
    this.modalService.showFuellungMaterialChangeModal()
      .afterClosed()
      .subscribe((result): void => {
        if (result) {
          this.selectedComponent.material = newValue
          this.selectedComponent.optionen = {Inside: [], Outside: []}
          void this.onSelectModel(
            this.selectedComponent.model.Id,
            this.selectedComponent.objectType,
            newValue,
          ).then((): void =>
            this.configuratorConfigurationModel.fillingMaterialChange.emit(this.selectedComponent.material)
          )
        } else {
          materialChange.cancel()
        }
      })
  }

  onOptionChange(): void {
    void this.getDeckschichtStaerken()
      .then((): void => {
        void this.getCompleteDoor()
      })
  }

  @HostListener('window:resize')
  onResize(): void {
    this.logger.trace('onResize')
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight
    this.isMobile = this.screenWidth <= 767
    if (this.configuratedDoor?.Hausfronten) {
      this.hausfrontMenuComponent?.updateDoorPosition()
    }
  }

  async onSelectModel(
    model_id: string | number,
    compTypeParam: ConstructionComponentType | FbsModelTyp,
    material?: Material,
  ): Promise<void> {
    try {
      let componentType: ConstructionComponentType
      if (!compTypeParam) {
        componentType = undefined
      } else if (!isConstructionComponentType(compTypeParam)) {
        switch (compTypeParam) {
          case FbsModelTyp.tuer:
            componentType = ConstructionComponentType.Door
            break
        }
      } else {
        componentType = compTypeParam
      }
      material ??= this.configuratorConfigurationModel.material
      this.loading = true
      await this.loadService.setComponentModelById(String(model_id), componentType, material, this.selectedComponent)
      await this.getCompleteDoor()
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.showModelHints()
      }
      this.colorsMenuComponent.resetColorSelectedItem()
    } catch (err) {
      this.logger.error(err)
    }
  }

  async onViewChange(): Promise<void> {
    if (this.configuratorMode === ConfiguratorMode.TTK) {
      await this.getCompleteDoor().catch((err): void => this.logger.error(err))
    }
  }

  onZmassChange(): void {
    if (this.selectedComponent.Zmass < this.configuratedDoor.MinZMass) {
      this.selectedComponent.Zmass = this.configuratedDoor.MinZMass
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.toastrService.warning(
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_PREFIX)
            + this.configuratedDoor.MinZMass
            + this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_SUFFIX),
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_TITLE)
        )
      }
    }
    if (this.selectedComponent.Zmass > this.configuratedDoor.MaxZMass) {
      this.selectedComponent.Zmass = this.configuratedDoor.MaxZMass
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.toastrService.warning(
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_PREFIX)
            + this.configuratedDoor.MaxZMass
            + this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_SUFFIX),
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_TITLE)
        )
      }
    }
    this.updateStaerkeValues()
    // TODO: Do not set glasaufbau at all, if it is empty
    if (
      this.selectedComponent.glasaufbau
        && this.selectedComponent.glasaufbau.Id !== 0
    ) {
      this.selectedComponent.glasaufbau.calcGlasStaerke()
      if (this._configuratorModeService.mode === ConfiguratorMode.FBS && this.selectedComponent.glasaufbau.errors.length > 0) {
        this.selectedComponent.glasaufbau.errors.forEach((err): void => {
          let title: string
          let message: string
          if (err instanceof SpacerThicknessUndercutError) {
            title = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNDERCUT_TITLE)
            message = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_PREFIX)
                + String(err.requiredSpacerThickness)
                + this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_SUFFIX)
          } else if (err instanceof SpacerThicknessExceededError) {
            title = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_EXCEEDED_TITLE)
            message = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_PREFIX)
                + String(err.requiredSpacerThickness)
                + this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_SUFFIX)
          } else {
            title = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNKNOWN_TITLE)
            message = this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_GLASS_SPACER_UNKNOWN_MESSAGE)
          }
          this.toastrService.error(message, title)
        })
      }
    }
  }

  postMultivision(): void {
    this.loading = true
    this.httpService.postMultivision(this.parameterService.parameter.tuerId, this.viewService.currentSide).subscribe({
      next: (): void => {
        this.loading = false
      },
      error: (error): void => {
        this.logger.error(error)
        this.loading = false
      }
    })
  }

  recalcMobileHeight(): string {
    const contentWrapper = document.getElementById('image_background')
    const sn = document.querySelector('.subnav')
    if (contentWrapper && sn) {
      const snh = this.screenHeight - contentWrapper.offsetHeight - 57 + 'px'
      if (sn instanceof HTMLElement) {
        sn.style.height = snh
      }
      return snh
    }
    return null
  }

  async resetConfiguratedDoor(material?: Material): Promise<void> {
    this.doorPosition = null
    this.result_inside = ''
    this.result_outside = ''
    await this.loadService.resetConfiguratedDoor(material)
    this.loading = false
  }

  selectFirstDoor(): void {
    if (this._componentSelection.selectedComponent._objectType !== ConstructionComponentType.Door) {
      this._componentSelection.selectedComponent = this.configuratedDoor.Components
        .filter((c: ConstructionComponent): boolean => c._objectType === ConstructionComponentType.Door)?.[0]?.Index
    }
  }

  setShowNextToggle(showNext: string): void {
    this.logger.trace('setShowNextToggle')
    if (this.showNextToggle === showNext) {
      this.showNextToggle = ''
    } else {
      this.showNextToggle = showNext
    }
    if (showNext === 'calculateFbs') {
      // this.showNextToggle = showNext
      this.modalService.showKalkulationModal()
    }
  }

  shareDialog(): void {
    const myNavigator: Navigator = window.navigator
    const data = {
      url: window.location.href,
      text:
          'Traumtür erstellt mit dem Rodenberg Traumtürkonfigurator (Referenzcode: ' +
          this.parameter.tuerId +
          ') ', // + (imageURL ? imageURL : ''),
      title:
          'Rodenberg Traumtürkonfigurator (Referenzcode: ' +
          this.parameter.tuerId +
          ')'
    }
    const options: ShareAPIPolyfillOptions = {
      // change configurations to hide specific unnecessary icons
      copy: true,
      email: true,
      print: true,
      sms: true,
      messenger: true,
      facebook: true,
      whatsapp: true,
      twitter: true,
      telegram: true,
      skype: false,
      linkedin: false,
      language: 'de' // specify the default language
    }
    if (myNavigator && myNavigator.share) {
      myNavigator
        .share(data, options)
        .catch((err): void => {
          this.logger.error(err)
        })
    }
  }

  showModelHints(): void {
    this.configuratedDoor.Components.forEach((c: ConstructionComponent): void => {
      if (c.model?.Hinweise?.length > 0) {
        const hinweise = c.model?.Hinweise.join('<br>')
        this.toastrService.info(hinweise, 'Hinweis', {enableHtml: true})
      }
    })
  }

  subscribeToEvents(): void {
    this.addSubscription(
      this.eventBus.on<string>(BusEvent.Render).subscribe((): void => {
        void this.getCompleteDoor()
      })
    )
    this.addSubscription(
      this.eventBus.on<string>(BusEvent.LoadRequest).subscribe((doorId: string): void => {
        void this.loadDoorById(doorId)
      })
    )
    this.addSubscription(
      this.eventBus.on<string>(BusEvent.SearchRequest).subscribe((searchTerm: string): void => {
        this.modelMenuComponent.modelTerm = searchTerm
        this.navigatorService.activateMenu(NavigationMenuEntryKey.Model)
      })
    )
    this.addSubscription(
      this.eventBus.on<HausfrontDoorPosition>(BusEvent.DoorPositionChange).subscribe((doorPosition: HausfrontDoorPosition): void => {
        this.doorPosition = doorPosition
      })
    )
    this.addSubscription(
      this.eventBus.on<CompactComponentModel>(BusEvent.SelectModel).subscribe((model: CompactComponentModel): void => {
        void this.onSelectModel(model.Id, model.Typ)
      })
    )
    this.addSubscription(
      this.eventBus.on<string>(BusEvent.GlasAufbauChange).subscribe((): void => {
        void this.getCompleteDoor()
      })
    )
    this.addSubscription(
      this.eventBus.on<SideType>(BusEvent.ChangeView).subscribe((view: SideType): void => {
        this.changeView(view)
      })
    )
    this.addSubscription(
      this.eventBus.on<void>(BusEvent.OptionChange).subscribe((): void => {
        this.onOptionChange()
      })
    )
    this.addSubscription(
      this.eventBus.on<void>(BusEvent.CloseCharacteristics).subscribe((): void => {
        this.showCharacteristics = false
      })
    )
    this.addSubscription(
      this.eventBus.on<GrundformTupel>(BusEvent.GrundformChange).subscribe((): void => {
        void this.onGrundformChange()
      })
    )
  }

  updateOeffnungsart(): void {
    this.updateOeffnungsartInternal()
    void this.getCompleteDoor()
  }

  updateOeffnungsartInternal(): void {
    if (this._componentSelection.selectedComponent.oeffnungsart === 'innen') {
      this._componentSelection.selectedComponent.dinfuellung = this._componentSelection.selectedComponent.din
    } else {
      this._componentSelection.selectedComponent.dinfuellung = this._componentSelection.selectedComponent.din === 0 ? 1 : 0
    }
  }

  updateStaerkeValues(): void {
    this._componentSelection.selectedComponent.staerke =
        this._componentSelection.selectedComponent.Zmass
        + this.configuratedDoor.DeckschichtStaerken.StaerkeAussen
        + this.configuratedDoor.DeckschichtStaerken.StaerkeInnen
  }

  async updateZmassValues(): Promise<boolean> {
    return this.configuratorConfigurationModel.updateZmassValues()
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }

  get configuratorDataModel(): ConfiguratorDataModel {
    return this._configuratorDataModel
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }

  get modell(): ParameterModelConfiguration {
    return this.parameterService.model || {} as ParameterModelConfiguration
  }

  get parameter(): Parameter {
    return this.parameterService.parameter
  }

  get selectedComponent(): ConstructionComponent {
    return this._componentSelection.selectedComponent
  }

  get settings(): Settings {
    return this.settingsService.settings
  }

  get view(): SideType {
    return this.viewService.currentSide
  }

  set view(side: SideType) {
    this.viewService.view = side
  }

}

declare global {
  interface Window {
    ttc: ConfiguratorComponent
  }
}
