import {ResponseHausfront} from '../../../api/grundformen/response-hausfront.interface'

export class HausfrontRequest implements ResponseHausfront {
  ElementBreite: number
  ElementHoehe: number
  GrundformId: number
  Id: number
  IsAussen: boolean
  IsInnen: boolean
  OberlichtBreite: number
  OberlichtHoehe: number
  SeitenteilBreite: number
  SeitenteilHoehe: number
  TuerBreite: number
  TuerHoehe: number
  X1: number
  X2: number
  X3: number
  X4: number
  Y1: number
  Y2: number
  Y3: number
  Y4: number

  constructor(data?: Partial<HausfrontRequest>) {
    this.Id = data && data.Id
    this.GrundformId = data && data.GrundformId
    this.IsAussen = data && data.IsAussen
    this.IsInnen = data && data.IsInnen
    this.ElementBreite = data && Math.round(data.ElementBreite)
    this.ElementHoehe = data && Math.round(data.ElementHoehe)
    this.TuerBreite = data && Math.round(data.TuerBreite)
    this.TuerHoehe = data && Math.round(data.TuerHoehe)
    this.SeitenteilBreite = data && Math.round(data.SeitenteilBreite)
    this.SeitenteilHoehe = data && Math.round(data.SeitenteilHoehe)
    this.OberlichtBreite = data && Math.round(data.OberlichtBreite)
    this.OberlichtHoehe = data && Math.round(data.OberlichtHoehe)
    this.X1 = data && Math.round(data.X1)
    this.X2 = data && Math.round(data.X2)
    this.X3 = data && Math.round(data.X3)
    this.X4 = data && Math.round(data.X4)
    this.Y1 = data && Math.round(data.Y1)
    this.Y2 = data && Math.round(data.Y2)
    this.Y3 = data && Math.round(data.Y3)
    this.Y4 = data && Math.round(data.Y4)
  }
}
