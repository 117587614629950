<div [class.hidden]="!this.show" id="cookieConsentDiv">
    <div class="consentContent">
        <h2>{{ TRANSLATION_KEY.COOKIE_CONSENT_MODAL_TITLE | translate }}</h2>
        <div>
            <div class="consentString">
                {{
                    TRANSLATION_KEY.COOKIE_CONSENT_MODAL_TEXT | translate
                }}
            </div>
        </div>
        <div>
            <div class="consentConfig">
                <div class="consentToggles">
                    <div class="cookieToggleGroup">
                        <span>{{ TRANSLATION_KEY.COOKIE_CONSENT_MODAL_NECESSARY_COOKIES | translate }}</span>
                        <div class="slideCheckbox"
                             [attr.data-off-string]=" TRANSLATION_KEY.COOKIE_CONSENT_MODAL_COOKIES_ON | translate"
                             [attr.data-on-string]=" TRANSLATION_KEY.COOKIE_CONSENT_MODAL_COOKIES_OFF | translate"
                        >
                            <input disabled type="checkbox" value="1" id="cookie_necessary" checked/>
                            <label class="disabled" for="cookie_necessary"></label>
                        </div>
                    </div>
                    <div class="cookieToggleGroup">
                        <span>{{TRANSLATION_KEY.COOKIE_CONSENT_MODAL_TRACKING | translate}}</span>
                        <div class="slideCheckbox"
                             [attr.data-off-string]="TRANSLATION_KEY.COOKIE_CONSENT_MODAL_COOKIES_OFF | translate"
                             [attr.data-on-string]="TRANSLATION_KEY.COOKIE_CONSENT_MODAL_COOKIES_ON | translate"
                        >
                            <input type="checkbox" id="cookie_external_fonts" [(ngModel)]="consentData.tracking" />
                            <label for="cookie_external_fonts"></label>
                        </div>
                    </div>
                </div>
                <button class="closeButton active" (click)="this.close()">
                    {{ TRANSLATION_KEY.COOKIE_CONSENT_MODAL_CLOSE_AND_SAVE | translate }}
                </button>
            </div>
        </div>


        <div class="links">
            <a routerLink="/privacy" target="_blank">{{ TRANSLATION_KEY.COOKIE_CONSENT_MODAL_PRIVACY | translate }}</a>
            -
            <a href="{{this.imprintUrl}}" target="_blank">{{ TRANSLATION_KEY.COOKIE_CONSENT_MODAL_IMPRINT | translate }}</a>
        </div>
    </div>
</div>

