import {
  ConstructionComponentType
} from '../../../../classes/model/component/construction/construction-component-type.enum'

type FakeComponent = {
  Index: number
  objectType: ConstructionComponentType
}
export class FakeConfiguratorConfigurationModelService {
  private objectTypes: ConstructionComponentType[]

  get configuratedDoor(): { Components: FakeComponent[] } {
    return {
      Components: this.objectTypes.map((objectType, Index): FakeComponent => ({ objectType, Index }))
    }
  }

  set types(types: ConstructionComponentType[]) {
    this.objectTypes = types
  }
}
