import {ColorBase} from './colorBase'
import {ModelConfigurationCoating} from '../../api/model/configuration/coating/model-configuration-coating.interface'
import {ColorType} from '../../../types'

export class Beschichtung {
  Bezeichnung: string
  Fixpunkt: number
  Guid: string
  Id: number
  Layer: string
  RGB: string
  Typ: ColorType

  constructor(data?: Partial<Beschichtung> | ModelConfigurationCoating) {
    this.Id = data?.Id
    this.Typ = data?.Typ

    if (
      typeof data === 'object'
      && (
        'Bezeichnung' in data
        || 'Fixpunkt' in data
        || 'Guid' in data
        || 'Layer' in data
        || 'RGB' in data
      )
    ) {
      this.Bezeichnung = data?.Bezeichnung
      this.Fixpunkt = data?.Fixpunkt
      this.Guid = data?.Guid
      this.Layer = data?.Layer
      this.RGB = data?.RGB
    }
  }

  colorize(color: ColorBase): void {
    this.Typ = color.getType()
    this.Id = color.Id
    this.Bezeichnung = color.Bezeichnung
    this.RGB = color && color.RGB
  }
}
