type UnknownMode = string

export const enum ParameterModes {
  New = 'N',
  Edit = 'E',
  Open = 'O',
}

export type ParameterMode = ParameterModes | UnknownMode

/*
export namespace ParameterModes {
  const isMode =  ():  void => {

  }
}
*/
