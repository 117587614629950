import {InsideOutsideArray, InsideOutsideObject} from './types'
import {Sprossen} from './classes/model/component/glassaufbau/sprossen'
import {ConfigurationComponent} from './classes/configurationComponent'
import {isMaterial, Material} from './classes/model/material'
import {
  ConstructionComponentType,
  isConstructionComponentType
} from './classes/model/component/construction/construction-component-type.enum'
import {ResponseKatalog} from './classes/api/kataloge/response-katalog.interface'
import {ResponseGlass} from './classes/api/glasses/response-glass.interface'
import {ResponseProfil} from './classes/api/profile/response-profil.interface'
import {ResponseProfilMasse} from './classes/api/profile/response-profil-masse.interface'
import {ResponseHausfront} from './classes/api/grundformen/response-hausfront.interface'
import {ModelConfigurationCoating} from './classes/api/model/configuration/coating/model-configuration-coating.interface'
import {ModelConfigurationSubassembly} from './classes/api/model/configuration/addons/model-configuration-subassembly.interface'
import {ModelDienstleistung} from './classes/api/model/addons/model-dienstleistung.interface'

export class Catalog implements ResponseKatalog {
  Beschreibung: string
  Bezeichnung: string
  Id: number
  ImageUrl: string
  KatalogId: number
  PreviewImageUrl: string
  loaded: boolean

  // Typ: string
  constructor(data?: ResponseKatalog) {
    this.Beschreibung = data && data.Beschreibung
    this.Bezeichnung = data && data.Bezeichnung
    this.Id = data && data.Id
    this.KatalogId = data && data.KatalogId
    this.ImageUrl = data && data.ImageUrl
    this.PreviewImageUrl = data && data.PreviewImageUrl
    // this.Typ = data && data.Typ
    this.loaded = false
  }
}

export class ConfiguratedSprossen {
  AnzahlFelder: number
  Art: string
  Beschichtung: ModelConfigurationCoating
  Id: number
  Typ: string

  constructor(data?: any) {
    this.Id = data && data.Id
    this.Art = data && data.Art
    this.Typ = data && data.Typ
    this.AnzahlFelder = data && data.AnzahlFelder
    this.Beschichtung = data && data.Beschichtung
  }

  public static fromSprossen(sprossen: Sprossen): ConfiguratedSprossen {
    const configuratedSprossen = new ConfiguratedSprossen()
    configuratedSprossen.Id = sprossen.Id
    configuratedSprossen.Art = sprossen.Art
    configuratedSprossen.Typ = sprossen.Typ
    configuratedSprossen.AnzahlFelder = sprossen.AnzahlFelder
    configuratedSprossen.Beschichtung = sprossen.Beschichtung
    return configuratedSprossen
  }
}

export interface ConfiguratedMassblatt {
  Id: number
  ScriptId: number
  Values: Record<string, number>
}

export interface ConfiguratedDienstleistung extends ModelDienstleistung {
  Id: number
  Massblaetter?: ConfiguratedMassblatt[]
  Typ: string
}

export interface ConfiguratedMehrpreis {
  Dienstleistungen: ConfiguratedDienstleistung[] | null
  HasDienstleistungen: boolean
  HasZubehoer: boolean
  Id: number
  ScriptId: number
  Typ: string
  Zubehoer: ConfiguratedZubehoer[] | null
}

export interface ConfiguratedOption extends ModelConfigurationSubassembly {
  Beschichtung: ModelConfigurationCoating
  HasBeschichtung: boolean
  Id: number
}

export interface ConfiguratedZubehoer {
  Id: number
  Typ: string
  Value?: string
}

export interface ConfiguratedBlendrahmen {
  Beschichtung: ModelConfigurationCoating
  HasBeschichtung: boolean
  Id: number
  Typ: 'blendrahmen'
}

export interface ConfiguratedDeckschicht extends ModelConfigurationSubassembly {
  Beschichtung: ModelConfigurationCoating
  BeschichtungAnpassen: boolean
  Bezeichnung: string
  HasBeschichtung: boolean
  Id: number
  IsDesignOberflaecheMoeglich: boolean
  IsFolieMoeglich: boolean
  IsLackMoeglich: boolean
  IsPulverMoeglich: boolean
  OberflaechenTyp: string
  Typ: string
}

export interface ConfiguratedFluegelrahmen {
  Beschichtung: ModelConfigurationCoating
  HasBeschichtung: boolean
  Id: number
  Typ: string
}

export class ConfiguratedElement {
  Aktion: string
  Bauform: string
  Bemerkungen: string
  Blendrahmen: InsideOutsideArray<ConfiguratedBlendrahmen>
  Breite: number
  Clerk: string
  Components: ConfigurationComponent[] = []
  CustomSize: boolean
  Din: number
  Hausfronten: InsideOutsideObject<Omit<ResponseHausfront, 'ElementX' | 'ElementY'> | null>
  Hoehe: number
  Images: InsideOutsideObject<string>
  Klebesystem: number
  Kommission: string
  MasseOffen: boolean
  Material: Material
  Pos: string
  ProfilId: number
  Seeklimatauglich: boolean
  SessionToken: string
  Software: string
  Staerke: number
  TransKey: string
  Werksmontage: boolean

  constructor(data?: Partial<ConfiguratedElement>) {
    this.Kommission = data && data.Kommission
    this.Bauform = data && data.Bauform
    this.Werksmontage = data && data.Werksmontage
    this.Seeklimatauglich = data && data.Seeklimatauglich
    this.CustomSize = data && data.CustomSize
    this.Breite = data && data.Breite
    this.Hoehe = data && data.Hoehe
    this.MasseOffen = data && data.MasseOffen
    this.Staerke = data && data.Staerke
    this.ProfilId = data && data.ProfilId
    this.Din = data && data.Din
    this.TransKey = data && data.TransKey
    this.Blendrahmen = data && data.Blendrahmen
    this.Material = data && data.Material
    this.Pos = data && data.Pos
    this.Klebesystem = data && data.Klebesystem
    this.Clerk = data && data.Clerk
    this.Images = data && data.Images
    this.SessionToken = data && data.SessionToken
    this.Software = data && data.Software
    this.Bemerkungen = data && data.Bemerkungen
    this.Aktion = data && data.Aktion
    this.Hausfronten = {
      Inside: data?.Hausfronten?.Inside ?? null,
      Outside: data?.Hausfronten?.Outside ?? null
    }
    data && data.Components && data.Components.forEach((c: ConfigurationComponent): void => {
      this.Components.push(new ConfigurationComponent(c))
    })
  }
}

export class FbsGlas implements ResponseGlass {
  Aktiv: boolean
  Beschreibung: string
  BleiglasId: string
  Bohrung: string
  Breite: number
  CreatedAt: string
  CreatedBy: string
  FarbglasId: string
  Fluegel: string
  GewichtProQM: number
  GlasscheibenId: string
  Guid: string
  Hoehe: number
  Id: number
  ImageUrl: string
  IsAussenMoeglich: boolean
  IsBearbeitungMoeglich: boolean
  IsBleiornamentikMoeglich: boolean
  IsBleiverglasungMoeglich: boolean
  IsESG: boolean
  IsFarbglas: boolean
  IsFolienklebungMoeglich: boolean
  IsInnenMoeglich: boolean
  IsMitteMoeglich: boolean
  IsOrnamentglas: boolean
  IsSandstrahlMoeglich: boolean
  IsSchliffornamentikMoeglich: boolean
  IsUVverklebungMoeglich: boolean
  IsVSG: boolean
  IsWSG: boolean
  IsWoelbungMoeglich: boolean
  Kante: string
  MaxBreite: number
  MaxHoehe: number
  MinBreite: number
  MinHoehe: number
  /** @deprecated Use Beschreibung instead */
  Name: string
  Nummer: string
  PreviewGuid: string
  PreviewImageUrl: string
  RefId: string
  RodenbergCode: string
  SprossenglasId: string
  Staerke: number
  Stempel: string
  Suchwort: string
  UpdatedAt: string
  UpdatedBy: string
  XxlglasId: string
  disabilityReasons: string[]
  loaded: boolean

  constructor(data?: ResponseGlass) {
    this.Aktiv = data && data.Aktiv
    this.Beschreibung = data && data.Beschreibung
    this.BleiglasId = data && data.BleiglasId
    this.Bohrung = data && data.Bohrung
    this.Breite = data && data.Breite
    this.CreatedAt = data && data.CreatedAt
    this.CreatedBy = data && data.CreatedBy
    this.FarbglasId = data && data.FarbglasId
    this.Fluegel = data && data.Fluegel
    this.GewichtProQM = data && data.GewichtProQM
    this.GlasscheibenId = data && data.GlasscheibenId
    this.Guid = data && data.Guid
    this.Hoehe = data && data.Hoehe
    this.Id = data && data.Id
    this.IsAussenMoeglich = data && data.IsAussenMoeglich
    this.IsBearbeitungMoeglich = data && data.IsBearbeitungMoeglich
    this.IsBleiornamentikMoeglich = data && data.IsBleiornamentikMoeglich
    this.IsBleiverglasungMoeglich = data && data.IsBleiverglasungMoeglich
    this.IsESG = data && data.IsESG
    this.IsFarbglas = data && data.IsFarbglas
    this.IsFolienklebungMoeglich = data && data.IsFolienklebungMoeglich
    this.IsInnenMoeglich = data && data.IsInnenMoeglich
    this.IsMitteMoeglich = data && data.IsMitteMoeglich
    this.IsOrnamentglas = data && data.IsOrnamentglas
    this.IsSandstrahlMoeglich = data && data.IsSandstrahlMoeglich
    this.IsSchliffornamentikMoeglich = data && data.IsSchliffornamentikMoeglich
    this.IsUVverklebungMoeglich = data && data.IsUVverklebungMoeglich
    this.IsVSG = data && data.IsVSG
    this.IsWSG = data && data.IsWSG
    this.IsWoelbungMoeglich = data && data.IsWoelbungMoeglich
    this.Kante = data && data.Kante
    this.MaxBreite = data && data.MaxBreite
    this.MaxHoehe = data && data.MaxHoehe
    this.MinBreite = data && data.MinBreite
    this.MinHoehe = data && data.MinHoehe
    this.Name = data && data.Name
    this.Nummer = data && data.Nummer
    this.PreviewGuid = data && data.PreviewGuid
    this.RefId = data && data.RefId
    this.RodenbergCode = data && data.RodenbergCode
    this.SprossenglasId = data && data.SprossenglasId
    this.Staerke = data && data.Staerke
    this.Stempel = data && data.Stempel
    this.Suchwort = data && data.Suchwort
    this.UpdatedAt = data && data.UpdatedAt
    this.UpdatedBy = data && data.UpdatedBy
    this.XxlglasId = data && data.XxlglasId
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.ImageUrl = data && data.ImageUrl
    this.disabilityReasons = []
    this.loaded = false
  }
}

export class Motiv {
  Aktiv: boolean
  Beschreibung: string
  Bezeichnung: string
  Created_At: string
  Created_By: string
  GrundglasId: number
  Guid: string
  Id: number
  ImageUrl: string
  IsAussenMoeglich: boolean
  IsBleiornamentik: boolean
  IsBleiverglasung: boolean
  IsDecorfacette: boolean
  IsEdelstahl: boolean
  IsFacettenschliff: boolean
  IsFolienverklebung: boolean
  IsFusing: boolean
  IsGelasert: boolean
  IsGewoelbt: boolean
  IsGrundglasVariabel: boolean
  IsInnenMoeglich: boolean
  IsIsoKlebetechnik: boolean
  IsLackiert: boolean
  IsMitteMoeglich: boolean
  IsSandstrahlung: boolean
  IsSchliffornamentik: boolean
  IsTiffanytechnik: boolean
  IsUVVerklebung: boolean
  MotivPosition2Fach: string
  MotivPosition3Fach: string
  MotivPosition4Fach: string
  Nummer: string
  Position: number
  PreviewGuid: string
  PreviewImageUrl: string
  RefId: string
  Suchwort: string
  Updated_At: string
  Updated_By: string
  disabilityReasons: string[]
  loaded: boolean

  constructor(data?: any) {
    this.Aktiv = data && data.Aktiv
    this.Beschreibung = data && data.Beschreibung
    this.Created_At = data && data.Created_At
    this.Created_By = data && data.Created_By
    this.GrundglasId = data && data.GrundglasId
    this.Id = data && data.Id
    this.IsAussenMoeglich = data && data.IsAussenMoeglich
    this.IsBleiornamentik = data && data.IsBleiornamentik
    this.IsBleiverglasung = data && data.IsBleiverglasung
    this.IsDecorfacette = data && data.IsDecorfacette
    this.IsEdelstahl = data && data.IsEdelstahl
    this.IsFacettenschliff = data && data.IsFacettenschliff
    this.IsFolienverklebung = data && data.IsFolienverklebung
    this.IsFusing = data && data.IsFusing
    this.IsGelasert = data && data.IsGelasert
    this.IsGewoelbt = data && data.IsGewoelbt
    this.IsGrundglasVariabel = data && data.IsGrundglasVariabel
    this.IsInnenMoeglich = data && data.IsInnenMoeglich
    this.IsIsoKlebetechnik = data && data.IsIsoKlebetechnik
    this.IsLackiert = data && data.IsLackiert
    this.IsMitteMoeglich = data && data.IsMitteMoeglich
    this.IsSandstrahlung = data && data.IsSandstrahlung
    this.IsSchliffornamentik = data && data.IsSchliffornamentik
    this.IsTiffanytechnik = data && data.IsTiffanytechnik
    this.IsUVVerklebung = data && data.IsUVVerklebung
    this.MotivPosition2Fach = data && data.MotivPosition2Fach
    this.MotivPosition3Fach = data && data.MotivPosition3Fach
    this.MotivPosition4Fach = data && data.MotivPosition4Fach
    this.Nummer = data && data.Nummer
    this.RefId = data && data.RefId
    this.Suchwort = data && data.Suchwort
    this.Updated_At = data && data.Updated_At
    this.Updated_By = data && data.Updated_By
    this.Bezeichnung = data && data.Bezeichnung
    this.Guid = data && data.Guid
    this.ImageUrl = data && data.ImageUrl
    this.PreviewGuid = data && data.PreviewGuid
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.Position = data && data.Position
    this.disabilityReasons = []
    this.loaded = false
  }
}

export interface FluegelrahmenColor extends ModelConfigurationSubassembly {
  Beschichtung: ModelConfigurationCoating
  Bezeichnung: string
  Id: number
  Typ: 'fluegelrahmen'
}

export class HausfrontDoorPosition {
  backgroundHeight: number
  backgroundWidth: number
  doorHeight: number
  doorOffset: number
  doorWidth: number
  x0: number
  x1: number
  x2: number
  x3: number
  y0: number
  y1: number
  y2: number
  y3: number

  constructor() {
    this.backgroundHeight = 0
    this.backgroundWidth = 0
    this.doorWidth = 0
    this.doorHeight = 0
    this.doorOffset = 0
    this.x0 = 0
    this.y0 = 0
    this.x1 = 0
    this.y1 = 0
    this.x2 = 0
    this.x3 = 0
    this.y3 = 0
  }

  get offsettedX0(): number {
    return this.doorOffset + this.x0
  }

  get offsettedX1(): number {
    return this.doorOffset + this.x1
  }

  get offsettedX2(): number {
    return this.doorOffset + this.x2
  }

  get offsettedX3(): number {
    return this.doorOffset + this.x3
  }
}

export class Favorite {
  hash: string
  status?: string
  thumb_url = ''
}

export class shareParameter {
  FromMail: string
  FromName: string
  Nachricht: string
  ToMail: string
  ToName: string
  TuerIds: string[]

  constructor(data: shareParameter) {
    this.FromName = data && data.FromName
    this.FromMail = data && data.FromMail
    this.ToName = data && data.ToName
    this.ToMail = data && data.ToMail
    this.Nachricht = data && data.Nachricht
    this.TuerIds = []
    data?.TuerIds.forEach((h: string): number => this.TuerIds.push(h))
  }
}

export class ProfilMasse {
  Aktiv: boolean
  BlendrahmenAussenOben: number
  BlendrahmenAussenSeite: number
  BlendrahmenAussenUnten: number
  BlendrahmenInnenOben: number
  BlendrahmenInnenSeite: number
  BlendrahmenInnenUnten: number
  FluegelrahmenAussenOben: number
  FluegelrahmenAussenSeite: number
  FluegelrahmenAussenUnten: number
  FluegelrahmenInnenOben: number
  FluegelrahmenInnenSeite: number
  FluegelrahmenInnenUnten: number
  Id: number
  MaxBreite: number
  MaxHoehe: number
  MinBreite: number
  MinHoehe: number
  Typ: ConstructionComponentType

  constructor(data?: ResponseProfilMasse) {
    this.Aktiv = data && data.Aktiv
    this.BlendrahmenAussenOben = data && data.BlendrahmenAussenOben
    this.BlendrahmenAussenSeite = data && data.BlendrahmenAussenSeite
    this.BlendrahmenAussenUnten = data && data.BlendrahmenAussenUnten
    this.BlendrahmenInnenOben = data && data.BlendrahmenInnenOben
    this.BlendrahmenInnenSeite = data && data.BlendrahmenInnenSeite
    this.BlendrahmenInnenUnten = data && data.BlendrahmenInnenUnten
    this.FluegelrahmenAussenOben = data && data.FluegelrahmenAussenOben
    this.FluegelrahmenAussenSeite = data && data.FluegelrahmenAussenSeite
    this.FluegelrahmenAussenUnten = data && data.FluegelrahmenAussenUnten
    this.FluegelrahmenInnenOben = data && data.FluegelrahmenInnenOben
    this.FluegelrahmenInnenSeite = data && data.FluegelrahmenInnenSeite
    this.FluegelrahmenInnenUnten = data && data.FluegelrahmenInnenUnten
    this.Id = data && data.Id
    this.MaxBreite = data && data.MaxBreite
    this.MaxHoehe = data && data.MaxHoehe
    this.MinBreite = data && data.MinBreite
    this.MinHoehe = data && data.MinHoehe
    this.Typ = isConstructionComponentType(data?.Typ) ? data.Typ : ConstructionComponentType.Door
  }
}

export class Profile implements ResponseProfil {
  Aktiv: boolean
  Beschreibung: string
  CanAufsatz: boolean
  CanEinsatz: boolean
  Id: number
  Masse: ProfilMasse[]
  Material: Material
  Name: string
  Standard: boolean
  ZMassAufsatzBeidseitig: number
  ZMassAufsatzEinseitig: number
  ZMassEinsatz: number

  constructor(data?: ResponseProfil) {
    this.Aktiv = data && data.Aktiv
    this.Beschreibung = data && data.Beschreibung
    this.Id = data?.Id ?? 0
    this.CanAufsatz = data && data.CanAufsatz
    this.CanEinsatz = data && data.CanEinsatz
    this.Material = isMaterial(data?.Material) ? data.Material : undefined
    this.Name = data && data.Name
    this.ZMassAufsatzBeidseitig = data && data.ZMassAufsatzBeidseitig
    this.ZMassAufsatzEinseitig = data && data.ZMassAufsatzEinseitig
    this.ZMassEinsatz = data && data.ZMassEinsatz
    this.Standard = data && data.Standard
    this.Masse = []
    data?.Masse.forEach(m => this.Masse.push(new ProfilMasse(m)))
  }

  get doorDimension(): ProfilMasse | undefined {
    return this.Masse.find(m => m.Typ === ConstructionComponentType.Door)
  }

  get sidepanelDimension(): ProfilMasse | undefined {
    return this.Masse.find(m => m.Typ === ConstructionComponentType.SidePanel)
  }

  get skylightDimension(): ProfilMasse | undefined {
    return this.Masse.find(m => m.Typ === ConstructionComponentType.Fanlight)
  }
}

export class Konstruktion {
  Beschreibung: string
  Bezeichnung: string
  Bild1Url: string
  Bild2Url: string
  Bild3Url: string
  Bild4Url: string
  Bild5Url: string
  Id: number
  ImageUrl: string
  IsAluMoeglich: boolean
  IsAufsatzBeidseitigMoeglich: boolean
  IsAufsatzEinseitigMoeglich: boolean
  IsAussenOeffnendMoeglich: boolean
  IsClipsystem: boolean
  IsGlasMoeglich: boolean
  IsInnenOeffnendMoeglich: boolean
  IsKunststoffMoeglich: boolean
  KlebesystemId: number
  PreviewImageUrl: string
  Typ: string

  constructor(data?: any) {
    this.Id = data && data.Id
    this.Bezeichnung = data && data.Bezeichnung
    this.Beschreibung = data && data.Beschreibung
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.ImageUrl = data && data.ImageUrl
    this.Typ = data && data.Typ
    this.IsAluMoeglich = data && data.IsAluMoeglich
    this.IsKunststoffMoeglich = data && data.IsKunststoffMoeglich
    this.IsGlasMoeglich = data && data.IsGlasMoeglich
    this.IsAufsatzEinseitigMoeglich = data && data.IsAufsatzEinseitigMoeglich
    this.IsAufsatzBeidseitigMoeglich = data && data.IsAufsatzBeidseitigMoeglich
    this.IsInnenOeffnendMoeglich = data && data.IsInnenOeffnendMoeglich
    this.IsAussenOeffnendMoeglich = data && data.IsAussenOeffnendMoeglich
    this.IsClipsystem = data && data.IsClipsystem
    this.KlebesystemId = data && data.KlebesystemId
    this.Bild1Url = data && data.Bild1Url
    this.Bild2Url = data && data.Bild2Url
    this.Bild3Url = data && data.Bild3Url
    this.Bild4Url = data && data.Bild4Url
    this.Bild5Url = data && data.Bild5Url
  }
}
