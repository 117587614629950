import TranslationKeys from './translation-keys'

export default {
  en: {
    [TranslationKeys.APP_TITLE]: 'Dream door configurator',
    [TranslationKeys.APP_LOADING]: 'Loading...',
  },
  de: {
    [TranslationKeys.APP_TITLE]: 'Traumtür-Konfigurator',
    [TranslationKeys.APP_LOADING]: 'Lade...',
  }
}
