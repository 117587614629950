import {Pipe, PipeTransform} from '@angular/core'
import {TranslateService} from './translate.service'

@Pipe({
  name: 'translate',
  pure: true
})
export class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(value: string): string {
    if (!value) {
      return
    }
    return this.translate.translate(value)
  }
}
