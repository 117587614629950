<div id='mehrpreisDiv'>
  <div (click)='onDismiss()' class='closeModal'><i (click)='onDismiss()' class='fa fa-times'></i></div>
  <h1>{{ TRANSLATION_KEY.TITLE | translate }}</h1>

  <mat-form-field appearance='fill'>
    <mat-label>{{ TRANSLATION_KEY.SEARCH|translate }}</mat-label>
    <input [(ngModel)]='this.filterTerm' matInput>
  </mat-form-field>

  <div class='content'>

    <ng-container *ngFor='let item of this.sprosseByTerm()'>

      <div
        (click)="item.DisabilityReasons.length === 0 ? this.preselect(item) : ''"
        [class.active]='this.isPreselected(item)'
        [class.disabled]='item.DisabilityReasons.length > 0'
        class='item'
      >
        <div
          [mtxTooltipDisabled]='item.DisabilityReasons.length === 0'
          [mtxTooltip]='StringUtil.concatStringArray(item.DisabilityReasons)'
          class='image-wrapper'
        >
          <div *ngIf='!item.loaded && item.PreviewImageUrl' class='icon loading-image-spinner'>
            <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          </div>
          <img (load)='item.loaded = true' [lazyLoad]='item.PreviewImageUrl'>
        </div>
        <p>
          <span class='option-text'>{{ item.Bezeichnung }}</span>
        </p>
      </div>

    </ng-container>


  </div>
  <mat-dialog-actions>
    <button (click)='this.onConfirm()' color='primary' mat-flat-button>{{ TRANSLATION_KEY.SAVE | translate }}</button>
  </mat-dialog-actions>
</div>

