import {ChangeEvent, ChangeType} from './events.types'
import AddonChangeEvent from './addon-change.event'
import {MehrpreisEntry} from '../component/extras/zubehoer/mehrpreisEntry'

export default class ZubehoerChangeEvent implements ChangeEvent<string> {
  constructor(readonly identifier: string, readonly type: ChangeType, readonly addonChanges: AddonChangeEvent[] = []) {

  }

  static added(zubehoer: MehrpreisEntry): ZubehoerChangeEvent {
    return new ZubehoerChangeEvent(
      zubehoer.Typ,
      ChangeType.Added,
      zubehoer.addons.map((addon): AddonChangeEvent => AddonChangeEvent.added(addon, zubehoer))
    )
  }

  static removed(zubehoer: MehrpreisEntry): ZubehoerChangeEvent {
    return new ZubehoerChangeEvent(
      zubehoer.Typ,
      ChangeType.Removed,
      zubehoer.addons.map((addon): AddonChangeEvent => AddonChangeEvent.removed(addon, zubehoer))
    )
  }

  static updated(zubehoer: MehrpreisEntry, addonChanges: AddonChangeEvent[] = []): ZubehoerChangeEvent {
    return new ZubehoerChangeEvent(zubehoer.Typ, ChangeType.Updated, addonChanges)
  }
}
