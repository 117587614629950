import {Observer, Subscriber} from 'rxjs'

export class ThenableSubscriber<T> extends Subscriber<T> {
  constructor(destination: Subscriber<T> | Observer<T>) {
    super(destination)
  }

  protected async _next(value: T): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    return await this.destination.next(value)
  }

  next(value?: T): Promise<void> {
    if (this.isStopped) {
      super.next(value)
    } else {
      return this._next(value)
    }
  }
}
