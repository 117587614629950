import {Component, DoCheck, ElementRef, Input, ViewChild} from '@angular/core'
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {NGXLogger} from 'ngx-logger'
import {HttpService} from '../../../http.service'
import {TranslateService} from '../../../translate'
import {ParameterService} from '../../../classes/service/parameter/parameter.service'
import {SettingsService} from '../../../classes/service/settings/settings.service'
import {Settings} from '../../../classes/service/settings/settings'
import MemoListService from '../../../classes/service/memo-list/memo-list.service'
import MemoListItem from '../../../classes/service/memo-list/memo-list-item'
import {MatCheckboxChange} from '@angular/material/checkbox'
import {RequestContact} from '../../../classes/api/contact/request-contact.interface'
import {ModalService} from '../../modal/modal.service'
import {Observable, Subscription} from 'rxjs'
import {crossOriginImageDownload} from './cross-origin-image-download'
import {MatButton} from '@angular/material/button'
import {ToastrService} from 'ngx-toastr'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {EventBusBase} from '../../../classes/service/eventBus/eventBusBase'
import {EventBusService} from '../../../classes/service/eventBus/eventBus'
import {BusEvent} from '../../../classes/service/eventBus/eventTypes'

const ZIP_VALIDATION_PATTERN = '^[0-9a-zA-Z]{4,5}$'
const TRANSLATION_KEY = {
  TITLE: 'RequestMenuComponent.Title',
  DOWNLOAD_IMAGE_NAME_OUTSIDE_PREFIX: 'RequestMenuComponent.Download.Image.Filename.Outside.Prefix',
  DOWNLOAD_IMAGE_NAME_OUTSIDE_SUFFIX: 'RequestMenuComponent.Download.Image.Filename.Outside.Suffix',
  DOWNLOAD_IMAGE_NAME_INSIDE_PREFIX: 'RequestMenuComponent.Download.Image.Filename.Inside.Prefix',
  DOWNLOAD_IMAGE_NAME_INSIDE_SUFFIX: 'RequestMenuComponent.Download.Image.Filename.Inside.Suffix',
  TOAST_ERROR_DOWNLOAD_IMAGE_TITLE: 'RequestMenuComponent.Toast.Error.ImageDownload.Title',
  TOAST_ERROR_DOWNLOAD_IMAGE_MESSAGE: 'RequestMenuComponent.Toast.Error.ImageDownload.Message',
  TOAST_ERROR_DOWNLOAD_IMAGE_MESSAGE_DETAILS_PREFIX: 'RequestMenuComponent.Toast.Error.ImageDownload.Message.Details.Prefix',
  TOAST_ERROR_DOWNLOAD_IMAGE_MESSAGE_DETAILS_SUFFIX: 'RequestMenuComponent.Toast.Error.ImageDownload.Message.Details.Suffix',
  TOAST_ERROR_DOWNLOAD_PDF_TITLE: 'RequestMenuComponent.Toast.Error.PdfDownload.Title',
  TOAST_ERROR_DOWNLOAD_PDF_MESSAGE: 'RequestMenuComponent.Toast.Error.PdfDownload.Message',
  TOAST_ERROR_DOWNLOAD_PDF_MESSAGE_DETAILS_PREFIX: 'RequestMenuComponent.Toast.Error.PdfDownload.Message.Details.Prefix',
  TOAST_ERROR_DOWNLOAD_PDF_MESSAGE_DETAILS_SUFFIX: 'RequestMenuComponent.Toast.Error.PdfDownload.Message.Details.Suffix',
  TOAST_ERROR_FORM_INVALID_TITLE: 'RequestMenuComponent.Toast.Error.FormInvalid.Title',
  TOAST_ERROR_FORM_INVALID_MESSAGE: 'RequestMenuComponent.Toast.Error.FormInvalid.Message',
  FORM_ERROR_REQUIRED: 'RequestMenuComponent.Form.ValidationError.Required',
  FORM_ERROR_REQUIRED_TRUE: 'RequestMenuComponent.Form.ValidationError.RequiredTrue',
  FORM_ERROR_FORMAT_MAIL: 'RequestMenuComponent.Form.ValidationError.Format.Mail',
  FORM_ERROR_FORMAT_ZIP: 'RequestMenuComponent.Form.ValidationError.Format.Zip',
  FORM_ERROR_FORMAT_GENERIC: 'RequestMenuComponent.Form.ValidationError.Format.Generic',
  SHARE_MENU_GENERATE_IMAGE: 'RequestMenuComponent.Generate.Image',
  SHARE_MENU_GENERATE_PDF: 'RequestMenuComponent.Generate.Pdf',
  SHARE_MENU_GENERATE_SHARE: 'RequestMenuComponent.Generate.Share',
  MEMOLIST_IMAGE_PREVIEW: 'RequestMenuComponent.MemoList.PreviewImage',
  MEMOLIST_LOAD_CONFIG: 'RequestMenuComponent.MemoList.LoadConfig',
  MEMOLIST_REMOVE_ENTRY: 'RequestMenuComponent.MemoList.Remove',
  MEMOLIST_ENTRY_LABEL_MODEL: 'RequestMenuComponent.MemoList.Entry.Label.Model',
  MEMOLIST_ENTRY_LABEL_ID: 'RequestMenuComponent.MemoList.Entry.Label.Id',
  MEMOLIST_ADD_ENTRY: 'RequestMenuComponent.MemoList.Entry.Add',
  MEMOLIST_SELECTED_ENTRY: 'RequestMenuComponent.MemoList.Entry.Selected',
  MEMOLIST_DOWNLOAD_ENTRY: 'RequestMenuComponent.MemoList.Entry.Download',
  MEMOLIST_SHARE_ENTRY: 'RequestMenuComponent.MemoList.Entry.Share',
  REQUEST_OFFER_TITLE: 'RequestMenuComponent.Offer.Request.Title',
  REQUEST_OFFER_FIRST_NAME_LABEL: 'RequestMenuComponent.Offer.Request.FirstName.Label',
  REQUEST_OFFER_LAST_NAME_LABEL: 'RequestMenuComponent.Offer.Request.LastName.Label',
  REQUEST_OFFER_STREET_LABEL: 'RequestMenuComponent.Offer.Request.Street.Label',
  REQUEST_OFFER_ZIP_LABEL: 'RequestMenuComponent.Offer.Request.Zip.Label',
  REQUEST_OFFER_CITY_LABEL: 'RequestMenuComponent.Offer.Request.City.Label',
  REQUEST_OFFER_COUNTRY_LABEL: 'RequestMenuComponent.Offer.Request.Country.Label',
  REQUEST_OFFER_PHONE_LABEL: 'RequestMenuComponent.Offer.Request.Phone.Label',
  REQUEST_OFFER_EMAIL_LABEL: 'RequestMenuComponent.Offer.Request.Email.Label',
  REQUEST_OFFER_FIRST_NAME_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.FirstName.Placeholder',
  REQUEST_OFFER_LAST_NAME_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.LastName.Placeholder',
  REQUEST_OFFER_STREET_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.Street.Placeholder',
  REQUEST_OFFER_ZIP_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.Zip.Placeholder',
  REQUEST_OFFER_CITY_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.City.Placeholder',
  REQUEST_OFFER_COUNTRY_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.Country.Placeholder',
  REQUEST_OFFER_PHONE_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.Phone.Placeholder',
  REQUEST_OFFER_EMAIL_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.Email.Placeholder',
  REQUEST_OFFER_SEND_CATALOG: 'RequestMenuComponent.Offer.Request.SendCatalog',
  REQUEST_OFFER_SHARE_WITH_RETAILER: 'RequestMenuComponent.Offer.Request.ShareWithRetailer',
  REQUEST_OFFER_EXISTING_PARTNER_STRING: 'RequestMenuComponent.Offer.Request.ExistingPartner.Message',
  REQUEST_OFFER_EXISTING_PARTNER_LABEL: 'RequestMenuComponent.Offer.Request.ExistingPartner.Label',
  REQUEST_OFFER_EXISTING_PARTNER_PLACEHOLDER: 'RequestMenuComponent.Offer.Request.ExistingPartner.Placeholder',
  HINT_MEASURES_NOT_OBLIGING: 'RequestMenuComponent.Hint.MeasuresNotObliging',
  REQUEST_STATUS_INITIAL: 'RequestMenuComponent.RequestStatus.Initial',
  REQUEST_STATUS_LOADING: 'RequestMenuComponent.RequestStatus.Loading',
  REQUEST_STATUS_SUCCESS: 'RequestMenuComponent.RequestStatus.Success',
  DATA_PRIVACY_HINT_PREFIX: 'RequestMenuComponent.Privacy.Prefix',
  DATA_PRIVACY_HINT_SUFFIX: 'RequestMenuComponent.Privacy.Suffix'
} as const

@Component({
  selector: 'configurator-request-menu',
  templateUrl: './request-menu.component.html',
  styleUrls: ['./request-menu.component.scss']
})
export class RequestMenuComponent extends EventBusBase implements DoCheck {
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected _fachpartnerInput: ElementRef<HTMLInputElement>
  protected currentDoor: MemoListItem
  @Input() isMobile: boolean
  protected readonly requestForm: FormGroup<{
    agree: FormControl<boolean>
    catalog: FormControl<boolean>
    city: FormControl<string>
    country: FormControl<string>
    email: FormControl<string>
    fachpartner: FormControl<string>
    lastname: FormControl<string>
    name: FormControl<string>
    phone: FormControl<string>
    street: FormControl<string>
    zip: FormControl<string>
  }>
  protected requestFormState: 'INITIAL' | 'LOADING' | 'SUCCESS' = 'INITIAL'
  protected selection: MemoListItem[]


  constructor(
    protected readonly configuratorConfigurationModel: ConfiguratorConfigurationModel,
    formBuilder: FormBuilder,
    private httpService: HttpService,
    private logger: NGXLogger,
    protected readonly navigatorService: NavigatorService,
    protected readonly parameterService: ParameterService,
    protected readonly settingsService: SettingsService,
    private translateService: TranslateService,
    protected readonly memoListService: MemoListService,
    private modalService: ModalService,
    private toastrService: ToastrService,
    eventBus: EventBusService
  ) {
    super(eventBus)
    this.selection = this.memoList.concat()
    this.requestForm = formBuilder.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      street: ['', [Validators.required]],
      zip: ['', [Validators.required, Validators.pattern(ZIP_VALIDATION_PATTERN)]],
      city: ['', [Validators.required]],
      phone: [''],
      country: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      catalog: [false],
      agree: [false],
      fachpartner: ['']
    })
    const applyValidatorsFromSettings = (settings: Settings): void => {
      if (settings.AnfrageHaendlerPflicht) {
        this.requestForm.controls.agree.setValidators([Validators.required, this.mustBeTrue()])
      }
      if (settings.IsTelefonPflicht) {
        this.requestForm.controls.phone.addValidators(Validators.required)
      } else {
        this.requestForm.controls.phone.removeValidators(Validators.required)
      }
      this.requestForm.controls.phone.updateValueAndValidity()
      if (settings.Fachpartner && settings.FachpartnerPflicht) {
        this.requestForm.controls.fachpartner.addValidators(Validators.required)
      } else {
        this.requestForm.controls.fachpartner.removeValidators(Validators.required)
      }
      this.requestForm.controls.fachpartner.updateValueAndValidity()
    }
    applyValidatorsFromSettings(settingsService.settings)
    settingsService.settingsChanged.subscribe(applyValidatorsFromSettings)
    this.memoListService.subscribeItemAdded((item): void => {
      if (!this.selection.map((s: MemoListItem): string => s.doorId).includes(item.doorId)) {
        this.selection.push(item)
      }
    })
    this.memoListService.subscribeItemRemoved((item): void => {
      const index = this.selection.indexOf(item)
      if (index !== -1) {
        this.selection.splice(index, 1)
      }
    })
  }

  protected closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  downloadImage(event?: MouseEvent): void {
    this.track('Download - Bild')
    this.withLoadingAnimation<MemoListItem, { Aussen?: string; Innen?: string }>(
      this.distinctSelection,
      (item): Observable<{ Aussen?: string; Innen?: string }> => item.imageUrls,
      (data, item): Promise<unknown> => Promise.all([
        crossOriginImageDownload(
          data?.Aussen,
          this.translateService.translate(TRANSLATION_KEY.DOWNLOAD_IMAGE_NAME_OUTSIDE_PREFIX)
              + item.doorId
              + this.translateService.translate(TRANSLATION_KEY.DOWNLOAD_IMAGE_NAME_OUTSIDE_SUFFIX),
          'jpg'
        ),
        crossOriginImageDownload(
          data?.Innen,
          this.translateService.translate(TRANSLATION_KEY.DOWNLOAD_IMAGE_NAME_INSIDE_PREFIX)
              + item.doorId
              + this.translateService.translate(TRANSLATION_KEY.DOWNLOAD_IMAGE_NAME_INSIDE_SUFFIX),
          'jpg'
        )
      ]),
      event?.currentTarget instanceof HTMLButtonElement ? event.currentTarget : undefined,
      15000
    ).catch((reason): void => {
      this.toastrService.error(
        this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_DOWNLOAD_IMAGE_MESSAGE) +
          (typeof reason === 'string') ?
          this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_DOWNLOAD_IMAGE_MESSAGE_DETAILS_PREFIX)
              + reason
              + this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_DOWNLOAD_IMAGE_MESSAGE_DETAILS_SUFFIX)
          : '',
        this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_DOWNLOAD_IMAGE_TITLE)
      )
    })
  }

  downloadPdf(event?: MouseEvent): void {
    this.track('Download - Pdf')
    this.withLoadingAnimation<MemoListItem, string>(
      this.distinctSelection,
      (item): Observable<string> => item.reportUrl,
      (url): Promise<void> => new Promise<void>((resolve): void => {
        const downloadLinkHelper = document.createElement('a')
        downloadLinkHelper.setAttribute('crossOrigin', 'anonymous')
        downloadLinkHelper.setAttribute('target', '_blank')
        downloadLinkHelper.setAttribute('download', '')
        downloadLinkHelper.setAttribute('href', url)
        downloadLinkHelper.click()
        resolve()
      }),
      event?.currentTarget instanceof HTMLButtonElement ? event.currentTarget : undefined,
      15000
    ).catch((reason): void => {
      this.toastrService.error(
        this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_DOWNLOAD_PDF_MESSAGE) +
          (typeof reason === 'string') ?
          this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_DOWNLOAD_PDF_MESSAGE_DETAILS_PREFIX)
              + reason
              + this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_DOWNLOAD_PDF_MESSAGE_DETAILS_SUFFIX)
          : '',
        this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_DOWNLOAD_PDF_TITLE)
      )
    })
  }

  getFormErrorForField(
    inputField: RequestMenuComponent['requestForm'] extends FormGroup<infer T> ? keyof T : never
  ): string | null {
    const errors: string[] = []
    if (this.requestForm.controls[inputField]?.errors?.hasOwnProperty('mustBeTrue')) {
      errors.push(this.translateService.translate(TRANSLATION_KEY.FORM_ERROR_REQUIRED_TRUE))
    }
    if (this.requestForm.controls[inputField]?.errors?.required) {
      errors.push(this.translateService.translate(TRANSLATION_KEY.FORM_ERROR_REQUIRED))
    }
    if (this.requestForm.controls[inputField]?.errors?.email) {
      errors.push(this.translateService.translate(TRANSLATION_KEY.FORM_ERROR_FORMAT_MAIL))
    }
    const patternError: unknown = this.requestForm.controls[inputField]?.errors?.pattern
    if (
      typeof patternError === 'object'
        && 'requiredPattern' in patternError
        && patternError.requiredPattern === ZIP_VALIDATION_PATTERN
    ) {
      errors.push(this.translateService.translate(TRANSLATION_KEY.FORM_ERROR_FORMAT_ZIP))
    }
    if (this.requestForm.controls[inputField]?.errors?.pattern) {
      errors.push(this.translateService.translate(TRANSLATION_KEY.FORM_ERROR_FORMAT_GENERIC))
    }
    return errors.length > 0 ? errors.join(', ') : null
  }

  hideAllPopOuts(skipElement?: HTMLElement): void {
    document.querySelectorAll('.pop-out-wrapper.show').forEach((e: Element): void => {
      if (skipElement && e === skipElement) {
        return
      } else {
        e.classList.remove('show')
      }
    })
  }

  isElementInViewPort(element: HTMLElement, additionalY: number = 0): boolean {
    const rect = element.getBoundingClientRect()
    // get the height of the window
    const viewPortBottom = window.innerHeight || document.documentElement.clientHeight
    // get the width of the window
    const viewPortRight = window.innerWidth || document.documentElement.clientWidth
    const isTopInViewPort = rect.top >= 0
    const isLeftInViewPort = rect.left >= 0
    const isBottomInViewPort = rect.bottom + additionalY <= viewPortBottom
    const isRightInViewPort = rect.right <= viewPortRight
    // check if element is completely visible inside the viewport
    return (isTopInViewPort && isLeftInViewPort && isBottomInViewPort && isRightInViewPort)
  }

  loadRequest(doorId: string): void {
    this.toggleEvent<string>(BusEvent.LoadRequest, doorId)
  }

  mustBeTrue(): ValidatorFn {
    return (control: FormControl): {
      [key: string]: boolean
    } | null => control.value === true ? null : {mustBeTrue: true}
  }

  ngDoCheck(): void {
    handleCurrentDoor: if (this.currentDoor?.doorId !== this.parameterService?.parameter?.tuerId) {
      const currentDoorUndefined = typeof this.currentDoor === 'undefined'
      const index = this.selection.indexOf(this.currentDoor)
      if (currentDoorUndefined) {
        this.currentDoor = this.selection.find(
          (value): boolean => value.doorId === this.parameterService?.parameter?.tuerId
        )
        if (typeof this.currentDoor !== 'undefined') {
          break handleCurrentDoor
        }
      }
      this.currentDoor = new MemoListItem(
        this.httpService,
        this.configuratorConfigurationModel.configuratedDoor?.tueren?.[0]?.model?.Bezeichnung,
        this.parameterService?.parameter?.tuerId
      )
      if (currentDoorUndefined) {
        this.selection.push(this.currentDoor)
      } else if (index !== -1) {
        this.selection.splice(index, 1, this.currentDoor)
      }
    }
    const description = this.configuratorConfigurationModel.configuratedDoor?.tueren?.[0]?.model?.Bezeichnung
    if (this.currentDoor && description && this.currentDoor.description !== description) {
      this.currentDoor.description = description
    }
  }

  onResultFormSubmit(event: Event): void {
    event.preventDefault()
    if (this.requestForm.invalid) {
      this.toastrService.error(
        this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_FORM_INVALID_MESSAGE),
        this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_FORM_INVALID_TITLE)
      )
      return
    }
    this.requestForm.disable()
    if (this.requestFormState !== 'INITIAL') {
      return
    }
    this.requestFormState = 'LOADING'
    const data: RequestContact = {
      Email: this.requestForm.value.email,
      Fachpartner: this.requestForm.value.fachpartner,
      Katalog: this.requestForm.value.catalog,
      Land: this.requestForm.value.country,
      Ort: this.requestForm.value.city,
      Plz: this.requestForm.value.zip,
      Strasse: this.requestForm.value.street,
      Nachname: this.requestForm.value.lastname,
      Telefon: this.requestForm.value.phone,
      TuerIds: this.selection.map((selection): string => selection.doorId),
      Vorname: this.requestForm.value.name,
      Zugestimmt: this.requestForm.value.agree
    }
    this.httpService.postContactRequest(data).subscribe({
      next: (): void => {
        this.requestFormState = 'SUCCESS'
      }
    })
  }

  onSelectionChange(event: MatCheckboxChange, selection: MemoListItem): void {
    if (event.checked) {
      if (!this.selection.includes(selection)) {
        this.selection.push(selection)
      }
    } else {
      const index = this.selection.indexOf(selection)
      if (index !== -1) {
        this.selection.splice(index, 1)
      }
    }
  }

  openShareModal(): void {
    if (this.selection.length !== 0) {
      this.modalService.showShareModal(this.selection)
    }
  }

  scrollIntoView(element: HTMLElement, scrollContainer: HTMLElement): void {
    const HEIGHT_OF_NAVBUTTONS: number = 50
    const WHILE_LIMIT: number = 100
    let WHILE_COUNT: number = 0
    while (WHILE_COUNT < WHILE_LIMIT && !this.isElementInViewPort(element, HEIGHT_OF_NAVBUTTONS)) {
      WHILE_COUNT += 1
      // scrollContainer.scrollBy({left: 0, top: 20, behavior: 'smooth'})
      scrollContainer.scrollBy(0, 20)
    }
  }

  showPrivacyDialog(): void {
    this.modalService.showPrivacyModal(this.settings.DatenschutzUrl || '')
  }

  togglePopOut(htmlItem: HTMLElement | MatButton): void {
    let currentPopOut: HTMLElement
    if (htmlItem instanceof MatButton) {
      currentPopOut = document.querySelector('#downloadButton .pop-out-wrapper')
    } else {
      currentPopOut = (htmlItem).querySelector('.pop-out-wrapper')
    }
    currentPopOut?.classList.toggle('show')
    if (currentPopOut?.classList.contains('show')) {
      this.scrollIntoView(currentPopOut, currentPopOut.closest('.container'))
    }
    this.hideAllPopOuts(currentPopOut)
  }

  track(trackEvent: string): void {
    this.toggleEvent<string>(BusEvent.Track, trackEvent)
  }

  updateAgreeValue(checked: boolean): void {
    this.requestForm.controls.agree.setValue(checked)
  }

  updateCatalogValue(checked: boolean): void {
    this.requestForm.controls.catalog.setValue(checked)
  }

  private withLoadingAnimation<I, T, O extends Observable<T> = Observable<T>>(
    items: I[],
    toObservable: (item: I) => O,
    action: (data: T, item: I) => Promise<unknown>,
    attributeTarget?: Element,
    timeout?: number
  ): Promise<void> {
    let canceled = false
    let timeoutId: ReturnType<typeof setTimeout>
    const rejects: Parameters<ConstructorParameters<typeof Promise>[0]>[1][] = []
    const resetTimeout = (): void => {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = undefined
      }
      if (timeout && timeout >= 0) {
        timeoutId = setTimeout(
          (): void => {
            canceled = true
            rejects.forEach((reject): void => reject('Timeout'))
          },
          timeout
        )
      }
    }
    attributeTarget?.setAttribute('data-loading', '')
    const promises = Promise.all(items.map((item): Promise<void> => new Promise<void>((resolve, reject): void => {
      const observable = toObservable(item)
      let unsubscribe = false
      // Needs to be initialised before subscribe call
      let subscription: Subscription = null
      const doUnsubscribe = (): void => {
        unsubscribe = true
        subscription?.unsubscribe()
      }
      rejects.push((reason: unknown): void => {
        doUnsubscribe()
        reject(reason)
      })
      subscription = observable
        .subscribe({
          next: (data): void => {
            if (data) {
              doUnsubscribe()
              if (!canceled) {
                action(data, item).then(resolve).catch(reject)
                resetTimeout()
              } else {
                reject('Already canceled')
              }
            }
          },
          error: (error): void => {
            console.error(error)
            reject(error)
          },
          complete: (): void => {
            doUnsubscribe()
            reject('Completed without valid data')
          }
        })
      if (unsubscribe) {
        try {
          subscription.unsubscribe()
        } catch {
          // ignore
        }
      }
    }))).then((): void => {
    }).finally(() => void attributeTarget?.removeAttribute('data-loading'))
    resetTimeout()
    return promises
  }

  get distinctSelection(): MemoListItem[] {
    return this.selection.filter((value, index, array): boolean =>
      index === array.findIndex((value2): boolean => value.doorId === value2.doorId)
    )
  }

  get fachpartnerInput(): ElementRef<HTMLInputElement> {
    return this._fachpartnerInput
  }

  @ViewChild('fachpartnerInput')
  set fachpartnerInput(fachpartnerInput: ElementRef<HTMLInputElement>) {
    setTimeout(() => void (this._fachpartnerInput = fachpartnerInput))
  }

  get memoList(): MemoListItem[] {
    return this.memoListService.get()
  }

  get settings(): Settings {
    return this.settingsService.settings
  }
}
