<div
        [class.active]="isActive()"
        [class.fbs]="this.configuratorMode === ConfiguratorMode.FBS"
        [ngClass]="menuClass"
        class="subNavigationContainer"
>
    <ng-template [ngTemplateOutlet]="menuHead"></ng-template>

    <configurator-component-selector *ngIf="componentSelector"></configurator-component-selector>

    <ng-container *ngIf="isActive()">
        <ng-content></ng-content>
    </ng-container>

    <configurator-menu-navigation></configurator-menu-navigation>
</div>
