import {Injectable} from '@angular/core'
import {DeviceService} from '../../device/device.service'
import {environment} from '../../../environments/environment'
import {NGXLogger} from 'ngx-logger'
import {HttpService} from '../../http.service'
import {ParameterService} from './parameter/parameter.service'
import {TranslateService} from '../../translate'
import {ParameterMode, ParameterModes} from './parameter/parameter-mode'
import {CookieConsentService} from '../../cookie-consent'

@Injectable()
export default class TrackingService {
  constructor(
    private httpService: HttpService,
    private logger: NGXLogger,
    private _translate: TranslateService,
    private parameterService: ParameterService,
    private deviceService: DeviceService,
    private cookieConsentService: CookieConsentService
  ) {
  }

  track(): void {
    /* if (!this.cookieConsentService.hasConsent('tracking') && environment.MODE !== 'FBS') {
      this.logger.info('Tracking is deactivated due to lack of user consent')
      return
    } */
    const deviceInfo = this.deviceService.getDeviceInfo()
    this.httpService.track({
      Application: environment.MODE === 'FBS' ? 'TTK-XPERT' : 'TTK',
      ApplicationVersion: environment.version,
      Url: document.location.href,
      Sprache: this._translate.currentLang,
      BrowserName: deviceInfo.browser,
      BrowserPlatform: deviceInfo.os_version,
      BrowserProductName: deviceInfo.os,
      BrowserProductVersion: deviceInfo.browser_version,
      BrowserUseragent: deviceInfo.userAgent,
      LogType:
        this.parameterService.parameter.mode !== ParameterModes.Edit  satisfies ParameterMode as ParameterMode
        && this.parameterService.parameter.mode !== ParameterModes.Open  satisfies ParameterMode as ParameterMode
          ? 'STARTED-NEW'
          : 'STARTED-EDIT',
      TransaktionsId: this.parameterService.parameter?.transkey ? this.parameterService.parameter.transkey : null,
      PositionsId: this.parameterService.model?.pos ? this.parameterService.model.pos : null,
      Software: this.parameterService.parameter?.software ? this.parameterService.parameter.software : null,
      Kommission: this.parameterService.parameter?.kommission ? this.parameterService.parameter.kommission : null,
      Profil: this.parameterService.parameter?.profil ? this.parameterService.parameter.profil : null
    }).subscribe({
      next: () => void this.logger.trace('sent trace'),
      error: (error) => void this.logger.error(error)
    })
  }
}
