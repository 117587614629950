import {Injectable} from '@angular/core'
import {Hausfront} from '../model/component/hausfront/hausfront'
import {Grundform} from '../model/component/other/grundform'
import {SideType} from '../../types'

@Injectable()
export class HausfrontService {
  private _hausfronten: Hausfront[] = []

  constructor(
  ) {
  }

  addHausfront(hausfront: Hausfront | Hausfront[]): void {
    if (!Array.isArray(hausfront)) {
      hausfront = [hausfront]
    }
    this._hausfronten.push(...hausfront)
  }

  applyHausfrontenFromGrundform(grundform: Grundform): void {
    this.clearHausfronten()
    this.addHausfront(grundform.Hausfronten)
  }

  clearHausfronten(): void {
    this._hausfronten = []
  }

  findHausfront(side: SideType, bezeichnung?: string): Hausfront | undefined {
    const hf = this.hausfronten.find(
      (hausfront: Hausfront): boolean =>
        (
          typeof bezeichnung !== 'string'
          || hausfront.Bezeichnung === bezeichnung
        ) && (
          hausfront.IsAussen && side === SideType.Outside
          || hausfront.IsInnen && side === SideType.Inside
        )
    )
    return hf
  }

  get hausfronten(): Hausfront[] {
    return this._hausfronten
  }
}
