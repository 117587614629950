import {Component, ViewEncapsulation} from '@angular/core'

@Component({
  selector: 'modal-loading-backdrop-style',
  template: '',
  styleUrls: ['./loading-modal-backdrop-style.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingModalBackdropStyleComponent {

}
