import {ConstructionComponent, ConstructionComponentFactory} from './constructionComponent'
import {GlasaufbauFactory} from '../glassaufbau/glasaufbau'
import {Injectable} from '@angular/core'
import {ConstructionComponentType} from './construction-component-type.enum'

export class Oberlicht extends ConstructionComponent {
  constructor(data: Partial<ConstructionComponent>, glasaufbauFactory: GlasaufbauFactory) {
    super(data, glasaufbauFactory)
    this._objectType = ConstructionComponentType.Fanlight
  }
}

@Injectable()
export class OberlichtFactory extends ConstructionComponentFactory<Oberlicht> {
  constructor(glasaufbauFactory: GlasaufbauFactory) {
    super(glasaufbauFactory, Oberlicht)
  }
}
