import {InjectionToken} from '@angular/core'
import {Subscribable} from 'rxjs'
import {FbsGlas} from '../../../../class'
import {ComponentModel} from '../../component/model/component-model'

export const GLASS_PROVIDER_TOKEN = new InjectionToken<GlassProvider>('app.configurator.dataprovider.glass')

export interface GlassProvider {
  findGlass(id: number): FbsGlas | undefined

  getAllGlasses(): readonly FbsGlas[]

  getDefaultGlass(): FbsGlas

  getDefaultMiddleGlass(): FbsGlas

  getGlassesForModel(model: ComponentModel): FbsGlas[]

  getObservableGlasses(): Subscribable<readonly FbsGlas[]>
}
