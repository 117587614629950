<modal-frame
  [footer]='footer'
  [title]='TRANSLATION_KEY.MODAL_TITLE|translate'
>
  <textarea [(ngModel)]='notes' cols='50' id='notes' name='notes' rows='4'>
  </textarea>
</modal-frame>
<ng-template #footer>
  <button mat-dialog-close='' mat-flat-button>{{ TRANSLATION_KEY.DISMISS | translate }}</button>
  <button [mat-dialog-close]='notes' color='primary' mat-raised-button>{{ TRANSLATION_KEY.SAVE | translate }}</button>
</ng-template>

