import {ConstructionDimensions} from '../../../classes/model/component/construction/construction-dimensions'

export class WritableConstructionDimensions implements WriteableKonstruktionsMasse {
  private a: number // A + B + C = D
  private b: number
  private c: number

  private e: number // E + F + G = H
  private f: number
  private g: number

  private i: number // I + J + K = L
  private j: number
  private k: number

  private m: number // M + N + O = P
  private n: number
  private o: number

  constructor(data?: Partial<WriteableKonstruktionsMasse>) {
    this.a = data?.A ?? 0
    this.b = data?.B ?? 0
    this.c = data?.C ?? 0
    // this.d = data?.D ?? 0;
    this.e = data?.E ?? 0
    this.f = data?.F ?? 0
    this.g = data?.G ?? 0
    // this.h = data?.H ?? 0;
    this.i = data?.I ?? 0
    this.j = data?.J ?? 0
    this.k = data?.K ?? 0
    // this.l = data?.L ?? 0;
    this.m = data?.M ?? 0
    this.n = data?.N ?? 0
    this.o = data?.O ?? 0
    // this.p = data?.P ?? 0;
  }

  get A(): number {
    return this.a
  }
  set A(value: number) {
    this.a = Math.max(0, value)
  }
  get B(): number {
    return this.b
  }
  set B(value: number) {
    this.b = Math.max(0, value)
  }
  get C(): number {
    return this.c
  }
  set C(value: number) {
    this.c = Math.max(0, value)
  }
  get D(): number {
    return this.a + this.b + this.c // A + B + C = D
  }
  set D(value: number) {
    this.b = Math.max(0, value - this.a - this.c) // A + B + C = D
  }
  get E(): number {
    return this.e
  }
  set E(value: number) {
    this.e = Math.max(0, value)
  }
  get F(): number {
    return this.f
  }
  set F(value: number) {
    this.f = Math.max(0, value)
  }
  get G(): number {
    return this.g
  }
  set G(value: number) {
    this.g = Math.max(0, value)
  }
  get H(): number {
    return this.e + this.f + this.g // E + F + G = H
  }
  set H(value: number) {
    this.f = Math.max(0, value - this.e - this.g) // E + F + G = H
  }
  get I(): number {
    return this.i
  }
  set I(value: number) {
    this.i = Math.max(0, value)
  }
  get J(): number {
    return this.j
  }
  set J(value: number) {
    this.j = Math.max(0, value)
  }
  get K(): number {
    return this.k
  }
  set K(value: number) {
    this.k = Math.max(0, value)
  }
  get L(): number {
    return this.i + this.j + this.k // I + J + K = L
  }
  set L(value: number) {
    this.j = Math.max(0, value - this.i - this.k) // I + J + K = L
  }
  get M(): number {
    return this.m
  }
  set M(value: number) {
    this.m = Math.max(0, value)
  }
  get N(): number {
    return this.n
  }
  set N(value: number) {
    this.n = Math.max(0, value)
  }
  get O(): number {
    return this.o
  }
  set O(value: number) {
    this.o = Math.max(0, value)
  }
  get P(): number {
    return this.m + this.n + this.o // M + N + O = P
  }
  set P(value: number) {
    this.n = Math.max(0, value - this.m - this.o) // M + N + O = P
  }
}

export type WriteableKonstruktionsMasse = {
  -readonly [P in keyof ConstructionDimensions as ConstructionDimensions[P] extends number
    ? Exclude<P, 'minFuellungsHoehe' | 'minFuellungsBreite' | 'maxFuellungsHoehe' | 'maxFuellungsBreite'>
    : never]: ConstructionDimensions[P]
}
