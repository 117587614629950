import { Injectable} from '@angular/core'
import {SideType} from '../../types'

@Injectable()
export class ViewService {
  private _view: SideType

  constructor(
  ) {
    this._view = SideType.Outside
  }

  set view(view: SideType) {
    this._view = view
  }

  get currentSide(): SideType {
    return this._view
  }
}
