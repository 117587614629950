import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

export interface ImageModalData {
  imageUrl: string
  nostring: string
  title: string
}

@Component({
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
})
export class ImageModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageModalData
  ) {
  }
}
