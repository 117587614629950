<header (click)="onAction()">
    <a (click)="$event.stopPropagation()" [href]='logoLinkUrl' class='logo'>
        <img [src]='logoUrl$ | async' alt='Logo'>
    </a>
    <h1>
        {{ TRANSLATION_KEY.CONFIGURATOR_HEADER_TITLE | translate }}
    </h1>
    <span>{{
        TRANSLATION_KEY_MAP[navigatorService.currentActiveMenu] ?? TRANSLATION_KEY.MENU_TITLE | translate
        }}</span>
    <i
        [class.fa-close]="this.navigatorService.isNavigationMenuOpen"
        [class.fa-navicon]="!this.navigatorService.isNavigationMenuOpen"
        class="fa"
    ></i>
</header>
