<div appCustomerStyle></div>

<div id='mainwrapper'>

  <app-cookie-consent
      (saveAndClose)='hideAndReloadConsent()'
      [imprintUrl]="settings.ImpressumUrl ?? 'https://www.rodenberg.ag/de/unternehmen/impressum' "
      [show]='cookieConsentService.showConsentBanner'
  ></app-cookie-consent>

  <div #contentWrapper class='contentWrapper' id='contentWrapper'>
    <ng-container *ngIf='result_outside'>

      <ng-container *ngIf='!configuratedDoor.Hausfronten[this.view]'>
        <div class='doorImages'>

          <ng-container
              *ngIf='this.configuratorMode === ConfiguratorMode.FBS
                                || this.configuratorMode === ConfiguratorMode.TTK && this.view === SideType.Outside'
          >
            <div
                (click)='this.view = SideType.Outside' [class.active]='this.view === SideType.Outside'
                class='outside'
            >
              <img [src]='result_outside'>
              <p>{{ TRANSLATION_KEY.VIEW_OUTSIDE | translate }} <i class='fa fa-check'></i></p>
              <a *ngIf="this.configuratorMode === ConfiguratorMode.FBS && this.settings.DownloadBilder"
                 [attr.href]="this.configuratedDoor.Images.Outside"
                 class="imageDownloadLink"
                 target="_blank"><i
                  class='fa fa-download'></i> {{ TRANSLATION_KEY.DOWNLOAD_DOOR_IMAGE | translate }}</a>
            </div>
          </ng-container>

          <ng-container
              *ngIf='this.configuratorMode === ConfiguratorMode.FBS
                            || this.configuratorMode === ConfiguratorMode.TTK && this.view === SideType.Inside'
          >
            <div
                (click)='this.view = SideType.Inside' [class.active]='this.view === SideType.Inside'
                class='inside'
            >
              <img [src]='result_inside'>
              <p>{{ TRANSLATION_KEY.VIEW_INSIDE | translate }} <i class='fa fa-check'></i></p>
              <a *ngIf="this.configuratorMode === ConfiguratorMode.FBS && this.settings.DownloadBilder"
                 [attr.href]="this.configuratedDoor.Images.Inside"
                 class="imageDownloadLink"
                 target="_blank"><i
                  class='fa fa-download'></i> {{ TRANSLATION_KEY.DOWNLOAD_DOOR_IMAGE | translate }}</a>
            </div>
          </ng-container>

        </div>
      </ng-container>

      <ng-container *ngIf='hausfrontMenuComponent && configuratedDoor.Hausfronten[this.view]'>

        <div
            (change)='this.recalcMobileHeight()'
            [ngStyle]="{
                        'background-image': 'url(' + hausfrontMenuComponent.backgroundImage + ')',
                        'background-size': '100%',
                        'height': hausfrontMenuComponent.offsetHeight + 'px'
                     }"
            class='background-image-blur blur'
        ></div>

        <img
            (load)='hausfrontMenuComponent.onBackgroundLoaded()'
            [src]='hausfrontMenuComponent.backgroundImage'
            id='image_background'
        >

        <door-view
            *ngIf='doorPosition && this.configuratedDoor?.Images?.[this.view]'
            [heightBackground]='doorPosition.backgroundHeight' [heightDoor]='doorPosition.doorHeight'
            [urlDoor]='this.configuratedDoor.Images[this.view]'
            [widthBackground]='doorPosition.backgroundWidth'
            [widthDoor]='doorPosition.doorWidth'
            [x0]='doorPosition.offsettedX0'
            [x1]='doorPosition.offsettedX1'
            [x2]='doorPosition.offsettedX2'
            [x3]='doorPosition.offsettedX3'
            [y0]='doorPosition.y0'
            [y1]='doorPosition.y1'
            [y2]='doorPosition.y2' [y3]='doorPosition.y3'
            id='image_door'
        >
        </door-view>


      </ng-container>


    </ng-container>


    <div
        [class.active]='this.loading || (
                this.configuratorMode === ConfiguratorMode.TTK && hausfrontMenuComponent?.loadingBackground
             )'
        class='loading-door-overlay-wrapper'
    >
      <div class='loading-door-overlay'>
        <i aria-hidden='true' class='fa fa-cog fa-spin fa-fw fa-3x'></i>
        <p>{{ TRANSLATION_KEY.LOADING_PLEASE_WAIT | translate }}...</p>
      </div>
    </div>


    <div *ngIf='this.isMobile && this.configuratedModelsLoaded()' class='frontOptions mobile'>

      <button [configurator-fullscreen-toggle]='contentWrapper' [isMobile]='this.isMobile'
              class='fullscreenButton'>
      </button>

      <button
          (click)='this.memoListService.has(parameter?.tuerId)
                            ? this.memoListService.removeCurrentDoor()
                            : this.memoListService.addCurrentDoor()' class='favoriteButton'
      >
        <span>{{ this.memoListService.length }}</span>
        <i class='icon-favorite'></i>
      </button>

      <button (click)='this.shareDialog()' class='shareButton'>
        <i class='fa fa-share-alt'></i>
      </button>

      <button (click)='this.postMultivision()' *ngIf='this.settings.Multivision' class='multivisionButton'>
        <i class='fa fa-television'></i>
      </button>

      <button (click)='changeDoorView(this.view === SideType.Inside ? SideType.Outside : SideType.Inside)'
              class='viewButton'>
        <i class='icon-view-mobile'></i>
      </button>
    </div>

    <ng-container *ngIf='!this.configuratedModelsLoaded()'>
      <div *ngIf="this.configuratorMode === ConfiguratorMode.FBS
            && ((isDevMode() && parameter?.token === '123') || this.settings.TuerIdOeffnen)">
        <ul class='frontOptions desktop'>
          <li>
            <configurator-load-configuration-button
                (loadRequest)='loadComponentByIndex($event)'
            >
            </configurator-load-configuration-button>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf='this.configuratedModelsLoaded()'>
      <div>
        <ul
            *ngIf='this.configuratorMode === ConfiguratorMode.FBS'
            class='frontOptions desktop'
        >
          <li [configurator-fullscreen-toggle]='contentWrapper' [isMobile]='this.isMobile'></li>

          <li
              (click)="setShowNextToggle('calculateFbs')"
              *ngIf='this._componentSelection.selectedComponent
                            && this._componentSelection.selectedComponent.model'
          >
            <i class='fa fa-calculator'></i>
            <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_PRICE | translate }}</p>
          </li>

          <li (click)='showCharacteristics = !showCharacteristics'>
            <i class='fa fa-list-ul'></i>
            <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_CHARACTERISTICS | translate }}</p>
          </li>

          <li *ngIf="this.configuratorMode === ConfiguratorMode.FBS
                    && ((isDevMode() && parameter?.token === '123') || this.settings.TuerIdOeffnen)">
            <configurator-load-configuration-button
                (loadRequest)='loadComponentByIndex($event)'
            >
            </configurator-load-configuration-button>
          </li>
        </ul>
      </div>
    </ng-container>

    <ng-container *ngIf='this.configuratorMode === ConfiguratorMode.TTK && !this.isMobile'>
      <ul class='frontOptions desktop'>
        <li [configurator-fullscreen-toggle]='contentWrapper' [isMobile]='this.isMobile'></li>

        <li
            (click)='this.memoListService.has(parameter?.tuerId)
                            ? this.memoListService.removeCurrentDoor()
                            : this.memoListService.addCurrentDoor()'
        >
          <div class='icon'>
            <i
                [class.fa-heart-o]='!this.memoListService.has(parameter?.tuerId)'
                [class.fa-heart]='this.memoListService.has(parameter?.tuerId)'
                class='fa fa-heart-o'
            ></i>
          </div>
          <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_ADDMEMO | translate }}
            ({{ this.memoListService.length }})</p>
        </li>

        <li (click)='showCharacteristics = !showCharacteristics'>
          <i class='fa fa-list-ul'></i>
          <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_CHARACTERISTICS | translate }}</p>
        </li>

        <li (click)='this.postMultivision()' *ngIf='this.settings.Multivision'>
          <i class='fa fa-television'></i>
          <p>{{ TRANSLATION_KEY.CONFIGURATOR_FRONTOPTIONS_MULTIVISION | translate }}</p>
        </li>

      </ul>

    </ng-container>

    <div
        *ngIf='this.selectedComponent' [class.mobile]='isMobile'
        [style.height.px]='isMobile ? this.recalcMobileHeight(): null'
        class='subnav'
    >

      <configurator-load-n-search-menu>
      </configurator-load-n-search-menu>

      <configurator-hausfront-menu [lazyImageLoaded]='lazyImageLoaded.bind(this)'>
      </configurator-hausfront-menu>

      <configurator-grundform-menu [lazyImageLoaded]='lazyImageLoaded.bind(this)'>
      </configurator-grundform-menu>

      <configurator-catalogue-menu
          [lazyImageLoaded]='lazyImageLoaded.bind(this)'
          [modelMenu]='modelMenuComponent'
      ></configurator-catalogue-menu>

      <configurator-model-menu>
      </configurator-model-menu>

      <configurator-basis-menu
          (constructionChange)='this.updateZmassValues(); this.clearFbsKonstruktion(); this.getCompleteDoor()'
          (elementDinChange)='this.getCompleteDoor()'
          (elementMaterialChange)='this.onMaterialChange($event)'
          (klebesystemChange)='this.updateZmassValues()'
          (oeffnungsartChange)='this.updateOeffnungsart()'
          (systemDinChange)='this.updateOeffnungsart()'
          (systemMaterialChange)='this.changeMaterialTuersystem($event)'
          (zMassChange)='this.onZmassChange()'
      ></configurator-basis-menu>

      <configurator-key-data-menu>
      </configurator-key-data-menu>

      <configurator-request-menu>
      </configurator-request-menu>


      <configurator-colors-menu
      ></configurator-colors-menu>

      <configurator-glasses-menu></configurator-glasses-menu>


      <configurator-options-menu
      ></configurator-options-menu>

      <configurator-mehrpreis-menu
      ></configurator-mehrpreis-menu>

    </div>

    <ng-template [ngTemplateOutlet]='viewSwitch'></ng-template>
  </div><!-- /mainWrapper -->

  <configurator-characteristics-menu
      *ngIf='showCharacteristics'
      class='subnav'
  ></configurator-characteristics-menu>


</div>
<footer>
  <span>
    {{
          TRANSLATION_KEY.HINT_MEASURES_NOT_OBLIGING | translate
    }}
  </span>
  &nbsp;-&nbsp;[{{ TRANSLATION_KEY.FOOTER_ITEMS_VERSION | translate }} {{
        version
  }}]
</footer>


<ng-template #viewSwitch>
  <ul *ngIf='this.configuratorMode === ConfiguratorMode.TTK' class='optionAspectList'>
    <li (click)='this.changeView(SideType.Outside)' [class.active]='view === SideType.Outside'>
      <i class='icon-view-l'></i>
      <p>{{ TRANSLATION_KEY.BUTTON_VIEWSWITCH_OUTSIDE | translate }}</p>
    </li>
    <li (click)='this.changeView(SideType.Inside)' [class.active]='view === SideType.Inside'>
      <i class='icon-view-r'></i>
      <p>{{ TRANSLATION_KEY.BUTTON_VIEWSWITCH_INSIDE | translate }}</p>
    </li>
  </ul>
</ng-template>
