import { Injectable } from '@angular/core'

type KeyOf<T> = (string | number) & keyof T

let allowCreate = false

export class LocalStorageService<TypeMapping> {
  constructor(private prefix: string) {
    if (!allowCreate) {
      throw new Error('Use the factory to create this class')
    }
  }

  /* get<K extends keyof TypeMapping>(key: K): TypeMapping[K] {
    return localStorage.getItem(key)
  }*/

  private get(key: KeyOf<TypeMapping>): string | null {
    return localStorage.getItem(this.key(key))
  }

  getBoolean<K extends KeyOf<TypeMapping>>(key: K): TypeMapping[K] extends boolean ? TypeMapping[K] | null : never {
    const val = this.get(key)
    if (val === 'true') {
      return true as never
    }
    if (val === 'false') {
      return false as never
    }
    return val as never
  }

  getNumber<K extends KeyOf<TypeMapping>>(key: K): TypeMapping[K] extends number ? TypeMapping[K] | null : never {
    const val = this.get(key)
    if (val !== null) {
      return parseFloat(this.get(key)) as never
    }
    return val as never
  }

  getObject<K extends KeyOf<TypeMapping>>(key: K): TypeMapping[K] extends object ? TypeMapping[K] | null : never {
    const val = this.get(key)
    if (val !== null) {
      return JSON.parse(val) as never
    }
  }

  getString<K extends KeyOf<TypeMapping>>(key: K): TypeMapping[K] extends string ? TypeMapping[K] | null : never {
    return this.get(key) as never
  }

  has(key: KeyOf<TypeMapping>): boolean {
    return this.get(key) !== null
  }

  private key(key: KeyOf<TypeMapping>): string {
    return 'lss.' + this.prefix + '.' + key
  }

  put<K extends KeyOf<TypeMapping>>(key: K, value: TypeMapping[K]): void {
    localStorage.setItem(this.key(key), JSON.stringify(value))
  }

  withPrefix<NewTypeMapping = TypeMapping>(newPrefix: string): LocalStorageService<NewTypeMapping> {
    return create(newPrefix)
  }
}

@Injectable()
export class LocalStorageServiceFactory {
  create<TypeMapping>(prefix: string): LocalStorageService<TypeMapping> {
    return create(prefix)
  }
}

const create = <TypeMapping>(prefix: string): LocalStorageService<TypeMapping> => {
  allowCreate = true
  const instance = new LocalStorageService<TypeMapping>(prefix)
  allowCreate = false
  return instance
}
