import {platformBrowserDynamic} from '@angular/platform-browser-dynamic'
import {enableProdMode} from '@angular/core'
import {environment} from './environments/environment'
import {AppModule} from './app/app.module'
import {registerLocaleData} from '@angular/common'
import localeDe from '@angular/common/locales/de'
import localeDeExtra from '@angular/common/locales/extra/de'

if (environment.production) {
  enableProdMode()
}
registerLocaleData(localeDe, 'de-DE', localeDeExtra)
void platformBrowserDynamic().bootstrapModule(AppModule)
