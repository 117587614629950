import {ColorBase} from './colorBase'
import {Folie} from './folie'
import {Lack} from './lack'
import {Pulver} from './pulver'
import {Beschichtung} from './beschichtung'
import {InsideOutsideObject, SideType} from '../../../types'
import {Material} from '../material'
import {ComponentModel} from '../component/model/component-model'
import {ConfiguratedOption} from '../../../class'
import {DesignOberflaeche} from './design'

export abstract class ColorizableComponent<
  BeschichtungType extends Beschichtung | InsideOutsideObject<Beschichtung>
  = Beschichtung | InsideOutsideObject<Beschichtung>
> {
  Beschichtung: BeschichtungType
  BeschichtungAnpassen: boolean
  IsDesignOberflaecheMoeglich: boolean
  IsFolieMoeglich: boolean
  IsLackMoeglich: boolean
  IsPulverMoeglich: boolean
  Typ: string

  constructor(data?: Omit<Partial<ColorizableComponent>, 'Beschichtung'>) {
    this.IsFolieMoeglich = data?.IsFolieMoeglich ?? false
    this.IsDesignOberflaecheMoeglich = data?.IsDesignOberflaecheMoeglich ?? false
    this.IsLackMoeglich = data?.IsLackMoeglich ?? false
    this.IsPulverMoeglich = data?.IsPulverMoeglich ?? false
    this.BeschichtungAnpassen = data?.BeschichtungAnpassen ?? false
    this.Typ = data?.Typ ?? 'unknown'
  }

  applyConfiguratedColor(model: ComponentModel, confOption: ConfiguratedOption, side: SideType, mat: Material): void {
    if (confOption.HasBeschichtung) {
      const color = model.findObjectFromData(confOption.Beschichtung)
      if (color && color instanceof ColorBase) {
        this.colorize(color, side, mat)
      }
    }
  }

  colorize(color: ColorBase, side: SideType, material: Material): boolean {
    // BeschichtungAnpassen Overrides Supported Color Check if Set !important
    if (this.isColorSupported(color, side, material) || this.BeschichtungAnpassen) {
      if (!this.Beschichtung) {
        this.Beschichtung = this.getDefaultBeschichtungTypeObject()
      }
      if (side in this.Beschichtung) {
        if (!this.Beschichtung[side]) {
          this.Beschichtung[side] = new Beschichtung()
        }
        (this.Beschichtung[side] as Beschichtung).colorize(color)
        return true
      } else if (this.Beschichtung instanceof Beschichtung) {
        this.Beschichtung.colorize(color)
        return true
      }
    }
    return false
  }

  protected abstract doColorMaterialSupportCheck(): boolean

  filterColors<Color extends ColorBase>(colors: Color[], side: SideType, material: Material): Color[] {
    return colors.filter((c): boolean => this.isColorSupported(c, side, material))
  }

  getColorSupportReasoning(color: ColorBase, side: SideType, material: Material, name: string): string {
    const {
      colorTypeSupported,
      materialSupported,
      sideSupported
    } = this.performColorSupportChecks(color, side, material)
    return ([] as string[]).concat(
      colorTypeSupported ? [] : [`Color of type '${color.getType()}' is not supported by '${name}'`],
      materialSupported
        ? []
        : [`Color '${color.Bezeichnung ?? color.Beschreibung}' does not support material '${material}'`],
      sideSupported
        ? []
        : [`Color '${color.Bezeichnung ?? color.Beschreibung}' is not supported on side '${side}'`]
        // elementTypeSupported ? [] : [`Color '${color.Bezeichnung ?? color.Beschreibung}' does not support element '${this.Typ}'`]
    ).join('\n')
  }

  protected abstract getDefaultBeschichtungTypeObject(): BeschichtungType

  isColorSupported(color: ColorBase, side: SideType, material: Material): boolean {
    const {
      colorTypeSupported,
      materialSupported,
      sideSupported
    } = this.performColorSupportChecks(color, side, material)
    return colorTypeSupported && materialSupported && sideSupported
  }

  protected performColorSupportChecks(color: ColorBase, side: SideType, material: Material): {
    colorTypeSupported: boolean
    materialSupported: boolean
    sideSupported: boolean
  } {
    const colorTypeSupported: boolean = (color instanceof Folie && this.IsFolieMoeglich)
        || (color instanceof Lack && this.IsLackMoeglich)
        || (color instanceof Pulver && this.IsPulverMoeglich)
        || (color instanceof DesignOberflaeche && this.IsDesignOberflaecheMoeglich)
    const materialSupported: boolean = !this.doColorMaterialSupportCheck()
        || (material === Material.Alu && color.IsAluMoeglich)
        || (material === Material.Kunststoff && color.IsKunststoffMoeglich)
        || (material === Material.Glas && color.IsGlasMoeglich)
    const sideSupported: boolean = (side === SideType.Inside && color.IsInnenMoeglich)
        || (side === SideType.Outside && color.IsAussenMoeglich)
    return {
      colorTypeSupported,
      materialSupported,
      sideSupported
    }
  }
}

export abstract class OneSidedColorizableComponent extends ColorizableComponent<Beschichtung> {
  protected getDefaultBeschichtungTypeObject(): Beschichtung {
    return new Beschichtung()
  }
}

export abstract class DoubleSidedColorizableComponent extends ColorizableComponent<InsideOutsideObject<Beschichtung>> {
  protected getDefaultBeschichtungTypeObject(): InsideOutsideObject<Beschichtung> {
    return {
      Inside: new Beschichtung(),
      Outside: new Beschichtung(),
    }
  }
}
