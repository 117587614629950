import {Injectable} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {HttpService} from '../../http.service'
import {Grundform} from '../model/component/other/grundform'
import {Hausfront} from '../model/component/hausfront/hausfront'
import {SideType} from '../../types'
import {ViewService} from './view.service'
import {HausfrontRequest} from '../model/component/hausfront/hausfront-request'

@Injectable()
export class GrundformService {
  private _currentGrundform: Grundform
  private _grundformen: Grundform[] = []

  constructor(
    private httpService: HttpService,
    private viewService: ViewService,
    private _logger: NGXLogger
  ) {
  }

  addCustomHausfront(grundformId: number, hausfront: Hausfront): void {
    this._logger.trace('addCustomHausfront', hausfront)
    this._logger.trace('grundformId', grundformId)

    // this.grundformen.filter((g: Grundform): boolean => g.Id === grundformId)[0].Hausfronten.unshift(hausfront)
    this.grundformen.forEach((g: Grundform): void => {
      const sample: Hausfront = g.Hausfronten.find((h: Hausfront): boolean => !h.Custom)
      const custom: Hausfront = new Hausfront(hausfront)
      custom.Bezeichnung = custom.Id.toString()
      custom.OberlichtBreite = sample.OberlichtBreite
      custom.OberlichtHoehe = sample.OberlichtHoehe
      custom.SeitenteilBreite = sample.SeitenteilBreite
      custom.SeitenteilHoehe = sample.SeitenteilHoehe
      custom.TuerBreite = sample.TuerBreite
      custom.TuerHoehe = sample.TuerHoehe
      custom.Name = sample.Name

      g.Hausfronten.unshift(custom)

    })
    this._logger.trace('grundformen', this.grundformen)
  }

  createCustomHausfront(hausfront: Hausfront, image: File, elementWidth: number, elementHeight: number): Promise<Hausfront> {
    return new Promise<Hausfront>((resolve): void => {
      this.httpService.postUploadedHausfront(image, this.generateRequestObject(hausfront, elementWidth, elementHeight)).subscribe({
        next: (apiHausfront: Hausfront): void => {
          hausfront.Id = apiHausfront.Id
          hausfront.Bezeichnung = apiHausfront.Id.toString()
          hausfront.PreviewUrl = apiHausfront.PreviewUrl
          hausfront.Url = apiHausfront.Url
          hausfront.Custom = true

          this.addCustomHausfront(hausfront.GrundformId, new Hausfront(hausfront))
          resolve(hausfront)
        },
        error: (error): void => {
          console.error(error)
        }
      })
    })
  }

  generateRequestObject(hausfront: Hausfront, elementWidth: number, elementHeight: number): HausfrontRequest {
    return new HausfrontRequest({
      GrundformId: hausfront.GrundformId,
      ElementBreite: Math.round(elementWidth),
      ElementHoehe: Math.round(elementHeight),
      TuerBreite: Math.round(hausfront.TuerBreite),
      TuerHoehe: Math.round(hausfront.TuerHoehe),
      SeitenteilBreite: Math.round(hausfront.SeitenteilBreite),
      SeitenteilHoehe: Math.round(hausfront.SeitenteilHoehe),
      OberlichtBreite: Math.round(hausfront.OberlichtBreite),
      OberlichtHoehe: Math.round(hausfront.OberlichtHoehe),
      IsAussen: this.viewService.currentSide === SideType.Outside,
      IsInnen: this.viewService.currentSide === SideType.Inside,
      X1: hausfront.X1,
      X2: hausfront.X2,
      X3: hausfront.X3,
      X4: hausfront.X4,
      Y1: hausfront.Y1,
      Y2: hausfront.Y2,
      Y3: hausfront.Y3,
      Y4: hausfront.Y4,
      Id: hausfront.Id
    })
  }

  getHausfront(grundformKey: string, hausfrontId: number, side: SideType): Hausfront | null {
    return this.grundformen.find((g: Grundform): boolean => g.Key === grundformKey)?.Hausfronten
      .find((h: Hausfront): boolean => h.Id === hausfrontId) ?? this.getHausfrontFallback(grundformKey, side)
  }

  getHausfrontFallback(grundformKey: string, side: SideType): Hausfront | null {
    return this.grundformen.find((g: Grundform): boolean => g.Key === grundformKey)?.Hausfronten
      .find((h: Hausfront): boolean => side === SideType.Inside ? h.IsInnen : h.IsAussen)
  }

  public load(): Promise<void> {
    this._logger.trace('loadAllGrundformen')
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getAllGrundformen().subscribe({
        next: (data): void => {
          data?.forEach(grundform => void this._grundformen.push(new Grundform(grundform)))
        },
        error: (error): void => {
          this._logger.error(['Error', error])
          reject()
        },
        complete: (): void => {
          resolve()
        }
      })
    })
  }

  sameSide(side: SideType, inside: boolean, outside: boolean): boolean {
    const bothInside: boolean = side === SideType.Inside && inside
    const bothOutside: boolean = side === SideType.Outside && outside
    return bothInside || bothOutside
  }

  updateAllCustomHausfrontenElementSizes(side: SideType, width: number, height: number): void{
    this.grundformen.forEach((g: Grundform): void => {
      g.Hausfronten.forEach((h: Hausfront): void => {
        if (h.Custom && this.sameSide(side, h.IsInnen, h.IsAussen)) {
          h.ElementBreite = width
          h.ElementHoehe = height
        }
      })
    })
  }

  updateCustomHausfront(hausfront: Hausfront, elementWidth: number, elementHeight: number): Promise<Hausfront> {
    return new Promise<Hausfront>((resolve): void => {
      this.httpService.postHausfrontUpdate(this.generateRequestObject(hausfront, elementWidth, elementHeight)).subscribe({
        next: (): void => {
          this.updateCustomHausfrontInGrundformen(hausfront)
          resolve(hausfront)
        },
        error: (error): void => {
          console.error(error)
        }
      })
    })
  }

  updateCustomHausfrontInGrundformen(hausfront: Hausfront): void{
    // this.grundformen.filter((g: Grundform): boolean => g.Id === hfr.GrundformId)[0]
    //     .Hausfronten.filter((hf: Hausfront): boolean => hf.Id === hfr.Id)[0] = hausfront

    this.grundformen.forEach((g: Grundform): void => {
      g.Hausfronten.filter((h: Hausfront): boolean => h.Id === hausfront.Id).forEach((h: Hausfront): void => {
        this.updateCustomHausfrontValues(hausfront, h, g)
      })
    })
  }

  updateCustomHausfrontValues(hausfrontSource: Hausfront, hausfrontTarget: Hausfront, grundform: Grundform): void{
    hausfrontTarget.GrundformId = grundform.Id
    hausfrontTarget.Name = grundform.Key
    hausfrontTarget.X1 = hausfrontSource.X1
    hausfrontTarget.X2 = hausfrontSource.X2
    hausfrontTarget.X3 = hausfrontSource.X3
    hausfrontTarget.X4 = hausfrontSource.X4
    hausfrontTarget.Y1 = hausfrontSource.Y1
    hausfrontTarget.Y2 = hausfrontSource.Y2
    hausfrontTarget.Y3 = hausfrontSource.Y3
    hausfrontTarget.Y4 = hausfrontSource.Y4
    hausfrontTarget.ElementBreite = hausfrontSource.ElementBreite
    hausfrontTarget.ElementHoehe = hausfrontSource.ElementHoehe
  }

  get grundformen(): Grundform[] {
    return this._grundformen
  }

  get grundform(): Grundform {
    return this._currentGrundform
  }

  set grundform(grundform: Grundform) {
    this._currentGrundform = grundform
  }
}
