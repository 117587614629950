import {ConfigurationLoaderService} from './configuration-loader.service'
import {ConstructionComponentType} from '../../classes/model/component/construction/construction-component-type.enum'
import {ConfiguratorConfigurationModel} from '../../classes/model/configuratorConfigurationModel'
import {NGXLogger} from 'ngx-logger'
import {ToastrService} from 'ngx-toastr'
import {Material} from '../../classes/model/material'
import {ModalService} from '../modal/modal.service'
import {Inject, Injectable} from '@angular/core'
import {MaterialConflictResolutionStrategy} from './material-conflict-resolution-strategy.enum'
import {TranslateService} from '../../translate'
import {LOAD_STRATEGY, LoadStrategy} from './load-strategy'
import {LoadRequestIdentifier} from '../components/load-configuration-button/load-configuration-button.component'
import {ConstructionComponent} from '../../classes/model/component/construction/constructionComponent'
import {ConfiguratorModeService} from '../../classes/service/configuratorMode.service'
import {ConfiguratorMode} from '../../types'
import {ConfigurationNotFoundError} from './error.type'

const TRANSLATION_KEY = {
  TOAST_ERROR_CONFIGURATION_NOT_FOUND_TITLE: 'ConfiguratorComponent.Toast.Error.ConfigurationNotFound.Title',
  TOAST_ERROR_CONFIGURATION_NOT_FOUND_MESSAGE: 'ConfiguratorComponent.Toast.Error.ConfigurationNotFound.Message',
  TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_TITLE: 'ConfiguratorComponent.Toast.Error.ConfigurationLoadUnknown.Title',
  TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_MESSAGE: 'ConfiguratorComponent.Toast.Error.ConfigurationLoadUnknown.Message',
  TOAST_ERROR_LOADING_CONFIG_MESSAGE: 'ConfiguratorComponent.Toast.Error.LoadingConfig.Message',
  TOAST_ERROR_LOADING_CONFIG_TITLE: 'ConfiguratorComponent.Toast.Error.LoadingConfig.Title',
  TOAST_INFO_ADJUSTING_MATERIAL_TITLE: 'ConfiguratorComponent.Toast.Info.AdjustingMaterial.Title',
  TOAST_INFO_ADJUSTING_MATERIAL_MESSAGE: 'ConfiguratorComponent.Toast.Info.AdjustingMaterial.Message',
  TOAST_INFO_MATERIAL_DIFFERS_TITLE: 'ConfiguratorComponent.Toast.Info.MaterialDiffers.Title',
  TOAST_INFO_MATERIAL_DIFFERS_MESSAGE: 'ConfiguratorComponent.Toast.Info.MaterialDiffers.Message'
} as const

@Injectable()
export class LoadService {
  constructor(
    private readonly configurationLoaderService: ConfigurationLoaderService,
    private readonly configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private readonly configuratorModeService: ConfiguratorModeService,
    @Inject(LOAD_STRATEGY) private readonly loadService: LoadStrategy,
    private readonly logger: NGXLogger,
    private readonly modalService: ModalService,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService
  ) {
  }

  public async loadComponentOfDoorFromLoadedConfig([doorId, componentIndex]: LoadRequestIdentifier): Promise<void> {
    this.configurationLoaderService.retainLoadedComponentConfig(componentIndex)
    await this.resolveLoadedMaterialConflict()
    return await this.loadDoorFromLoadedConfig([doorId, componentIndex])
  }

  public async loadDoorById(doorId: string): Promise<void> {
    try {
      await this.configurationLoaderService.loadConfig(doorId)
      await this.resetConfiguratedDoor()
      await this.resolveLoadedMaterialConflict()
      await this.loadDoorFromLoadedConfig()
    } catch (err) {
      if (err instanceof ConfigurationNotFoundError) {
        this.toastrService.error(
          this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_CONFIGURATION_NOT_FOUND_MESSAGE),
          this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_CONFIGURATION_NOT_FOUND_TITLE)
        )
      } else {
        this.toastrService.error(
          this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_MESSAGE),
          this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_TITLE)
        )
      }
    }
  }

  public async loadDoorFromLoadedConfig(
    loadRequestIdentifier?: LoadRequestIdentifier
  ): Promise<void> {
    errorHandling: try {
      const loadProcess = this.loadService.loadDoorFromLoadedConfig(loadRequestIdentifier)
      const modelLoadResult = await loadProcess.next()
      if (modelLoadResult.done !== false) {
        this.logger.error(
          'Error loading door from loaded config: generator finished unexpectedly',
          {generator: loadProcess, result: modelLoadResult}
        )
        break errorHandling
      }
      await this.configuratorConfigurationModel.newConfiguratedDoorInitialized.emitThenable()
      const applyConfigResult = await loadProcess.next()
      if (applyConfigResult.done !== true) {
        this.logger.error(
          'Error loading door from loaded config: generator did not finish when expected to',
          {generator: loadProcess, result: applyConfigResult, previousResult: modelLoadResult}
        )
        break errorHandling
      }
      return await this.configuratorConfigurationModel.configuratedDoorOpened.emitThenable()
    } catch (err) {
      this.logger.error('Error loading door from loaded config', err)
    }
    this.toastrService.error(
      this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_LOADING_CONFIG_MESSAGE),
      this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_LOADING_CONFIG_TITLE)
    )
  }

  public async loadModelsAfterGrundformChange(): Promise<void> {
    await this.loadService.loadModelsAfterGrundformChange()
    await this.configuratorConfigurationModel.newConfiguratedDoorInitialized.emitThenable()
  }

  public async resetConfiguratedDoor(material?: Material): Promise<void> {
    const persistHausfront = this.configuratorConfigurationModel.configuratedDoor?.Hausfronten
    return this.configuratorConfigurationModel.initializeConfiguratedDoor(
      this.configurationLoaderService.hasLoadedConfig()
        ? this.configurationLoaderService.getLoadedDoorFillingMaterial()
        : material
    ).then((): void => {
      this.configuratorConfigurationModel.configuratedDoor.Hausfronten =
        persistHausfront ?? this.configuratorConfigurationModel.configuratedDoor.Hausfronten
    })
  }

  public async resolveLoadedMaterialConflict(): Promise<void> {
    if (!this.configurationLoaderService.hasUnresolvedMaterialConflict()) {
      return
    }
    try {
      return await new Promise<void>((resolve): void => {
        this.modalService.showMaterialChangedModal({width: '800px', disableClose: true})
          .afterClosed()
          .subscribe((result): void => {
            if (!result) {
              // this.configurationLoaderService.removeLoadedConfig()
              this.configurationLoaderService.resolveMaterialConflict(
                MaterialConflictResolutionStrategy.Ignore
              )
              resolve()
              return
            }
            this.toastrService.warning(
              this.translateService.translate(TRANSLATION_KEY.TOAST_INFO_ADJUSTING_MATERIAL_MESSAGE),
              this.translateService.translate(TRANSLATION_KEY.TOAST_INFO_ADJUSTING_MATERIAL_TITLE)
            )
            this.configurationLoaderService.resolveMaterialConflict(
              MaterialConflictResolutionStrategy.MatchFillingToSystem
            )
            resolve()
            return
          })
      })
    } catch (error) {
      this.logger.error(error)
    }
  }

  public async setComponentModelById(
    modelId: string,
    componentType: ConstructionComponentType,
    material: Material,
    component: ConstructionComponent
  ): Promise<void> {
    const modelSetResult = await this.loadService.setModel(modelId, componentType, material, component)
    if (modelSetResult === 'MaterialMismatch' && this.configuratorModeService.mode === ConfiguratorMode.FBS) {
      this.toastrService.info(
        this.translateService.translate(TRANSLATION_KEY.TOAST_INFO_MATERIAL_DIFFERS_MESSAGE),
        this.translateService.translate(TRANSLATION_KEY.TOAST_INFO_MATERIAL_DIFFERS_TITLE)
      )
    }
    await this.configuratorConfigurationModel.newConfiguratedDoorInitialized.emitThenable()
  }

  public async setInitialDoorModel(modelId: string, material: Material): Promise<void> {
    await this.setComponentModelById(
      modelId,
      ConstructionComponentType.Door,
      material,
      this.configuratorConfigurationModel.configuratedDoor.tueren[0]
      ?? this.configuratorConfigurationModel.configuratedDoor.Components[0]
    )
  }
}

