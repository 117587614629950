<configurator-base-menu
  [menuHead]="head"
  [menuNavigatorIdentifier]='NavigationMenuEntryKey.Dimension'
  has-component-selector
  menuClass="masse"
>
  <div class="container">
    <div class="container-inner max">
      <mat-accordion>

        <mat-expansion-panel *ngIf="this.configuratedDoor.Grundform.ChangeDinPossible" expanded="true" togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ TRANSLATION_KEY.DIN_TITLE|translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="flex-row gap-3">
            <div
              (click)="din = Din.Left"
              [matBadgeHidden]="dinFuellung !== Din.Left"
              class="flex-column cursor-pointer"
              matBadge="✔"
              matBadgePosition="above before"
              matBadgeSize="large"
            >
              <img class="py-3" src="https://static.rodenberg.ag/ttk/din_links.png">
              <button [class.active]="dinFuellung === Din.Left" class="w-100">{{ TRANSLATION_KEY.DIN_LEFT | translate }}
              </button>
            </div>
            <div
              (click)="din = Din.Right"
              [matBadgeHidden]="dinFuellung !== Din.Right"
              class="flex-column cursor-pointer"
              matBadge="✔"
              matBadgePosition="above before"
              matBadgeSize="large"
            >
              <img class="py-3" src="https://static.rodenberg.ag/ttk/din_rechts.png">
              <button [class.active]="dinFuellung === Din.Right" class="w-100">{{ TRANSLATION_KEY.DIN_RIGHT |translate }}
              </button>
            </div>
          </div>

        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="selectedComponent?.model" togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ TRANSLATION_KEY.CONSTRUCTION_TITLE|translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <h3>{{ TRANSLATION_KEY.CONSTRUCTION_OUTSIDE|translate }}</h3>

          <div class="flex-row gap-3 mb-5">
            <div
              (click)="setConstruction(this.selectedComponent, SingleConstruction.Einsatz, SideType.Outside)"
              [attr.disabled]="getConstructionDisallowedError(SingleConstruction.Einsatz, SideType.Outside) !== undefined"
              [matBadgeHidden]="construction !== SimpleConstruction.Einsatz"
              [matTooltipDisabled]="getConstructionDisallowedError(SingleConstruction.Einsatz, SideType.Outside) === undefined"
              [matTooltip]="getConstructionDisallowedError(SingleConstruction.Einsatz, SideType.Outside)"
              class="flex-column cursor-pointer"
              matBadge="✔"
              matBadgePosition="above before"
              matBadgeSize="large"
            >
              <img class="pb-3" src="https://static.rodenberg.ag/ttk/einsatz.de.png">
              <strong>{{ TRANSLATION_KEY.CONSTRUCTION_FILLING_INSERTED|translate }}</strong>
              <p>
                {{ TRANSLATION_KEY.CONSTRUCTION_FILLING_INSERTED_TEXT | translate }}
              </p>
              <button
                [class.active]="construction === SimpleConstruction.Einsatz"
                [disabled]="getConstructionDisallowedError(SingleConstruction.Einsatz, SideType.Outside) !== undefined"
                class="w-100"
              >
                {{ TRANSLATION_KEY.CONSTRUCTION_FILLING_INSERTED_SHORT|translate }}
              </button>
            </div>
            <div
              (click)="setConstruction(this.selectedComponent, SingleConstruction.Aufsatz, SideType.Outside)"
              [attr.disabled]="getConstructionDisallowedError(SingleConstruction.Aufsatz, SideType.Outside) !== undefined"
              [matBadgeHidden]="construction === SimpleConstruction.Einsatz"
              [matTooltipDisabled]="getConstructionDisallowedError(SingleConstruction.Aufsatz, SideType.Outside) === undefined"
              [matTooltip]="getConstructionDisallowedError(SingleConstruction.Aufsatz, SideType.Outside)"
              class="flex-column cursor-pointer"
              matBadge="✔"
              matBadgePosition="above before"
              matBadgeSize="large"
            >
              <img class="pb-3" src="https://static.rodenberg.ag/ttk/aufsatz.de.png">
              <strong>{{ TRANSLATION_KEY.CONSTRUCTION_FILLING_CONCEALING|translate }}</strong>
              <p>
                {{

                  TRANSLATION_KEY.CONSTRUCTION_FILLING_CONCEALING_TEXT  | translate
                }}
              </p>
              <button
                [class.active]="construction !== SimpleConstruction.Einsatz"
                [disabled]="getConstructionDisallowedError(SingleConstruction.Aufsatz, SideType.Outside) !== undefined"
                class="w-100"
              >
                {{ TRANSLATION_KEY.CONSTRUCTION_FILLING_CONCEALING_SHORT|translate }}
              </button>
            </div>
          </div>

          <h3>{{ TRANSLATION_KEY.CONSTRUCTION_INSIDE|translate }}</h3>
          <div class="flex-row gap-3">
            <div
              (click)="setConstruction(this.selectedComponent, SingleConstruction.Einsatz, SideType.Inside)"
              [attr.disabled]="getConstructionDisallowedError(SingleConstruction.Einsatz, SideType.Inside) !== undefined"
              [matBadgeHidden]="construction === SimpleConstruction.AufsatzBeidseitig"
              [matTooltipDisabled]="getConstructionDisallowedError(SingleConstruction.Einsatz, SideType.Inside) === undefined"
              [matTooltip]="getConstructionDisallowedError(SingleConstruction.Einsatz, SideType.Inside)"
              class="flex-column cursor-pointer"
              matBadge="✔"
              matBadgePosition="above before"
              matBadgeSize="large"
            >
              <img class="pb-3" src="https://static.rodenberg.ag/ttk/einsatz.de.png">
              <strong>{{ TRANSLATION_KEY.CONSTRUCTION_FILLING_INSERTED|translate }}</strong>
              <p>
                {{ TRANSLATION_KEY.CONSTRUCTION_FILLING_INSERTED_TEXT | translate }}
              </p>
              <button
                [class.active]="construction !== SimpleConstruction.AufsatzBeidseitig"
                [disabled]="getConstructionDisallowedError(SingleConstruction.Einsatz, SideType.Inside) !== undefined"
                class="w-100"
              >
                {{ TRANSLATION_KEY.CONSTRUCTION_FILLING_INSERTED_SHORT|translate }}
              </button>
            </div>
            <div
              (click)="setConstruction(this.selectedComponent, SingleConstruction.Aufsatz, SideType.Inside)"
              [attr.disabled]="getConstructionDisallowedError(SingleConstruction.Aufsatz, SideType.Inside) !== undefined"
              [matBadgeHidden]="construction !== SimpleConstruction.AufsatzBeidseitig"
              [matTooltipDisabled]="getConstructionDisallowedError(SingleConstruction.Aufsatz, SideType.Inside) === undefined"
              [matTooltip]="getConstructionDisallowedError(SingleConstruction.Aufsatz, SideType.Inside)"
              class="flex-column cursor-pointer"
              matBadge="✔"
              matBadgePosition="above before"
              matBadgeSize="large"
            >
              <img class="pb-3" src="https://static.rodenberg.ag/ttk/aufsatz.de.png">
              <strong>{{ TRANSLATION_KEY.CONSTRUCTION_FILLING_CONCEALING|translate }}</strong>
              <p>{{
                  TRANSLATION_KEY.CONSTRUCTION_FILLING_CONCEALING_TEXT | translate
                }}
              </p>
              <button
                [class.active]="construction === SimpleConstruction.AufsatzBeidseitig"
                [disabled]="getConstructionDisallowedError(SingleConstruction.Aufsatz, SideType.Inside) !== undefined"
                class="w-100"
              >
                {{ TRANSLATION_KEY.CONSTRUCTION_FILLING_CONCEALING_SHORT|translate }}
              </button>
            </div>
          </div>

        </mat-expansion-panel>

        <mat-expansion-panel class="measures" togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ TRANSLATION_KEY.MEASURES_TITLE|translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>


          <form #measuresFormRef>

            <div *ngFor="let door of configuratedDoor.tueren; let door_index = index" class="input-group">
              <mat-form-field appearance="fill">
                <mat-label>{{
                    TRANSLATION_KEY.MEASURES_DOOR | translate
                  }}{{
                    configuratedDoor.tueren.length > 1 ? ' ' + (door_index + 1) : ''
                  }} {{ TRANSLATION_KEY.MEASURES_WIDTH | translate }}
                </mat-label>
                <input
                  #measureInput (change)="this.reviseSize(door, $event.target, SizeType.Width)"
                  [attr.data-element-index]="door.Index"
                  [attr.data-size-type]="SizeType.Width"
                  [attr.value]="door.breite"
                  matInput
                >
                <span matTextSuffix>{{ TRANSLATION_KEY.MEASURES_MM | translate }}</span>
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>{{
                    TRANSLATION_KEY.MEASURES_DOOR | translate
                  }}{{
                    configuratedDoor.tueren.length > 1 ? ' ' + (door_index + 1) : ''
                  }} {{ TRANSLATION_KEY.MEASURES_HEIGHT | translate }}
                </mat-label>
                <input
                  #measureInput (change)="this.reviseSize(door, $event.target, SizeType.Height)"
                  [attr.data-element-index]="door.Index"
                  [attr.data-size-type]="SizeType.Height"
                  [attr.value]="door.hoehe"
                  matInput
                >
                <span matTextSuffix>{{ TRANSLATION_KEY.MEASURES_MM | translate }}</span>
              </mat-form-field>

              <ng-container *ngIf="showPrice">
                <ng-template
                  [ngTemplateOutletContext]="{element: door}"
                  [ngTemplateOutlet]="staerkeDropdown"
                ></ng-template>
              </ng-container>
            </div>

            <ng-container *ngIf="configuratedDoor?.seitenteile?.length > 0">
              <mat-divider></mat-divider>
            </ng-container>
            <div *ngFor="let sidepanel of configuratedDoor.seitenteile; let sidepanel_index = index" class="input-group">
              <mat-form-field appearance="fill">
                <mat-label>{{
                    TRANSLATION_KEY.MEASURES_SIDEPANEL | translate
                  }}{{
                    configuratedDoor.seitenteile.length > 1 ? ' ' + (sidepanel_index + 1) : ''
                  }} {{
                    TRANSLATION_KEY.MEASURES_WIDTH | translate
                  }}
                </mat-label>
                <input
                  #measureInput (change)="this.reviseSize(sidepanel, $event.target, SizeType.Width)"
                  [attr.data-element-index]="sidepanel.Index"
                  [attr.data-size-type]="SizeType.Width"
                  [attr.value]="sidepanel.breite"
                  matInput
                >
                <span matTextSuffix>{{ TRANSLATION_KEY.MEASURES_MM | translate }}</span>
              </mat-form-field>


              <ng-container *ngIf="showPrice">
                <ng-template
                  [ngTemplateOutletContext]="{element: sidepanel}"
                  [ngTemplateOutlet]="staerkeDropdown"
                ></ng-template>
              </ng-container>
            </div>


            <ng-container *ngIf="configuratedDoor?.oberlichter?.length > 0">
              <mat-divider></mat-divider>
            </ng-container>
            <div *ngFor="let fanlight of configuratedDoor.oberlichter; let fanlight_index = index" class="input-group">
              <mat-form-field appearance="fill">
                <mat-label> {{
                    TRANSLATION_KEY.MEASURES_FANLIGHT | translate
                  }}{{
                    configuratedDoor.oberlichter.length > 1 ? ' ' + (fanlight_index + 1) : ''
                  }} {{
                    TRANSLATION_KEY.MEASURES_HEIGHT | translate
                  }}
                </mat-label>
                <input
                  #measureInput (change)="this.reviseSize(fanlight, $event.target, SizeType.Height)"
                  [attr.data-element-index]="fanlight.Index"
                  [attr.data-size-type]="SizeType.Height"
                  [attr.value]="fanlight.hoehe"
                  matInput
                >
                <span matTextSuffix>{{ TRANSLATION_KEY.MEASURES_MM | translate }}</span>
              </mat-form-field>

              <ng-container *ngIf="showPrice">
                <ng-template
                  [ngTemplateOutletContext]="{element: fanlight}"
                  [ngTemplateOutlet]="staerkeDropdown"
                ></ng-template>
              </ng-container>
            </div>


            <mat-divider></mat-divider>
            <ng-container
              *ngIf="!this.configuratedDoor.onlyOneDoorPresent()
                            && this.configuratedDoor
                            && this.configuratedDoor.width
                            && this.configuratedDoor.height"
            >
              <div class="input-group">
                <mat-form-field appearance="fill">
                  <mat-label>{{ TRANSLATION_KEY.MEASURES_FULL_WIDTH | translate }}</mat-label>
                  <input [value]="this.configuratedDoor.widthString" matInput readonly>
                  <span matTextSuffix>{{ TRANSLATION_KEY.MEASURES_MM | translate }}</span>
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label>{{ TRANSLATION_KEY.MEASURES_FULL_HEIGHT | translate }}</mat-label>
                  <input [value]="this.configuratedDoor.heightString" matInput readonly>
                  <span matTextSuffix>{{ TRANSLATION_KEY.MEASURES_MM | translate }}</span>
                </mat-form-field>
              </div>

              <ng-container
                *ngIf="this.configuratedDoor.numOfTueren
                                || this.configuratedDoor.numOfSeitenteile
                                || this.configuratedDoor.numOfOberlichter"
              >
                <p class="footnote">
                  * {{ TRANSLATION_KEY.MEASURES_FOOTNOTE | translate }}</p>
              </ng-container>
            </ng-container>


            <button (click)="this.checkMeasureChanges()" class="w-100">{{ TRANSLATION_KEY.MEASURES_SAVE | translate }}</button>

          </form>

        </mat-expansion-panel>

      </mat-accordion>


    </div>
  </div>
</configurator-base-menu>
<ng-template #head>
  <div class="name">
    <span>{{ TRANSLATION_KEY.MENU_TITLE | translate }}</span>
    <span (click)="closeMenu()" class="close"></span>
  </div>
</ng-template>

<ng-template #staerkeDropdown let-element="element">
  <mat-form-field>
    <mat-label>{{ TRANSLATION_KEY.MEASURES_THICKNESS | translate }}</mat-label>
    <mat-select [(ngModel)]="element.staerke">
      <mat-option
        *ngFor="let staerke of this.configuratedDoor.staerken"
        [attr.selected]="element.staerke === staerke ? 'selected' : null"
        [value]="staerke"
      >
        {{ staerke }}{{ TRANSLATION_KEY.MEASURES_MM | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
