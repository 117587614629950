import {Component, Inject} from '@angular/core'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ConfiguratorMode} from '../../../types'
import * as ConstructionModule from '../../../classes/model/component/other/construction'
import {SimpleConstruction} from '../../../classes/model/component/other/construction'
import {DIALOG_DATA} from '@angular/cdk/dialog'
import {MatDialogRef} from '@angular/material/dialog'
import {WritableConstructionDimensions, WriteableKonstruktionsMasse} from './writable-construction-dimensions'
import {ConstructionDimensions} from '../../../classes/model/component/construction/construction-dimensions'

const TRANSLATION_KEY = {
  HEADING_DISPLAY: 'MasseModalComponent.Heading.Display',
  HEADING_INPUT: 'MasseModalComponent.Heading.Input',
  FORM_HEADING: 'MasseModalComponent.DimensionForm.Heading',
  FORM_HEADING_OUTSIDE: 'MasseModalComponent.DimensionForm.Heading.Outside',
  FORM_HEADING_INSIDE: 'MasseModalComponent.DimensionForm.Heading.Inside',
  FORM_LABEL_A: 'MasseModalComponent.DimensionForm.Label.A',
  FORM_LABEL_B: 'MasseModalComponent.DimensionForm.Label.B',
  FORM_LABEL_C: 'MasseModalComponent.DimensionForm.Label.C',
  FORM_LABEL_D: 'MasseModalComponent.DimensionForm.Label.D',
  FORM_LABEL_E: 'MasseModalComponent.DimensionForm.Label.E',
  FORM_LABEL_F: 'MasseModalComponent.DimensionForm.Label.F',
  FORM_LABEL_G: 'MasseModalComponent.DimensionForm.Label.G',
  FORM_LABEL_H: 'MasseModalComponent.DimensionForm.Label.H',
  FORM_LABEL_I: 'MasseModalComponent.DimensionForm.Label.I',
  FORM_LABEL_J: 'MasseModalComponent.DimensionForm.Label.J',
  FORM_LABEL_K: 'MasseModalComponent.DimensionForm.Label.K',
  FORM_LABEL_L: 'MasseModalComponent.DimensionForm.Label.L',
  FORM_LABEL_M: 'MasseModalComponent.DimensionForm.Label.M',
  FORM_LABEL_N: 'MasseModalComponent.DimensionForm.Label.N',
  FORM_LABEL_O: 'MasseModalComponent.DimensionForm.Label.O',
  FORM_LABEL_P: 'MasseModalComponent.DimensionForm.Label.P',
  FORM_LABEL_UNIT: 'MasseModalComponent.DimensionForm.Label.Unit',
  TEXT_DISPLAY: 'MasseModalComponent.InformationText.Display',
  TEXT_INPUT: 'MasseModalComponent.InformationText.Input',
  BUTTON_LABEL_DISPLAY: 'ConstructionChooserModalComponent.ButtonLabel.Display',
  BUTTON_LABEL_INPUT: 'ConstructionChooserModalComponent.ButtonLabel.Input',
} as const

export interface MasseModalComponentData {
  construction?: SimpleConstruction
  mode: 'display' | 'input'
}

@Component({
  templateUrl: './masse-modal.component.html',
  styleUrls: ['./masse-modal.component.scss']
})
export class MasseModalComponent {
  protected readonly ConfiguratorMode = ConfiguratorMode
  protected readonly construction: SimpleConstruction
  protected readonly constructionDimensions: WriteableKonstruktionsMasse
  protected readonly mode: 'display' | 'input'
  protected readonly SimpleConstruction = (ConstructionModule as {
    SimpleConstruction: Required<typeof SimpleConstruction>
  }).SimpleConstruction
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY

  constructor(
    private componentSelection: ComponentSelectionService,
    private configuratorModeServirce: ConfiguratorModeService,
    private dialogRef: MatDialogRef<MasseModalComponent>,
    @Inject(DIALOG_DATA) data: MasseModalComponentData
  ) {
    this.mode = data.mode
    this.constructionDimensions = this.mode === 'input' ? new WritableConstructionDimensions() :{
      ...(this.componentSelection?.selectedComponent?.KonstruktionsMasse
          ?? new ConstructionDimensions()
      ) satisfies WriteableKonstruktionsMasse as WriteableKonstruktionsMasse
    }
    this.construction = data.construction
      ?? this.componentSelection?.selectedComponent?.konstruktion
      ?? SimpleConstruction.Einsatz
  }

  onAction(): void {
    if (this.mode === 'input') {
      this.dialogRef.close(new ConstructionDimensions(this.constructionDimensions))
    } else {
      this.dialogRef.close()
    }
  }

  get configuratorMode(): ConfiguratorMode {
    return this.configuratorModeServirce.mode
  }
}
