import {Component, Input} from '@angular/core'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ConfiguratorMode} from '../../../types'

const TRANSLATION_KEY = {
  MENU_ENTRY_PREISBERECHNUNG: 'MenuNavigationComponent.Button.Calculation',
  MENU_NAVIGATION_BUTTON_NEXT: 'MenuNavigationComponent.Button.Next',
  MENU_NAVIGATION_BUTTON_PREVIOUS: 'MenuNavigationComponent.Button.Previous',
}

export type ButtonSettings = {
  disabled?: boolean | (() => boolean)
  displayString?: string | (() => string)
  onClick?: () => void
}
type ButtonConfiguration = {
  getDisplayString: () => string
  isDisabled: () => boolean
  onClick: () => void
}

@Component({
  selector: 'configurator-menu-navigation',
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['menu-navigation.component.scss']
})
export class MenuNavigationComponent {
  protected nextButtonConfiguration: Required<ButtonConfiguration>
  protected previousButtonConfiguration: Required<ButtonConfiguration>

  constructor(
    protected readonly navigatorService: NavigatorService,
    readonly configuratorModeService: ConfiguratorModeService
  ) {
    this.nextButtonConfiguration = {
      getDisplayString: (): string => {
        const menuEntries = this.navigatorService.configuredMenuEntries
        if (
          configuratorModeService.mode === ConfiguratorMode.FBS
          && menuEntries.length > 0
          && menuEntries[menuEntries.length - 1] === this.navigatorService.currentActiveMenu) {
          return TRANSLATION_KEY.MENU_ENTRY_PREISBERECHNUNG
        }
        return TRANSLATION_KEY.MENU_NAVIGATION_BUTTON_NEXT
      },
      isDisabled: (): boolean => !navigatorService.canActivateNextMenu(),
      onClick: (): void => navigatorService.activateNextMenu(),
    }
    this.previousButtonConfiguration = {
      getDisplayString: (): string => TRANSLATION_KEY.MENU_NAVIGATION_BUTTON_PREVIOUS,
      isDisabled: (): boolean => !navigatorService.canActivatePreviousMenu(),
      onClick: (): void => navigatorService.activatePreviousMenu(),
    }
  }

  private applySettingsToButton(
    button: 'nextButtonConfiguration' | 'previousButtonConfiguration',
    settings: ButtonSettings
  ): void {
    if (typeof settings?.onClick === 'function') {
      this[button].onClick = settings.onClick
    }
    if (typeof settings?.disabled === 'boolean') {
      const disabled = settings.disabled
      this[button].isDisabled = (): boolean => disabled
    } else if (typeof settings?.disabled === 'function') {
      const isDisabled = settings.disabled
      this[button].isDisabled = (): boolean => isDisabled()
    }
    if (typeof settings?.displayString === 'string') {
      const displayString = settings.displayString
      this[button].getDisplayString = (): string => displayString
    } else if (typeof settings?.displayString === 'function') {
      const getDisplayString = settings.displayString
      this[button].getDisplayString = (): string => getDisplayString()
    }
  }

  protected isActive(menuEntry: NavigationMenuEntryKey): boolean {
    return this.navigatorService.currentActiveMenu === menuEntry
  }

  protected toggleNavigationMenuOpen(): void {
    this.navigatorService.toggleNavigationMenuOpen()
  }

  get configuredMenuEntries(): NavigationMenuEntryKey[] {
    return this.navigatorService.configuredMenuEntries
  }

  @Input({required: false}) set nextButton(settings: ButtonSettings) {
    this.applySettingsToButton('nextButtonConfiguration', settings)
  }

  @Input({required: false}) set previousButton(settings: ButtonSettings) {
    this.applySettingsToButton('previousButtonConfiguration', settings)
  }
}
