import {Glasaufbau} from './model/component/glassaufbau/glasaufbau'
import {ConfiguratedSprossen, FbsGlas, Motiv} from '../class'
import {ModelConfigurationGlasaufbau} from './api/model/configuration/glass/model-configuration-glasaufbau.interface'
import {ModelConfigurationGlass} from './api/model/configuration/glass/model-configuration-glass.interface'

export class ConfiguratedGlasaufbau implements ModelConfigurationGlasaufbau {
  Bezeichnung: string
  GlasscheibeAussen: ModelConfigurationGlass
  GlasscheibeInnen: ModelConfigurationGlass
  GlasscheibeMitte1: ModelConfigurationGlass
  GlasscheibeMitte2: ModelConfigurationGlass
  Id: number
  IsStandard: boolean
  MaxStaerkeSZR: number
  MinAnzahlGlasscheiben: number
  MinStaerkeSZR: number
  Sprossen: ConfiguratedSprossen

  public static fromGlasaufbau(glasaufbau: Glasaufbau | null): ConfiguratedGlasaufbau {
    const configuratedGlasaufbau = new ConfiguratedGlasaufbau()

    configuratedGlasaufbau.GlasscheibeAussen =
      ConfiguratedGlasaufbau.toConfigurationGlass(glasaufbau?.GlasscheibeAussen ?? null, glasaufbau?.MotivAussen ?? null)
    configuratedGlasaufbau.GlasscheibeInnen =
      ConfiguratedGlasaufbau.toConfigurationGlass(glasaufbau?.GlasscheibeInnen ?? null, glasaufbau?.MotivInnen ?? null)
    configuratedGlasaufbau.GlasscheibeMitte1 =
      ConfiguratedGlasaufbau.toConfigurationGlass(glasaufbau?.GlasscheibeMitte1 ?? null, glasaufbau?.MotivMitte1 ?? null)
    configuratedGlasaufbau.GlasscheibeMitte2 =
      ConfiguratedGlasaufbau.toConfigurationGlass(glasaufbau?.GlasscheibeMitte2 ?? null, glasaufbau?.MotivMitte2 ?? null)
    configuratedGlasaufbau.Id = glasaufbau?.Id ?? 0
    configuratedGlasaufbau.IsStandard = glasaufbau?.IsStandard ?? false
    configuratedGlasaufbau.MaxStaerkeSZR = glasaufbau?.MaxStaerkeSZR ?? 0
    configuratedGlasaufbau.MinAnzahlGlasscheiben = glasaufbau?.MinAnzahlGlasscheiben ?? 0
    configuratedGlasaufbau.MinStaerkeSZR = glasaufbau?.MinStaerkeSZR ?? 0
    configuratedGlasaufbau.Sprossen = glasaufbau?.Sprossen
      ? ConfiguratedSprossen.fromSprossen(glasaufbau?.Sprossen)
      : null;
    // Remove after API removed those properties --->
    ((): void => {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
      const cg: any = configuratedGlasaufbau
      cg.GlasscheibeAussenId = configuratedGlasaufbau.GlasscheibeAussen?.Id ?? 0
      cg.GlasscheibeInnenId = configuratedGlasaufbau.GlasscheibeInnen?.Id ?? 0
      cg.GlasscheibeMitte1Id = configuratedGlasaufbau.GlasscheibeMitte1?.Id ?? 0
      cg.GlasscheibeMitte2Id = configuratedGlasaufbau.GlasscheibeMitte2?.Id ?? 0
      cg.MotivAussenId = configuratedGlasaufbau.GlasscheibeAussen?.Motiv?.Id ?? 0
      cg.MotivInnenId = configuratedGlasaufbau.GlasscheibeInnen?.Motiv?.Id ?? 0
      cg.MotivMitte1Id = configuratedGlasaufbau.GlasscheibeMitte1?.Motiv?.Id ?? 0
      cg.MotivMitte2Id = configuratedGlasaufbau.GlasscheibeMitte2?.Motiv?.Id ?? 0
    })()
    // <--- Remove after API removed those properties
    return configuratedGlasaufbau
  }

  public static toConfigurationGlass(glass?: FbsGlas, motiv?: Motiv): ModelConfigurationGlass {
    return glass ? {
      Bezeichnung: glass.Beschreibung,
      Guid: glass.Guid,
      Id: glass.Id,
      Motiv: motiv ? {
        Bezeichnung: motiv.Bezeichnung,
        Guid: motiv.Guid,
        Id: motiv.Id,
      } : null
    } : null
  }
}
