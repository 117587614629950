import { Injectable} from '@angular/core'
import {LocalStorageService, LocalStorageServiceFactory} from '../storage/local-storage.service'
import {CookieConsentData} from './cookie-consent-data.type'
import {HttpService} from '../http.service'
import {firstValueFrom} from 'rxjs'

@Injectable()
export class CookieConsentService {
  private localStorageService: LocalStorageService<CookieConsentData & { showConsentBanner: boolean}>
  private saveDebounceTimeout: ReturnType<typeof setTimeout> | null
  constructor(
    private httpService: HttpService,
    localStorageServiceFactory: LocalStorageServiceFactory,
  ) {
    this.localStorageService = localStorageServiceFactory.create('cookieConsent')
    this.saveDebounceTimeout = null
  }

  addConsent(key: keyof CookieConsentData): Promise<void> {
    this.localStorageService.put(key, true)
    return this.saveConsent()
  }

  hasConsent(key: keyof CookieConsentData): boolean {
    return this.localStorageService.getBoolean(key) === true
  }

  removeConsent(key: keyof CookieConsentData): Promise<void> {
    this.localStorageService.put(key, false)
    return this.saveConsent()
  }

  private saveConsent(): Promise<void> {
    if (this.saveDebounceTimeout !== null) {
      return Promise.resolve()
    }
    return new Promise((resolve, reject): void => {
      this.saveDebounceTimeout = setTimeout((): void => {
        const consentData: CookieConsentData = {
          necessary: true,
          tracking: this.hasConsent('tracking')
        }
        firstValueFrom(this.httpService.saveCookieConsent(consentData)).catch(reject).finally(resolve)
        this.saveDebounceTimeout = null
      })
    })
  }

  get showConsentBanner(): boolean | null {
    return this.localStorageService.getBoolean('showConsentBanner') !== false
  }
  set showConsentBanner(show: boolean) {
    this.localStorageService.put('showConsentBanner', show)
  }
}
