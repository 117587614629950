export const enum SimpleConstruction {
  Einsatz = 0,
  AufsatzEinseitig = 1,
  AufsatzBeidseitig = 2
}

export const enum SingleConstruction {
  Einsatz = 0,
  Aufsatz = 1
}

export const isSimpleConstruction = (construction: number): construction is SimpleConstruction =>
  [SimpleConstruction.Einsatz, SimpleConstruction.AufsatzEinseitig, SimpleConstruction.AufsatzBeidseitig]
    .includes(construction)
