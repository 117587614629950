import {Rahmen, RahmenConstructorParameter} from './rahmen'
import {ColorBase} from '../../color/colorBase'
import {SideType} from '../../../../types'
import {Material} from '../../material'

export class Blendrahmen extends Rahmen {
  constructor(data?: RahmenConstructorParameter<Blendrahmen>) {
    super(data)
    this.Typ = 'blendrahmen'
  }

  isColorSupported(color: ColorBase, side: SideType, material: Material): boolean {
    return color?.IsBlendrahmenMoeglich && super.isColorSupported(color, side, material)
  }
}
