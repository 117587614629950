import {Component, Inject, OnInit} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {HttpService} from '../../../http.service'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ModalService} from '../modal.service'
import {ZubehoerAddonEntry} from '../../../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import {Massblatt} from '../../../classes/model/component/extras/massblatt'
import {SelectControlValueAccessor} from '@angular/forms'

const TRANSLATION_KEY = {
  SELECT_MASSBLATT_LABEL: 'MassblattModal.Select.Massblatt.Label',
  SELECT_SCRIPT_LABEL: 'MassblattModal.Select.Script.Label',
  SAVE_BUTTON_LABEL: 'MassblattModal.Button.Save',
  TITLE: 'MassblattModal.Title'
}

export interface MassblattModalData {
  addon: ZubehoerAddonEntry
  massblaetter: Massblatt[]
}

@Component({
  templateUrl: './massblatt-modal.component.html',
  styleUrls: ['./massblatt-modal.component.scss']
})
export class MassblattModalComponent implements OnInit {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  readonly currentAddon: ZubehoerAddonEntry
  readonly massblaetter: Massblatt[]

  constructor(
    private modalService: ModalService,
    private httpService: HttpService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    @Inject(MAT_DIALOG_DATA) data: MassblattModalData
  ) {
    this.massblaetter = data.massblaetter
    this.currentAddon = data.addon
  }

  compareMassblaetter(
    massblatt1: Parameters<SelectControlValueAccessor['compareWith']>[0],
    massblatt2: Parameters<SelectControlValueAccessor['compareWith']>[0]
  ): boolean {
    return massblatt1 instanceof Massblatt
      && massblatt2 instanceof Massblatt
      && massblatt1?.Id === massblatt2?.Id
  }

  ngOnInit(): void {
    void this.updateMassblaetterFromApi()
  }

  showImageModalDialog(title: string, url: string): void {
    this.modalService.showImageModal(title, url)
  }

  updateMassblaetterFromApi(event?: Event): Promise<void> {
    if (event && event.target) {
      (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value?.toString().replace(',', '.')
    }
    return this.configuratorConfigurationModel.updateMassblaetter()
  }
}

