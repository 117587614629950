<modal-frame
  [footer]='footer'
  [title]='title'
  contentClass='content'
  hostClass='customModal'
  hostId='supportModal'
  modalCloseButton
>

  <a class='item' href='https://rodenberg-ag.atlassian.net/servicedesk/customer/portal/4' target='_blank'>
    <div class='image-wrapper'>
      <div class=''><i class='fa fa-ticket fa-3x fa-fw'></i></div>
    </div>
    <p><span class='option-text'>Rodenberg<br>{{ TRANSLATION_KEY.TICKETSYSTEM|translate }}</span></p>
  </a>

  <a class='item' href='tel:+495731768583'>
    <div class='image-wrapper'>
      <div class=''><i class='fa fa-phone fa-3x fa-fw'></i></div>
    </div>
    <p><span class='option-text'>{{ TRANSLATION_KEY.PHONE|translate }}<br>+49 (0) 5731 768 583</span></p>
  </a>

  <a class='item' href='mailto:cloud@rodenbergmail.de'>
    <div class='image-wrapper'>
      <div class=''><i class='fa fa-envelope fa-3x fa-fw'></i></div>
    </div>
    <p><span class='option-text'>{{ TRANSLATION_KEY.EMAIL | translate }}<br>cloud@rodenbergmail.de</span></p>
  </a>

  <a class='item' href='https://cloud.rodenberg.ag/s/iyMPCkPFYAiGDDc' target='_blank'>
    <div class='image-wrapper'>
      <div class=''><i class='fa fa-desktop fa-3x fa-fw'></i></div>
    </div>
    <p><span class='option-text'>{{ TRANSLATION_KEY.REMOTESUPPORT|translate }}<br>Teamviewer</span></p>
  </a>

  <a
    (click)='$event.preventDefault(); modalService.showUrlParameterModal();'
    *ngIf='modeService.mode === ConfiguratorMode.FBS'
    class='item' href='#' mat-dialog-close
  >
    <div class='image-wrapper'>
      <div class=''><i class='fa fa-code fa-3x fa-fw'></i></div>
    </div>
    <p><span class='option-text'>{{ TRANSLATION_KEY.URLPARAMS|translate }}</span></p>
  </a>

</modal-frame>
<ng-template #title>
  <i class='fa fa-life-ring'></i> {{ TRANSLATION_KEY.SUPPORT | translate }}
</ng-template>
<ng-template #footer>
  <button color='primary' mat-dialog-close mat-flat-button>{{ TRANSLATION_KEY.CLOSE | translate }}</button>
</ng-template>
