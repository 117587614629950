<modal-frame hostId="mehrpreisDiv" hostClass="masse" contentClass="content" [footer]="footer"
             [hideCloseButton]="mode === 'input'"
             [title]="(mode === 'display' ? TRANSLATION_KEY.HEADING_DISPLAY : TRANSLATION_KEY.HEADING_INPUT) | translate"
>
    <form class="spalte links" (keyup.enter)="onAction()">
        <div>
            <h3>
                {{ (this.construction === SimpleConstruction.AufsatzBeidseitig
                ? TRANSLATION_KEY.FORM_HEADING_OUTSIDE : TRANSLATION_KEY.FORM_HEADING) | translate
                }}
            </h3>
            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_A | translate }}</mat-label>
                <input [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz" type="number"
                       matInput [(ngModel)]="constructionDimensions.A" name="A"
                >
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_B | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.B" name="B">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_C | translate }}</mat-label>
                <input [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz" type="number"
                       matInput [(ngModel)]="constructionDimensions.C" name="C">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_D | translate }}</mat-label>
                <input [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz" type="number"
                       matInput [(ngModel)]="constructionDimensions.D" name="D">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_E | translate }}</mat-label>
                <input [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz" type="number"
                       matInput [(ngModel)]="constructionDimensions.E" name="E">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_F | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.F" name="F">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_G | translate }}</mat-label>
                <input [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz" type="number"
                       matInput [(ngModel)]="constructionDimensions.G" name="G">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_H | translate }}</mat-label>
                <input [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz" type="number"
                       matInput [(ngModel)]="constructionDimensions.H" name="H">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>
        </div>
        <div *ngIf="construction === SimpleConstruction.AufsatzBeidseitig">
            <h3>{{ TRANSLATION_KEY.FORM_HEADING_INSIDE }}</h3>
            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_I | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.I" name="I">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_J | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.J" name="J">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_K | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.K" name="K">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_L | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.L" name="L">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_M | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.M" name="M">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_N | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.N" name="N">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_O | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.O" name="O">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_P | translate }}</mat-label>
                <input [disabled]="mode === 'display'" type="number" matInput [(ngModel)]="constructionDimensions.P" name="P">
                <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
            </mat-form-field>
        </div>
    </form>
    <div class="spalte rechts">
        <img class='constructionImage' [src]="'https://images.rodenberg.ag/konstruktionen/'+ construction +'.png'">
    </div>
</modal-frame>
<ng-template #footer>
    <p *ngIf="configuratorMode === ConfiguratorMode.FBS" class="infoText">
        {{ (mode === 'display' ? TRANSLATION_KEY.TEXT_DISPLAY : TRANSLATION_KEY.TEXT_INPUT) | translate }}
    </p>
    <button mat-flat-button (click)="onAction()" color="primary">
        {{ (mode === 'display' ? TRANSLATION_KEY.BUTTON_LABEL_DISPLAY : TRANSLATION_KEY.BUTTON_LABEL_INPUT) | translate }}
    </button>
</ng-template>
