import {ResponseTextItem} from '../../../api/texts/response-text-item.interface'

export class CharacteristicsDataItem implements ResponseTextItem {
  constructor(
    readonly Category: string,
    readonly Prefix: string,
    readonly Text: string,
    readonly Titel: string,
    readonly Typ: string,
  ) {
  }

  static fromResponse(category: string, {Prefix, Text, Titel, Typ}: ResponseTextItem): InstanceType<typeof this> {
    return new this(category, Prefix, Text, Titel, Typ)
  }
}
