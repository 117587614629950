import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EmbeddedViewRef,
  Input,
  OnDestroy,
  SkipSelf,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core'

@Component({
  selector: 'modal-frame, modal-frame[modalCloseButton]',
  templateUrl: './modal-frame.component.html',
  styleUrls: ['./modal-frame.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ModalFrameComponent<T extends TemplateRef<D>, D, R> implements AfterViewInit, OnDestroy {
  protected readonly TemplateRef = TemplateRef
  protected _footer: T
  protected _title: string | TemplateRef<unknown>
  protected closeValue: R
  @Input() contentClass: string = ''
  private embeddedView: EmbeddedViewRef<unknown> | undefined
  @Input() footerContext: D
  protected hideHeadCloseButton: boolean = false
  private hostClasses: string[] = []
  private hostIdValue: string | undefined
  @ViewChild('modalContent') modalContent: TemplateRef<unknown>
  private parentHost: Element | undefined

  constructor(
    private changeDetector: ChangeDetectorRef,
    @SkipSelf() private parentViewRef: ViewContainerRef
  ) {
  }

  private extractViewEncapsulationId(attribute: string): number | null {
    const matchResult = attribute.match(/^_ng(host|content)-ng-c(\d+)$/)
    if (matchResult !== null && matchResult.length > 2) {
      const id = Number.parseInt(matchResult[2], 10)
      if (!Number.isNaN(id)) {
        return id
      }
    }
    return null
  }

  private getViewEncapsulationAttribute(id: number, type: 'host' | 'content'): string {
    return '_ng' + type + '-ng-c' + id
  }

  private getViewEncapsulationContentAttribute(id: number): string {
    return this.getViewEncapsulationAttribute(id, 'content')
  }

  private getViewEncapsulationHostAttribute(id: number): string {
    return this.getViewEncapsulationAttribute(id, 'host')
  }

  private isViewEncapsulationAttribute(attribute: string, type: 'host' | 'content'): boolean {
    return attribute.match(new RegExp('^_ng' + type + '-ng-c\\d+$')) !== null
  }

  private isViewEncapsulationContentAttribute(attribute: string): boolean {
    return this.isViewEncapsulationAttribute(attribute, 'content')
  }

  private isViewEncapsulationHostAttribute(attribute: string): boolean {
    return this.isViewEncapsulationAttribute(attribute, 'host')
  }

  ngAfterViewInit(): void {
    // render inside parents parent container
    const parentElement = (this.parentViewRef.element as ElementRef<Element>)?.nativeElement?.parentElement
    if (parentElement instanceof Element) {
      this.parentHost = parentElement
    }
    // defer view creation out of lifecycle hook
    setTimeout((): void => {
      this.embeddedView = this.parentViewRef.createEmbeddedView(this.modalContent)
      // fix view encapsulation emulation
      const hostElement = (this.parentViewRef.element as ElementRef<Element>)?.nativeElement
      let hostId: null | number = null
      for (const attribute of Array.from(hostElement.attributes)) {
        if (this.isViewEncapsulationHostAttribute(attribute.name)) {
          hostId = this.extractViewEncapsulationId(attribute.name)
        }
      }
      if (hostId) {
        const contentAttribute = this.getViewEncapsulationContentAttribute(hostId)
        for (const node of this.embeddedView.rootNodes) {
          if (node instanceof Element) {
            node.setAttribute(contentAttribute, '')
          }
        }
      }
      // set host class and id
      this.hostClass = this.hostClasses.join(' ')
      this.hostId = this.hostIdValue

      this.changeDetector.detectChanges()
    })
  }

  ngOnDestroy(): void {
    this.embeddedView?.destroy()
  }

  @Input() set footer(footer: T) {
    this._footer = footer
    this.embeddedView?.detectChanges()
  }

  @Input() set hideCloseButton(val: boolean) {
    this.hideHeadCloseButton = val
  }

  @Input() set hostClass(value: string) {
    this.parentHost?.classList.remove(...this.hostClasses)
    this.hostClasses = value.split(' ').filter((className): boolean => className !== '')
    this.parentHost?.classList.add(...this.hostClasses)
  }

  @Input() set hostId(value: string | undefined) {
    this.hostIdValue = value
    if (this.parentHost) {
      this.parentHost.id = value
    }
  }

  @Input() set modalCloseButton(returnValue: R) {
    this.closeValue = returnValue
  }

  @Input({required: true}) set title(title: string | TemplateRef<unknown>) {
    this._title = title
    this.embeddedView?.detectChanges()
  }
}
