import {EventEmitter, Injectable, Injector} from '@angular/core'
import {ConstructionComponent} from '../model/component/construction/constructionComponent'
import {ConfiguratorConfigurationModel} from '../model/configuratorConfigurationModel'

@Injectable()
export class ComponentSelectionService {
  private currentIndex: number = 0
  public readonly selectedComponentChange: EventEmitter<ConstructionComponent>

  constructor(
    private injector: Injector
  ) {
    this.selectedComponentChange = new EventEmitter<ConstructionComponent>()
  }

  updateSelectedIndex(): void {
    if (this.injector.get(ConfiguratorConfigurationModel, null)?.configuratedDoor?.Components?.length <= this.currentIndex){
      this.selectedComponent = 0
    }
  }


  get selectedComponent(): ConstructionComponent {
    return this.injector.get(ConfiguratorConfigurationModel, null)?.configuratedDoor?.Components[this.currentIndex]
  }

  set selectedComponent(componentIndex: number) {
    this.currentIndex = componentIndex
    this.selectedComponentChange.emit(this.selectedComponent)
  }

  get selectedComponentIndex(): number {
    return this.currentIndex
  }
}


