export const enum ConstructionComponentType {
  Door = 'tuer',
  SidePanel = 'seitenteil',
  Fanlight = 'oberlicht'
}

export const isConstructionComponentType = (value: unknown): value is ConstructionComponentType =>
  typeof value === 'string'
  && [
    ConstructionComponentType.Door as string,
    ConstructionComponentType.SidePanel,
    ConstructionComponentType.Fanlight
  ].includes(value)
