import {Injectable} from '@angular/core'
import {HttpService} from '../http.service'
import {
  EncodedWorkflow,
  Workflow,
  WorkflowCondition,
  WorkflowExecutor,
  WorkflowSubscriber,
  WorkflowSubscription,
  WorkflowTrigger
} from './workflow.types'
import {WorkflowAccessorBuilder} from './workflow-accessor-builder'
import {MEHRPREISE_TRIGGER_KEY, WorkflowTriggerParser} from './workflow-trigger-parser'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {ParameterService} from '../classes/service/parameter/parameter.service'
import {NGXLogger} from 'ngx-logger'
import WorkflowModule from './workflow.module'
import WorkflowParser from './workflow-parser'
import {ResponseWorkflow} from '../classes/api/workflow/response-workflow.interface'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'
import {ToastrService} from 'ngx-toastr'
import {WorkflowExecutionParser} from './workflow-execution-parser'

@Injectable({providedIn: WorkflowModule})
export class WorkflowService {
  constructor(
    private apiService: HttpService,
    private accessorBuilder: WorkflowAccessorBuilder,
    private workflowExecutionParser: WorkflowExecutionParser,
    private triggerParser: WorkflowTriggerParser,
    private logger: NGXLogger
  ) {
  }

  public load(): Promise<WorkflowSubscriber[]> {
    return new Promise((resolve, reject): void => {
      this.apiService.getWorkflows().subscribe({
        next: (workflows): void => {
          resolve(
            workflows
              .map((w): EncodedWorkflow => this.transform(w))
              .map((encodedWorkflow): WorkflowSubscriber & Workflow => {
                const conditions: WorkflowCondition[] = []
                for (const condition of Object.getOwnPropertyNames(encodedWorkflow.Condition)) {
                  const accessor = this.accessorBuilder.buildAccessor(condition)
                  if (typeof accessor === 'undefined') {
                    this.logger.warn(`Could not build accessor for condition '${condition}'`)
                    continue
                  }
                  conditions.push((ccm, cdm, ps): boolean =>
                    condition.startsWith(WorkflowParser.metaCharacters.not) !==
                      (
                        WorkflowParser.metaCharacters.isSet === encodedWorkflow.Condition[condition]
                          ? typeof accessor(ccm, cdm, ps) !== 'undefined'
                          : accessor(ccm, cdm, ps) === encodedWorkflow.Condition[condition]
                      )
                  )
                }
                const checkConditions = (
                  ccm: ConfiguratorConfigurationModel,
                  cdm: ConfiguratorDataModel,
                  ps: ParameterService
                ): boolean =>
                  conditions.reduce((acc, curr): boolean => acc && curr(ccm, cdm, ps), true)

                const executors: WorkflowExecutor[] = []
                for (const execution of Object.getOwnPropertyNames(encodedWorkflow.Execution)) {
                  const executionFunction = this.workflowExecutionParser.buildExecution(execution)
                  if (typeof executionFunction === 'undefined') {
                    this.logger.warn(`Could not build setter for executor '${execution}'!`)
                    continue
                  }
                  executors.push((ccm, cdm, ps, ts): void | Promise<void> =>
                    executionFunction(ccm, cdm, ps, ts, encodedWorkflow.Execution[execution](ccm, cdm, ps))
                  )
                }
                const runExecutors = (
                  ccm: ConfiguratorConfigurationModel,
                  cdm: ConfiguratorDataModel,
                  ps: ParameterService,
                  ts: ToastrService
                ): Promise<void> =>
                  Promise.all<void>(executors.map((e): void | Promise<void> => e(ccm, cdm, ps, ts)))
                    .then<void>(() => void 0)

                const execute = async (
                  ccm: ConfiguratorConfigurationModel,
                  cdm: ConfiguratorDataModel,
                  ps: ParameterService,
                  ts: ToastrService
                ): Promise<void> =>
                  checkConditions(ccm, cdm, ps) && await runExecutors(ccm, cdm, ps, ts)

                const workflow: Workflow = {execute}


                const triggerSubscribers: WorkflowTrigger[] = []
                for (const triggerString of encodedWorkflow.Trigger) {
                  triggerSubscribers.push(this.triggerParser.buildTrigger(triggerString))
                }
                const subscriber: WorkflowSubscriber = {
                  subscribe: (ccm, cdm, ps, ts): WorkflowSubscription => {
                    const subscriptions: WorkflowSubscription[] = triggerSubscribers.map(
                      (sub): WorkflowSubscription => sub.subscribeTrigger(ccm, cdm, ps, ts, workflow)
                    )
                    return {
                      unsubscribe: (): void => {
                        subscriptions.forEach((sub: WorkflowSubscription): void => sub.unsubscribe())
                      }
                    }
                  }
                }

                return {
                  ...subscriber,
                  ...workflow
                }
              })
          )
        },
        error: reject,
        // complete: resolve
      })
    })
  }

  private processAddRemove(this: void, encodedWorkflow: EncodedWorkflow): EncodedWorkflow {
    const processedWorkflow: EncodedWorkflow = {
      ...encodedWorkflow,
      Trigger: []
    }
    for (const trigger of encodedWorkflow.Trigger) {
      const triggerLc = trigger.toLowerCase()
      if (
        !triggerLc.startsWith(MEHRPREISE_TRIGGER_KEY) &&
        (triggerLc.endsWith('.add') || triggerLc.endsWith('.remove'))
      ) {
        const triggerProp = trigger.slice(0, trigger.lastIndexOf('.'))
        const condition = (triggerLc.endsWith('.remove') ? WorkflowParser.metaCharacters.not : '') + triggerProp
        processedWorkflow.Trigger.push(triggerProp + '.Change')
        processedWorkflow.Condition[condition] = WorkflowParser.metaCharacters.isSet
      } else {
        processedWorkflow.Trigger.push(trigger)
      }
    }
    return processedWorkflow
  }

  private processApiDecode(responseWorkflow: ResponseWorkflow): EncodedWorkflow {
    const encodeCondition = (arr: ResponseWorkflow['Condition']): EncodedWorkflow['Condition'] =>
      !Array.isArray(arr) ? arr : arr.reduce(
        (acc, keyValuePair): Record<string, object> => {
          if (typeof keyValuePair.Key !== 'undefined') {
            acc[keyValuePair.Key] = keyValuePair.Value
          }
          return acc
        },
        {} as Record<string, object>
      )
    const encodeExecution = (arr: ResponseWorkflow['Execution']): EncodedWorkflow['Execution'] =>
      arr.reduce(
        (acc, execution): EncodedWorkflow['Execution'] => {
          if (typeof execution.Key !== 'undefined') {
            if (execution.Typ === 'reference') {
              const accessor = this.accessorBuilder.buildAccessor(String(execution.Value))
              if (typeof accessor === 'undefined') {
                this.logger.warn(`Could not build accessor for execution reference '${String(execution.Value)}'`, {execution})
              }
              acc[execution.Key] = accessor
            } else {
              acc[execution.Key] = (): unknown => execution.Value
            }
          }
          return acc
        },
        {} as Record<string, (ccm: ConfiguratorConfigurationModel, cdm: ConfiguratorDataModel
          , ps: ParameterService) => unknown>
      )
    return {
      Trigger: responseWorkflow.Trigger,
      Condition: encodeCondition(responseWorkflow.Condition),
      Execution: encodeExecution(responseWorkflow.Execution)
    }
  }

  private processRedundantTriggers(this: void, encodedWorkflow: EncodedWorkflow): EncodedWorkflow {
    const hasInitialiseNewTrigger = encodedWorkflow.Trigger.some(
      (t): boolean => t.toLowerCase() === 'initialisierung.new'
    )
    return {
      ...encodedWorkflow,
      Trigger: encodedWorkflow.Trigger.filter((value, index, array): boolean => !(
        hasInitialiseNewTrigger
        && ['materialtürfüllung', 'materialtuerfüllung', 'materialtürfuellung', 'materialtuerfuellung']
          .map((k): string => 'basis.' + k + '.change').includes(value.toLowerCase())
      ) && array.indexOf(value) === index)
    }
  }

  private transform(responseWorkflow: ResponseWorkflow): EncodedWorkflow {
    return [
      // this.process$,
      this.processAddRemove,
      this.processRedundantTriggers
    ].reduce(
      (acc, curr): EncodedWorkflow => curr(acc),
      this.processApiDecode(responseWorkflow)
    )
  }

  /* private process$(this: void, encodedWorkflow: EncodedWorkflow): EncodedWorkflow {
    const processedWorkflow: EncodedWorkflow = {
      ...encodedWorkflow,
      Condition: {}
    }
    for (const key in encodedWorkflow.Condition) {
      if (key.startsWith('$')) {
        const condition = key.slice(1)
        processedWorkflow.Trigger.push(condition + '.Change')
        processedWorkflow.Condition[condition] = encodedWorkflow.Condition[key]
      } else {
        processedWorkflow.Condition[key] = encodedWorkflow.Condition[key]
      }
    }
    return processedWorkflow
  } */
}
