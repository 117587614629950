<ng-container *ngIf='components?.length > 1'>
  <ng-container *ngFor='let component of this.components' [ngSwitch]='component.objectType'>
    <button
      (click)='this.selected = component'
      *ngSwitchCase='ConstructionComponentType.Fanlight'
      [class.active]='this.selected === component'
      class='fanlight'
    >
      {{ TRANSLATION_KEY.FANLIGHT | translate }}
    </button>
    <button
      (click)='this.selected = component'
      *ngSwitchCase='ConstructionComponentType.Door'
      [class.active]='this.selected === component'
    >
      {{ TRANSLATION_KEY.DOOR | translate }}
    </button>
    <button
      (click)='this.selected = component'
      *ngSwitchCase='ConstructionComponentType.SidePanel'
      [class.active]='this.selected === component'
    >
      {{ TRANSLATION_KEY.SIDEPANEL | translate }}
    </button>
  </ng-container>
</ng-container>
