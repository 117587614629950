import {MAT_RADIO_GROUP, MatRadioChange, MatRadioGroup} from '@angular/material/radio'
import {AfterContentInit, Directive, EventEmitter, forwardRef, Output} from '@angular/core'
import {NG_VALUE_ACCESSOR} from '@angular/forms'

type Cancelable<T> = T & {
  cancel: () => void
}
type WithPrevious<T> = T & {
  previous: unknown
}
export type BetterMatRadioChange = WithPrevious<Cancelable<MatRadioChange>>
@Directive({
  selector: '[appBetterMatRadioGroup]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef((): typeof BatterMatRadioGroupDirective => BatterMatRadioGroupDirective),
      multi: true,
    },
    {provide: MAT_RADIO_GROUP, useExisting: BatterMatRadioGroupDirective},
  ],
})
export default class BatterMatRadioGroupDirective<V> extends MatRadioGroup implements AfterContentInit {
  // Override default change event
  #isInitialized = false
  #oldValue: V
  #olderValue: V
  #preventChange = false
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public readonly change: EventEmitter<BetterMatRadioChange>

  _emitChangeEvent(): void {
    if (this.#isInitialized && !this.#preventChange) {
      this.change.emit(Object.assign(new MatRadioChange(this.selected, this.value), {
        cancel: (): void => {
          const oldValue = this.#oldValue
          this.#oldValue = this.#olderValue
          this.#preventChange = true
          super.value = oldValue
          this.#preventChange = false
        },
        previous: this.#oldValue
      }))
    }
  }

  ngAfterContentInit(): void {
    // Mark this component as initialized in AfterContentInit because the initial value can
    // possibly be set by NgModel on MatRadioGroup, and it is possible that the OnInit of the
    // NgModel occurs *after* the OnInit of the MatRadioGroup.
    this.#isInitialized = true
    super.ngAfterContentInit()
  }

  get value(): V {
    return super.value as V
  }

  set value(newValue: V) {
    this.#olderValue = this.#oldValue
    this.#oldValue = this.value
    super.value = newValue
  }
}
