import {Massblatt} from '../massblatt'
import {Dienstleistung} from '../mehrpreis/dienstleistung'

import {ResponseMassblaetter} from '../../../../api/massblaetter/response-massblaetter.interface'

export class ZubehoerAddonEntry {
  Id: number
  Massblatt?: Massblatt
  Typ: string

  constructor(data: Omit<ZubehoerAddonEntry, 'updateMassblatt'>) {
    this.Id = data.Id
    this.Typ = data.Typ
    if (typeof data.Massblatt !== 'undefined') {
      this.Massblatt = new Massblatt(data.Massblatt)
    }
  }

  static fromDienstleistung(dienstleistung: Dienstleistung): ZubehoerAddonEntry {
    return new ZubehoerAddonEntry({
      Id: dienstleistung.Id,
      Typ: dienstleistung.Typ,
      ...(dienstleistung.Massblaetter.length ? {Massblatt: dienstleistung.Massblaetter[0]} : {})
    })
  }

  /**
   * Updates the values of the dimension sheet of with this addon
   * @param values the values to change
   * @returns whether any value did change
   */
  updateMassblatt(massblaetterUpdate: ResponseMassblaetter): boolean {
    if (this.Massblatt) {
      return massblaetterUpdate.Massblaetter
        ?.filter((massblattUpdate): boolean => massblattUpdate.Id === this.Massblatt.Id)
        .reduce(
          (changed, massblattUpdate): boolean => this.Massblatt.updateValues(massblattUpdate.Values ?? {})
          , false
        )
    }
    return false
  }
}
