import {ParameterMode} from './parameter-mode'
import {ParamMap} from '@angular/router'

export class Parameter {
  brutto: boolean // defaults to false
  clerk?: string
  close?: string
  color?: string
  ext_id?: string
  kommission?: string
  lang?: string
  logo?: string
  mode?: ParameterMode
  modelle?: string
  profil?: string
  request?: string
  request_catalog?: string
  request_dealer?: string
  session_token?: string
  showrahmen: boolean // defaults to false
  software?: string
  to?: string
  token?: string
  transkey?: string
  tuerId?: string

  constructor(data?: ParamMap) {
    if (typeof data !== 'undefined') {
      this.brutto = data.has('brutto') && data.get('brutto') === 'true'
      if (data.has('clerk')) {
        this.clerk = data.get('clerk')
      }
      if (data.has('ext_id')) {
        this.ext_id = data.get('ext_id')
      }
      if (data.has('close')) {
        this.close = data.get('close')
      }
      if (data.has('color')) {
        this.color = data.get('color')
      }
      if (data.has('kommission')) {
        this.kommission = data.get('kommission')
      }
      if (data.has('lang')) {
        this.lang = data.get('lang')
      }
      if (data.has('logo')) {
        this.logo = data.get('logo')
      }
      if (data.has('mode')) {
        this.mode = data.get('mode')
      }
      if (data.has('modelle')) {
        this.modelle = data.get('modelle')
      }
      if (data.has('profil')) {
        this.profil = data.get('profil')
      }
      if (data.has('request')) {
        this.request = data.get('request')
      }
      if (data.has('request_catalog')) {
        this.request_catalog = data.get('request_catalog')
      }
      if (data.has('request_dealer')) {
        this.request_dealer = data.get('request_dealer')
      }
      this.showrahmen = data.has('showrahmen') && data.get('showrahmen') === 'true'
      if (data.has('session_token')) {
        this.session_token = data.get('session_token')
      }
      if (data.has('sw')) {
        this.software = data.get('sw')
      }
      if (data.has('to')) {
        this.to = data.get('to')
      }
      if (data.has('token')) {
        this.token = data.get('token')
      }
      if (data.has('transkey')) {
        this.transkey = data.get('transkey')
      }
      if (data.has('tuerId')) {
        this.tuerId = data.get('tuerId')
      }
    }
  }
}
