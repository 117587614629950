import {
  Component, ElementRef, Input
} from '@angular/core'

@Component({
  template: '',
  styleUrls: ['./image-loading-animation.component.scss'],
})
export class ImageLoadingAnimationComponent {
  constructor(
    private hostElement: ElementRef<HTMLElement>
  ) {
  }

  @Input() set loadingAnimationBackground(background: CSSColorDefinition | boolean) {
    if (typeof background === 'string') {
      this.hostElement.nativeElement.setAttribute('loading-animation-background', '')
      this.hostElement.nativeElement.style.setProperty('--loading-animation-background-color', background)
    } else if (background) {
      this.hostElement.nativeElement.setAttribute('loading-animation-background', '')
      this.hostElement.nativeElement.style.removeProperty('--loading-animation-background-color')
    } else {
      this.hostElement.nativeElement.removeAttribute('loading-animation-background')
      this.hostElement.nativeElement.style.removeProperty('--loading-animation-background-color')
    }
  }

  @Input() set placementStrategy(placementStrategy: 'here' | 'container') {
    if (placementStrategy === 'container') {
      this.hostElement.nativeElement.setAttribute('loading-animation-placement', 'container')
    } else {
      this.hostElement.nativeElement.removeAttribute('loading-animation-placement')
    }
  }

  @Input() set sizing(sizing: 'fill' | 'css-variable' | undefined) {
    switch (sizing) {
      case 'fill':
        this.hostElement.nativeElement.setAttribute('loading-animation-sizing', 'fill')
        break
      case 'css-variable':
        this.hostElement.nativeElement.setAttribute('loading-animation-sizing', 'var')
        break
      default:
        this.hostElement.nativeElement.removeAttribute('loading-animation-sizing')
    }
  }
}

type CSSColorDefinition = string
