<modal-frame
  [footer]='footer'
  [modalCloseButton]='false'
  [title]='TRANSLATION_KEY.TITLE | translate'
  hostId='datenschutzDiv'
>
  <div class='content'>

    <h1>Datenschutzerklärung für den Traumtür-Konfigurator</h1>
    <h3>1. Datenschutz auf einen Blick</h3>
    <h5>Allgemeine Hinweise</h5>
    <p>
      Wir nutzen für die Gestaltung Ihrer Haustür den Traumtür-Konfigurator, eine Web-Application der Rodenberg Türsysteme AG,
      Osterkamp 3, 32457 Porta Westfalica.
    </p>
    <p>
      Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie
      diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden
      können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
      Datenschutzerklärung.
    </p>

    <h5>Datenerfassung auf dieser Website</h5>
    <h5>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h5>
    <p>
      Die verantwortliche Stelle für die Datenerfassung entnehmen Sie bitte dem Impressum.
    </p>


    <h5>Wie erfassen wir Ihre Daten?</h5>
    <p>
      Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die
      über das Angebotsformular eingeben.
    </p>
    <p>
      Andere Daten werden automatisch beim Besuch der Website erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser,
      Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere
      Website betreten.
    </p>

    <h5>Wofür nutzen wir Ihre Daten?</h5>
    <p>
      Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur
      Analyse Ihres Nutzerverhaltens verwendet werden.
    </p>


    <h5>Welche Rechte haben Sie bezüglich Ihrer Daten?</h5>
    <p>
      Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen
      Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen.
      Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen
      Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
    </p>

    <h5>Analyse-Tools und Tools von Drittanbietern</h5>
    <p>
      Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und
      mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten
      kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung
      bestimmter Tools verhindern. Detaillierte Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
      Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung
      informieren.
    </p>

    <h5>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h5>
    <p>
      Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte
      Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
      der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
    </p>

    <h5>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h5>
    <p>
      Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes,
      in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können
      folgendem Link entnommen werden:
      <a href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html' target='_blank'>
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
      </a>.
    </p>

    <h5>
      Recht auf Datenübertragbarkeit
    </h5>

    <p>
      Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten,
      an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
      direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
      machbar ist.
    </p>

    <h5>SSL- bzw. TLS-Verschlüsselung</h5>
    <p>
      Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
      oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte
      Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an
      dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
      die Sie an uns übermitteln, nicht von Dritten mitgelesen werden
    </p>

    <h5>
      Auskunft, Sperrung, Löschung
    </h5>
    <p>
      Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
      über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
      Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu
      weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen
      Adresse an uns wenden.
    </p>

    <h4>
      2. Datenerfassung auf unserer Website
    </h4>

    <h5>Cookies</h5>
    <p>
      Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten
      keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies
      sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
      <br> Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs
      automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies
      ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
      <br> Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur
      im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische
      Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
      dieser Website eingeschränkt sein.
      <br> Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter,
      von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6
      Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies
      zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies
      zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
    </p>


    <h5>Server-Log-Dateien</h5>
    <p>
      Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser
      automatisch an uns übermittelt. Dies sind:
      <br>
    </p>
    <ul>
      <li>Browsertyp und Browserversion</li>
      <li>verwendetes Betriebssystem</li>
      <li>Referrer URL</li>
      <li>Hostname des zugreifenden Rechners</li>
      <li>Uhrzeit der Serveranfrage</li>
      <li>IP-Adresse</li>
    </ul>
    <p>
      Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.Grundlage für die Datenverarbeitung ist
      Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erf&uuml;llung eines Vertrags oder vorvertraglicher
      Maßnahmen gestattet.
    </p>

    <h5>Formular Angebot anfordern</h5>
    <p>
      Wenn Sie uns per Formular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
      angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert.
      Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
      <br> Die Verarbeitung der in das Formular eingegebenen Daten erfolgt gem. Art. 6 Abs. 1 lit. b DSGVO.
    </p>

    <h4>3. Analyse Tools und Werbung</h4>

    <h5>Google Analytics</h5>
    <p>
      Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway,
      Mountain View, CA 94043, USA.
      <br> Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden
      und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
      über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort
      gespeichert.
      <br> Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber
      hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine
      Werbung zu optimieren.
    </p>

    <h5>IP Anonymisierung</h5>
    <p>Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb
      von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
      Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
      Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google
      diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten
      zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber
      dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse
      wird nicht mit anderen Daten von Google zusammengeführt.</p>

    <h5>Browser Plugin</h5>
    <p>
      Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
      Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich
      werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung
      der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
      verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
      <a href='https://tools.google.com/dlpage/gaoptout?hl=de'>https://tools.google.com/dlpage/gaoptout?hl=de</a>.
    </p>

    <h5>Widerspruch gegen Datenerfassung</h5>
    <p>
      Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein
      Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert: Google
      Analytics deaktivieren.
      <br> Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von
      Google:
      <a href='https://support.google.com/analytics/answer/6004245?hl=de'>https://support.google.com/analytics/answer/6004245?hl=de</a>
    </p>

    <h5>Auftragsdatenverarbeitung</h5>
    <p>Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der
      deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.</p>


    <h5>Demografische Merkmale bei Google Analytics</h5>
    <p>
      Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden,
      die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener
      Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet
      werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren
      oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt
      generell untersagen.
    </p>

    <h4>4. Plugins und Tools</h4>

    <h5>Google Web Fonts</h5>
    <p>
      Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden.
      Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten
      korrekt anzuzeigen.
      <br> Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch
      erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google
      Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies
      stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      <br> Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
      <br> Weitere Informationen zu Google Web Fonts finden Sie unter https://developers.google.com/fonts/faq und in der
      Datenschutzerklärung von Google: https://www.google.com/policies/privacy/.
      <br>
    </p>

    <h5>Web Fonts von Adobe Typekit</h5>
    <p>
      Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Adobe Typekit bereitgestellt
      werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und
      Schriftarten korrekt anzuzeigen.
    </p>
    <p>
      Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Adobe Typekit aufnehmen. Hierdurch erlangt
      Adobe Typekit Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Adobe
      Typekit Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote.
      Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
    </p>
    <p>
      Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.
      <br>
      <br> Weitere Informationen zu Adobe Typekit Web Fonts finden Sie unter https://typekit.com/ und in der Datenschutzerklärung
      von Adobe Typekit: https://www.adobe.com/de/privacy/policies/typekit.html
    </p>


    <h4>5. Änderungen</h4>
    <p>
      Von Zeit zu Zeit ist es erforderlich, den Inhalt der vorliegenden Hinweise zum Datenschutz für zukünftig
      erhobene Daten anzupassen. Wir behalten uns daher vor, diese Hinweise jederzeit zu ändern. Wir werden die
      geänderte Version der Datenschutzhinweise ebenfalls an dieser Stelle veröffentlichen. Wenn Sie uns wieder
      besuchen, sollten Sie sich daher die Datenschutzhinweise erneut durchlesen.
    </p>

    <p>
      <ng-container *ngIf="this.privacyUrl !== ''">
        {{ TRANSLATION_KEY.MORE_INFO | translate }}
        <br> <a href='{{ this.privacyUrl }}'>{{ this.privacyUrl }}</a>
      </ng-container>
    </p>
    <p>Stand Mai 2018</p>


  </div>
</modal-frame>
<ng-template #footer>
  <button (click)='this.onDismiss()' color='primary' mat-flat-button>{{ TRANSLATION_KEY.CLOSE | translate }}</button>
</ng-template>
