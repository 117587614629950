import {ComponentModel} from '../../classes/model/component/model/component-model'

export class ConfigurationNotFoundError extends Error {
  constructor(cause?: unknown) {
    super('The configuration was not found!', {cause})
  }
}

export class InvalidModelIdInLoadedConfigError extends Error {
  constructor(options?: ErrorOptions) {
    super('Error loading model: Invalid model id', options)
  }
}

export class MaterialConflictError extends Error {
  constructor(options?: ErrorOptions) {
    super('There is a material conflict with the loaded configuration', options)
  }
}

export class ModelHasDisabilitiesError extends Error {
  constructor(private model: ComponentModel) {
    super(model?.disabilityReasons.reduce((acc, curr): string => `${acc}\n${curr}`, ''))
  }
}

export class ServiceHasNoLoadedConfigError extends Error {
  constructor(options?: ErrorOptions) {
    super('Service has no config loaded!', options)
  }
}

export class WrappedError extends Error {
  constructor(message: string, options: { cause: unknown }) {
    super(message, options)
  }
}
