import {Injectable} from '@angular/core'
import {Params, Router} from '@angular/router'
import {ParameterModelConfiguration} from '../../model/modelConfiguration/parameterModelConfiguration'
import {Parameter} from './parameter'
import {NGXLogger} from 'ngx-logger'
import {ExtraParameter} from './extra-parameter'

@Injectable()
export class ParameterService {
  readonly extraParameter: ExtraParameter
  readonly modelle: ParameterModelConfiguration[] = []
  readonly parameter: Parameter
  readonly params: Readonly<Params>

  constructor(
    private logger: NGXLogger,
    private router: Router,
  ) {
    if (router.navigated || router.getCurrentNavigation() !== null) {
      const navigation = this.router.getCurrentNavigation() ?? this.router.lastSuccessfulNavigation
      const urlTree = navigation?.finalUrl ?? navigation?.extractedUrl ?? this.router.parseUrl(this.router.url)
      this.params = urlTree.queryParams
      this.parameter = new Parameter(urlTree.queryParamMap)
      const [extraParameter, warnings] = ExtraParameter.create(urlTree.queryParamMap)
      this.extraParameter = extraParameter
      warnings.forEach((warning): void => this.logger.warn(warning))
      this.populateModels()
    } else {
      this.params = {}
      this.parameter = new Parameter()
      const [extraParameter, warnings] = ExtraParameter.create()
      this.extraParameter = extraParameter
      warnings.forEach((warning): void => this.logger.warn(warning))
      this.logger.error('Router not initialized! Url and query params unavailable!')
    }
  }

  private populateModels(): void {
    if (this.parameter?.modelle) {
      const models: unknown = JSON.parse(
        // Fix JSON
        this.parameter.modelle.replace(
          /(['"])?([a-zA-Z0-9_]+)(['"])?:/g,
          '"$2": '
        )
      )
      if (models instanceof Array) {
        models.forEach((modelData: unknown) => void this.modelle.push(new ParameterModelConfiguration(modelData)))
      }
    }
  }

  setTuerId(tuerId: string): void {
    if (typeof tuerId !== 'string' || tuerId === '') {
      return
    }
    this.parameter.tuerId = tuerId
    this.router.navigate(
      [],
      {
        queryParams: {
          tuerId
        },
        queryParamsHandling: 'merge'
      }
    ).catch(err => void this.logger.error(err))
  }

  get model(): ParameterModelConfiguration | undefined {
    if (this.modelle[0]) {
      return this.modelle[0]
    }
  }

  get queryParameter(): Params {
    const params = Object.assign({}, this.parameter)
    params.modelle = JSON.stringify(this.modelle)
    return params
  }
}
