import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {DialogueModalData} from '../dialogue-modal/dialogue-modal.component'
import * as ConstructionModule from '../../../classes/model/component/other/construction'
import {isSimpleConstruction, SimpleConstruction} from '../../../classes/model/component/other/construction'

const TRANSLATION_KEY = {
  HEADING: 'ConstructionChooserModalComponent.Heading',
  TEXT: 'ConstructionChooserModalComponent.Text',
  LABEL: 'ConstructionChooserModalComponent.ConstructionDropdown.Label',
  PLACEHOLDER: 'ConstructionChooserModalComponent.ConstructionDropdown.Placeholder',
  ERROR: 'ConstructionChooserModalComponent.ConstructionDropdown.Error',
  BUTTON_LABEL: 'ConstructionChooserModalComponent.AcceptButton.Label',
  CONSTRUCTION_INSERT: 'ConstructionChooserModalComponent.Construction.Insert',
  CONSTRUCTION_ATTACH: 'ConstructionChooserModalComponent.Construction.AttachOne',
  CONSTRUCTION_ATTACH_BOTH: 'ConstructionChooserModalComponent.Construction.AttachBoth'
} as const

@Component({
  templateUrl: './construction-chooser-modal.component.html',
  styleUrls: ['./construction-chooser-modal.component.scss']
})
export class ConstructionChooserModalComponent {
  protected readonly SimpleConstruction = (ConstructionModule as {
    SimpleConstruction: Required<typeof SimpleConstruction>
  }).SimpleConstruction
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected construction: SimpleConstruction

  constructor(
    public dialogRef: MatDialogRef<ConstructionChooserModalComponent, SimpleConstruction>,
    @Inject(MAT_DIALOG_DATA) public data: DialogueModalData
  ) {
  }

  onSubmit(): void {
    if (!isSimpleConstruction(this.construction)) {
      return
    }
    this.dialogRef.close(this.construction)
  }
}
