export interface InsideOutsideObject<Type> {
  Inside: Type
  Outside: Type
}

export type InsideOutsideArray<Type> = InsideOutsideObject<Type[]>

export interface SimpleIdentifier {
  Id: number
  Typ: ColorType
}

export type OptionType = string
export type ValueChange<V> = {
  new: V
  old: V
}

export const enum Din {
  Left = 0,
  Right = 1
}

export enum SideType {
  Inside = 'Inside',
  Outside = 'Outside'
}

export type ColorFilter = 'farben' | 'pulver' | 'folien' | 'designoberflaechen'
export type ColorType = 'farbe' | 'folie' | 'pulver' | 'designoberflaeche'
export type GlasPositionLong = 'GlasscheibeMitte1' | 'GlasscheibeMitte2' | 'GlasscheibeAussen' | 'GlasscheibeInnen'
export type MotivPositionLong =  'MotivInnen' | 'MotivMitte1' | 'MotivMitte2' | 'MotivAussen'
export type GlasaufbauPositionShort = 'innen' | 'aussen' | 'mitte1' | 'mitte2'

export type Oeffnungsart = 'innen' | 'aussen'

export interface errorEntry {
  [key: string]: string
}

export interface minMax {
  maxHeight: number
  maxWidth: number
  minHeight: number
  minWidth: number
}

export enum SizeType {
  Height = 'height',
  Width = 'width'
}

export enum ConfiguratorMode {
  FBS = 'FBS',
  TTK = 'TTK'
}
