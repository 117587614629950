import {ZubehoerAddonEntry} from './zubehoerAddonEntry'
import {Zubehoer} from './zubehoer'
import {ChangeType} from '../../../event/events.types'
import {ResponseMassblaetter} from '../../../../api/massblaetter/response-massblaetter.interface'
import AddonChangeEvent from '../../../event/addon-change.event'

export class MehrpreisEntry {
  #Item: Zubehoer | undefined
  Typ: string
  addons?: ZubehoerAddonEntry[]

  constructor(data?: Partial<MehrpreisEntry>) {
    this.Typ = data && data.Typ
    this.#Item = data && data.Item && new Zubehoer(data.Item)
    this.addons = []
    if (data && data.addons) {
      data.addons.forEach((a): void => {
        this.addons.push(new ZubehoerAddonEntry(a))
      })
    }
  }

  /**
   * Adds an addon to the MehrpreisEntry.
   * If the addon is already added, it will not be added again.
   * If the addon is a montage addon and the item does not support montages, the addon will not be added.
   *
   * @param addon - The addon to add
   * @return The AddonChangeEvent if the addon was added, otherwise undefined
   */
  addAddon(addon: ZubehoerAddonEntry): AddonChangeEvent | undefined {
    if (
      typeof this.getAddon(addon.Typ) === 'undefined'
      && ((this.#Item?.IsMontageMoeglich ?? true) || !addon.Typ.includes('montage'))
    ) {
      this.addons.push(addon)
      return AddonChangeEvent.added(addon, this)
    }
  }

  getAddon(addonTyp: string): ZubehoerAddonEntry | undefined {
    return this.addons.find((a): boolean => a.Typ === addonTyp)
  }

  isEmpty(): boolean {
    return this.addons.length <= 0 && !this.#Item
  }

  /**
   * Removes an addon from the MehrpreisEntry.
   * @param addon - The addon to remove
   * @return The AddonChangeEvent if the addon was removed, otherwise undefined
   */
  removeAddon(addon: ZubehoerAddonEntry): AddonChangeEvent | undefined {
    const index = this.addons.indexOf(addon)
    if (index > -1) {
      this.addons.splice(index, 1)
      return AddonChangeEvent.removed(addon, this)
    }
  }

  /**
   * Sets the item of the MehrpreisEntry.
   * If the item is already set, the old item will be replaced.
   * If the item can be installed and there is an installation addon, the addon will be added.
   * If the item can not be installed and there is an installation addon, the addon will be removed.
   *
   * @param item - The item to set
   * @param montageAddon - The installation addon
   * @return The AddonChangeEvent if an addon was added or removed, otherwise undefined
   */
  setItem(item: Zubehoer, montageAddon: ZubehoerAddonEntry): AddonChangeEvent | undefined {
    this.#Item = new Zubehoer(item)
    const hasMontageAddon = this.addons?.some((a): boolean => a.Typ.includes('montage'))
    if (hasMontageAddon && !this.#Item.IsMontageMoeglich || !hasMontageAddon && this.#Item.IsMontageStandard) {
      return this.toggleAddon(montageAddon)
    }
  }

  /**
   * Toggles an addon in the MehrpreisEntry.
   * @param addonData - The addon to toggle
   * @return The AddonChangeEvent if the addon was added or removed, otherwise undefined
   */
  toggleAddon(addonData: ConstructorParameters<typeof ZubehoerAddonEntry>[0]): AddonChangeEvent {
    if (!addonData) {
      console.warn('Adding addon is impossible. Addon is falsy. (undefined or something...)')
      return
    }
    const zubehoerAddon = this.getAddon(addonData.Typ)
    if (typeof zubehoerAddon === 'undefined') {
      return this.addAddon(new ZubehoerAddonEntry(addonData))
    }
    return this.removeAddon(zubehoerAddon)
  }

  /**
   * Removes the item from the MehrpreisEntry.
   * If the item is optional, it will be removed.
   * If the item is not optional, it will not be removed.
   *
   * @return ChangeType.Removed if the item was removed, otherwise false
   */
  unsetItem(): ChangeType.Removed | false {
    if (this.#Item?.IsOptional !== false) {
      this.#Item = undefined
      return ChangeType.Removed
    }
    return false
  }

  updateAddonsMassblaetter(massblaetterUpdate: ResponseMassblaetter): void {
    this.addons.forEach(zubehoerAddonEntry => void zubehoerAddonEntry.updateMassblatt(massblaetterUpdate))
  }

  get Item(): Zubehoer {
    return this.#Item
  }
}
