import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {TranslateService} from '../../../translate'
import {ConfiguratorComponent} from '../../configurator.component'
import {Tuer} from '../../../classes/model/component/construction/tuer'
import {Sprossen} from '../../../classes/model/component/glassaufbau/sprossen'
import {Seitenteil} from '../../../classes/model/component/construction/seitenteil'
import {Oberlicht} from '../../../classes/model/component/construction/oberlicht'
import {StringUtil} from '../../../classes/util/stringUtil'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'

export type SprossenModalData = {
  element: Tuer | Seitenteil | Oberlicht
}
const TRANSLATION_KEY = {
  TITLE: 'BarsModal.Title',
  SAVE: 'BarsModal.Save',
  SEARCH: 'BarsModal.Search'
} as const

@Component({
  templateUrl: './sprossen-modal.component.html'
})
export class SprossenModalComponent {
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  configurator: ConfiguratorComponent
  element: Tuer | Seitenteil | Oberlicht
  filterTerm: string
  preselected: Sprossen

  constructor(
    public dialogRef: MatDialogRef<SprossenModalComponent, Sprossen | null | false>,
    private _translate: TranslateService,
    private _configuratorConfigurationModel: ConfiguratorConfigurationModel,
    @Inject(MAT_DIALOG_DATA) public data: SprossenModalData
  ) {
    this.element = data.element
    this.filterTerm = ''
    this.preselected = null
    if (this.element.glasaufbau.Sprossen) {
      this.preselected = new Sprossen(this.element.glasaufbau.Sprossen)
    }
  }

  isPreselected(item: Sprossen): boolean {
    return this.preselected && this.preselected.Id === item.Id
  }

  onConfirm(): void {
    if (this.preselected) {
      this.element.glasaufbau.Sprossen = new Sprossen(this.preselected)
    } else {
      this.element.glasaufbau.Sprossen = null
    }
    this.dialogRef.close(this.preselected)
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false)
  }

  preselect(item: Sprossen): void {
    if (this.preselected && this.preselected.Id === item.Id) {
      this.preselected = null
    } else {
      this.preselected = item
    }
  }

  sprosseByTerm(): Sprossen[] {
    if (this.filterTerm.length === 0) {
      return this.element.model.Sprossen
    }
    return this.element.model.Sprossen.filter((z): boolean => {
      const bezeichnung = z.Bezeichnung && z.Bezeichnung.toLowerCase().includes(this.filterTerm.toLowerCase())
      const beschreibung = z.Beschreibung && z.Beschreibung.toLowerCase().includes(this.filterTerm.toLowerCase())
      const id = z.Id.toString().includes(this.filterTerm)
      return bezeichnung || beschreibung || id
    })
  }
}
