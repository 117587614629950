import {Pipe, PipeTransform} from '@angular/core'
import {DomSanitizer, SafeHtml} from '@angular/platform-browser'
import DOMPurify from 'dompurify'

@Pipe({name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(value: string | Node): SafeHtml {
    const sanitizedContent: string = DOMPurify.sanitize((value as string).replace(/\\n/g, '<br/>'))
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent)
  }
}
