<modal-frame
  [footer]='footer'
  [modalCloseButton]='false'
  [title]='(TRANSLATION_KEY.TITLE_PREFIX|translate) + (this.mehrpreisBezeichnung() | translate) + (TRANSLATION_KEY.TITLE_SUFFIX|translate)'
  hostId='mehrpreisDiv'
>

  <mat-form-field appearance='fill'>
    <mat-label>{{ TRANSLATION_KEY.MODAL_SEARCH_INPUT_LABEL | translate }}</mat-label>
    <input [(ngModel)]='this.filterTerm' matInput>
  </mat-form-field>

  <div [class]='this.category' class='content'>

    <ng-container *ngFor='let item of this.zubehoerByTerm()'>

      <div
        (click)="item.disabilityReasons.length === 0 ? this.preselectZubehoer(item) : ''"
        *ngIf='this.updateZubehoerVisibility(item)'
        [class.active]='this.zubehoerPreselected(item)'
        [class.current]='this.selectedZubehoerIds.includes(item.Id)'
        [class.disabled]='item.disabilityReasons.length > 0'
        class='item'
      >
        <div
          [mtxTooltipDisabled]='item.disabilityReasons.length === 0'
          [mtxTooltip]='StringUtil.concatStringArray(item.disabilityReasons)'
          class='image-wrapper'
        >
          <div *ngIf='!item.loaded && item.PreviewImageUrl' class='icon loading-image-spinner'>
            <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          </div>
          <img (load)='item.loaded = true' [lazyLoad]='item.PreviewImageUrl || item.ImageUrl'>
        </div>
        <p>
          <span class='option-text'>{{ item.Beschreibung  | translate }}</span>
        </p>
      </div>
    </ng-container>

  </div>
</modal-frame>
<ng-template #footer>
  <button (click)='this.onConfirm()' color='primary' mat-flat-button>{{ TRANSLATION_KEY.MODAL_BUTTON_SAVE | translate }}</button>
</ng-template>
