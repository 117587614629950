import {EventEmitter, Injectable} from '@angular/core'
import {Settings} from './settings'
import {ParameterService} from '../parameter/parameter.service'
import {HttpService} from '../../../http.service'
import {NGXLogger} from 'ngx-logger'
import {ConfiguratorModeService} from '../configuratorMode.service'
import {ConfiguratorMode} from '../../../types'
import {environment} from '../../../../environments/environment'
import {BehaviorSubject, Observable} from 'rxjs'

@Injectable()
export class SettingsService {
  private logoUrlSubject: BehaviorSubject<string>
  readonly settings: Settings = new Settings()
  readonly settingsChanged: EventEmitter<Settings>
  readonly subdomain: string

  constructor(
    private readonly parameterService: ParameterService,
    private readonly httpService: HttpService,
    private logger: NGXLogger,
    private configuratorModeService: ConfiguratorModeService,
  ) {
    this.settingsChanged = new EventEmitter<Settings>()
    this.logoUrlSubject = new BehaviorSubject<string>(undefined)
    const domain = window.location.hostname
    this.populateConfig()
    if (domain.indexOf('.') < 0 || domain.split('.')[0] === 'www') {
      this.subdomain = environment.SUBDOMAIN
    } else {
      this.subdomain = domain.split('.')[0]
    }
    if (this.subdomain === 'app') {
      this.settings.update({Logo: 'rodenberg', Farbe: 'b1032b'})
    }
  }

  public async getSettings(): Promise<void> {
    this.logoUrlSubject.next(undefined)
    if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
      await this.loadFBSSettings()
    } else if (this.configuratorModeService.mode === ConfiguratorMode.TTK) {
      await this.loadTTKSettings()
    } else {
      this.logger.error(
        'SettingsService::getSettings: Unknown configurator mode',
        {mode: this.configuratorModeService.mode}
      )
      return
    }
    if (this.settings.Sprache && this.settings.Sprache !== '') {
      this.httpService.setLang(this.settings.Sprache)
    }
    if (this.settings.Token && this.settings.Token !== '') {
      this.httpService.setToken(this.settings.Token)
    }

    if (this.settings.Logo && this.settings.Logo !== '') {
      this.httpService.getLogo('url', this.settings.Logo).subscribe({
        next: (data): void => {
          this.logoUrlSubject.next(data.toString())
        },
        error: (error): void => {
          this.logger.error(error)
        },
      })
    }

    this.settingsChanged.emit(this.settings)
  }

  private loadFBSSettings(): Promise<void> {
    this.logger.trace('SettingsService::loadSettings')
    const token = this.parameterService.parameter?.token
    if (typeof token === 'undefined' || token === '') {
      this.logger.error('Error. No parameters present')
      return Promise.reject('Error. No parameters present')
    }
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getSettingsFBS(token).subscribe({
        next: (data): void => {
          if (this.parameterService.parameter.brutto === true) {
            data.BruttoPreise = true
            data.NettoPreise = false
          }
          this.settings.update(data)
        },
        error: (error): void => {
          this.logger.error(['Error', error])
          reject(error)
        },
        complete: (): void => {
          resolve()
        }
      }
      )
    })
  }

  private loadTTKSettings(): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getSettingsTTK(this.subdomain).subscribe({
        next: (data): void => {
          // Hack to have a LogoID in TTK
          // TODO: Change API and remove this
          if (data?.Logo && data.Logo.indexOf('/') === -1 && data.Logo.endsWith('.png')) {
            data.Logo = data.Logo.slice(0, -4)
          }
          this.settings.update(data)
        },
        error: (error): void => {
          this.logger.error(['Error', error])
          reject(error)
        },
        complete: (): void => {
          resolve()
        }
      })
    })
  }

  private populateConfig(): void {
    if (this.parameterService.parameter.lang && this.parameterService.parameter.lang !== '') {
      this.settings.Sprache = this.parameterService.parameter.lang
    }
    if (this.parameterService.parameter.token && this.parameterService.parameter.token !== '') {
      this.settings.Token = this.parameterService.parameter.token
    }
    if (this.parameterService.parameter.logo && this.parameterService.parameter.logo !== '') {
      this.settings.Logo = this.parameterService.parameter.logo
    } else {
      this.settings.Logo = '0'
    }
    if (this.parameterService.parameter.color && this.parameterService.parameter.color !== '') {
      this.settings.Farbe = this.parameterService.parameter.color
    }
    if (this.parameterService.parameter.request_dealer && this.parameterService.parameter.request_dealer !== '') {
      this.settings.AnfrageHaendler = this.parameterService.parameter.request_dealer === 'true'
    }
    if (
      this.parameterService.parameter.request_catalog
      && this.parameterService.parameter.request_catalog !== ''
    ) {
      this.settings.AnfrageKatalog = this.parameterService.parameter.request_catalog === 'true'
    }
    if (this.parameterService.parameter.request && this.parameterService.parameter.request !== '') {
      this.settings.IsAnfragenMoeglich = this.parameterService.parameter.request === 'true'
    }
  }

  get logoUrl$(): Observable<string> {
    return this.logoUrlSubject.asObservable()
  }
}
