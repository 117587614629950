import {Injectable} from '@angular/core'
import {HttpService} from '../http.service'
import {NGXLogger} from 'ngx-logger'
import PretranslatedTexts from './pretranslated-texts'

@Injectable()
export class TranslateService {
  private _currentLang: string
  private _missingTranslations: Set<string> = new Set<string>()
  private translations: Record<string, string> = {}
  private translationsLoaded = false

  constructor(
    private httpService: HttpService,
    private logger: NGXLogger
  ) {
    const browserLang = this.getBrowserLang()
    this.setLanguageData(PretranslatedTexts[browserLang], browserLang)
  }

  private getBrowserLang(): string {
    const langTag = navigator.language
    const seperatorIndex = langTag.indexOf('-')
    if (seperatorIndex !== -1) {
      return langTag.slice(0, seperatorIndex)
    }
    return langTag
  }

  public loadLanguage(lang: string): Promise<void> {
    lang = lang ? lang : 'de'
    if (!this.translationsLoaded) {
      this.setLanguageData(PretranslatedTexts[lang], lang)
    }
    this.translationsLoaded = false
    this.translations = {}
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getTranslations(lang).subscribe({
        next: (data): void => {
          this.setLanguageData(data, lang)
          this.translationsLoaded = true
        },
        error: (error): void => {
          this.logger.error(error)
          reject(error)
        },
        complete: (): void => resolve()
      })
    })
  }

  /* eslint-disable jsdoc/no-types -- Give a type hint for the unknown type */
  /**
   * Sets the translations
   * @param {Record<string, string>} translations
   * @param languageTag
   * @private
   */

  /* eslint-enable jsdoc/no-types */
  private setLanguageData(translations: unknown, languageTag?: string): void {
    const translateMap: Record<string, string> = {}
    if (translations instanceof Object) {
      for (const key in translations) {
        if (translations?.hasOwnProperty(key)) {
          const translation: unknown = translations[key]
          if (typeof translation === 'string') {
            translateMap[key] = translation
          } else {
            this.logger.error(
              `Data property for '${key}' has wrong type, expected 'string' but got '${typeof translation}'!`,
              translations
            )
          }
        } else {
          this.logger.info(`Skipping data key '${key}'`, translations)
        }
      }
    } else {
      this.logger.warn('Data is not an object!', translations)
    }
    if (typeof languageTag === 'string') {
      this._currentLang = languageTag
    }
    this.translations = translateMap
  }

  public translate(key: string): string {
    if (!this._currentLang) {
      return key
    }
    if (this.translations.hasOwnProperty(key)) {
      return this.translations[key]
    }
    this._missingTranslations.add(key)
    return key
  }

  get currentLang(): string {
    return this._currentLang
  }

  get missingTranslations(): Set<string> {
    return new Set(Array.from(this._missingTranslations).sort())
  }
}
