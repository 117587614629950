<configurator-base-menu
    (keydown.control.enter)='onDebugKeyCombinationPress()'
    [menuHead]='head'
    [menuNavigatorIdentifier]='NavigationMenuEntryKey.Color'
    has-component-selector
    menuClass='farben'
>
  <div class='container'>
    <div class='container-inner colorsMenu'>
      <div class='inputArea'>
        <ng-template [ngTemplateOutlet]='colorSearchForm'></ng-template>
        <ng-template [ngTemplateOutlet]='menuViewSwitch'></ng-template>

        <div
            *ngIf='fbsModel'
            class='colorSelectButtons'
        >
          <button
              (click)="onSelectFarbenFilter('farben')"
              [class.active]="farbenFilter === 'farben'"
              [class.disabled]="fbsModel.Farben.length === 0 || !this.colortypeSuitable('lack')"
          >
            {{
                  (this.configuratorMode === ConfiguratorMode.FBS
                      ? TRANSLATION_KEY.BUTTON_TITLE_LACK_FBS
                      : TRANSLATION_KEY.BUTTON_TITLE_LACK_TTK) | translate
            }}
          </button>
          <button
              (click)="onSelectFarbenFilter('folien')"
              [class.active]="farbenFilter === 'folien'"
              [class.disabled]="fbsModel.Folien.length === 0 || !this.colortypeSuitable('folie')"
          >
            {{
                  (this.configuratorMode === ConfiguratorMode.FBS
                      ? TRANSLATION_KEY.BUTTON_TITLE_FOLIE_FBS
                      : TRANSLATION_KEY.BUTTON_TITLE_FOLIE_TTK) | translate
            }}
          </button>
          <button
              (click)="onSelectFarbenFilter('pulver')"
              *ngIf='this.configuratorMode === ConfiguratorMode.FBS'
              [class.active]="farbenFilter === 'pulver'"
              [class.disabled]="fbsModel.Pulver.length === 0 || !this.colortypeSuitable('pulver')"
          >
            {{ TRANSLATION_KEY.BUTTON_TITLE_PULVER | translate }}
          </button>
          <button
              (click)="onSelectFarbenFilter('designoberflaechen')"
              *ngIf='this.selectedComponent?.model?.DesignOberflaechen?.length > 0'
              [class.active]="farbenFilter === 'designoberflaechen'"
          >
            {{ TRANSLATION_KEY.BUTTON_TITLE_DESIGN | translate }}
          </button>
        </div>

        <div id='mainselection'>
          <mat-form-field>
            <mat-select
                (selectionChange)='this.selectedElementColorType()'
                [(ngModel)]='this.colorSelectedItem'
            >
              <mat-option [value]="'komplett'">
                {{
                      (this.view === this.SideType.Outside
                          ? TRANSLATION_KEY.COLORIZE_ALL_OUTSIDE
                          : TRANSLATION_KEY.COLORIZE_ALL_INSIDE) | translate
                }}
              </mat-option>

              <mat-option [value]='blendrahmen'>
                {{
                      (this.view === this.SideType.Outside
                          ? TRANSLATION_KEY.COLORIZE_BLENDRAHMEN_OUTSIDE
                          : TRANSLATION_KEY.COLORIZE_BLENDRAHMEN_INSIDE) | translate
                }}
              </mat-option>

              <mat-option [value]='fluegelrahmen'>
                {{
                      (this.view === this.SideType.Outside
                          ? TRANSLATION_KEY.COLORIZE_FLUEGELRAHMEN_OUTSIDE
                          : TRANSLATION_KEY.COLORIZE_FLUEGELRAHMEN_INSIDE) | translate
                }}
              </mat-option>

              <mat-option
                  *ngFor='let deckschicht of this.filterComponentsByColorType(element.Deckschichten[this.view])'
                  [value]='deckschicht'
              >
                {{ deckschicht.Bezeichnung | translate }}
              </mat-option>

              <mat-option
                  *ngFor='let option of this.filterComponentsByColorType(this.selectedComponent.optionen[this.view])'
                  [value]='option'
              >
                {{ option.Bezeichnung | translate }}
              </mat-option>

              <mat-option
                  *ngIf='element && element.glasaufbau && element.glasaufbau.Sprossen
                                    && (element.glasaufbau.Sprossen.IsFolieMoeglich
                                        || element.glasaufbau.Sprossen.IsLackMoeglich
                                        || element.glasaufbau.Sprossen.IsPulverMoeglich
                                        || element.glasaufbau.Sprossen.IsDesignOberflaecheMoeglich)
                                    '
                  [value]='element.glasaufbau.Sprossen'
              >
                {{ TRANSLATION_KEY.COLORIZE_DROPDOWN_OPTION_BARS | translate }}
                {{
                      (this.view === this.SideType.Outside ? TRANSLATION_KEY.VIEW_OUTSIDE : TRANSLATION_KEY.VIEW_INSIDE) | translate
                }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class='contentArea virtualscroll'>

        <div
            (click)='extendedColorTooltips = false'
            *ngIf='extendedColorTooltips'
        >
          {{ TRANSLATION_KEY.EXTENDED_COLOR_REASONING_ENABLED }}
          <br><br>
          <ng-container *ngIf='element'>
            <ng-container *ngFor='let deckschicht of element.Deckschichten[this.view]'>
              <ng-container
                  *ngIf='!this.filterComponentsByColorType(element.Deckschichten[this.view]).includes(deckschicht)'>
                {{ deckschicht.Bezeichnung | translate }} {{ TRANSLATION_KEY.COLORTYPE_NOT_SUPPORTED }}
              </ng-container>
            </ng-container>

            <ng-container
                *ngFor='let option of this.selectedComponent.optionen[this.view]'
            >
              <ng-container
                  *ngIf='!this.filterComponentsByColorType(this.selectedComponent.optionen[this.view]).includes(option)'
              >
                {{ option.Bezeichnung | translate }} {{ TRANSLATION_KEY.COLORTYPE_NOT_SUPPORTED }}
              </ng-container>
            </ng-container>

            <ng-container
                *ngIf='element && element.glasaufbau && element.glasaufbau.Sprossen
                                        && !(element.glasaufbau.Sprossen.IsFolieMoeglich
                                            || element.glasaufbau.Sprossen.IsLackMoeglich
                                            || element.glasaufbau.Sprossen.IsPulverMoeglich
                                            || element.glasaufbau.Sprossen.IsDesignOberflaecheMoeglich)
                                '
            >
              {{ TRANSLATION_KEY.COLORIZE_DROPDOWN_OPTION_BARS | translate }} {{
                    (this.view === this.SideType.Outside ? TRANSLATION_KEY.VIEW_OUTSIDE : TRANSLATION_KEY.VIEW_INSIDE) | translate
              }} {{ TRANSLATION_KEY.COLORTYPE_NOT_SUPPORTED | translate }}
            </ng-container>
          </ng-container>

        </div>

        <ng-container *ngIf="farbenFilter === 'farben'">
          <cdk-virtual-scroll-viewport
              #colorViewPort
              [itemSize]='this.VIRTUAL_SCROLL_ITEM_HEIGHT'
              class='viewport'
              style='width: 100%'
          >
            <ng-container *cdkVirtualFor='let row of this.filteredColors'>
              <div class='colorRow'>
                <ng-container *ngFor='let baseColor of row;'>
                  <article
                      (click)='onSelectColor(baseColor)'
                      *ngIf='baseColor.RGB'
                      [class.active]="this.fbsColorActiveCheck(this.colorSelectedItem, baseColor, 'farbe')"
                      class='color'
                  >
                    <div
                        *ngIf='baseColor | instanceof: Lack as lack'
                        [mtxTooltip]='this.getLackTooltip(lack)'
                        [style.background]='baseColor.RGB'
                        class='colorBox'
                    >
                    </div>
                    <p>{{ baseColor.Bezeichnung | translate }}</p>
                  </article>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf='this.filteredColors.length === 0'>
              <p class='empty'>
                {{
                      (this.configuratorMode === ConfiguratorMode.FBS
                          ? TRANSLATION_KEY.NO_LACKE_FOR_CONFIG_FBS
                          : TRANSLATION_KEY.NO_LACKE_FOR_CONFIG_TTK) | translate
                }}
              </p>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </ng-container>

        <ng-container *ngIf="farbenFilter === 'folien'">
          <cdk-virtual-scroll-viewport
              #colorViewPort
              [itemSize]='this.VIRTUAL_SCROLL_FOLIEN_HEIGHT'
              class='viewport'
              style='width: 100%'
          >
            <ng-container *cdkVirtualFor='let row of this.filteredColors'>
              <div class='colorRow'>
                <ng-container *ngFor='let baseColor of row; let index = index'>
                  <article
                      (click)='onSelectColor(baseColor)'
                      [class.active]="this.fbsColorActiveCheck(this.colorSelectedItem, baseColor, 'folie')"
                      class='color'
                  >
                    <div
                        *ngIf='baseColor | instanceof: Folie as folie'
                        [mtxTooltip]='this.getFolieTooltip(folie)'
                        class='image-wrapper'
                    >
                      <img
                          [attr.src]='folie.PreviewImageUrl'
                          alt='{{translator.translate(folie.Bezeichnung)}}'
                      >
                    </div>
                    <p *ngIf='!this.settingsService.settings?.EinfacheDekorbezeichnung && baseColor | instanceof: Folie as folie'>
                      {{ this.dekorBezeichnung(folie, false) }}
                    </p>
                    <p *ngIf='this.settingsService.settings?.EinfacheDekorbezeichnung && baseColor | instanceof: Folie as folie'>
                      {{ this.dekorBezeichnung(folie, true) }}</p>
                  </article>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf='this.filteredColors.length === 0'>
              <p class='empty'>
                {{
                      (this.configuratorMode === ConfiguratorMode.FBS
                          ? TRANSLATION_KEY.NO_FOLIEN_FOR_CONFIG_FBS
                          : TRANSLATION_KEY.NO_FOLIEN_FOR_CONFIG_TTK) | translate
                }}
              </p>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </ng-container>

        <ng-container *ngIf="farbenFilter === 'designoberflaechen'">
          <cdk-virtual-scroll-viewport
              #colorViewPort
              [itemSize]='this.VIRTUAL_SCROLL_DESIGNOBERFLAECHEN_HEIGHT'
              class='viewport'
              style='width: 100%'
          >

            <ng-container *cdkVirtualFor='let row of this.filteredColors'>
              <div class='colorRow'>

                <ng-container *ngFor='let baseColor of row; let index = index'>
                  <article
                      (click)='onSelectColor(baseColor)'
                      [class.active]="this.fbsColorActiveCheck(this.colorSelectedItem, baseColor, 'designoberflaeche')"
                      class='color'
                  >
                    <div
                        *ngIf='baseColor | instanceof: DesignOberflaeche as designOberflaeche'
                        [mtxTooltip]='this.getDesignOberflaecheTooltip(designOberflaeche)'
                        class='image-wrapper'
                    >
                      <img
                          [attr.src]='designOberflaeche.PreviewImageUrl'
                          alt='{{translator.translate(designOberflaeche.Bezeichnung)}}'
                      >
                    </div>
                    <p *ngIf='baseColor | instanceof: DesignOberflaeche as designOberflaeche'>
                      {{ translator.translate(designOberflaeche.Bezeichnung) }}</p>
                  </article>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf='this.filteredColors.length === 0'>
              <p class='empty'> {{ TRANSLATION_KEY.NO_DESIGNS_FOR_CONFIG | translate }}</p>
            </ng-container>
          </cdk-virtual-scroll-viewport>

        </ng-container>

        <ng-container *ngIf="farbenFilter === 'pulver'">
          <cdk-virtual-scroll-viewport
              #colorViewPort
              [itemSize]='this.VIRTUAL_SCROLL_ITEM_HEIGHT'
              class='viewport'
              style='width: 100%'
          >
            <ng-container *cdkVirtualFor='let row of this.filteredColors'>
              <div class='colorRow'>
                <ng-container *ngFor='let baseColor of row; let index = index'>

                  <article
                      (click)='onSelectColor(baseColor)'
                      *ngIf='baseColor.RGB'
                      [class.active]="this.fbsColorActiveCheck(this.colorSelectedItem, baseColor, 'pulver')"
                      class='color'
                  >
                    <div
                        *ngIf='baseColor | instanceof: Pulver as pulver'
                        [mtxTooltip]='this.getPulverTooltip(pulver)'
                        [style.background]='baseColor.RGB'
                        class='colorBox'
                    ></div>
                    <p>{{ baseColor.Bezeichnung | translate }} [{{ baseColor.RodenbergCode }}]</p>
                  </article>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngIf='this.filteredColors.length === 0'>
              <p class='empty'>
                {{ TRANSLATION_KEY.NO_PULVER_FOR_CONFIG | translate }}
              </p>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </div>

    </div>
  </div>
</configurator-base-menu>
<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.COLORS_MENU_TITLE | translate }}</span>
    <span
        (click)='this.closeMenu()'
        class='close'
    ></span>
  </div>
</ng-template>
<ng-template #colorSearchForm>
  <mat-form-field
      appearance='fill'
      class='colorSearchForm'
  >
    <mat-label>{{ TRANSLATION_KEY.SEARCH_COLOR_DEKOR | translate }}</mat-label>
    <input
        #colorSearchInput
        (keyup)='this.setFarbenFilter(this.farbenFilter)'
        [(ngModel)]='colorSearchTerm'
        matInput
        type='text'
    />
    <button
        (click)='this.resetColorTerm()'
        aria-label='Clear'
        mat-icon-button
        matTextSuffix
    >
      <i class='fa fa-2x fa-times'></i>
    </button>
  </mat-form-field>
</ng-template>
<ng-template
    #menuViewSwitch
    let-params='class'
>
  <div class='viewSwitchButtons {{ params }}'>
    <button
        (click)='this.changeView(SideType.Outside)'
        [class.active]='view === SideType.Outside'
        class='viewSwitchButton'
    >
      {{ TRANSLATION_KEY.MENU_VIEWSWITCH_OUTSIDE | translate }}
    </button>
    <button
        (click)='this.changeView(SideType.Inside)'
        [class.active]='view === SideType.Inside'
        class='viewSwitchButton'
    >
      {{ TRANSLATION_KEY.MENU_VIEWSWITCH_INSIDE | translate }}
    </button>
  </div>
</ng-template>

