import {Dienstleistung} from './dienstleistung'
import {ModelMehrpreis} from '../../../../api/model/addons/model-mehrpreis.interface'

export type MehrpreisCategory = string

export class fbsMehrpreis {
  Beschreibung: string
  Bezeichnung: string
  Dienstleistungen: Dienstleistung[] = []
  Id: number
  IsAluMoeglich: boolean
  IsAufsatzBeidseitigMoeglich: boolean
  IsAufsatzEinseitigMoeglich: boolean
  IsEinsatzMoeglich: boolean
  IsGlasMoeglich: boolean
  IsKunststoffMoeglich: boolean
  Sort: number
  Typ: MehrpreisCategory

  constructor(data?: Partial<fbsMehrpreis> | Partial<ModelMehrpreis>) {
    this.Beschreibung = data?.Beschreibung
    this.Bezeichnung = data?.Bezeichnung
    this.Id = data?.Id
    this.IsAluMoeglich = data?.IsAluMoeglich
    this.IsGlasMoeglich = data?.IsGlasMoeglich
    this.IsKunststoffMoeglich = data?.IsKunststoffMoeglich
    this.Sort = data?.Sort
    this.Typ = data?.Typ
    if (data && 'IsAufsatzBeidseitigMoeglich' in data) {
      this.IsAufsatzBeidseitigMoeglich = data?.IsAufsatzBeidseitigMoeglich
      this.IsAufsatzEinseitigMoeglich = data?.IsAufsatzEinseitigMoeglich
      this.IsEinsatzMoeglich = data?.IsEinsatzMoeglich
    }
    data?.Dienstleistungen.forEach((d): number => this.Dienstleistungen.push(new Dienstleistung(d)))
  }
}
