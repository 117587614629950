import {Injectable} from '@angular/core'
import {NavigationMenuEntryKey} from './navigation-menu-entry'
import {ComponentModel} from '../../model/component/model/component-model'
import {ComponentSelectionService} from '../componentSelectionService'
import {SettingsService} from '../settings/settings.service'
import {ConfiguratorModeService} from '../configuratorMode.service'
import {ConfiguratorMode} from '../../../types'
import {MenuClosed} from './menu-closed.type'
import {ActivableNavigationMenu} from './activable-navigation-menu.type'
import {BehaviorSubject, Observable} from 'rxjs'
import {ModalService} from '../../../configurator/modal/modal.service'


@Injectable()
export class NavigatorService {
  #configuredMenuEntries: NavigationMenuEntryKey[]
  readonly #currentActiveMenu$: BehaviorSubject<ActivableNavigationMenu>
  #isNavigationMenuOpen: boolean = false

  constructor(
    private readonly configuratorModeService: ConfiguratorModeService,
    private readonly componentSelectionService: ComponentSelectionService,
    readonly settingsService: SettingsService,
    private readonly modalService: ModalService
  ) {
    settingsService.settingsChanged.subscribe({
      next: (/* settings: Settings */): void => this.setSettings(/* settings */)
    })
    this.setSettings(/* settingsService.settings */)

    const currentActiveMenu = this.#configuredMenuEntries.length > 0
      ? this.#configuredMenuEntries[0]
      : MenuClosed
    this.#currentActiveMenu$ = new BehaviorSubject(currentActiveMenu)
  }

  activateMenu(menu: ActivableNavigationMenu | BehindLastMenu): void {
    if (menu !== MenuClosed && !this.canActivateMenu(menu)) {
      return
    }
    if (menu === BehindLastMenu) {
      this.modalService.showKalkulationModal()
      return
    }
    this.#isNavigationMenuOpen = false
    this.#currentActiveMenu$.next(menu)
  }

  activateNextMenu(): void {
    const nextMenu = this.findNextActivableMenu()
    if (typeof nextMenu !== 'undefined') {
      this.activateMenu(nextMenu)
    }
  }

  activatePreviousMenu(): void {
    const previousMenu = this.findPreviousActivableMenu()
    if (typeof previousMenu !== 'undefined') {
      this.activateMenu(previousMenu)
    }
  }

  canActivateMenu(menuEntry: NavigationMenuEntryKey | BehindLastMenu): boolean {
    switch (menuEntry) {
      case NavigationMenuEntryKey.BaseShape:
      case NavigationMenuEntryKey.Catalogue:
      case NavigationMenuEntryKey.Characteristics:
      case NavigationMenuEntryKey.Dimension:
      case NavigationMenuEntryKey.Finalize:
      case NavigationMenuEntryKey.HouseFront:
      case NavigationMenuEntryKey.Load:
      case NavigationMenuEntryKey.Model:
        return true
      case NavigationMenuEntryKey.Addon:
      case NavigationMenuEntryKey.Basis:
        return !!this.componentSelectionService?.selectedComponent?.model
      case NavigationMenuEntryKey.Color:
        return areColorsAvailable(this.componentSelectionService?.selectedComponent?.model)
      case NavigationMenuEntryKey.Glass:
        return this.componentSelectionService?.selectedComponent?.model?.isGlassPossible()
      case NavigationMenuEntryKey.Option:
        return this.componentSelectionService?.selectedComponent?.model?.hasOptions()
      case BehindLastMenu:
        return this.configuratorModeService?.mode === ConfiguratorMode.FBS
          && !!this.componentSelectionService?.selectedComponent?.model
    }
  }

  canActivateNextMenu(): boolean {
    return typeof this.findNextActivableMenu() !== 'undefined'
  }

  canActivatePreviousMenu(): boolean {
    return typeof this.findPreviousActivableMenu() !== 'undefined'
  }

  closeMenu(): void {
    this.activateMenu(MenuClosed)
  }

  private findNextActivableMenu(): NavigationMenuEntryKey | BehindLastMenu | undefined {
    if (this.currentActiveMenu === MenuClosed) {
      return undefined
    }
    const currentIndex = this.#configuredMenuEntries.indexOf(this.currentActiveMenu)
    if (currentIndex < 0) {
      return undefined
    }
    for (let i = currentIndex + 1; i < this.#configuredMenuEntries.length; ++i) {
      if (this.canActivateMenu(this.#configuredMenuEntries[i])) {
        return this.#configuredMenuEntries[i]
      }
    }
    if (this.canActivateMenu(BehindLastMenu)) {
      return BehindLastMenu
    }
    return undefined
  }

  private findPreviousActivableMenu(): NavigationMenuEntryKey | undefined {
    if (this.currentActiveMenu === MenuClosed) {
      return undefined
    }
    const currentIndex = this.#configuredMenuEntries.indexOf(this.currentActiveMenu)
    for (let i = currentIndex - 1; i >= 0; --i) {
      if (this.canActivateMenu(this.#configuredMenuEntries[i])) {
        return this.#configuredMenuEntries[i]
      }
    }
    return undefined
  }

  private setSettings(/* settings: Settings */): void {
    this.#configuredMenuEntries = this.configuratorModeService?.mode === ConfiguratorMode.FBS
      ? [
        NavigationMenuEntryKey.Catalogue,
        NavigationMenuEntryKey.Model,
        NavigationMenuEntryKey.Basis,
        NavigationMenuEntryKey.Option,
        NavigationMenuEntryKey.Glass,
        NavigationMenuEntryKey.Color,
        NavigationMenuEntryKey.Addon,
      ]
      : this.configuratorModeService?.mode === ConfiguratorMode.TTK
        ? [
          NavigationMenuEntryKey.Load,
          NavigationMenuEntryKey.BaseShape,
          NavigationMenuEntryKey.Dimension,
          NavigationMenuEntryKey.HouseFront,
          NavigationMenuEntryKey.Catalogue,
          NavigationMenuEntryKey.Model,
          NavigationMenuEntryKey.Option,
          NavigationMenuEntryKey.Glass,
          NavigationMenuEntryKey.Color,
          NavigationMenuEntryKey.Addon,
          NavigationMenuEntryKey.Finalize,
        ]
        : []
  }

  public toggleNavigationMenuOpen(): void {
    this.#isNavigationMenuOpen = !this.#isNavigationMenuOpen
  }

  get configuredMenuEntries(): (NavigationMenuEntryKey)[] {
    return this.#configuredMenuEntries
  }

  get currentActiveMenu(): ActivableNavigationMenu {
    return this.#currentActiveMenu$.value
  }

  get currentActiveMenu$(): Observable<ActivableNavigationMenu> {
    return this.#currentActiveMenu$
  }

  get isNavigationMenuOpen(): boolean {
    return this.#isNavigationMenuOpen
  }
}

const areColorsAvailable = (model: ComponentModel | undefined): boolean =>
  model?.Farben.length > 0 || model?.Folien.length > 0 || model?.Pulver.length > 0

const BehindLastMenu = Symbol()
type BehindLastMenu = typeof BehindLastMenu
