export type Tuple<T, S extends number> =
  S extends 0 ? [] :
    S extends 1 ? [T] :
      S extends 2 ? [T, T] :
        S extends 3 ? [T, T, T] :
          S extends 4 ? [T, T, T, T] :
            S extends 5 ? [T, T, T, T, T] :
              T[]
export default class ArrayUtil {
  static groupedArray<T, S extends number>(array: T[], groupSize: S): Tuple<T, S>[] {
    const rows: Tuple<T, S>[] = []
    for (let i = 0; i < array.length; i += groupSize) {
      rows.push(array.slice(i, i + groupSize) as Tuple<T, S>)
    }
    return rows
  }
}
