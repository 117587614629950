import {Attribute, Component, Input, Optional, TemplateRef} from '@angular/core'
import {ConfiguratorMode} from '../../../types'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'

@Component({
  selector: 'configurator-base-menu, configurator-base-menu[component-selector]',
  templateUrl: './base-menu.component.html',
  styleUrls: ['base-menu.component.scss']
})
export class BaseMenuComponent {
  protected readonly ConfiguratorMode = ConfiguratorMode
  protected componentSelector: boolean
  @Input() menuClass: string
  @Input() menuHead: TemplateRef<unknown>
  @Input() menuNavigatorIdentifier: NavigationMenuEntryKey

  constructor(
    private readonly navigatorService: NavigatorService,
    private _configuratorModeService: ConfiguratorModeService,
    @Optional() @Attribute('has-component-selector') componentSelector: string | boolean | null
  ) {
    this.componentSelector = componentSelector !== null && componentSelector !== 'false'
  }

  protected isActive(): boolean {
    return this.navigatorService.currentActiveMenu === this.menuNavigatorIdentifier
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }
}
