import {Material} from '../../material'


export const enum PackageType {
  Security = 'sicherheitspaket',
  Soundproofing = 'schallschutz',
  ThermalInsulation = 'waermeschutz',
}

export class Paket {
  readonly Beschreibung: string
  readonly Bezeichnung: string
  readonly Id: number
  readonly ImageUrl: string
  readonly IsAluMoeglich: boolean
  readonly IsGlasMoeglich: boolean
  readonly IsKunststoffMoeglich: boolean
  readonly Key: string
  readonly PreviewImageUrl: string
  readonly Typ: PackageType

  constructor(data?: Partial<Paket>) {
    this.Beschreibung = data && data.Beschreibung
    this.Bezeichnung = data && data.Bezeichnung
    this.Id = data && data.Id
    this.ImageUrl = data && data.ImageUrl
    this.IsAluMoeglich = data && data.IsAluMoeglich
    this.IsGlasMoeglich = data && data.IsGlasMoeglich
    this.IsKunststoffMoeglich = data && data.IsKunststoffMoeglich
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.Typ = data && data.Typ
    this.Key = data && data.Key
  }

  hasType(type: PackageType): boolean {
    return type === this.Typ
  }

  isMaterialSupported(material: Material): boolean {
    return (material === Material.Alu && this.IsAluMoeglich)
      || (material === Material.Kunststoff && this.IsKunststoffMoeglich)
      || (material === Material.Glas && this.IsGlasMoeglich)
  }
}
