export class UnknownWorkflowFilterKey extends Error {
  constructor(
    public readonly key: string,
    public readonly workflowKey?: string
  ) {
    super(
      `Unknown workflow filter key "${key}${typeof workflowKey !== 'undefined' ? `" on "${workflowKey}` : ''}"!`
    )
  }
}
