import {ConfigurationLoaderService} from './configuration-loader.service'
import {ConfiguratorConfigurationModel} from '../../classes/model/configuratorConfigurationModel'
import {ConfiguratorDataModel} from '../../classes/model/configuratorDataModel'
import {NGXLogger} from 'ngx-logger'
import {ConfiguratorModeService} from '../../classes/service/configuratorMode.service'
import {ParameterService} from '../../classes/service/parameter/parameter.service'
import {LoadRequestIdentifier} from '../components/load-configuration-button/load-configuration-button.component'
import {InvalidModelIdInLoadedConfigError, ServiceHasNoLoadedConfigError, WrappedError} from './error.type'
import {ConstructionComponentType} from '../../classes/model/component/construction/construction-component-type.enum'
import {Material} from '../../classes/model/material'
import {ConstructionComponent} from '../../classes/model/component/construction/constructionComponent'
import {ComponentModel} from '../../classes/model/component/model/component-model'
import {DinUtil} from '../../classes/util/dinUtil'
import {ConfiguratorMode, Oeffnungsart} from '../../types'
import {Injectable, InjectionToken} from '@angular/core'
import {environment} from '../../../environments/environment'
import ColorRetainApplicator from '../../classes/color-retain-applicator/color-retain-applicator'
import {Tuer} from '../../classes/model/component/construction/tuer'
import {Seitenteil} from '../../classes/model/component/construction/seitenteil'
import {ConfigurationComponent} from '../../classes/configurationComponent'


export const LOAD_STRATEGY = new InjectionToken<LoadStrategy>('Load Strategy')

@Injectable()
export class LoadStrategy {
  constructor(
    private readonly configurationLoaderService: ConfigurationLoaderService,
    private readonly configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private readonly configuratorDataModel: ConfiguratorDataModel,
    private readonly logger: NGXLogger,
    private readonly configuratorModeService: ConfiguratorModeService,
    private readonly parameterService: ParameterService
  ) {
  }

  private getMissingModelIds(): [ConstructionComponent, string][] {
    const config = this.configurationLoaderService.loadedConfig?.Components
    return this.configuratorConfigurationModel.configuratedDoor.Components
      .filter((c): boolean => !c.model)
      .map((c): [ConstructionComponent, string] => {
        if (config) {
          const configuration = config[c.Index]
          if (
            typeof configuration !== 'undefined'
            && (c.objectType as string) === configuration.Typ
            // Do not check ModelId. When loading a TTK configuration in Expert, this is not the same
            // && component.model.Id === configuration.ModelId
          ) {
            return [c, String(configuration.ModelId)]
          }
        }
        switch (c.objectType) {
          case ConstructionComponentType.Door:
            return [
              c,
              String(
                // 1. get model from another door
                this.configuratorConfigurationModel.configuratedDoor.tueren.find((t): boolean => !!t.model)?.model?.Id
                // 2. load default door model id
                ?? '' + environment.DEFAULT_DOOR_MODEL_ID
              )
            ]
          case ConstructionComponentType.SidePanel:
            return [
              c,
              String(
                // 1. get model from another seitenteil
                this.configuratorConfigurationModel.configuratedDoor.seitenteile.find((s): boolean => !!s.model)?.model?.Id
                // 2. load default seitenteilmodel from door model
                ?? this.configuratorConfigurationModel.configuratedDoor.tueren.find((t): boolean => !!t.model)
                  ?.model?.Seitenteile[0]?.Id?.toString()
                // 3. load default seitenteil model id
                ?? '' + environment.DEFAULT_SIDEPANEL_MODEL_ID
              )
            ]
          case ConstructionComponentType.Fanlight:
            return [
              c,
              String(
                // 1. get model from another oberlicht
                this.configuratorConfigurationModel.configuratedDoor.oberlichter.find((o): boolean => !!o.model)?.model?.Id
                // 2. load default oberlichtmodel from door model
                ?? this.configuratorConfigurationModel.configuratedDoor.tueren.find((t): boolean => !!t.model)
                  ?.model?.Oberlichter[0]?.Id?.toString()
                // 3. load default seitenteil model id
                ?? '' + environment.DEFAULT_FANLIGHT_MODEL_ID
              )
            ]
          default:
            console.error('unknown objectType')
            return [c, '']
        }
      })
  }

  /**
   * Returns an async generator, which will yield at most once, exactly after setting the components model before
   * applying / restoring the saved configuration
   * @param loadRequestIdentifier
   */
  async *loadDoorFromLoadedConfig(
    loadRequestIdentifier?: LoadRequestIdentifier
  ): AsyncGenerator<true | 'MaterialMismatch', void, void> {
    const errorContainer: { error?: Error } = {}
    handleError: {
      if (!this.configurationLoaderService.hasLoadedConfig()) {
        errorContainer.error = new ServiceHasNoLoadedConfigError()
        break handleError
      }
      const modelId =
        typeof loadRequestIdentifier !== 'undefined'
          ? this.configurationLoaderService.loadedConfig?.Components[loadRequestIdentifier[1]]?.ModelId
          : this.configurationLoaderService.getLoadedDoorModelId()
      if (typeof modelId !== 'number' || modelId === 0 || Number.isNaN(modelId)) {
        errorContainer.error = new InvalidModelIdInLoadedConfigError()
      }
      try {
        yield await this.setModel(
          String(modelId),
          ConstructionComponentType.Door,
          this.configurationLoaderService.getLoadedDoorFillingMaterial(),
          this.configuratorConfigurationModel.configuratedDoor.tueren[0]
          ?? this.configuratorConfigurationModel.configuratedDoor.Components[0],
          { loadRequestIdentifier }
        )
        await this.configurationLoaderService.applyLoadedConfig()
      } catch (err) {
        errorContainer.error = new WrappedError('loadDoorByService failed setting model', {cause: err})
      }
    }
    if ('error' in errorContainer) {
      this.logger.error(errorContainer.error)
      throw errorContainer.error
    }
  }

  async loadModel(
    modelId: string,
    componentType: ConstructionComponentType,
    material: Material,
    construction: number,
    oeffnungsart: Oeffnungsart,
    dinFuellung: number,
    loadRequestIdentifier?: LoadRequestIdentifier
  ): Promise<ComponentModel> {
    this.logger.trace('getModel')
    return await this.configuratorDataModel
      .loadModel(
        modelId,
        componentType,
        material ?? Material.Kunststoff,
        this.parameterService.parameter.profil ?? this.configuratorConfigurationModel.configuratedDoor.profile.Beschreibung,
        construction,
        this.parameterService.model,
        oeffnungsart,
        dinFuellung,
        loadRequestIdentifier
      )
  }

  public async loadModelsAfterGrundformChange(): Promise<void> {
    const colorHelper = new ColorRetainApplicator(this.configuratorConfigurationModel.configuratedDoor.Components)
    const material = this.configuratorConfigurationModel.configuratedDoor.Components
      .filter((c): boolean => !!c.model)
      .sort((a, b): number =>
        a instanceof Tuer ? 1 : b instanceof Tuer
          ? -1 : a instanceof Seitenteil
            ? 1 : b instanceof Seitenteil
              ? -1 : 0
      )[0]?.material
      ?? this.parameterService.model?.material
      ?? Material.Kunststoff
    return Promise.all(
      this.getMissingModelIds().map(([component, modelId]): Promise<true | 'MaterialMismatch'> =>
        this.setModel(modelId, component.objectType, material, component, {colorRetainApplicator: colorHelper})
      )
    ).catch(err => void this.logger.error(err))
      .then((): void => this.configuratorConfigurationModel.modelsLoadedAfterGrundformChange.emit())
  }

  async setModel(
    modelId: string,
    componentType: ConstructionComponentType,
    material: Material,
    component: ConstructionComponent,
    options?: {
      colorRetainApplicator?: ColorRetainApplicator
      configuration?: ConfigurationComponent
      loadRequestIdentifier?: LoadRequestIdentifier
    }
  ): Promise<true | 'MaterialMismatch'> {
    const { colorRetainApplicator, configuration, loadRequestIdentifier } = options ?? {}
    try {
      const construction =
        typeof configuration === 'object' && typeof configuration.Konstruktion == 'number' && !isNaN(configuration.Konstruktion)
          ? configuration.Konstruktion
          : component.konstruktion
      const oeffnungsart =
        configuration?.Oeffnungsart === 'innen'
          ? 'innen'
          : configuration?.Oeffnungsart === 'aussen'
            ? 'aussen'
            : component.oeffnungsart
      const dinFuellung =
        (
          typeof configuration === 'object' && typeof configuration.Din == 'number' && !isNaN(configuration.Din)
            ? configuration.Din
            : component.dinfuellung
        ) ?? (
          oeffnungsart === 'innen'
            ? DinUtil.fromString(this.parameterService.model?.din)
            : DinUtil.fromString(this.parameterService.model?.din) as number === 0
              ? 1
              : 0
        )

      const loadedModel = await this.loadModel(
        modelId,
        componentType,
        material,
        construction,
        oeffnungsart,
        dinFuellung,
        loadRequestIdentifier
      )
      if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
        // await this.resetConfiguratedDoor(material)
        //   => initialize Door + restore Hausfronten
      }

      return await this.configuratorConfigurationModel.setComponentModel(loadedModel, component, colorRetainApplicator)
    } catch (err) {
      this.throwWrapped('setModel failed', err)
    }
  }

  private throwWrapped(message: string, error: unknown): never {
    throw new WrappedError(message, {cause: error})
  }
}
