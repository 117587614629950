<div *ngIf="visible" class="panel panel-default" [ngClass]="{'panel-open': isOpen}">
    <div class="panel-heading" [attr.allow]="allowToggle" (click)="toggleOpen($event)">
        <h4 class="panel-title">
            <a href tabindex="0"><span><i class="fa" [class.fa-plus]="!isOpen"
                                          [class.fa-minus]="isOpen"></i>{{heading}}</span></a>
        </h4>
    </div>
    <div class="panel-collapse" [hidden]="!isOpen">
        <div class="panel-body">
            <ng-content></ng-content>
        </div>
    </div>
</div>
