import {Injectable} from '@angular/core'
import {ConfiguratorMode} from '../../types'
import {environment} from '../../../environments/environment'

@Injectable()
export class ConfiguratorModeService {
  #currentMode: ConfiguratorMode

  constructor() {
    if (environment.MODE === 'FBS') {
      this.#currentMode = ConfiguratorMode.FBS
    } else if (environment.MODE === 'TTK') {
      this.#currentMode = ConfiguratorMode.TTK
    }
  }

  get mode(): ConfiguratorMode {
    return this.#currentMode
  }
}
