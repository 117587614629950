import {Component} from '@angular/core'
import {ModalService} from '../modal.service'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ConfiguratorMode} from '../../../types'

const TRANSLATION_KEY = {
  TICKETSYSTEM: 'SupportModal.TicketSystem',
  PHONE: 'SupportModal.Phone',
  REMOTESUPPORT: 'SupportModal.RemoteSupport',
  URLPARAMS: 'SupportModal.URLParams',
  SUPPORT: 'SupportModal.Support',
  EMAIL: 'SupportModal.EMail',
  CLOSE: 'SupportModal.Close'
} as const

@Component({
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent {
  protected readonly ConfiguratorMode = ConfiguratorMode
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY

  constructor(
    protected readonly modalService: ModalService,
    protected readonly modeService: ConfiguratorModeService
  ) {
  }
}
