import {ComponentModel} from '../../component/model/component-model'
import {Tuer} from '../../component/construction/tuer'
import {Seitenteil} from '../../component/construction/seitenteil'
import {Oberlicht} from '../../component/construction/oberlicht'
import {InjectionToken} from '@angular/core'
import {ParameterModelConfiguration} from '../../modelConfiguration/parameterModelConfiguration'
import {Material} from '../../material'
import {ConstructionComponentType} from '../../component/construction/construction-component-type.enum'
import {CompactComponentModel} from '../../../api/model/compact-component-model.interface'

export const FBS_MODEL_PROVIDER_TOKEN = new InjectionToken<FbsModelProvider>('app.configurator.dataprovider.fbsmodel')

export interface FbsModelProvider {
  calcMinMaxValuesFBS(
    element: Tuer | Seitenteil | Oberlicht,
    model: CompactComponentModel,
    construction: number
  ): {
    maxHeight: number
    maxWidth: number
    minHeight: number
    minWidth: number
  }

  filterModels(
    elementToFit: Tuer | Seitenteil | Oberlicht,
    construction: number | number[],
    modelSearchTerm?: string,
    selectedCatalogueId?: number,
    models?: (CompactComponentModel & {disabilityReasons?: string[]})[]
  ): (CompactComponentModel & {disabilityReasons?: string[]})[]

  loadModel(
    modelId: string,
    componentType: ConstructionComponentType,
    modelMaterial: Material,
    profil: string,
    konstruktion: number,
    parameterModel: ParameterModelConfiguration,
    oeffnungsart: string,
    dinFuellung: number,
  ): Promise<ComponentModel>
}
