import {Din} from '../../types'

export class DinUtil {
  static fromString(s: string, _default: Din = Din.Right): Din {
    return s === 'L' ? Din.Left : (s === 'R' ? Din.Right : _default)
  }
  static getDinFuellung(oeffnungsart: 'aussen' | 'innen', din: Din): Din {
    return oeffnungsart !== 'aussen'
      ? din
      : din === Din.Left
        ? Din.Right
        : Din.Left
  }
}
