import {Injectable} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {Workflow, WorkflowSubscription, WorkflowTrigger} from './workflow.types'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {Observer} from 'rxjs'
import {ParameterService} from '../classes/service/parameter/parameter.service'
import WorkflowModule from './workflow.module'
import WorkflowParser from './workflow-parser'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'
import {MehrpreisEntry} from '../classes/model/component/extras/zubehoer/mehrpreisEntry'
import {UnknownWorkflowFilterKey} from './internal.error'
import {ChangeType} from '../classes/model/event/events.types'
import ZubehoerChangeEvent from '../classes/model/event/zubehoer-change.event'
import {ZubehoerAddonEntry} from '../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import AddonChangeEvent from '../classes/model/event/addon-change.event'
import {ToastrService} from 'ngx-toastr'

export const MEHRPREISE_TRIGGER_KEY = 'mehrpreise'
export const DIENSTLEISTUNG_TRIGGER_KEY = 'dienstleistung'

@Injectable({providedIn: WorkflowModule})
export class WorkflowTriggerParser {
  constructor(
    private logger: NGXLogger
  ) {
  }

  private buildBasisTrigger(triggerParts: string[]): WorkflowTrigger {
    if (triggerParts.length < 2) {
      this.logger.warn('Too few workflow trigger child keys in "basis"!', triggerParts)
    } else if (triggerParts.length > 2) {
      this.logger.warn('Too many workflow trigger child keys in "basis"!', triggerParts)
    } else if (triggerParts[1].toLowerCase() !== 'change') {
      this.logger.warn(
        `Unknown workflow trigger action "${triggerParts[1]}" on "basis.${triggerParts[0].toLowerCase()}", expected ` +
        '"change"!'
      )
    }

    switch (triggerParts[0].toLowerCase()) {
      case 'konstruktionsvariante':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow): WorkflowSubscription =>
            ccm.constructionVariationChanged.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, ts, wf))
        }

      case 'materialtürfüllung':
      case 'materialtuerfüllung':
      case 'materialtürfuellung':
      case 'materialtuerfuellung':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow): WorkflowSubscription =>
            ccm.fillingMaterialChange.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, ts, wf))
        }


      case 'materialtürsystem':
      case 'materialtuersystem':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow): WorkflowSubscription =>
            ccm.systemMaterialChange.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, ts, wf))
        }

      case 'sicherheitspaket':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow): WorkflowSubscription =>
            ccm.securityPackageChange.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, ts, wf))
        }

      case 'wärmeschutzpaket':
      case 'waermeschutzpaket':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow): WorkflowSubscription =>
            ccm.thermalInsulationPackageChange.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, ts, wf))
        }

      case 'z-maß':
      case 'zmaß':
      case 'z-mass':
      case 'zmass':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow): WorkflowSubscription =>
            ccm.zMassChanged.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, ts, wf))
        }

      default:
        this.logger.error('Unknown workflow trigger key "' + triggerParts[0] + '" on "basis"!')
        return this.getUndefinedTriggerSubscriber()
    }
  }

  private buildInitTrigger(triggerParts: string[]): WorkflowTrigger {
    if (triggerParts.length > 1) {
      this.logger.warn('Too many workflow trigger child keys in "initialisierung"!', triggerParts)
    }
    switch (triggerParts[0].toLowerCase()) {
      case 'open':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow
          ): WorkflowSubscription =>
            ccm.configuratedDoorOpened.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, ts, wf))
        }

      case 'new':
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow): WorkflowSubscription =>
            ccm.newConfiguratedDoorInitialized.subscribe(this.createWorkflowSubscriber(ccm, cdm, ps, ts, wf))
        }

      default:
        this.logger.error('Unknown workflow trigger key "' + triggerParts[0] + '" on "initialisierung"!')
        return this.getUndefinedTriggerSubscriber()
    }
  }


  private buildMehrpreiseTrigger(triggerParts: string[]): WorkflowTrigger {
    if (triggerParts.length < 2) {
      this.logger.error(`Too few workflow trigger child keys in "${MEHRPREISE_TRIGGER_KEY}"!`, triggerParts)
      return this.getUndefinedTriggerSubscriber()
    } else if (triggerParts.length > 3) {
      this.logger.error(`Too many workflow trigger child keys in "${MEHRPREISE_TRIGGER_KEY}"!`, triggerParts)
      return this.getUndefinedTriggerSubscriber()
    } else if (['add', 'remove'].indexOf(triggerParts[triggerParts.length - 1].toLowerCase()) === -1) {
      this.logger.error(
        `Unknown workflow trigger action "${triggerParts[triggerParts.length - 1]}" on "${MEHRPREISE_TRIGGER_KEY}", `
        + 'expected on of "add", "remove"!'
      )
      return this.getUndefinedTriggerSubscriber()
    }
    const mehrpreisPredicateParseResult: (((e: MehrpreisEntry) => boolean)|UnknownWorkflowFilterKey)[] =
      WorkflowParser.buildPredicates(triggerParts[0], WorkflowParser.isSupportedMehrpreisKey, MEHRPREISE_TRIGGER_KEY)
    mehrpreisPredicateParseResult.filter((p): boolean => p instanceof UnknownWorkflowFilterKey)
      .forEach((err: UnknownWorkflowFilterKey): void => {
        this.logger.warn(err.message)
      })
    const mehrpreisePredicates = mehrpreisPredicateParseResult
      .filter((p): p is ((t: MehrpreisEntry) => boolean) => typeof p === 'function')

    const mehrpreisTriggerPredicate = (changeEvents: ZubehoerChangeEvent[]): ZubehoerChangeEvent[] =>
      mehrpreisePredicates.reduce<[ZubehoerChangeEvent, MehrpreisEntry][]>(
        (acc, curr): [ZubehoerChangeEvent, MehrpreisEntry][] =>
          acc.filter(([, entry]: [ZubehoerChangeEvent, MehrpreisEntry]): boolean => curr(entry)),
        changeEvents.map((event): [ZubehoerChangeEvent, MehrpreisEntry] =>
          [event, new MehrpreisEntry({Typ: event.identifier})]
        )
      ).map(([event]): ZubehoerChangeEvent => event)

    if (triggerParts.length === 2) {
      switch (triggerParts[1].toLowerCase()) {
        case 'add':
        case 'remove':
          const changeType = ({
            add: ChangeType.Added,
            remove: ChangeType.Removed,
          })[triggerParts[1].toLowerCase()]
          return {
            subscribeTrigger: (
              ccm: ConfiguratorConfigurationModel,
              cdm: ConfiguratorDataModel,
              ps: ParameterService,
              ts: ToastrService,
              wf: Workflow
            ): WorkflowSubscription => ccm.zubehoerChanged.subscribe(
              (changeEvent: ZubehoerChangeEvent): Promise<void> => {
                if (changeEvent.type === changeType && mehrpreisTriggerPredicate([changeEvent]).length) {
                  return wf.execute(ccm, cdm, ps, ts) || Promise.resolve()
                }
                return Promise.resolve()
              })
          }
        default:
          this.logger.error(
            `The impossible happened! "${triggerParts[1]}" must be  on of "add", "remove"!`,
            triggerParts
          )
          return this.getUndefinedTriggerSubscriber()
      }
    }

    if (!triggerParts[1].toLowerCase().startsWith(DIENSTLEISTUNG_TRIGGER_KEY)) {
      this.logger.error(
        `Unknown workflow trigger child key "${triggerParts[1]}" on ${MEHRPREISE_TRIGGER_KEY}!`,
        triggerParts
      )
      return this.getUndefinedTriggerSubscriber()
    }

    const addonPredicateParseResult: (((e: Partial<ZubehoerAddonEntry>) => boolean)|UnknownWorkflowFilterKey)[] =
      WorkflowParser.buildPredicates(triggerParts[1], WorkflowParser.isSupportedDienstleistungKey, DIENSTLEISTUNG_TRIGGER_KEY)
    addonPredicateParseResult.filter((p): boolean => p instanceof UnknownWorkflowFilterKey)
      .forEach((err: UnknownWorkflowFilterKey): void => {
        this.logger.warn(err.message)
      })
    const addonPredicates = addonPredicateParseResult
      .filter((p): p is ((t: Partial<ZubehoerAddonEntry>) => boolean) => typeof p === 'function')

    const addonTriggerPredicate = (changeEvents: AddonChangeEvent[]): AddonChangeEvent[] =>
      addonPredicates.reduce<[AddonChangeEvent, ZubehoerAddonEntry][]>(
        (acc, curr): [AddonChangeEvent, ZubehoerAddonEntry][] =>
          acc.filter(([,entry]: [AddonChangeEvent, ZubehoerAddonEntry]): boolean => curr(entry)),
        changeEvents.map((event): [AddonChangeEvent, ZubehoerAddonEntry] =>
          [event, new ZubehoerAddonEntry(event.identifier)]
        )
      ).map(([event]): AddonChangeEvent => event)


    switch (triggerParts[2].toLowerCase()) {
      case 'add':
      case 'remove':
        const changeType = ({
          add: ChangeType.Added,
          remove: ChangeType.Removed,
        })[triggerParts[2].toLowerCase()]
        return {
          subscribeTrigger: (
            ccm: ConfiguratorConfigurationModel,
            cdm: ConfiguratorDataModel,
            ps: ParameterService,
            ts: ToastrService,
            wf: Workflow
          ): WorkflowSubscription => ccm.zubehoerAddonChanged.subscribe(
            (changeEvent: AddonChangeEvent): Promise<void> => {
              if (changeEvent.type === changeType && addonTriggerPredicate([changeEvent]).length) {
                return wf.execute(ccm, cdm, ps, ts) || Promise.resolve()
              }
              return Promise.resolve()
            })
        }
      default:
        this.logger.error(
          `The impossible happened! "${triggerParts[1]}" must be  on of "add", "remove"!`,
          triggerParts
        )
    }
    return this.getUndefinedTriggerSubscriber()
  }

  public buildTrigger(triggerString: string): WorkflowTrigger {
    const triggerParts = triggerString.split('.')
    const firstTriggerPartLc = triggerParts[0].toLowerCase()
    switch (firstTriggerPartLc) {
      case 'initialisierung':
        return this.buildInitTrigger(triggerParts.slice(1))

      case 'basis':
        return this.buildBasisTrigger(triggerParts.slice(1))

    }
    if (firstTriggerPartLc.startsWith(MEHRPREISE_TRIGGER_KEY)) {
      return this.buildMehrpreiseTrigger(triggerParts)
    }

    this.logger.error('Unknown workflow trigger key "' + triggerParts[0] + '"!')
    return this.getUndefinedTriggerSubscriber()
  }

  private createWorkflowSubscriber(
    ccm: ConfiguratorConfigurationModel,
    cdm: ConfiguratorDataModel,
    ps: ParameterService,
    ts: ToastrService,
    wf: Workflow
  ): Pick<Observer<void>, 'next'> | { next: (value: void) => Promise<void> } {
    return {
      next: (): Promise<void> | void => wf.execute(ccm, cdm, ps, ts)
    }
  }

  private getUndefinedTriggerSubscriber(): WorkflowTrigger {
    return {
      subscribeTrigger: (): ReturnType<WorkflowTrigger['subscribeTrigger']> => ({
        unsubscribe: (): void => {
        }
      })
    }
  }
}
