import {AfterViewInit, Component, EventEmitter, Output, TemplateRef, ViewChild} from '@angular/core'
import {MatDialogRef} from '@angular/material/dialog'
import {NGXLogger} from 'ngx-logger'
import {ConfigurationLoaderService} from '../../load'
import {HttpService} from '../../../http.service'
import {firstValueFrom} from 'rxjs'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {FakeConfiguratorConfigurationModelService} from './providers/fake-configurator-configuration-model.service'
import {FakeComponentSelectionService} from './providers/fake-component-selection.service'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ConstructionComponentType} from '../../../classes/model/component/construction/construction-component-type.enum'

const TRANSLATION_KEY = {
  TITLE_DEFAULT: 'LoadDoorIdModal.Title.Default',
  TITLE_COMPONENT: 'LoadDoorIdModal.Title.Component',
  LOAD_ID_INPUT_LABEL: 'LoadDoorIdModal.Input.LoadId.Label',
  LOAD_ID_INPUT_PLACEHOLDER: 'LoadDoorIdModal.Input.LoadId.Placeholder',
  LOAD_ID_INPUT_HINT: 'LoadDoorIdModal.Input.LoadId.Hint',
  LOAD_ID_BUTTON_LABEL: 'LoadDoorIdModal.Button.LoadId.Label',
  LOADING_CONFIGURATION: 'LoadDoorIdModal.LoadingConfiguration.Title'
}

export type LoadConfigRequest = [DoorId, ComponentIndex] | false
type ComponentIndex = number
type DoorId = string

@Component({
  templateUrl: './load-door-id-modal.component.html',
  styleUrls: ['./load-door-id-modal.component.scss'],
  providers: [
    {provide: FakeComponentSelectionService},
    {provide: FakeConfiguratorConfigurationModelService},
    {provide: ComponentSelectionService, useExisting: FakeComponentSelectionService},
    {provide: ConfiguratorConfigurationModel, useExisting: FakeConfiguratorConfigurationModelService}
  ]
})
export class LoadDoorIdModalComponent implements AfterViewInit {
  #state: 'INITIAL' | 'LOADING' | 'LOADED'
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected doorIdValue: string
  protected doorImageUrl: string
  protected error: string | undefined
  protected grundformKeys: string[]
  @ViewChild('loadButtonFooter') loadButtonFooter: TemplateRef<unknown>
  @Output() protected loadRequest: EventEmitter<ComponentIndex>
  protected modalFooter: TemplateRef<unknown>
  protected modalTitle: string

  constructor(
    protected logger: NGXLogger,
    private dialogRef: MatDialogRef<LoadDoorIdModalComponent, LoadConfigRequest>,
    private configurationLoaderService: ConfigurationLoaderService,
    private httpService: HttpService,
    private fakeComponentProvider: FakeConfiguratorConfigurationModelService,
    componentSelection: FakeComponentSelectionService
  ) {
    this.state = 'INITIAL'
    this.modalTitle = TRANSLATION_KEY.TITLE_DEFAULT
    componentSelection.registerSelectionHandler((index) => void this.loadComponentConfiguration(index))
  }

  loadComponentConfiguration(componentIndex: ComponentIndex): void {
    this.dialogRef.close([this.doorIdValue, componentIndex])
  }

  loadConfiguration(): void {
    this.logger.trace('loadConfiguration', this.doorIdValue)
    this.error = undefined
    this.state = 'LOADING'
    Promise.all([
      this.configurationLoaderService.loadConfigFromDoorId(this.doorIdValue),
      firstValueFrom(this.httpService.getDoorImageThumbnails(this.doorIdValue))
    ]).then((data): void => {
      this.doorImageUrl = data?.[1]?.Images?.Aussen
      const grundform = this.configurationLoaderService.getLoadedGrundformKey()
      if (grundform.length <= 1) {
        this.dialogRef.close([this.doorIdValue, 0])
      } else {
        this.grundformKeys = grundform.split('')
        this.fakeComponentProvider.types = this.grundformKeys.map((key): ConstructionComponentType => {
          switch (key) {
            case 'T':
              return ConstructionComponentType.Door
            case 'S':
              return ConstructionComponentType.SidePanel
            case 'O':
              return ConstructionComponentType.Fanlight
          }
        }).filter(String)
        this.state = 'LOADED'
      }
    }).catch((err): void => {
      this.error = String(err)
      this.state = 'INITIAL'
    })
  }

  ngAfterViewInit(): void {
    // defer setting footer template out of lifecycle hook
    setTimeout(() => void (this.state = this.#state))
  }

  get state(): 'INITIAL' | 'LOADING' | 'LOADED' {
    return this.#state
  }

  set state(value: 'INITIAL' | 'LOADING' | 'LOADED') {
    this.#state = value
    switch (value) {
      case 'INITIAL':
        this.modalTitle = TRANSLATION_KEY.TITLE_DEFAULT
        this.modalFooter = this.loadButtonFooter
        break
      case 'LOADING':
        this.modalTitle = TRANSLATION_KEY.TITLE_DEFAULT
        this.modalFooter = undefined
        break
      case 'LOADED':
        this.modalTitle = TRANSLATION_KEY.TITLE_COMPONENT
        this.modalFooter = undefined
        break
    }
  }
}
