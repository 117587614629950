<modal-frame
    [footer]='footer'
    hostId='kalkulationModal'
    modalCloseButton
    title='{{TRANSLATION_KEY.CALC_MODAL_TITLE | translate}}'
>


  <div *ngIf='this.configuratedDoor && this.configuratorMode === ConfiguratorMode.FBS' class='calculate'>
    <div class='content'>
      <div class='row'>
        <div class='column'>

          <ng-template [ngTemplateOutlet]='uValues'></ng-template>


          <ng-container *ngIf='this.configuratedDoor.summary.dataArray.length > 0'>
            <h1>{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_SUMMARY | translate }}</h1>
            <table [dataSource]='configuratedDoor.summary.dataArray' class='summary' mat-table>

              <!-- Category Column -->
              <ng-container matColumnDef='Category'>
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_ASSIGNMENT | translate }}
                </th>
                <td *matCellDef='let element' mat-cell> {{ element.Category | translate }}</td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef='Prefix'>
                <th *matHeaderCellDef
                    mat-header-cell>{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_PREFIX | translate }}
                </th>
                <td *matCellDef='let element' mat-cell> {{ element.Prefix | translate }}</td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef='Titel'>
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_TITLE | translate }}
                </th>
                <td *matCellDef='let element' mat-cell> {{ element.Titel | translate }}</td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef='Text'>
                <th
                    *matHeaderCellDef
                    mat-header-cell
                >{{ TRANSLATION_KEY.CALC_MODAL_TABLE_COLUMN_TEXT | translate }}
                </th>
                <td *matCellDef='let element' mat-cell> {{ element.Text | translate }}</td>
              </ng-container>

              <tr
                  *matHeaderRowDef="
                                    configuratedDoor.summary.getCategoryCount() < 2
                                        ? ['Prefix','Titel','Text']
                                        : ['Category', 'Prefix','Titel','Text']
                                "
                  mat-header-row
              ></tr>
              <tr
                  *matRowDef="
                                    let emprow;
                                    columns: configuratedDoor.summary.getCategoryCount() < 2
                                        ? ['Prefix','Titel','Text']
                                        : ['Category', 'Prefix','Titel','Text']
                                "
                  mat-row
              ></tr>
            </table>
          </ng-container>
          <ng-container *ngIf='this.configuratedDoor?.summary?.dataArray?.length === 0'>
            <p class='loadingInformation'>
              {{ TRANSLATION_KEY.LOADING_CONFIGURATION | translate }} <i
                class='fa fa-2x fa-spin fa-spinner'></i>
            </p>
          </ng-container>


        </div>

        <div class='column'>

          <div class='scrollWrapper'>

            <div
                *ngIf="this.selectedComponent.model?.MenueEinstellungen?.Kalkulation?.Aktion?.Sichtbar
                || this.selectedComponent.model?.MenueEinstellungen?.Kalkulation?.Kommission?.Sichtbar"
                class='row'>
              <div *ngIf="this.selectedComponent.model?.MenueEinstellungen?.Kalkulation?.Kommission?.Sichtbar"
                   class='column'>
                <h1>{{ TRANSLATION_KEY.CALC_MODAL_INPUT_KOMMISSION_TITLE | translate }}</h1>
                <p class='kommission'>
                  <input
                      [(ngModel)]='this.configuratedDoor.Kommission'
                      [disabled]="!this.selectedComponent.model?.MenueEinstellungen?.Kalkulation?.Kommission?.Aenderbar"
                      [value]="parameter.kommission ?? ''"
                  >
                </p>
              </div>
              <div *ngIf='this.selectedComponent.model?.MenueEinstellungen?.Kalkulation?.Aktion?.Sichtbar'
                   class='column'>
                <h1>{{ TRANSLATION_KEY.CALC_MODAL_INPUT_ACTION_TITLE | translate }}</h1>
                <p class='aktion'>
                  <input
                      (keyup)='this.delayedCalculation()'
                      [(ngModel)]='this.configuratedDoor.Aktion'
                      [disabled]="!this.selectedComponent.model?.MenueEinstellungen?.Kalkulation?.Aktion?.Aenderbar"
                      [value]='this.configuratedDoor.Aktion'
                  >
                </p>
              </div>
            </div>

            <h1>{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TITLE | translate }}</h1>

            <div *ngIf='this.configuratedDoor.Prices' class='priceDataWrapper'>

              <table [dataSource]='this.configuratedDoor.Prices.Data' class='prices' mat-table>

                <!-- Name Column -->
                <ng-container matColumnDef='nr'>
                  <th
                      *matHeaderCellDef
                      mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_NR | translate }}
                  </th>
                  <td *matCellDef='let element' mat-cell> {{ element.Nr }}</td>
                </ng-container>
                <ng-container matColumnDef='text'>
                  <th
                      *matHeaderCellDef
                      mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_DESCRIPTION | translate }}
                  </th>
                  <td *matCellDef='let element' mat-cell> {{ element.Text  | translate }}</td>
                </ng-container>
                <!-- Symbol Column -->
                <ng-container matColumnDef='brutto'>
                  <th
                      *matHeaderCellDef
                      mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_BRUTTO | translate }}
                  </th>
                  <td
                      *matCellDef='let element'
                      mat-cell
                  > {{
                          element.PreisBrutto.toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR'
                          })
                    }}
                  </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef='rabatt'>
                  <th
                      *matHeaderCellDef
                      mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_DISCOUNT | translate }}
                  </th>
                  <td *matCellDef='let element' mat-cell>
                    {{
                          element.Rabatt !== 0 ? element.Rabatt : ''
                    }}{{

                          element.Rabatt !== null && element.Rabatt !== 0 ? '%' : ''
                    }}
                    <span *ngIf='element.Sonderrabatt !== null && element.Sonderrabatt !== 0'>
                                            + {{ element.Sonderrabatt }}{{ element.Sonderrabatt !== null ? '%' : '' }}
                                        </span>
                  </td>
                </ng-container>
                <!-- Weight Column -->
                <ng-container matColumnDef='netto'>
                  <th
                      *matHeaderCellDef
                      mat-header-cell
                  >{{ TRANSLATION_KEY.CALC_MODAL_PRICES_TABLE_COLUMN_NETTO | translate }}
                  </th>
                  <td
                      *matCellDef='let element'
                      mat-cell
                  > {{
                          element.PreisNetto.toLocaleString('de-DE', {
                              style: 'currency',
                              currency: 'EUR'
                          })
                    }}
                  </td>
                </ng-container>

                <tr *matHeaderRowDef='this.getCalculationColumns()' mat-header-row></tr>
                <tr
                    *matRowDef='let emprow; columns: this.getCalculationColumns() ;'
                    mat-row
                ></tr>
              </table>

            </div>

            <ng-container *ngIf='!this.configuratedDoor.Prices'>
              <p class='loadingInformation'>{{ TRANSLATION_KEY.CALC_MODAL_LOADING_DATA | translate }}<i
                  class='fa fa-2x fa-spin fa-spinner'
              ></i></p>
            </ng-container>

            <ng-container *ngIf='this.configuratedDoor.Infos.length > 0'>
              <h1>{{ TRANSLATION_KEY.CALC_MODAL_HINTLIST_HINTS_TITLE | translate }}</h1>

              <div *ngFor='let info of this.configuratedDoor.Infos ' class='configurationInfo'>
                <p class='message'><i class='fa fa-info-circle'></i> {{ info['Message']  | translate }}
                </p>
              </div>
            </ng-container>

            <ng-container *ngIf='this.configuratedDoor.Warnings.length > 0'>
              <h1>{{ TRANSLATION_KEY.CALC_MODAL_HINTLIST_WARNINGS_TITLE | translate }}</h1>

              <div *ngFor='let warning of this.configuratedDoor.Warnings ' class='configurationWarning'>
                <p class='message'><i
                    class='fa fa-exclamation-triangle'
                ></i> {{ warning['Message']  | translate }}</p>
              </div>
            </ng-container>

            <ng-container *ngIf='this.configuratedDoor.Errors.length > 0'>
              <h1>{{ TRANSLATION_KEY.CALC_MODAL_HINTLIST_ERRORS_TITLE | translate }}</h1>

              <div *ngFor='let error of this.configuratedDoor.Errors ' class='configurationError'>
                <p class='message'><i
                    class='fa fa-exclamation-circle'
                ></i> {{ error['Message']  | translate }}</p>
              </div>
            </ng-container>

          </div>


        </div>
      </div>
    </div>
  </div>

</modal-frame>
<ng-template #footer>
  <table class='buttons'>
    <tr>
      <td>
        <button (click)='showNotesDialog()' class='notesButton'>
          {{ TRANSLATION_KEY.BUTTON_NOTES | translate }}
        </button>
      </td>
      <td>
        <button
            (click)='this.saveFbs()'
            [disabled]='!this.configuratedDoor.Prices'
            id='sendFBSConfig'
        >
          <i class='fa fa-floppy-o'></i>
          <span>{{ TRANSLATION_KEY.BUTTON_SAVE | translate }}</span>
        </button>
      </td>
    </tr>
  </table>
</ng-template>

<ng-template #uValues>
  <h1>{{ TRANSLATION_KEY.CALC_MODAL_VALUES_TITLE | translate }}</h1>
  <table class='calcValues'>
    <tr *ngIf='this.configuratedDoor.DoorWeight && this.configuratedDoor.Insulation'>
      <td>
        <p>{{ TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_TITLE | translate }}</p>
      </td>
      <td>
        <p class='weight'>{{ TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_PREFIX | translate }}
          {{ this.configuratedDoor.DoorWeight }}{{ TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_SUFFIX | translate }}</p>
      </td>
      <td>
        <p [innerHTML]='this.getUPString() | safeHtml'></p>
      </td>
      <td>
        <p class='insulation'>{{ this.configuratedDoor.Insulation.Up }}</p>
      </td>
      <td>
        <p [innerHTML]='this.getUGString() | safeHtml'></p>
      </td>
      <td>
        <p class='insulation'>{{ this.configuratedDoor.Insulation.Ug }}</p>
      </td>
    </tr>
    <tr *ngIf='!this.configuratedDoor.DoorWeight || !this.configuratedDoor.Insulation'>
      <td>
        <ng-container *ngIf='!this.configuratedDoor.Prices'>
          <p class='loadingInformation'>{{ TRANSLATION_KEY.CALC_MODAL_LOADING_DATA | translate }}
            <i class='fa fa-2x fa-spin fa-spinner'></i></p>
        </ng-container>
      </td>
    </tr>
  </table>
</ng-template>
