import { Injectable} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {HttpService} from '../../http.service'
import {Profile} from '../../class'

@Injectable()
export class ProfileService {
  private _profiles: Profile[] = []

  constructor(
    private httpService: HttpService,
    private logger: NGXLogger
  ) {
  }

  findById(id: number): Profile | null {
    return this._profiles.find((p): boolean => p.Id === id) ?? null
  }

  getDefault(): Profile | null {
    return this._profiles.find((p): boolean => p.Standard) ?? this._profiles.find((): boolean => true) ?? null
  }

  public load(): Promise<void> {
    this.logger.trace('loadAllProfiles')
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getProfiles().subscribe({
        next: (data): void => {
          if (data) {
            this._profiles = []
            data.forEach((item) => void this.profiles.push(new Profile(item)))
            resolve()
          }
        },
        error: (error): void => {
          this.logger.error(['Error', error])
          reject()
        },
        complete: (): void => {
          resolve()
        }
      })
    })
  }

  get profiles(): Profile[] {
    return this._profiles
  }
}
