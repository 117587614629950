import {Component, Inject} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {Favorite, shareParameter} from '../../../class'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {HttpService} from '../../../http.service'
import {FormBuilder, FormGroup, Validators} from '@angular/forms'
import {Parameter} from '../../../classes/service/parameter/parameter'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {ParameterService} from '../../../classes/service/parameter/parameter.service'
import MemoListItem from '../../../classes/service/memo-list/memo-list-item'
import {ModalService} from '../modal.service'
import {TranslateService} from '../../../translate'

const TRANSLATION_KEY = {
  TITLE: 'ShareModal.Title',
  YOUR_CONFIGS_SINGULAR: 'ShareModal.YourConfigs.Singular',
  YOUR_CONFIGS_PLURAL: 'ShareModal.YourConfigs.Plural',
  DOOR_PREVIEW_IMAGE_ALT: 'ShareModal.PreviewImage.Alt',
  BUTTON_COPY_TO_CLIPBOARD_TITLE: 'ShareModal.Button.CopyToClipboard.Title',
  HINT_NEW_ID_WILL_BE_GENERATED: 'ShareModal.Hint.NewIdWillBeGenerated',
  FORM_SENDER_NAME_LABEL: 'ShareModal.Form.Sender.Name.Label',
  FORM_SENDER_NAME_PLACEHOLDER: 'ShareModal.Form.Sender.Name.Placeholder',
  FORM_RECIPIENT_NAME_LABEL: 'ShareModal.Form.Recipient.Name.Label',
  FORM_RECIPIENT_NAME_PLACEHOLDER: 'ShareModal.Form.Recipient.Name.Placeholder',
  FORM_SENDER_EMAIL_LABEL: 'ShareModal.Form.Sender.Email.Label',
  FORM_SENDER_EMAIL_PLACEHOLDER: 'ShareModal.Form.Sender.Email.Placeholder',
  FORM_RECIPIENT_EMAIL_LABEL: 'ShareModal.Form.Recipient.Email.Label',
  FORM_RECIPIENT_EMAIL_PLACEHOLDER: 'ShareModal.Form.Recipient.Email.Placeholder',
  FORM_MESSAGE_LABEL: 'ShareModal.Form.Message.Label',
  BUTTON_SEND_CONFIG: 'ShareModal.Form.Button.SendConfig',
  BUTTON_CONFIG_SENT: 'ShareModal.Form.Button.ConfigSent',
  FORM_ERROR_REQUIRED: 'ShareModal.Form.Error.Required',
  FORM_ERROR_MAIL_FORMAT: 'ShareModal.Form.Error.MailFormat'
}
export type ShareModalData = MemoListItem[]

@Component({
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  parameter: Parameter
  shareButton = 0
  shareFavorite: Favorite
  shareItems: MemoListItem[] = []
  shareSendResult: FormGroup
  shareSendResultSubmit: boolean = false
  urlPrefix: string = 'https://' + window.location.hostname + '/?tuerId='

  constructor(
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private httpService: HttpService,
    private parameterService: ParameterService,
    fb: FormBuilder,
    private modalService: ModalService,
    private _translate: TranslateService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) shareItems: ShareModalData
  ) {
    this.shareItems = shareItems
    this.shareSendResult = fb.group({
      name: [null, Validators.required],
      mail: [null, [Validators.required, Validators.email]],
      recipient_name: [null, Validators.required],
      recipient_mail: [null, [Validators.required, Validators.email]],
      hashes: [this.shareItems.map((i: MemoListItem): string => i.doorId)],
      info: [null]
    })
    this.shareSendResult.valueChanges.subscribe((): void => {
      this.shareButton = 0
    })
  }

  getFormErrorForField(
    inputField: string
  ): string | null {
    const errors: string[] = []
    if (this.shareSendResult.controls[inputField]?.errors.required) {
      errors.push(this._translate.translate(TRANSLATION_KEY.FORM_ERROR_REQUIRED))
    }
    if (this.shareSendResult.controls[inputField]?.errors.email) {
      errors.push(this._translate.translate(TRANSLATION_KEY.FORM_ERROR_MAIL_FORMAT))
    }
    return errors.length > 0 ? errors.join(', ') : null
  }

  sendShareForm(data: this['shareSendResult']['value']): void {
    this.logger.trace('sendShareForm')
    if (this.shareSendResult.valid && this.shareButton !== 2) {
      this.shareButton = 1
      this.shareSendResultSubmit = false
      this.httpService
        .postShareConfiguration(
          new shareParameter({
            FromName: data.name as string,
            FromMail: data.mail as string,
            ToName: data.recipient_name as string,
            ToMail: data.recipient_mail as string,
            TuerIds: data.hashes as string[],
            Nachricht: data.info as string
          })
        ).subscribe({
          error: (error): void => this.logger.error(error),
          complete: (): void => {
            this.shareButton = 2
          }
        })
    } else {
      this.shareSendResultSubmit = true
    }
  }
}
