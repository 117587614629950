import {Beschichtung} from './beschichtung'
import {OneSidedColorizableComponent} from './colorizableComponent'
import {ColorBase} from './colorBase'
import {SideType} from '../../../types'
import {Folie} from './folie'
import {Lack} from './lack'
import {Material} from '../material'
import {ConfiguratedDeckschicht} from '../../../class'

export class Deckschicht extends OneSidedColorizableComponent {
  Beschichtung: Beschichtung
  Bezeichnung: string
  Id: number
  ObjectType: string
  OberflaechenTyp: string

  constructor(data?: Partial<Deckschicht> | Partial<ConfiguratedDeckschicht>) {
    super(data)
    this.Beschichtung = data?.Beschichtung && new Beschichtung(data.Beschichtung)
    this.Id = data?.Id
    this.Bezeichnung = data?.Bezeichnung
    this.OberflaechenTyp = data?.OberflaechenTyp
    this.ObjectType = 'Deckschicht'
  }

  protected doColorMaterialSupportCheck(): boolean {
    return true
  }

  isColorSupported(color: ColorBase, side: SideType, material: Material): boolean {
    return (
      (!(color instanceof Folie || color instanceof Lack))
      || color.IsDeckschichtMoeglich
    ) && super.isColorSupported(color, side, material)
  }
}
