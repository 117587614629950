<modal-frame [hideCloseButton]="true" [title]="TRANSLATION_KEY.HEADING | translate" [footer]="footer">
    {{ TRANSLATION_KEY.TEXT | translate }}
    <br>
    <mat-form-field>
        <mat-label>{{ TRANSLATION_KEY.LABEL | translate }}</mat-label>
        <mat-select required placeholder="{{ TRANSLATION_KEY.PLACEHOLDER | translate }}" #select="ngModel" [(ngModel)]="construction">
            <mat-option [value]="SimpleConstruction.Einsatz">{{ TRANSLATION_KEY.CONSTRUCTION_INSERT | translate }}</mat-option>
            <mat-option [value]="SimpleConstruction.AufsatzEinseitig">{{ TRANSLATION_KEY.CONSTRUCTION_ATTACH | translate }}</mat-option>
            <mat-option [value]="SimpleConstruction.AufsatzBeidseitig">
                {{ TRANSLATION_KEY.CONSTRUCTION_ATTACH_BOTH | translate }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="select.touched && select.hasError('required')">{{ TRANSLATION_KEY.ERROR | translate }}</mat-error>
    </mat-form-field>
</modal-frame>
<ng-template #footer>
    <button mat-raised-button color="primary" (click)="onSubmit()">{{ TRANSLATION_KEY.BUTTON_LABEL | translate }}</button>
</ng-template>
