export enum NavigationMenuEntryKey {
  Addon = 'addon',
  BaseShape = 'base-shape',
  Basis = 'basis',
  Catalogue = 'catalogue',
  Characteristics = 'characteristics',
  Color = 'color',
  Dimension = 'dimension',
  Finalize = 'finalize',
  Glass = 'glass',
  HouseFront = 'house-front',
  Load = 'load',
  Model = 'model',
  Option = 'option'
}

export const TRANSLATION_KEY = {
  MENU_ENTRY_ADDON: 'MenuEntry.Mehrpreise',
  MENU_ENTRY_BASE_SHAPE: 'MenuEntry.Grundform',
  MENU_ENTRY_BASIS: 'MenuEntry.Basis',
  MENU_ENTRY_CATALOGUE: 'MenuEntry.Katalog',
  MENU_ENTRY_CHARACTERISTICS: 'MenuEntry.Characteristics',
  MENU_ENTRY_COLOR: 'MenuEntry.Farben',
  MENU_ENTRY_DIMENSION: 'MenuEntry.Masse',
  MENU_ENTRY_FINALIZE: 'MenuEntry.Fertig',
  MENU_ENTRY_GLASS: 'MenuEntry.Glaeser',
  MENU_ENTRY_HOUSE_FRONT: 'MenuEntry.Hausfront',
  MENU_ENTRY_LOAD: 'MenuEntry.Load',
  MENU_ENTRY_MODEL: 'MenuEntry.Modell',
  MENU_ENTRY_OPTION: 'MenuEntry.Optionen',
} as const

export const TRANSLATION_KEY_MAP: Record<NavigationMenuEntryKey, string> = {
  [NavigationMenuEntryKey.Addon]: TRANSLATION_KEY.MENU_ENTRY_ADDON,
  [NavigationMenuEntryKey.BaseShape]: TRANSLATION_KEY.MENU_ENTRY_BASE_SHAPE,
  [NavigationMenuEntryKey.Basis]: TRANSLATION_KEY.MENU_ENTRY_BASIS,
  [NavigationMenuEntryKey.Catalogue]: TRANSLATION_KEY.MENU_ENTRY_CATALOGUE,
  [NavigationMenuEntryKey.Characteristics]: TRANSLATION_KEY.MENU_ENTRY_CHARACTERISTICS,
  [NavigationMenuEntryKey.Color]: TRANSLATION_KEY.MENU_ENTRY_COLOR,
  [NavigationMenuEntryKey.Dimension]: TRANSLATION_KEY.MENU_ENTRY_DIMENSION,
  [NavigationMenuEntryKey.Finalize]: TRANSLATION_KEY.MENU_ENTRY_FINALIZE,
  [NavigationMenuEntryKey.Glass]: TRANSLATION_KEY.MENU_ENTRY_GLASS,
  [NavigationMenuEntryKey.HouseFront]: TRANSLATION_KEY.MENU_ENTRY_HOUSE_FRONT,
  [NavigationMenuEntryKey.Load]: TRANSLATION_KEY.MENU_ENTRY_LOAD,
  [NavigationMenuEntryKey.Model]: TRANSLATION_KEY.MENU_ENTRY_MODEL,
  [NavigationMenuEntryKey.Option]: TRANSLATION_KEY.MENU_ENTRY_OPTION
}
