<modal-frame
  hostClass='favoriteModal'
  modalCloseButton
  title='{{TRANSLATION_KEY.TITLE}}'
>
  <div class='content'>
    <div class='inner'>
      <div class='inner--mask'>

        <div class='qrhead'>
          <div class='tuerids'>
            <h3>{{
                (this.shareItems.length > 1
                  ? TRANSLATION_KEY.YOUR_CONFIGS_PLURAL
                  : TRANSLATION_KEY.YOUR_CONFIGS_SINGULAR) | translate
              }}</h3>

            <div class='list'>
              <div *ngFor='let item of this.shareItems' class='item'>
                <img
                  *loadingAnimation="item?.thumbnailUrl | async; background: true; placement: 'here'"
                  alt='{{TRANSLATION_KEY.DOOR_PREVIEW_IMAGE_ALT|translate}} {{  item?.doorId  }}'
                  class='thumbnail'
                />
                <div class='title'>
                  <a href='{{ this.urlPrefix + item.doorId}}' target='_blank'>{{ item.doorId }}</a>
                  <button
                    [attr.aria-label]='TRANSLATION_KEY.BUTTON_COPY_TO_CLIPBOARD_TITLE|translate'
                    [title]='TRANSLATION_KEY.BUTTON_COPY_TO_CLIPBOARD_TITLE|translate'
                    mat-icon-button
                    matSuffix
                  >
                    <i class='fa fa-copy'></i>
                  </button>
                </div>

              </div>
            </div>
            <p>{{
                TRANSLATION_KEY.HINT_NEW_ID_WILL_BE_GENERATED | translate
              }}</p>
          </div>
        </div>

        <article *ngIf='shareFavorite' class='add'>
          <section
            [ngStyle]="{'background-image': 'url(' + shareFavorite.thumb_url + ')'}"
            class='image'
          >
            <i [class.hidden]='shareFavorite.thumb_url' class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          </section>
        </article>

        <form (ngSubmit)='sendShareForm(shareSendResult.value)' [formGroup]='shareSendResult' novalidate>
          <div class='form-group'>
            <mat-form-field appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.FORM_SENDER_NAME_LABEL | translate }}</mat-label>
              <input
                class='form-control' formControlName='name' matInput
                placeholder='{{TRANSLATION_KEY.FORM_SENDER_NAME_PLACEHOLDER | translate}}'
              >
              <mat-error *ngIf="shareSendResult.controls['name'].invalid">
                {{ this.getFormErrorForField('name') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.FORM_SENDER_EMAIL_LABEL | translate }}</mat-label>
              <input
                class='form-control' formControlName='mail' matInput
                placeholder='{{TRANSLATION_KEY.FORM_SENDER_EMAIL_PLACEHOLDER | translate}}'
              >
              <mat-error *ngIf="shareSendResult.controls['mail'].invalid">
                {{ this.getFormErrorForField('mail') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.FORM_RECIPIENT_NAME_LABEL | translate }}</mat-label>
              <input
                class='form-control' formControlName='recipient_name' matInput
                placeholder='{{TRANSLATION_KEY.FORM_RECIPIENT_NAME_PLACEHOLDER | translate}}'
              >
              <mat-error *ngIf="shareSendResult.controls['recipient_name'].invalid">
                {{ this.getFormErrorForField('recipient_name') }}
              </mat-error>
            </mat-form-field>


            <mat-form-field appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.FORM_RECIPIENT_EMAIL_LABEL | translate }}</mat-label>
              <input
                class='form-control' formControlName='recipient_mail' matInput
                placeholder='{{TRANSLATION_KEY.FORM_RECIPIENT_EMAIL_PLACEHOLDER | translate}}'
              >
              <mat-error *ngIf="shareSendResult.controls['recipient_mail'].invalid">
                {{ this.getFormErrorForField('recipient_mail') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ TRANSLATION_KEY.FORM_MESSAGE_LABEL | translate }}</mat-label>
              <textarea
                #autosize='cdkTextareaAutosize'
                cdkAutosizeMaxRows='5'
                cdkAutosizeMinRows='1'
                cdkTextareaAutosize
                class='form-control'
                formControlName='info'
                matInput
              ></textarea>
            </mat-form-field>

            <button [class.shared]='shareButton === 2'>
              <i *ngIf='shareButton === 0' class='fa fa-mail-forward'></i>
              <i *ngIf='shareButton === 1' aria-hidden='true' class='fa fa-cog fa-spin fa-fw'></i>
              <i *ngIf='shareButton === 2' class='fa fa fa-check'></i>
              <span *ngIf='shareButton < 2'>{{ TRANSLATION_KEY.BUTTON_SEND_CONFIG | translate }}</span>
              <span *ngIf='shareButton === 2'>{{ TRANSLATION_KEY.BUTTON_CONFIG_SENT | translate }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</modal-frame>

