import {Component} from '@angular/core'
import {TranslateService, TranslationKeys} from '../translate'

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
  protected readonly TranslationKeys = TranslationKeys
  constructor(protected readonly translateService: TranslateService) {}
}
