import {NgModule} from '@angular/core'
import {CookieConsentComponent} from './cookie-consent.component'
import {StorageModule} from '../storage/storage.module'
import {CookieConsentService} from './cookie-consent.service'
import {TranslateModule} from '../translate'
import {RouterLink} from '@angular/router'
import {FormsModule} from '@angular/forms'

export { CookieConsentService, CookieConsentComponent }

@NgModule({
  imports: [StorageModule, TranslateModule, RouterLink, FormsModule],
  providers: [ CookieConsentService ],
  declarations: [ CookieConsentComponent ],
  exports: [ CookieConsentComponent ],
})
export class CookieConsentModule { }
