import {Massblatt} from '../massblatt'
import {ModelDienstleistung} from '../../../../api/model/addons/model-dienstleistung.interface'

export class Dienstleistung {
  Beschreibung: string
  Bezeichnung: string
  Id: number
  IsAluMoeglich: boolean
  IsAufsatzBeidseitigMoeglich: boolean
  IsAufsatzEinseitigMoeglich: boolean
  IsEinsatzMoeglich: boolean
  IsGlasMoeglich: boolean
  IsKunststoffMoeglich: boolean
  Massblaetter: Massblatt[] = []
  Typ: string


  constructor(data?: Partial<Dienstleistung | ModelDienstleistung>) {
    this.Beschreibung = data && data.Beschreibung
    this.Bezeichnung = data && data.Bezeichnung
    this.Id = data && data.Id
    this.IsAluMoeglich = data && data.IsAluMoeglich
    this.IsGlasMoeglich = data && data.IsGlasMoeglich
    this.IsKunststoffMoeglich = data && data.IsKunststoffMoeglich
    this.Typ = data && data.Typ
    data?.Massblaetter?.forEach(m => void this.Massblaetter.push(new Massblatt(m)))
    if (data) {
      if ('IsAufsatzBeidseitigMoeglich' in data) {
        this.IsAufsatzBeidseitigMoeglich = data && data.IsAufsatzBeidseitigMoeglich
      }
      if ('IsAufsatzEinseitigMoeglich' in data) {
        this.IsAufsatzEinseitigMoeglich = data && data.IsAufsatzEinseitigMoeglich
      }
      if ('IsEinsatzMoeglich' in data) {
        this.IsEinsatzMoeglich = data && data.IsEinsatzMoeglich
      }
    }
  }
}
