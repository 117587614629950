import {Injectable} from '@angular/core'
import {ConfiguratorModeService} from './configuratorMode.service'
import {ModelConfiguration} from '../model/modelConfiguration/modelConfiguration'
import {ConfiguratorMode} from '../../types'
import {ParameterService} from './parameter/parameter.service'
import {InitialModelConfiguration} from '../model/modelConfiguration/initialModelConfiguration'

@Injectable()
export class ModelConfigurationService {
  private readonly ttkModelConfiguration: ModelConfiguration
  constructor(
    private readonly parameterService: ParameterService,
    private readonly mode: ConfiguratorModeService
  ) {
    if (mode.mode !== ConfiguratorMode.FBS) {
      this.ttkModelConfiguration = new InitialModelConfiguration()
    }
  }

  get modelConfiguration(): ModelConfiguration {
    return this.mode.mode === ConfiguratorMode.FBS ? this.parameterService.model : this.ttkModelConfiguration
  }
}
