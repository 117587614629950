abstract class BaseSpacerThicknessError extends Error {
  protected constructor(
    readonly requiredSpacerThickness: number,
    message?: string,
    options?: unknown
  ) {
    super(message, options)
  }
}

export type SpacerThicknessError = BaseSpacerThicknessError

export class SpacerThicknessUndercutError extends BaseSpacerThicknessError {
  constructor(requiredSpacerThickness: number) {
    super(requiredSpacerThickness, 'Spacer thickness was undercut!')
  }
}

export class SpacerThicknessExceededError extends BaseSpacerThicknessError {
  constructor(requiredSpacerThickness: number) {
    super(requiredSpacerThickness, 'Spacer thickness was exceeded!')
  }
}
