import {Component, EventEmitter, HostListener, Output} from '@angular/core'
import {ModalService} from '../../modal/modal.service'

const TRANSLATION_KEY = {
  TITLE: 'LoadConfigButton.Title'
}
export type LoadRequestIdentifier = [DoorId, ComponentIndex]
type ComponentIndex = number
type DoorId = string

@Component({
  selector: 'configurator-load-configuration-button',
  templateUrl: './load-configuration-button.component.html'
})
export class LoadConfigurationButtonComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @Output() protected loadRequest: EventEmitter<LoadRequestIdentifier>

  constructor(private modalService: ModalService) {
    this.loadRequest = new EventEmitter<LoadRequestIdentifier>()
  }

  private actionHandler(): void {
    this.modalService.showLoadDoorIdModal().afterClosed().subscribe((requestedComponentIdentifier): void => {
      if (typeof requestedComponentIdentifier !== 'undefined' && requestedComponentIdentifier !== false) {
        this.loadRequest.emit(requestedComponentIdentifier)
      }
    })
  }

  @HostListener('click') clickHandler(): void {
    this.actionHandler()
  }
}
