<modal-frame
  [footer]='footer'
  [title]='this.konstruktion.Beschreibung'
  contentClass='content'
  hostId='massblattDiv'
  modalCloseButton
>
  <div class='images'>
    <ng-container *ngIf='this.konstruktion.Bild1Url'>
      <div class='image'>
        <img
          (click)='this.showImageModalDialog(this.konstruktion.Bezeichnung, this.konstruktion.Bild1Url)'
          [src]='this.konstruktion.Bild1Url'
        >
      </div>
    </ng-container>

    <ng-container *ngIf='this.konstruktion.Bild2Url'>
      <div class='image'>
        <img
          (click)='this.showImageModalDialog(this.konstruktion.Bezeichnung, this.konstruktion.Bild2Url)'
          [src]='this.konstruktion.Bild2Url'
        >
      </div>
    </ng-container>

    <ng-container *ngIf='this.konstruktion.Bild3Url'>
      <div class='image'>
        <img
          (click)='this.showImageModalDialog(this.konstruktion.Bezeichnung, this.konstruktion.Bild3Url)'
          [src]='this.konstruktion.Bild3Url'
        >
      </div>
    </ng-container>

    <ng-container *ngIf='this.konstruktion.Bild4Url'>
      <div class='image'>
        <img
          (click)='this.showImageModalDialog(this.konstruktion.Bezeichnung, this.konstruktion.Bild4Url)'
          [src]='this.konstruktion.Bild4Url'
        >
      </div>
    </ng-container>

    <ng-container *ngIf='this.konstruktion.Bild5Url'>
      <div class='image'>
        <img
          (click)='this.showImageModalDialog(this.konstruktion.Bezeichnung, this.konstruktion.Bild5Url)'
          [src]='this.konstruktion.Bild5Url'
        >
      </div>
    </ng-container>

  </div>
</modal-frame>
<ng-template #footer>
  <button color='primary' mat-dialog-close mat-flat-button>{{ TRANSLATION_KEY.CLOSE_BUTTON | translate }}</button>
</ng-template>

