<nav [class.active]="navigatorService.isNavigationMenuOpen">
    <ul>
        <ng-container *ngFor='let menuEntryKey of this.configuredMenuEntries'>
            <ng-container *ngTemplateOutlet="menuEntryTemplateMap[menuEntryKey]"></ng-container>
        </ng-container>
        <li class="legal">
            <a (click)='this.showSupport(); $event.preventDefault()' href='#'>
                {{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_SUPPORT | translate }}
            </a>
            <a (click)='this.showPrivacyDialog(); $event.preventDefault()' href='#'>
                {{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_PRIVACY | translate }}
            </a>
            <a [href]="imprintUrl" target='_blank'>
                {{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_IMPRINT | translate }}
            </a>
            <a (click)='cookieConsentService.showConsentBanner = true; $event.preventDefault()' href="#">
                {{ TRANSLATION_KEY.CONFIGURATOR_NAVIGATION_COOKIES | translate }}
            </a>
        </li>
    </ul>
</nav>
<ng-template #menuAddon>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Addon)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Addon)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Addon)"
        >
            <i class="icon-handle"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_ADDON | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuBaseShape>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.BaseShape)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.BaseShape)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.BaseShape)"
        >
            <i class="icon-basic-form"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_BASE_SHAPE | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuBasis>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Basis)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Basis)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Basis)"
        >
            <i class="fa fa-cogs"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_BASIS | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuCatalogue>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Catalogue)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Catalogue)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Catalogue)"
        >
            <i class="icon-catalog"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_CATALOGUE | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuCharacteristics>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Characteristics)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Characteristics)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Characteristics)"
        >
            <i class="fa fa-list-ul"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_CHARACTERISTICS | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuColor>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Color)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Color)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Color)"
        >
            <i class="icon-color"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_COLOR | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuDimension>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Dimension)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Dimension)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Dimension)"
        >
            <i class="fa fa-pencil"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_DIMENSION | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuFinalize>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Finalize)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Finalize)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Finalize)"
        >
            <i class="fa fa-check-square-o"></i>
            <span>({{ memoListService.length }}) {{ TRANSLATION_KEY.MENU_ENTRY_FINALIZE | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuGlass>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Glass)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Glass)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Glass)"
        >
            <i class="icon-glas"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_GLASS | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuHouseFront>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.HouseFront)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.HouseFront)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.HouseFront)"
        >
            <i class="icon-facade"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_HOUSE_FRONT | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuLoad>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Load)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Load)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Load)"
        >
            <i class="fa fa-spinner"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_LOAD | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuModel>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Model)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Model)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Model)"
        >
            <i class="icon-model"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_MODEL | translate }}</span>
        </a>
    </li>
</ng-template>
<ng-template #menuOption>
    <li>
        <a
            (click)="onAction(NavigationMenuEntryKey.Option)"
            [class.active]="isMenuEntryActive(NavigationMenuEntryKey.Option)"
            [class.disabled]="isMenuEntryDisabled(NavigationMenuEntryKey.Option)"
        >
            <i class="icon-options"></i>
            <span>{{ TRANSLATION_KEY.MENU_ENTRY_OPTION | translate }}</span>
        </a>
    </li>
</ng-template>
