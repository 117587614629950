<configurator-base-menu
  [menuHead]='head'
  [menuNavigatorIdentifier]='NavigationMenuEntryKey.Load'
>
  <div class='content-container'>

    <div class='basisrow'>
      <div class='title'>
        {{ TRANSLATION_KEY.LOADNSEARCH_FIND_MODEL_BOX_TITLE |translate }}
      </div>
      <div class='content'>
        <mat-form-field>
          <mat-label>{{ TRANSLATION_KEY.LOADNSEARCH_FIND_MODEL_INPUT_LABEL |translate }}</mat-label>
          <input
            #searchModelInput (keydown.enter)='searchModel(searchModelInput.value)'
            [placeholder]='TRANSLATION_KEY.LOADNSEARCH_FIND_MODEL_INPUT_PLACEHOLDER | translate'
            class='text-left'
            matInput
          />
          <mat-hint align='start'>{{ TRANSLATION_KEY.LOADNSEARCH_FIND_MODEL_INPUT_HINT|translate }}</mat-hint>
          <button
            (click)='searchModel(searchModelInput.value)'
            [attr.aria-label]='TRANSLATION_KEY.LOADNSEARCH_FIND_MODEL_BUTTON_TITLE|translate'
            [title]='TRANSLATION_KEY.LOADNSEARCH_FIND_MODEL_BUTTON_TITLE|translate'
            mat-icon-button
            matSuffix
          >
            <i (click)='searchModel(searchModelInput.value)' class='fa fa-search'></i>
          </button>
        </mat-form-field>
      </div>
    </div>


    <div class='basisrow'>
      <div class='title'>
        {{ TRANSLATION_KEY.LOADNSEARCH_LOAD_ID_BOX_TITLE | translate }}
      </div>
      <div class='content'>
        <mat-form-field (focusin)='$event.stopImmediatePropagation()'>
          <mat-label>{{ TRANSLATION_KEY.LOADNSEARCH_LOAD_ID_INPUT_LABEL|translate }}</mat-label>
          <input
            #loadDoorIdInput (keydown.enter)='loadDoor(loadDoorIdInput.value)'
            [placeholder]='TRANSLATION_KEY.LOADNSEARCH_LOAD_ID_INPUT_PLACEHOLDER| translate'
            class='text-left'
            matInput
          />
          <mat-hint align='start'><strong>{{ TRANSLATION_KEY.LOADNSEARCH_LOAD_ID_INPUT_HINT|translate }}</strong></mat-hint>
          <button
            (click)='loadDoor(loadDoorIdInput.value)'
            [attr.aria-label]='TRANSLATION_KEY.LOADNSEARCH_LOAD_ID_BUTTON_TITLE|translate'
            [title]='TRANSLATION_KEY.LOADNSEARCH_LOAD_ID_BUTTON_TITLE|translate'
            mat-icon-button
            matSuffix
          >
            <i class='fa fa-folder-open'></i>
          </button>
        </mat-form-field>

      </div>
    </div>


    <div class='basisrow'>
      <div class='title'>
        {{ TRANSLATION_KEY.LOADNSEARCH_CURRENT_ID_BOX_TITLE |translate }}
      </div>
      <div class='content'>
        <mat-form-field
          #currentDoorIdFormField
          (cdkCopyToClipboardCopied)='copyDone($event)'
          (click)='copyIdClicked($event)'
          [cdkCopyToClipboard]='doorId'
          class='copy-field'
          subscriptSizing='dynamic'
        >
          <mat-label>{{ TRANSLATION_KEY.LOADNSEARCH_CURRENT_ID_INPUT_LABEL|translate }}</mat-label>
          <input
            #currentDoorIdInput
            (cdkCopyToClipboardCopied)='copyDone($event)'
            (click)='copyIdClicked($event)'
            [cdkCopyToClipboard]='doorId'
            [readonly]='true'
            [value]='doorId'
            class='text-left'
            matInput
            tabindex='-1'
          />
          <button
            [attr.aria-label]='TRANSLATION_KEY.LOADNSEARCH_CURRENT_ID_BUTTON_HINT|translate'
            [title]='TRANSLATION_KEY.LOADNSEARCH_CURRENT_ID_BUTTON_HINT|translate'
            mat-icon-button
            matSuffix
          >
            <i class='fa fa-copy'></i>
          </button>
        </mat-form-field>
      </div>
    </div>


    <strong>
      {{ TRANSLATION_KEY.LOADNSEARCH_NO_ID_HINT|translate }}
    </strong>

  </div>
</configurator-base-menu>

<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.LOADNSEARCH_HEAD_TITLE | translate }}</span>
    <span (click)="closeMenu()" class='close'></span>
  </div>
</ng-template>
