import {Component} from '@angular/core'
import {Tuer} from '../../../classes/model/component/construction/tuer'
import {Seitenteil} from '../../../classes/model/component/construction/seitenteil'
import {Oberlicht} from '../../../classes/model/component/construction/oberlicht'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'
import {ModalService} from '../modal.service'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {MatDialogRef} from '@angular/material/dialog'
import {ConfiguratorMode} from '../../../types'
import {SettingsService} from '../../../classes/service/settings/settings.service'
import {ToastrService} from 'ngx-toastr'
import {TranslateService} from '../../../translate'
import {SaveConfigurationResponse} from '../../../classes/api/saveConfigurationResponse'
import {Parameter} from '../../../classes/service/parameter/parameter'
import {ParameterService} from '../../../classes/service/parameter/parameter.service'
import {Settings} from '../../../classes/service/settings/settings'
import {NGXLogger} from 'ngx-logger'
import {HttpService} from '../../../http.service'
import {ParameterModelConfiguration} from '../../../classes/model/modelConfiguration/parameterModelConfiguration'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ConfiguratedDoorDto} from '../../../classes/api/configuratedDoor.dto'
import {ResponsePriceItem} from '../../../classes/api/price/response-price-item.interface'
import {CharacteristicsData} from '../../../classes/model/dataProvider/characteristics-data/characteristics-data'
import {ResponseInsulation} from '../../../classes/api/insulations/response-insulation.interface'
import {ResponseValidation} from '../../../classes/api/validation/response-validation.interface'
import {ResponseText} from '../../../classes/api/texts/response-text.interface'
import {
  CharacteristicsDataFactory
} from '../../../classes/model/dataProvider/characteristics-data/characteristics-data.factory'

const TRANSLATION_KEY = {
  CALC_MODAL_LOADING_DATA: 'ConfiguratorComponent.CalculationModal.LoadingData',
  CALC_MODAL_TITLE: 'ConfiguratorComponent.CalculationModal.Title',
  CALC_MODAL_TABLE_COLUMN_SUMMARY: 'ConfiguratorComponent.CalculationModal.Table.Column.Summary',
  CALC_MODAL_TABLE_COLUMN_ASSIGNMENT: 'ConfiguratorComponent.CalculationModal.Table.Column.Assignment',
  CALC_MODAL_TABLE_COLUMN_PREFIX: 'ConfiguratorComponent.CalculationModal.Table.Column.Prefix',
  CALC_MODAL_TABLE_COLUMN_TITLE: 'ConfiguratorComponent.CalculationModal.Table.Column.Title',
  CALC_MODAL_TABLE_COLUMN_TEXT: 'ConfiguratorComponent.CalculationModal.Table.Column.Text',
  CALC_MODAL_INPUT_KOMMISSION_TITLE: 'ConfiguratorComponent.CalculationModal.Input.Kommission.Title',
  CALC_MODAL_INPUT_ACTION_TITLE: 'ConfiguratorComponent.CalculationModal.Input.Action.Title',
  CALC_MODAL_PRICES_TITLE: 'ConfiguratorComponent.CalculationModal.Prices.Title',
  CALC_MODAL_PRICES_TABLE_COLUMN_NR: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Nr',
  CALC_MODAL_PRICES_TABLE_COLUMN_DESCRIPTION: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Description',
  CALC_MODAL_PRICES_TABLE_COLUMN_BRUTTO: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Brutto',
  CALC_MODAL_PRICES_TABLE_COLUMN_NETTO: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Netto',
  CALC_MODAL_PRICES_TABLE_COLUMN_DISCOUNT: 'ConfiguratorComponent.CalculationModal.Prices.Table.Column.Discount',
  CALC_MODAL_HINTLIST_HINTS_TITLE: 'ConfiguratorComponent.CalculationModal.HintList.Hints.Title',
  CALC_MODAL_HINTLIST_WARNINGS_TITLE: 'ConfiguratorComponent.CalculationModal.HintList.Warnings.Title',
  CALC_MODAL_HINTLIST_ERRORS_TITLE: 'ConfiguratorComponent.CalculationModal.HintList.Errors.Title',
  CALC_MODAL_VALUES_TITLE: 'ConfiguratorComponent.CalculationModal.Values.Title',
  CALC_MODAL_VALUES_WEIGHT_TITLE: 'ConfiguratorComponent.CalculationModal.Values.Weight.Title',
  CALC_MODAL_VALUES_WEIGHT_PREFIX: 'ConfiguratorComponent.CalculationModal.Values.Weight.Prefix',
  CALC_MODAL_VALUES_WEIGHT_SUFFIX: 'ConfiguratorComponent.CalculationModal.Values.Weight.Suffix',
  CALC_MODAL_VALUES_WEIGHT_UPVALUE_U: 'ConfiguratorComponent.CalculationModal.Values.UPValue.U',
  CALC_MODAL_VALUES_WEIGHT_UPVALUE_P: 'ConfiguratorComponent.CalculationModal.Values.UPValue.P',
  CALC_MODAL_VALUES_WEIGHT_UPVALUE_TITLE: 'ConfiguratorComponent.CalculationModal.Values.UPValue.Title',
  CALC_MODAL_VALUES_WEIGHT_UGVALUE_U: 'ConfiguratorComponent.CalculationModal.Values.UGValue.U',
  CALC_MODAL_VALUES_WEIGHT_UGVALUE_G: 'ConfiguratorComponent.CalculationModal.Values.UGValue.G',
  CALC_MODAL_VALUES_WEIGHT_UGVALUE_TITLE: 'ConfiguratorComponent.CalculationModal.Values.UGValue.Title',
  TOAST_SUCCESS_NOTE_SAVED_TITLE: 'ConfiguratorComponent.Toast.Success.NoteSaved.Title',
  TOAST_SUCCESS_NOTE_SAVED_MESSAGE: 'ConfiguratorComponent.Toast.Success.NoteSaved.Message',
  BUTTON_NOTES: 'ConfiguratorComponent.Button.Notes',
  BUTTON_SAVE: 'ConfiguratorComponent.Button.Save',
  BUTTON_CLOSE: 'ConfiguratorComponent.CalculationModal.Button.Close',
  LOADING_CONFIGURATION: 'ConfiguratorComponent.Loading.Configuration',
} as const

@Component({
  templateUrl: './kalkulation-modal.component.html',
  styleUrls: ['./kalkulation-modal.component.scss']
})
export class KalkulationModalComponent {
  protected readonly ConfiguratorMode = ConfiguratorMode
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  private delayedCalculationTimer: ReturnType<typeof setTimeout>

  constructor(
    public dialogRef: MatDialogRef<KalkulationModalComponent>,
    private modalService: ModalService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private componentSelection: ComponentSelectionService,
    private settingsService: SettingsService,
    private toastrService: ToastrService,
    private _translate: TranslateService,
    private parameterService: ParameterService,
    private logger: NGXLogger,
    private httpService: HttpService,
    private _configuratorModeService: ConfiguratorModeService,
    private characteristicsDataFactory: CharacteristicsDataFactory,
  ) {
    this.configuratedDoor.Prices = null
    this.sendCalculationData()
  }

  collectTextsPromises(elem: ConfiguratedDoorDto): Promise<void | CharacteristicsData>[] {
    return [
      this.fbsGetWeightsData(elem),
      this.fbsGetInsulationsData(elem),
      this.fbsGetValidationData(elem),
      this.fbsGetTextsData(elem)
    ]
  }

  delayedCalculation(): void {
    if (this.delayedCalculationTimer) {
      clearTimeout(this.delayedCalculationTimer)
    }
    this.delayedCalculationTimer = setTimeout((): void => {
      this.sendCalculationData()
    }, 500)
  }

  fbsGetInsulationsData(requestData?: ConfiguratedDoorDto): Promise<void> {
    this.configuratedDoor.Insulation = null
    return new Promise<void>((resolve, reject): void => {
      this.httpService.fbsGetInsulations(requestData || this.generateElement()).subscribe(
        (data: ResponseInsulation): void => {
          this.configuratedDoor.Insulation = data
        },
        (error): void => {
          this.logger.error(error)
          reject()
        },
        (): void => {
          resolve()
        }
      )
    })
  }

  fbsGetTextsData(requestData?: ConfiguratedDoorDto, saveResponse: boolean = true): Promise<CharacteristicsData> {
    return new Promise<ResponseText>((resolve, reject): void => {
      this.httpService.fbsGetTexts(requestData || this.generateElement()).subscribe({
        next: resolve,
        error: (error): void => {
          this.logger.error(error)
          reject()
        }
      })
    }).then((textData): CharacteristicsData => {
      const characteristicsData = this.characteristicsDataFactory.create(textData)
      if (saveResponse) {
        this.configuratedDoor.summary = characteristicsData
      }
      return characteristicsData
    })
  }

  fbsGetValidationData(requestData?: ConfiguratedDoorDto): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.configuratedDoor.Errors = []
      this.configuratedDoor.Warnings = []
      this.configuratedDoor.Infos = []
      this.httpService.fbsGetValidations(requestData || this.generateElement()).subscribe({
        next: (data: ResponseValidation): void => {
          this.configuratedDoor.Errors = data.Errors
          this.configuratedDoor.Warnings = data.Warnings
          this.configuratedDoor.Infos = data.Infos
        },
        error: (error): void => {
          this.logger.trace(error)
          reject()
        },
        complete: (): void => {
          resolve()
        }
      })
    })
  }

  fbsGetWeightsData(requestData?: ConfiguratedDoorDto): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.configuratedDoor.DoorWeight = null
      this.httpService.fbsGetWeights(requestData || this.generateElement()).subscribe(
        (data): void => {
          this.configuratedDoor.DoorWeight = data.Weight
        },
        (error): void => {
          this.logger.error(error)
          reject()
        },
        (): void => {
          resolve()
        }
      )
    })
  }

  generateElement(): ConfiguratedDoorDto {
    return this.configuratorConfigurationModel.configuratedDoorDto
  }

  getCalculationColumns(): string[] {
    const cols = ['nr', 'text']
    if (this.settingsService.settings?.BruttoPreise) {
      cols.push('brutto')
    }
    if (this.settingsService.settings?.NettoPreise) {
      cols.push('rabatt')
      cols.push('netto')
    }
    return cols
  }

  getUGString(): string {
    return this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UGVALUE_U)
        + '<sub>' + this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UGVALUE_G)
        + '</sub> - '
        + this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UGVALUE_TITLE)
  }

  getUPString(): string {
    return this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UPVALUE_U)
        + '<sub>' + this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UPVALUE_P)
        + '</sub> - '
        + this._translate.translate(TRANSLATION_KEY.CALC_MODAL_VALUES_WEIGHT_UPVALUE_TITLE)
  }

  onConfirm(): void {
    if (this.parameter.hasOwnProperty('close')) {
      try {
        if (this.parameter.close === '1') {
          if (window.external != null && 'closeMe' in window.external && window.external.closeMe instanceof Function) {
            window.external.closeMe(0)
          }
        } else if (this.parameter.close === '2') {
          window.close()
        } else if (this.parameter.close === '3') {
          window.parent.postMessage('close', '*')
        } else if (this.parameter.close === '4') {
          const postMessage =
              (window as unknown as Record<string, Record<string, Record<string, unknown>>>
              )?.chrome?.webview?.postMessage
          if (typeof postMessage === 'function') {
            postMessage('close')
          }
        }
      } catch (e) {
        this.logger.error(e)
      }
    }
    this.dialogRef.close(true)
  }

  saveFbs(): void {
    this.configuratedDoor.TransKey = this.parameter.transkey
    this.configuratedDoor.Pos = this.modell.pos

    const sendbuttonI = document.querySelector('#sendFBSConfig i')
    sendbuttonI.classList.remove('fa-floppy-o')
    sendbuttonI.classList.add('fa-spinner')
    sendbuttonI.classList.add('fa-spin')
    this.httpService.postFbsConfig(this.generateElement()).subscribe({
      next: (response: SaveConfigurationResponse): void => {
        if (this.settings.OeffnePdfDatei) {
          const downloadLinkHelper = document.createElement('a')
          downloadLinkHelper.setAttribute('crossOrigin', 'anonymous')
          downloadLinkHelper.setAttribute('target', '_blank')
          downloadLinkHelper.setAttribute('download', '')
          downloadLinkHelper.setAttribute('href', response.Files[0].Url)
          downloadLinkHelper.click()
          downloadLinkHelper.remove()
        }
        sendbuttonI.classList.remove('fa-spinner')
        sendbuttonI.classList.remove('fa-spin')
        sendbuttonI.classList.add('fa-check')
        this.onConfirm()
      },
      error: (error): void => {
        this.logger.error(error)
        sendbuttonI.classList.remove('fa-spinner')
        sendbuttonI.classList.remove('fa-spin')
        sendbuttonI.classList.add('fa-times')
      }
    }
    )
  }

  sendCalculationData(element?: ConfiguratedDoorDto): void {
    const elem: ConfiguratedDoorDto = element || this.generateElement()
    const loadData = this.collectTextsPromises(elem)
    this.configuratedDoor.Prices = null
    void Promise.all(loadData)
      .then((): void => {
        this.httpService.getCalculationFBS(elem).subscribe({
          next: (response): void => {
            const data: ConfiguratedDoor['Prices']['Data'] = response.Data || []
            if (response.Data.length) {
              const filterRetainPreisgruppeZuschlag = (item: ResponsePriceItem): boolean =>
                item.mehrpreisgruppe === 'ZUSCHLAG-PROZENT-NETTO'
              const filterRemovePreisgruppeZuschlag = (item: ResponsePriceItem): boolean => !filterRetainPreisgruppeZuschlag(item)
              if (data.filter(filterRetainPreisgruppeZuschlag).length > 0) {
                let zwischensummeBrutto = 0
                let zwischensummeNetto = 0
                const idx = data.indexOf(data.filter(filterRetainPreisgruppeZuschlag)[0])
                data.filter(filterRemovePreisgruppeZuschlag)
                  .forEach((item): void => {
                    zwischensummeBrutto += item.PreisBrutto
                    zwischensummeNetto += item.PreisNetto
                  })
                data.splice(idx, 0, {
                  summe: false,
                  mehrpreisgruppe: '',
                  Nr: null,
                  PreisBrutto: zwischensummeBrutto,
                  PreisNetto: zwischensummeNetto,
                  Rabatt: null,
                  Sonderrabatt: null,
                  Text: 'Zwischensumme',
                  Zuschlag: 0
                })
              }
              data.push({
                summe: true,
                mehrpreisgruppe: '',
                Nr: null,
                PreisBrutto: response.PreisBrutto,
                PreisNetto: response.PreisNetto,
                Rabatt: null,
                Sonderrabatt: null,
                Text: 'Summe',
                Zuschlag: 0
              })
            } else if (response.Status && response.Status === '500') {
              data.push({
                summe: false,
                mehrpreisgruppe: '',
                Nr: null,
                PreisBrutto: 0,
                PreisNetto: 0,
                Rabatt: null,
                Sonderrabatt: null,
                Text: 'Fehler bei der Preisberechnung.',
                Zuschlag: 0
              })
            }
            this.configuratedDoor.Prices = response
          },
          error: (error): void => {
            this.logger.error(error)
          }
        }
        )
      })
  }

  showNotesDialog(): void {
    this.modalService.showNotesModal(this.configuratedDoor.notes || '')
      .afterClosed()
      .subscribe((result: string): void => {
        if (!result) {
          return
        }
        this.configuratedDoor.notes = result
        this.toastrService.success(
          this._translate.translate(TRANSLATION_KEY.TOAST_SUCCESS_NOTE_SAVED_MESSAGE),
          this._translate.translate(TRANSLATION_KEY.TOAST_SUCCESS_NOTE_SAVED_TITLE)
        )
      })
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }

  get modell(): ParameterModelConfiguration {
    return this.parameterService.model || {} as ParameterModelConfiguration
  }

  get parameter(): Parameter {
    return this.parameterService.parameter
  }

  get selectedComponent(): Tuer | Seitenteil | Oberlicht {
    return this.componentSelection.selectedComponent
  }

  get settings(): Settings {
    return this.settingsService.settings
  }
}
