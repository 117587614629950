import {
  INGXLoggerConfig, INGXLoggerLogPosition,
  INGXLoggerMetadata,
  NGXLoggerMapperService
} from 'ngx-logger'
import {Injectable} from '@angular/core'
import {map, Observable} from 'rxjs'

@Injectable()
export default class LoggerWebpackMapperService extends NGXLoggerMapperService {
  public getLogPosition(config: INGXLoggerConfig, metadata: INGXLoggerMetadata): Observable<INGXLoggerLogPosition> {
    config.proxiedSteps = (config.proxiedSteps || 0) + 1
    return super.getLogPosition(config, metadata).pipe(map((logPosition): INGXLoggerLogPosition => {
      if (logPosition?.fileName?.startsWith('./src')) {
        logPosition.fileName = `webpack://${logPosition.fileName.slice(1)}`
      }
      return logPosition
    }))
  }
}
