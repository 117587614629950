import {ConfigurationComponent} from './configurationComponent'
import {Din, InsideOutsideArray} from '../types'
import {ConfiguratedBlendrahmen} from '../class'
import {ModelConfiguration} from './api/model/configuration/model-configuration.interface'

export class Konfiguration implements ModelConfiguration {
  Blendrahmen: InsideOutsideArray<ConfiguratedBlendrahmen>
  Breite: number
  Components: ConfigurationComponent[] = []
  Din: Din
  Hoehe: number
  ProfilId: number
  Staerke: number
  Typ: string
  Werksmontage: boolean

  constructor(data?: ModelConfiguration) {
    this.Blendrahmen = {
      Inside: data?.Blendrahmen?.Inside?.map(
        ({Beschichtung, Id, HasBeschichtung}): ConfiguratedBlendrahmen => ({
          Beschichtung,
          Id,
          HasBeschichtung,
          Typ: 'blendrahmen'
        })
      ) ?? [],
      Outside: data?.Blendrahmen?.Outside?.map(
        ({Beschichtung, Id, HasBeschichtung}): ConfiguratedBlendrahmen => ({
          Beschichtung,
          Id,
          HasBeschichtung,
          Typ: 'blendrahmen'
        })
      ) ?? [],
    }
    this.Breite = data?.Breite
    this.Components = data?.Components?.map((c): ConfigurationComponent => new ConfigurationComponent(c))
    this.Din = data?.Din
    this.Hoehe = data?.Hoehe
    this.ProfilId = data?.ProfilId ?? 0
    this.Staerke = data?.Staerke
    this.Typ = data?.Typ
    this.Werksmontage = data?.Werksmontage
  }
}
