import {Component} from '@angular/core'
import {Tuer} from '../../../classes/model/component/construction/tuer'
import {Seitenteil} from '../../../classes/model/component/construction/seitenteil'
import {Oberlicht} from '../../../classes/model/component/construction/oberlicht'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'
import {ModalService} from '../modal.service'
import {Konstruktion} from '../../../class'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {Material} from '../../../classes/model/material'
import {MatRadioChange} from '@angular/material/radio'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {SimpleConstruction} from '../../../classes/model/component/other/construction'
import {MatDialogRef} from '@angular/material/dialog'

const TRANSLATION_KEY = {
  TITLE: 'ConstructionModal.Title',
  SAVE: 'ConstructionModal.Save',
  ADHESIVE_SYSTEM_LABEL: 'ConstructionModal.AdhesiveSystem.Label'
} as const

@Component({
  templateUrl: './konstruktionsauswahl-modal.component.html',
  styleUrls: ['./konstruktionsauswahl-modal.component.scss']
})
export class KonstruktionsauswahlModalComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  preselected: Konstruktion

  constructor(
    public dialogRef: MatDialogRef<KonstruktionsauswahlModalComponent>,
    private modalService: ModalService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private componentSelection: ComponentSelectionService
  ) {
    if (this.element.fbsKonstruktion) {
      this.preselected = this.element.fbsKonstruktion
    }
  }

  fbsSetKonstruktion(): void {
    this.element.fbsKonstruktion = this.preselected
    this.element.Klebeset = this.element.fbsKonstruktion.KlebesystemId === 2
    this.configuratorConfigurationModel.constructionVariationChanged.emit()
  }

  filterKonstruktionen(elem: Tuer | Seitenteil | Oberlicht): Konstruktion[] {
    return elem.model.Konstruktionen.filter((k): boolean => {
      // werte des Modells und der Konstruktion muessen identisch sein, sonst passt die Konstruktion nicht
      const materialGleich: boolean = (elem.material === Material.Alu && k.IsAluMoeglich)
          || (elem.material === Material.Kunststoff && k.IsKunststoffMoeglich)
      // 0 = Einsatz, 1 = Aufsatz einseitig, 2 = Aufsatz beidseig
      // Einsatz nicht pruefen, da fuer den Fall keine Konstruktionen vorhanden sind
      const aufsatzGleich: boolean = (
        this.componentSelection.selectedComponent.konstruktion === SimpleConstruction.AufsatzEinseitig
          && k.IsAufsatzEinseitigMoeglich
      ) || (
        this.componentSelection.selectedComponent.konstruktion === SimpleConstruction.AufsatzBeidseitig
          && k.IsAufsatzBeidseitigMoeglich
      )
      const klebesystemGleich: boolean = elem.Klebesystem?.Id ? elem.Klebesystem.Id === k.KlebesystemId : false
      const oeffnungsartGleich: boolean = (elem.oeffnungsart === 'aussen' && k.IsAussenOeffnendMoeglich)
          || (elem.oeffnungsart === 'innen' && k.IsInnenOeffnendMoeglich)
      return materialGleich && aufsatzGleich && klebesystemGleich && oeffnungsartGleich
    })
  }

  klebesystemRadioChange($event: MatRadioChange): void {
    this.element.Klebesystem = this.element.model.Klebesysteme.find((k): boolean => k.Id === $event.value)
  }

  konstruktionSelected(konstruktion: Konstruktion): boolean {
    return this.preselected?.Id === konstruktion.Id
  }

  onConfirm(): void {
    this.fbsSetKonstruktion()
    void this.configuratorConfigurationModel.updateDeckschichtStaerke().then((): void => {
      this.configuratorConfigurationModel.zMassChanged.emit()
    })
    this.dialogRef.close()
  }

  preselectKonstruktionsvariante(konstruktion: Konstruktion): void {
    if (this.preselected?.Id === konstruktion.Id) {
      this.preselected = null
    } else {
      this.preselected = konstruktion
    }
  }

  showKonstruktionModal(konstruktion: Konstruktion): void {
    this.modalService.showKonstruktionModal(konstruktion)
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }

  get element(): Tuer | Seitenteil | Oberlicht {
    return this.componentSelection.selectedComponent
  }
}
