<modal-frame
  [footer]='footer'
  [modalCloseButton]='this.currentAddon.Massblatt'
  hostId='massblattDiv'
  title='{{TRANSLATION_KEY.TITLE | translate}}'
>


  <mat-form-field class='massblattSelect'>
    <mat-label>{{ TRANSLATION_KEY.SELECT_MASSBLATT_LABEL | translate }}</mat-label>
    <mat-select
      (selectionChange)='this.updateMassblaetterFromApi()'
      [(ngModel)]='this.currentAddon.Massblatt'
      [compareWith]='compareMassblaetter'
    >
      <mat-option *ngFor='let massblatt of massblaetter' [value]='massblatt'>
        {{ massblatt.Bezeichnung | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>


  <mat-form-field *ngIf='this.currentAddon.Massblatt?.Scripts?.length > 1' class='scriptSelect'>
    <mat-label>{{ TRANSLATION_KEY.SELECT_SCRIPT_LABEL | translate }}</mat-label>
    <mat-select
      (change)='this.updateMassblaetterFromApi()'

      [(ngModel)]='this.currentAddon.Massblatt.ScriptId'
    >

      <mat-option *ngFor='let script of this.currentAddon.Massblatt?.Scripts' [value]='script.Id'>
        {{ script.Bezeichnung | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class='content'>
    <div *ngIf='this.currentAddon.Massblatt' class='inputs'>
      <ng-container *ngFor='let letter of this.currentAddon.Massblatt.parameters'>
        <div class='row'>

          <mat-form-field class='example-full-width'>
            <mat-label>{{ letter }}</mat-label>
            <input
              (change)='this.updateMassblaetterFromApi($event)' [(ngModel)]='this.currentAddon.Massblatt.Values[letter]'
              [disabled]='!this.currentAddon.Massblatt.isActiveValue(letter)'
              matInput
            >
          </mat-form-field>
        </div>
      </ng-container>
    </div>

    <div class='images'>
      <ng-container *ngIf='this.currentAddon.Massblatt.ImageUrl'>
        <div class='image'>
          <img
            (click)='this.showImageModalDialog(
                                         this.currentAddon.Massblatt.Beschreibung,
                                         this.currentAddon.Massblatt.ImageUrl
                                     )'
            [src]='this.currentAddon.Massblatt.ImageUrl'
          />
        </div>
      </ng-container>

      <ng-container *ngIf='this.currentAddon.Massblatt.ImageUrl2'>
        <div class='image'>
          <img
            (click)='this.showImageModalDialog(
                                         this.currentAddon.Massblatt.Beschreibung,
                                         this.currentAddon.Massblatt.ImageUrl2
                                     )'
            [src]='this.currentAddon.Massblatt.ImageUrl2'
          />
        </div>
      </ng-container>

      <ng-container *ngIf='this.currentAddon.Massblatt.ImageUrl3'>
        <div class='image'>
          <img
            (click)='this.showImageModalDialog(
                                         this.currentAddon.Massblatt.Beschreibung,
                                         this.currentAddon.Massblatt.ImageUrl3
                                     )'
            [src]='this.currentAddon.Massblatt.ImageUrl3'
          />
        </div>
      </ng-container>

    </div>
  </div>
</modal-frame>
<ng-template #footer>
  <button
    [mat-dialog-close]='this.currentAddon.Massblatt' color='primary' mat-flat-button
  >{{ TRANSLATION_KEY.SAVE_BUTTON_LABEL | translate }}
  </button>
</ng-template>
