<modal-frame
  [footer]='footer'
  hostId='mehrpreisDiv'
  modalCloseButton
  title='{{TRANSLATION_KEY.TITLE | translate}}'
>

  <div class='klebesystembox'>
    <mat-label>{{ TRANSLATION_KEY.ADHESIVE_SYSTEM_LABEL| translate }}</mat-label>
    <mat-radio-group
      (change)='this.klebesystemRadioChange($event)'
    >
      <ng-container *ngIf='this.element.model'>
        <mat-radio-button
          *ngFor='let k of this.element.model.Klebesysteme' [checked]='this.element.Klebesystem.Id === k.Id'
          [value]='k.Id' class='example-radio-button'
        >
          {{ k.Bezeichnung | translate }}
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
  <div class='content konstruktion'>

    <ng-container *ngFor='let item of this.filterKonstruktionen(this.element)'>

      <div
        (click)='this.preselectKonstruktionsvariante(item)'
        [class.active]='this.konstruktionSelected(item)'
        class='item'
      >
        <div class='image-wrapper'>
          <div (click)='this.showKonstruktionModal(item )' class='massblatticon'>
            <i class='fa fa-search-plus fa-3x fa-fw'></i>
          </div>

          <img src='{{ item.Bild1Url }}'>
        </div>
        <p>
          <span class='option-text'>{{ item.Bezeichnung | translate }}</span>
        </p>
      </div>

    </ng-container>

  </div>
</modal-frame>
<ng-template #footer>
  <button (click)='this.onConfirm()' color='primary' mat-flat-button>{{ TRANSLATION_KEY.SAVE | translate }}</button>
</ng-template>

