import {Rahmen, RahmenConstructorParameter} from './rahmen'
import {ColorBase} from '../../color/colorBase'
import {SideType} from '../../../../types'
import {Material} from '../../material'

export class Fluegelrahmen extends Rahmen {
  constructor(data?: RahmenConstructorParameter<Fluegelrahmen>) {
    super(data)
    this.Typ = 'fluegelrahmen'
  }

  isColorSupported(color: ColorBase, side: SideType, material: Material): boolean {
    return color?.IsFluegelrahmenMoeglich && super.isColorSupported(color, side, material)
  }
}
