import {Component, TemplateRef, ViewChild} from '@angular/core'
import {ConfiguratorMode} from '../../../types'
import {NavigationMenuEntryKey, TRANSLATION_KEY} from '../../../classes/service/navigation/navigation-menu-entry'
import {SettingsService} from '../../../classes/service/settings/settings.service'
import MemoListService from '../../../classes/service/memo-list/memo-list.service'
import {ModalService} from '../../../configurator/modal/modal.service'
import {CookieConsentService} from '../../../cookie-consent'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  protected readonly ConfiguratorMode = ConfiguratorMode
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly TRANSLATION_KEY = {
    ...TRANSLATION_KEY,

    CONFIGURATOR_NAVIGATION_SUPPORT: 'NavigationComponent.Support',
    CONFIGURATOR_NAVIGATION_PRIVACY: 'NavigationComponent.Privacy',
    CONFIGURATOR_NAVIGATION_IMPRINT: 'NavigationComponent.Imprint',
    CONFIGURATOR_NAVIGATION_COOKIES: 'NavigationComponent.Cookies',
  } as const
  protected menuEntryTemplateMap: Partial<Record<NavigationMenuEntryKey, TemplateRef<unknown>>> = {}

  constructor(
    protected readonly cookieConsentService: CookieConsentService,
    protected readonly memoListService: MemoListService,
    private readonly modalService: ModalService,
    protected readonly navigatorService: NavigatorService,
    protected readonly settingsService: SettingsService
  ) {
  }

  protected isMenuEntryActive(navigationEntry: NavigationMenuEntryKey): boolean {
    return this.navigatorService.currentActiveMenu === navigationEntry
  }

  protected isMenuEntryDisabled(navigationEntry: NavigationMenuEntryKey): boolean {
    return !this.navigatorService.canActivateMenu(navigationEntry)
  }

  protected onAction(navigationEntry: NavigationMenuEntryKey): void {
    this.navigatorService.activateMenu(navigationEntry)
  }

  protected showPrivacyDialog(): void {
    this.modalService.showPrivacyModal(this.settingsService.settings.DatenschutzUrl || '')
  }

  protected showSupport(): void {
    this.modalService.showSupportModal()
  }

  get configuredMenuEntries(): NavigationMenuEntryKey[] {
    return this.navigatorService.configuredMenuEntries
  }

  get imprintUrl(): string {
    return this.settingsService.settings.ImpressumUrl ?? 'https://www.rodenberg.ag/de/unternehmen/impressum'
  }

  @ViewChild('menuAddon', {read: TemplateRef, static: true}) set menuAddon(menuAddon: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Addon] = menuAddon
  }

  @ViewChild('menuBaseShape', {read: TemplateRef, static: true}) set menuBaseShape(menuBaseShape: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.BaseShape] = menuBaseShape
  }

  @ViewChild('menuBasis', {read: TemplateRef, static: true}) set menuBasis(menuBasis: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Basis] = menuBasis
  }

  @ViewChild('menuCatalogue', {read: TemplateRef, static: true}) set menuCatalogue(menuCatalogue: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Catalogue] = menuCatalogue
  }

  @ViewChild('menuCharacteristics', {read: TemplateRef, static: true}) set menuCharacteristics(menuCharacteristics: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Characteristics] = menuCharacteristics
  }

  @ViewChild('menuColor', {read: TemplateRef, static: true}) set menuColor(menuColor: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Color] = menuColor
  }

  @ViewChild('menuDimension', {read: TemplateRef, static: true}) set menuDimension(menuDimension: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Dimension] = menuDimension
  }

  @ViewChild('menuFinalize', {read: TemplateRef, static: true}) set menuFinalize(menuFinalize: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Finalize] = menuFinalize
  }

  @ViewChild('menuGlass', {read: TemplateRef, static: true}) set menuGlass(menuGlass: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Glass] = menuGlass
  }

  @ViewChild('menuHouseFront', {read: TemplateRef, static: true}) set menuHouseFront(menuHouseFront: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.HouseFront] = menuHouseFront
  }

  @ViewChild('menuLoad', {read: TemplateRef, static: true}) set menuLoad(menuLoad: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Load] = menuLoad
  }

  @ViewChild('menuModel', {read: TemplateRef, static: true}) set menuModel(menuModel: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Model] = menuModel
  }

  @ViewChild('menuOption', {read: TemplateRef, static: true}) set menuOption(menuOption: TemplateRef<unknown>) {
    this.menuEntryTemplateMap[NavigationMenuEntryKey.Option] = menuOption
  }
}
