import {Injectable, OnDestroy} from '@angular/core'
import {Subscription} from 'rxjs'
import {EventBusService} from './eventBus'

@Injectable()
export class EventBusBase implements OnDestroy {
  protected subscriptions: Subscription[] = []
  protected eventBus: EventBusService

  constructor(eventBus: EventBusService) {
    this.eventBus = eventBus
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub: Subscription): void => sub.unsubscribe())
  }

  toggleEvent<T>(name: string, data?: T): void {
    this.eventBus.publish<T>(name, data)
  }

  protected addSubscription(subscription: Subscription): void {
    this.subscriptions.push(subscription)
  }
}
