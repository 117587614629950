import {Hausfront} from '../hausfront/hausfront'
import {ResponseGrundform} from '../../../api/grundformen/response-grundform.interface'
import {ResponseHausfront} from '../../../api/grundformen/response-hausfront.interface'
import {DinUtil} from '../../../util/dinUtil'
import {Din} from '../../../../types'

export type GrundformTupel = {
  Current: Grundform
  Previous: Grundform
}

export class Grundform implements ResponseGrundform {
  // FIXME: Name und Bezeichnung sind gleich?
  Bezeichnung: string
  ChangeDinPossible: boolean
  DinComponenten: string
  DinElement: string
  Hausfronten: Hausfront[]
  Id: number
  Key: string
  KeyIntern: string
  Name: string
  Url: string
  loaded: boolean

  constructor(data?: ResponseGrundform) {
    this.Bezeichnung = data?.Bezeichnung
    this.ChangeDinPossible = data?.ChangeDinPossible
    this.DinComponenten = data?.DinComponenten
    this.DinElement = data?.DinElement
    this.Hausfronten = []
    data?.Hausfronten?.forEach((item: ResponseHausfront): void => {
      this.Hausfronten.push(new Hausfront(item))
    })
    this.Id = data?.Id
    this.Key = data?.Key
    this.KeyIntern = data?.KeyIntern
    this.loaded = false
    this.Name = data?.Name
    this.Url = data?.Url
  }

  dinForNthComponent(i: number): Din {
    return DinUtil.fromString(this.DinComponenten?.split('')[i])
  }
}

