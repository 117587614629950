import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core'
import {TranslateService} from '../translate'
import {ConfiguratorMode} from '../types'
import {SettingsService} from '../classes/service/settings/settings.service'
import {Settings} from '../classes/service/settings/settings'
import {ConfiguratorModeService} from '../classes/service/configuratorMode.service'

const TRANSLATION_KEY = {
  SELECTED_LABEL: 'CustomerStyle.Selected.Label'
} as const

@Directive({
  selector: '[appCustomerStyle]'
})
export class CustomerStyleDirective implements OnInit {
  private color: string | null
  private nativeElement: Node

  constructor(
    private translateService: TranslateService,
    private renderer: Renderer2,
    private element: ElementRef,
    private configuratorModeService: ConfiguratorModeService,
    settingsService: SettingsService
  ) {
    this.color = null
    settingsService.settingsChanged.subscribe((settings) => void this.updateSettings(settings))
    this.updateSettings(settingsService.settings)
  }

  ngOnInit(): void {
    this.render()
  }

  render(): void {
    const element: unknown = this.element.nativeElement
    if (element instanceof Node) {
      this.nativeElement = element
    }
    if (!this.nativeElement) {
      return
    }
    if (this.nativeElement.childNodes.length > 0) {
      this.nativeElement.removeChild(this.nativeElement.childNodes[0])
    }
    const inputElement = this.renderer.createElement('style') as unknown
    if (!(inputElement instanceof Element)) {
      return
    }
    let style = '' +
      '#mehrpreisDiv .content .item.current:before,' +
      '#glassmodalDiv .content .item.current:before {' +
      '   width: 100%; background-color:white;' +
      '   color: ' + this.color + ';' +
      '   content: \'' + this.translateService.translate(TRANSLATION_KEY.SELECTED_LABEL) + '\';' +
      '} '
    if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
      style += 'article.glaeser img { border: 1px solid #373636; }'
      style += 'div.contentWrapper {  height: calc(100vh - 0px); }'
      style += '.subnav .subNavigationContainer {  min-height: calc(100vh - 0px); }'
    }
    if (this.color !== null && this.color.trim() !== '#') {
      style += 'html { --customer-color: ' + this.color + '; }'
    }
    inputElement.innerHTML = style
    this.renderer.appendChild(this.nativeElement, inputElement)
  }

  private updateSettings(settings: Settings): void {
    this.color = settings.Farbe ? `#${settings.Farbe}` : this.color
    this.render()
  }
}
