import {Component, EventEmitter, Input, Output} from '@angular/core'
import {CookieConsentService} from './cookie-consent.service'
import {CookieConsentData} from './cookie-consent-data.type'

const TRANSLATION_KEY = {
  COOKIE_CONSENT_MODAL_TITLE: 'CookieConsent.Modal.Title',
  COOKIE_CONSENT_MODAL_TEXT: 'CookieConsent.Modal.Text',
  COOKIE_CONSENT_MODAL_NECESSARY_COOKIES: 'CookieConsent.Modal.Necessary',
  COOKIE_CONSENT_MODAL_COOKIES_ON: 'CookieConsent.Modal.Cookies.On',
  COOKIE_CONSENT_MODAL_COOKIES_OFF: 'CookieConsent.Modal.Cookies.Off',
  COOKIE_CONSENT_MODAL_TRACKING: 'CookieConsent.Modal.Tracking',
  COOKIE_CONSENT_MODAL_CLOSE_AND_SAVE: 'CookieConsent.Modal.CloseAndSave',
  COOKIE_CONSENT_MODAL_PRIVACY: 'CookieConsent.Modal.Privacy',
  COOKIE_CONSENT_MODAL_IMPRINT: 'CookieConsent.Modal.Imprint'
} as const

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['cookie-consent.component.scss']
})
export class CookieConsentComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected consentData: CookieConsentData
  @Input() imprintUrl: string
  @Output() saveAndClose = new EventEmitter<string>()
  @Input() show: boolean

  constructor(
    private cookieConsentService: CookieConsentService
  ) {
    this.show = false
    this.consentData = {
      necessary: true,
      tracking: true
    }
  }

  close(): void {
    void Promise.all([
      this.cookieConsentService.addConsent('necessary'),
      this.cookieConsentService[this.consentData.tracking ? 'addConsent' : 'removeConsent']('tracking')
    ]).finally(() => void this.saveAndClose.emit())
  }
}
