import {ConfiguratorConfigurationModel} from '../../configuratorConfigurationModel'
import {TranslateService} from '../../../../translate'
import {Injectable} from '@angular/core'
import {ResponseText} from '../../../api/texts/response-text.interface'
import {CharacteristicsData} from './characteristics-data'
import {Tuer} from '../../component/construction/tuer'
import {Seitenteil} from '../../component/construction/seitenteil'
import {Oberlicht} from '../../component/construction/oberlicht'
import {ConstructionComponent} from '../../component/construction/constructionComponent'

const TRANSLATION_KEY = {
  DOOR: 'CharacteristicsFactory.Door',
  SIDEPANEL: 'CharacteristicsFactory.Sidepanel',
  FANLIGHT: 'CharacteristicsFactory.Fanlight',
  COMPONENT: 'CharacteristicsFactory.Component',
  DATA_GENERAL_LABEL: 'CharacteristicsFactory.General.Label'
} as const

@Injectable()
export class CharacteristicsDataFactory {
  constructor(
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private translateService: TranslateService
  ) {
  }

  create(responseText: ResponseText): CharacteristicsData {
    return new CharacteristicsData(
      responseText,
      this.translateService.translate(TRANSLATION_KEY.DATA_GENERAL_LABEL),
      this.configuratorConfigurationModel.configuratedDoor.Components.map((component: ConstructionComponent): string =>
        `${this.translateService.translate(
          component instanceof Tuer
            ? TRANSLATION_KEY.DOOR
            : component instanceof Seitenteil
              ? TRANSLATION_KEY.SIDEPANEL
              : component instanceof Oberlicht
                ? TRANSLATION_KEY.FANLIGHT
                : TRANSLATION_KEY.COMPONENT
        )} (${component.model.Bezeichnung})`
      )
    )
  }
}
