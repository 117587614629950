type SelectionHandler = (index: number) => void
export class FakeComponentSelectionService {
  componentSelectedHandler: SelectionHandler[]
  constructor() {
    this.componentSelectedHandler = []
  }

  registerSelectionHandler(handler: SelectionHandler): void {
    this.componentSelectedHandler.push(handler)
  }

  get selectedComponent(): undefined {
    return undefined
  }

  set selectedComponent(index: number) {
    this.componentSelectedHandler.forEach(handler => void handler(index))
  }
}
