import {ResponseText} from '../../../api/texts/response-text.interface'
import {ResponseTextComponent} from '../../../api/texts/response-text-component.interface'
import {ResponseTextItem} from '../../../api/texts/response-text-item.interface'
import {CharacteristicsDataItem} from './characteristics-data-item'

export class CharacteristicsData {
  private readonly data: Map<string, ResponseTextItem[]>
  readonly dataArray: readonly CharacteristicsDataItem[]

  constructor()
  constructor(textData: ResponseText, commonCategoryLabel: string | undefined, componentLabels: string[] | undefined)
  constructor(
    private textData?: ResponseText,
    readonly commonCategoryLabel?: string,
    readonly componentLabels?: readonly string[]
  ) {
    this.data = new Map<string, ResponseTextItem[]>()
    const dataArray = []

    if ((textData?.Texte?.length ?? 0) > 0) {
      this.data.set(commonCategoryLabel, textData.Texte)
      dataArray.push(
        ...textData.Texte.map((commonTextData: ResponseTextItem, textIndex: number): CharacteristicsDataItem =>
          CharacteristicsDataItem.fromResponse(textIndex === 0 ? commonCategoryLabel : '', commonTextData)
        ))
    }
    textData?.Components?.forEach((componentTextData: ResponseTextComponent, componentIndex: number): void => {
      const componentLabel = componentLabels?.[componentIndex] ?? 'Component ' + componentIndex
      this.data.set(componentLabel, componentTextData.Texte)
      dataArray.push(
        ...componentTextData.Texte.map(
          (componentTextItem: ResponseTextItem, textIndex: number): CharacteristicsDataItem =>
            CharacteristicsDataItem.fromResponse(textIndex === 0 ? componentLabel : '', componentTextItem)
        ))
    })
    this.dataArray = dataArray
  }

  getCategory(category: string | number): ResponseTextItem[] | undefined {
    if (typeof category === 'number') {
      category = Array.from(this.data.keys())[category]
    }
    return this.data.get(category)
  }

  getCategoryCount(): number {
    return this.data.size
  }

  getCommonCharacteristicsData(): ResponseTextItem[] {
    return this.textData?.Texte ?? []
  }

  getComponentCharacteristicsData(componentIndex: number): ResponseTextItem[] {
    return this.textData?.Components[componentIndex]?.Texte ?? []
  }
}
