import {Component} from '@angular/core'
import {Location} from '@angular/common'
import {SettingsService} from '../../../classes/service/settings/settings.service'
import {Observable} from 'rxjs'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {TRANSLATION_KEY_MAP} from '../../../classes/service/navigation/navigation-menu-entry'

const TRANSLATION_KEY = {
  MENU_TITLE: 'HeaderComponent.NavigationMenu',
  CONFIGURATOR_HEADER_TITLE: 'HeaderComponent.Title',
} as const

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected readonly TRANSLATION_KEY_MAP = TRANSLATION_KEY_MAP
  protected readonly logoLinkUrl: string

  constructor(
    protected readonly navigatorService: NavigatorService,
    private readonly settingsService: SettingsService,
    location: Location
  ) {
    this.logoLinkUrl = window.location.href.substring(0, window.location.href.indexOf(location.path())) || window.location.href
  }

  protected onAction(): void {
    this.navigatorService.toggleNavigationMenuOpen()
  }

  get logoUrl$(): Observable<string> {
    return this.settingsService.logoUrl$
  }
}
