import {Component, HostListener, Inject, ViewChild} from '@angular/core'
import {ConfiguratorMode, GlasaufbauPositionShort, GlasPositionLong, MotivPositionLong} from '../../../types'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {StringUtil} from '../../../classes/util/stringUtil'
import {ModalService} from '../../modal/modal.service'
import {GlassModalMode} from '../../modal/glass-modal/glass-modal.component'
import {ConstructionComponent} from '../../../classes/model/component/construction/constructionComponent'
import {ObjectUtil} from '../../../classes/util/objectUtil'
import {PackageType, Paket} from '../../../classes/model/component/other/paket'
import {GlassService} from '../../../classes/model/dataProvider/glass/glass.service'
import {MatRadioChange} from '@angular/material/radio'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {FbsGlas, Motiv} from '../../../class'
import {GLASS_PROVIDER_TOKEN, GlassProvider} from '../../../classes/model/dataProvider/glass/glass.type'
import ArrayUtil from '../../../classes/util/arrayUtil'
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling'
import {GlassUpdateHelper} from '../../../classes/model/dataProvider/glass/glass-update-helper'
import {TranslateService} from 'src/app/translate'
import {
  SpacerThicknessExceededError,
  SpacerThicknessUndercutError
} from '../../../classes/model/component/glassaufbau/spacer-thickness.error'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {EventBusBase} from '../../../classes/service/eventBus/eventBusBase'
import {EventBusService} from '../../../classes/service/eventBus/eventBus'
import {BusEvent} from '../../../classes/service/eventBus/eventTypes'

const TRANSLATION_KEY = {
  ERROR_SPACER_UNDERCUT_MESSAGE_PREFIX: 'GlassesMenuComponent.Error.SpacerThicknessUndercut.Message.Prefix',
  ERROR_SPACER_UNDERCUT_MESSAGE_SUFFIX: 'GlassesMenuComponent.Error.SpacerThicknessUndercut.Message.Suffix',
  ERROR_SPACER_EXCEEDED_MESSAGE_PREFIX: 'GlassesMenuComponent.Error.SpacerThicknessExceeded.Message.Prefix',
  ERROR_SPACER_EXCEEDED_MESSAGE_SUFFIX: 'GlassesMenuComponent.Error.SpacerThicknessExceeded.Message.Suffix',
  ERROR_SPACER_UNKNOWN_MESSAGE: 'GlassesMenuComponent.Error.SpacerThicknessUnknown.Message',
  ERROR_MOTIVE_GLASS_INCOMPATIBLE: 'GlassesMenuComponent.Error.MotivAndGlassIncompatible.Message',
  GLASSES_MENU_BUTTON_GLASSES: 'GlassesMenuComponent.Button.Glasses',
  GLASSES_MENU_BUTTON_MOTIVES: 'GlassesMenuComponent.Button.Motives',
  GLASSES_MENU_HINT_NO_GLASSES: 'GlassesMenuComponent.Hint.NoGlasses',
  GLASSES_MENU_HINT_NO_MOTIVES: 'GlassesMenuComponent.Hint.NoMotives',
  GLASSES_MENU_TITLE: 'GlassesMenuComponent.Title',
  GLASSES_MENU_DROPDOWN_PACKAGE_TITLE: 'GlassesMenuComponent.Dropdown.Package.Title',
  GLASSES_MENU_DROPDOWN_BARS_TITLE: 'GlassesMenuComponent.Dropdown.Bars.Title',
  GLASSES_MENU_DROPDOWN_BARS_CHOICE_LABEL: 'GlassesMenuComponent.Dropdown.Bars.Choice.Label',
  GLASSES_MENU_BUTTON_CHOOSE_BARS_LABEL: 'GlassesMenuComponent.Button.ChooseBars.Label',
  GLASSES_MENU_DROPDOWN_INSULATION_TITLE: 'GlassesMenuComponent.Dropdown.Insulation.Title',
  GLASSES_MENU_DROPDOWN_INSULATION_BLANK_OPTION: 'GlassesMenuComponent.Dropdown.Insulation.BlankOption',
  GLASSES_MENU_GLASSES_NUM_TITLE: 'GlassesMenuComponent.RadioButtons.GlassesNum.Title',
  GLASSES_MENU_GLASSES_NUM_TWO: 'GlassesMenuComponent.RadioButtons.GlassesNum.Two.Label',
  GLASSES_MENU_GLASSES_NUM_THREE: 'GlassesMenuComponent.RadioButtons.GlassesNum.Three.Label',
  GLASSES_MENU_GLASSES_NUM_FOUR: 'GlassesMenuComponent.RadioButtons.GlassesNum.Four.Label',
  HINT_TITLE: 'GlassesMenuComponent.Hint.Title',
  HINT_2_GLASSES_MOTIVE_POSITION: 'GlassesMenuComponent.Hint.2Glasses',
  HINT_3_GLASSES_MOTIVE_POSITION: 'GlassesMenuComponent.Hint.3Glasses',
  HINT_4_GLASSES_MOTIVE_POSITION: 'GlassesMenuComponent.Hint.4Glasses',
  GLASPOSITION_OUTSIDE: 'GlassesMenuComponent.Glasposition.Outside',
  GLASPOSITION_MID1: 'GlassesMenuComponent.Glasposition.Mid1',
  GLASPOSITION_MID2: 'GlassesMenuComponent.Glasposition.Mid2',
  GLASPOSITION_INSIDE: 'GlassesMenuComponent.Glasposition.Inside',
  MOTIVPOSITION_OUTSIDE: 'GlassesMenuComponent.Motivposition.Outside',
  MOTIVPOSITION_MID1: 'GlassesMenuComponent.Motivposition.Mid1',
  MOTIVPOSITION_MID2: 'GlassesMenuComponent.Motivposition.Mid2',
  MOTIVPOSITION_INSIDE: 'GlassesMenuComponent.Motivposition.Inside',
  INPUT_CHOICE_LABEL: 'GlassesMenuComponent.Input.Choice.Label',
  INPUT_CHOICE_PLACEHOLDER: 'GlassesMenuComponent.Input.Choice.Placeholder',
  GLASS: 'GlassesMenuComponent.Glass',
  MOTIVE: 'GlassesMenuComponent.Motive',
  CLEAR: 'GlassesMenuComponent.Button.Clear',
  CHOOSE_GLASS: 'GlassesMenuComponent.Button.ChooseGlass.Label',
  CHOOSE_MOTIVE: 'GlassesMenuComponent.Button.ChooseMotive.Label',
  GLASSES_TITLE_TTK: 'GlassesMenuComponent.Title.Glasses.Ttk',
  SEARCH_GLASS: 'GlassesMenuComponent.Input.Search.Glass.Label',
  SEARCH_MOTIVE: 'GlassesMenuComponent.Input.Search.Motive.Label'
} as const

@Component({
  selector: 'configurator-glasses-menu',
  templateUrl: './glasses-menu.component.html',
  styleUrls: ['./glasses-menu.component.scss']
})
export class GlassesMenuComponent extends EventBusBase {
  protected readonly ConfiguratorMode = ConfiguratorMode
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly ObjectUtil = ObjectUtil
  protected readonly SpacerThicknessExceededError = SpacerThicknessExceededError
  protected readonly SpacerThicknessUndercutError = SpacerThicknessUndercutError
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  _glassMode: ('glas' | 'motiv') = 'glas'
  currentGlasses: FbsGlas[][] = []
  currentMotive: Motiv[][] = []
  @ViewChild('glasViewPort', {static: false}) glasViewPort: CdkVirtualScrollViewport
  protected glassUpdate: GlassUpdateHelper
  protected itemsPerRow: number
  @ViewChild('motivViewPort', {static: false}) motivViewPort: CdkVirtualScrollViewport
  protected virtualScrollItemHeight: number

  constructor(
    private readonly navigatorService: NavigatorService,
    private _configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private glassService: GlassService,
    private modalService: ModalService,
    private componentSelection: ComponentSelectionService,
    private _configuratorModeService: ConfiguratorModeService,
    private translator: TranslateService,
    @Inject(GLASS_PROVIDER_TOKEN) private glassProvider: GlassProvider,
    eventBus: EventBusService
  ) {
    super(eventBus)
    this.updateItemHeight()
    this.glassUpdate = new GlassUpdateHelper(this.translator, this.componentSelection.selectedComponent)
    this.componentSelection.selectedComponentChange.subscribe((component): void => {
      if (
        this._glassMode === 'glas'
          && component?.model?.Glasscheiben.length === 0
          && component?.model?.Motive.length > 0
      ) {
        this._glassMode = 'motiv'
      } else if (
        this._glassMode === 'motiv'
          && component?.model?.Motive.length === 0
          && component?.model?.Glasscheiben.length > 0) {
        this._glassMode = 'glas'
      }
      this.glassUpdate.element = component
      this.collectAndScroll()
    })
    this._configuratorConfigurationModel.modelsLoadedAfterGrundformChange
      .subscribe(() => void (this.glassUpdate.element = this.componentSelection?.selectedComponent))
    this._configuratorConfigurationModel.newConfiguratedDoorInitialized
      .subscribe(() => void (this.glassUpdate.element = this.componentSelection?.selectedComponent))
    this.navigatorService.currentActiveMenu$.subscribe({
      next: (menuEntry): void => {
        if (menuEntry === NavigationMenuEntryKey.Glass) {
          this.collectAndScroll()
          this.checkViewportSize()
        }
      }
    })
  }

  checkViewportSize(): void {
    this.glasViewPort?.checkViewportSize()
    this.motivViewPort?.checkViewportSize()
  }

  closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  collect<T extends FbsGlas | Motiv>(typ: (T extends FbsGlas ? 'glas' : 'motiv')): T[][] {
    const listKey = typ === 'glas' ? 'currentGlasses' : 'currentMotive'
    const list: T[] = []
    if (listKey === 'currentGlasses') {
      list.push(...this.glassProvider.getGlassesForModel(this.glassUpdate?.element.model) as T[])
    } else {
      list.push(...(this.componentSelection?.selectedComponent?.model?.Motive || []) as T[])
    }
    if (list.length > 0) {
      (this[listKey] as T[][]) = ArrayUtil.groupedArray(
        list.filter((item: T): boolean =>
          this.translator.translate(item.Beschreibung)
            .toLowerCase()
            .includes(this.glassUpdate.filterTerm.toLowerCase())
        ),
        this.itemsPerRow
      )
      setTimeout((): void => {
        this.checkViewportSize()
      })
    } else {
      this[listKey] = []
    }
    return this[listKey] as T[][]
  }

  collectAndScroll(): void {
    this.collect('glas')
    this.collect('motiv')
    setTimeout((): void => {
      const element = this.componentSelection.selectedComponent
      const data = this._glassMode === 'glas' ? {
        glasaufbauKey: 'GlasscheibeAussen',
        list: this.currentGlasses,
        viewPort: this.glasViewPort
      } as const : {
        glasaufbauKey: 'MotivAussen',
        list: this.currentMotive,
        viewPort: this.motivViewPort
      } as const
      if (element?.glasaufbau?.[data.glasaufbauKey]?.Id) {
        const groupIndex = data.list.findIndex((group: FbsGlas[] | Motiv[]): boolean =>
          group.some((item: { Beschreibung: string; Id: number }): boolean =>
            item.Id === element?.glasaufbau?.[data.glasaufbauKey]?.Id
                || item.Beschreibung === element?.glasaufbau?.[data.glasaufbauKey]?.Beschreibung)
        )
        if (groupIndex !== -1) {
          const SCROLL_DISTANCE = 5
          if (groupIndex >= SCROLL_DISTANCE) {
            data.viewPort?.scrollToIndex(groupIndex - SCROLL_DISTANCE, 'instant')
          }
          data.viewPort?.scrollToIndex(groupIndex, 'smooth')
        }
      }
    })
  }

  handleMotivClick(item: Motiv): void {
    if (item.disabilityReasons.length !== 0) {
      return
    }
    if (this.isSameMotivAussen(item)) {
      this.unset('motiv', 'aussen')
    } else {
      this.setMotiv('aussen', item)
    }
  }

  isSameMotivAussen(motiv: Motiv): boolean {
    return this.selectedComponent?.glasaufbau?.MotivAussen?.Id === motiv?.Id
  }

  moveGlass(glasQuelle: GlasPositionLong, glasZiel: GlasaufbauPositionShort): void {
    const glasaufbau = this.componentSelection.selectedComponent.glasaufbau
    glasaufbau.setGlas(glasZiel, glasaufbau[glasQuelle])
    switch (glasQuelle) {
      case 'GlasscheibeAussen':
        glasaufbau.setGlas('aussen', this.glassService.getDefaultGlass())
        break
      case 'GlasscheibeMitte1':
        glasaufbau.setGlas('mitte1', this.glassService.getDefaultMiddleGlass())
        break
      case 'GlasscheibeMitte2':
        glasaufbau.setGlas('mitte2', this.glassService.getDefaultMiddleGlass())
        break
      default:
        console.warn('GlasQuelle unknown')
    }
  }

  moveMotiv(motivQuelle: MotivPositionLong, motivZiel: GlasaufbauPositionShort): void {
    const glasaufbau = this.componentSelection.selectedComponent.glasaufbau
    glasaufbau.setMotiv(motivZiel, glasaufbau[motivQuelle])
    switch (motivQuelle) {
      case 'MotivAussen':
        glasaufbau.unsetMotiv('aussen')
        break
      case 'MotivMitte1':
        glasaufbau.unsetMotiv('mitte1')
        break
      case 'MotivMitte2':
        glasaufbau.unsetMotiv('mitte2')
        break
      default:
        console.warn('MotivQuelle unknown')
    }
  }

  onAnzahlGlaeserChanged(event: MatRadioChange): void {
    const newAnzahl = parseInt(typeof event.value === 'string' ? event.value : 'NAN', 10)
    const glasaufbau = this.componentSelection.selectedComponent.glasaufbau
    const changeDirection: { from: number; to: number } = {from: glasaufbau.numGlasses, to: newAnzahl}
    let didChange = false
    if (isNaN(newAnzahl) || newAnzahl < glasaufbau.MinAnzahlGlasscheiben) {
      return
    }
    if (changeDirection.from === 2 && changeDirection.to === 3) {
      if (glasaufbau.MotivAussen) {
        this.moveMotiv((this.positionToLong('aussen', 'motiv') as MotivPositionLong), 'mitte1')
      }
      if (glasaufbau.GlasscheibeAussen.IsOrnamentglas) {
        this.moveGlass('GlasscheibeAussen', 'mitte1')
        glasaufbau.setGlas('aussen', this.glassService.getDefaultGlass())
      }
    }
    if (changeDirection.from === 2 && changeDirection.to === 4) {
      if (glasaufbau.MotivAussen) {
        this.moveMotiv((this.positionToLong('aussen', 'motiv') as MotivPositionLong), 'mitte1')
      }
      if (glasaufbau.GlasscheibeAussen.IsOrnamentglas) {
        this.moveGlass('GlasscheibeAussen', 'mitte1')
        glasaufbau.setGlas('aussen', this.glassService.getDefaultGlass())
      }
    }
    if (changeDirection.from === 3 && changeDirection.to === 4) {
      if (glasaufbau.GlasscheibeAussen.IsOrnamentglas) {
        if (glasaufbau.MotivMitte1) {
          this.moveMotiv((this.positionToLong('mitte1', 'motiv') as MotivPositionLong), 'mitte2')
        }
        if (glasaufbau.GlasscheibeMitte1.IsOrnamentglas) {
          this.moveGlass('GlasscheibeMitte1', 'mitte2')
          glasaufbau.setGlas('mitte1', this.glassService.getDefaultMiddleGlass())
        }
        if (glasaufbau.MotivAussen) {
          this.moveMotiv((this.positionToLong('aussen', 'motiv') as MotivPositionLong), 'mitte1')
        }
        this.moveGlass('GlasscheibeAussen', 'mitte1')
        glasaufbau.setGlas('aussen', this.glassService.getDefaultGlass())
      }
    }
    if (changeDirection.from === 3 && changeDirection.to === 2) {
      if (glasaufbau.MotivMitte1) {
        this.moveMotiv((this.positionToLong('mitte1', 'motiv') as MotivPositionLong), 'aussen')
      }
      if (glasaufbau.GlasscheibeMitte1.IsOrnamentglas) {
        this.moveGlass('GlasscheibeMitte1', 'aussen')
        glasaufbau.setGlas('mitte1', this.glassService.getDefaultGlass())
      }
    }
    if (changeDirection.from === 4 && changeDirection.to === 3) {
      if (glasaufbau.MotivMitte2) {
        this.moveMotiv((this.positionToLong('mitte2', 'motiv') as MotivPositionLong), 'mitte1')
      }
      if (!glasaufbau.GlasscheibeMitte1.IsOrnamentglas && glasaufbau.GlasscheibeMitte2.IsOrnamentglas) {
        this.moveGlass('GlasscheibeMitte2', 'mitte1')
        glasaufbau.setGlas('mitte2', this.glassService.getDefaultMiddleGlass())
      }
    }
    if (changeDirection.from === 4 && changeDirection.to === 2) {
      if (glasaufbau.GlasscheibeMitte1.IsOrnamentglas) {
        this.moveGlass('GlasscheibeMitte1', 'aussen')
        glasaufbau.setGlas('mitte1', this.glassService.getDefaultGlass())
        glasaufbau.unsetMotiv('aussen')
        if (glasaufbau.MotivMitte1) {
          this.moveMotiv((this.positionToLong('mitte1', 'motiv') as MotivPositionLong), 'aussen')
        }
      } else if (glasaufbau.GlasscheibeMitte2.IsOrnamentglas) {
        this.moveGlass('GlasscheibeMitte2', 'aussen')
        glasaufbau.setGlas('mitte2', this.glassService.getDefaultGlass())
        glasaufbau.unsetMotiv('aussen')
        if (glasaufbau.MotivMitte2) {
          this.moveMotiv((this.positionToLong('mitte2', 'motiv') as MotivPositionLong), 'aussen')
        }
      }
    }
    if (newAnzahl >= 4 && !glasaufbau.GlasscheibeMitte2) {
      glasaufbau.setGlas('mitte2', this.glassService.getDefaultMiddleGlass())
      didChange = true
    }
    if (newAnzahl >= 3 && !glasaufbau.GlasscheibeMitte1) {
      glasaufbau.setGlas('mitte1', this.glassService.getDefaultMiddleGlass())
      didChange = true
    }
    if (newAnzahl <= 3 && glasaufbau.GlasscheibeMitte2) {
      glasaufbau.unsetGlas('mitte2')
      didChange = true
    }
    if (newAnzahl <= 2 && glasaufbau.GlasscheibeMitte1) {
      glasaufbau.unsetGlas('mitte1')
      didChange = true
    }
    if (didChange) {
      this.renderRequest()
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateItemHeight()
  }

  positionToLong(position: GlasaufbauPositionShort, type: ('glas' | 'motiv')): GlasPositionLong | MotivPositionLong {
    let theString: GlasPositionLong | MotivPositionLong
    switch (position) {
      case 'aussen':
        if (type === 'glas') {
          theString = 'GlasscheibeAussen'
        } else {
          theString = 'MotivAussen'
        }
        break
      case 'mitte1':
        if (type === 'glas') {
          theString = 'GlasscheibeMitte1'
        } else {
          theString = 'MotivMitte1'
        }
        break
      case 'mitte2':
        if (type === 'glas') {
          theString = 'GlasscheibeMitte2'
        } else {
          theString = 'MotivMitte2'
        }
        break
      case 'innen':
        if (type === 'glas') {
          theString = 'GlasscheibeInnen'
        } else {
          theString = 'MotivInnen'
        }
        break
    }
    return theString
  }

  renderRequest(): void {
    this.toggleEvent<string>(BusEvent.Render)
  }

  resetGlasTerm(): void {
    this.glassUpdate.filterTerm = ''
    this.collectAndScroll()
  }

  setGlas(position: GlasaufbauPositionShort, glas: FbsGlas, callRender: boolean = true): void {
    this.glassUpdate.element = this.componentSelection.selectedComponent
    this.glassUpdate.mode = 'glass'
    this.glassUpdate.position = position
    this.glassUpdate.selectedGlas = glas
    this.glassUpdate.updateSelection()
    this.unset('motiv', 'aussen', false)
    if (callRender) {
      this.renderRequest()
    }
  }

  setMotiv(position: GlasaufbauPositionShort, motiv: Motiv, callRender: boolean = true): void {
    this.setGlas('aussen', this.glassProvider.getAllGlasses().concat().find((g: FbsGlas): boolean => g.Id === motiv.GrundglasId), false)
    this.glassUpdate.element = this.componentSelection.selectedComponent
    this.glassUpdate.mode = 'motiv'
    this.glassUpdate.position = position
    this.glassUpdate.selectedMotiv = motiv
    this.glassUpdate.updateSelection()
    if (callRender) {
      this.renderRequest()
    }
  }

  showGlassModal(position: GlasaufbauPositionShort, mode: GlassModalMode): void {
    if (mode === 'motiv' && !this.selectedComponent.glasaufbau.motivPossible(position)) {
      return
    }
    this.modalService.showGlassModal(position, mode)
      .afterClosed()
      .subscribe((didChange): void => {
        if (didChange) {
          this.renderRequest()
        }
      })
  }

  showImageModal(item: FbsGlas | Motiv, e: Event): void {
    e.stopPropagation()
    this.modalService.showImageModal(
      item.Beschreibung,
      item.PreviewImageUrl,
      {panelClass: 'detailview'}
    )
  }

  showSprossenModal(element: ConstructionComponent): void {
    this.modalService.showSprossenModal(element)
      .afterClosed()
      .subscribe((result): void => {
        if (result !== false && result !== element.glasaufbau.Sprossen) {
          this.renderRequest()
        }
      })
  }

  unset(typ: 'sprosse'): void

  unset(typ: 'glass' | 'motiv', position: GlasaufbauPositionShort, emitGlassaufbauChange?: boolean): void

  unset(typ: 'glass' | 'motiv' | 'sprosse', position?: GlasaufbauPositionShort, emitGlassaufbauChange: boolean = true): void {
    if (typ === 'sprosse') {
      this.componentSelection.selectedComponent.glasaufbau.Sprossen = null
    } else {
      this.componentSelection.selectedComponent.glasaufbau[typ === 'glass' ? 'unsetGlas' : 'unsetMotiv'](position)
    }
    if (emitGlassaufbauChange) {
      this.renderRequest()
    }
  }

  updateItemHeight(): void {
    if (window.innerWidth <= 767) {
      this.itemsPerRow = 3
      this.virtualScrollItemHeight = parseInt(((window.innerWidth * 0.8) / this.itemsPerRow).toString(), 10) + 30
    } else {
      this.itemsPerRow = 2
      this.virtualScrollItemHeight = 207
    }
    if (this.glassUpdate?.element) {
      this.collect('glas')
      this.collect('motiv')
    }
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }

  get glassMode(): ('glas' | 'motiv') {
    return this._glassMode
  }

  set glassMode(mode: ('glas' | 'motiv')) {
    this._glassMode = mode
    this.collectAndScroll()
  }

  get selectedComponent(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }

  get thermalInsulationPackage(): Paket {
    return this._configuratorConfigurationModel.thermalInsulationPackage
  }

  set thermalInsulationPackage(thermalInsulationPackage: Paket) {
    this._configuratorConfigurationModel.thermalInsulationPackage = thermalInsulationPackage
  }

  get thermalInsulations(): Paket[] {
    return this.componentSelection.selectedComponent.model?.getPackages({
      material: this.componentSelection.selectedComponent.material,
      type: PackageType.ThermalInsulation
    }) ?? []
  }
}
