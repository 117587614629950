export enum BusEvent {
  Render = 'render',
  LoadRequest = 'loadRequest',
  SearchRequest = 'searchRequest',
  DoorPositionChange = 'doorPositionChange',
  SelectModel = 'selectModel',
  GlasAufbauChange = 'glasaufbauChange',
  ChangeView = 'changeView',
  OptionChange = 'optionChange',
  CloseCharacteristics = 'closeCharasteristics',
  GrundformChange = 'grundformChange',
  SimpleConstructionChanged = 'simpleConstructionChanged',
  Track = 'track'
}
