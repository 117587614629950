import {AbbreviatedCoordinateData} from '../../coordinate/abbreviatedCoordinateData.type'

export type ConstructionDimensionsData = Pick<ConstructionDimensions,
'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P'
>

export class ConstructionDimensions {
  readonly A: number
  readonly B: number
  readonly C: number
  readonly D: number
  readonly E: number
  readonly F: number
  readonly G: number
  readonly H: number
  readonly I: number
  readonly J: number
  readonly K: number
  readonly L: number
  readonly M: number
  readonly N: number
  readonly O: number
  readonly P: number

  constructor(data?: Partial<ConstructionDimensionsData>) {
    this.A = data && data.A || 0
    this.B = data && data.B || 0
    this.C = data && data.C || 0
    this.D = data && data.D || 0
    this.E = data && data.E || 0
    this.F = data && data.F || 0
    this.G = data && data.G || 0
    this.H = data && data.H || 0
    this.I = data && data.I || 0
    this.J = data && data.J || 0
    this.K = data && data.K || 0
    this.L = data && data.L || 0
    this.M = data && data.M || 0
    this.N = data && data.N || 0
    this.O = data && data.O || 0
    this.P = data && data.P || 0
  }

  static fromParameterModel(model: AbbreviatedCoordinateData, konstruktion: number): ConstructionDimensions {
    const allValues = {
      A: Math.abs(model.bxa - model.soxa),
      B: Math.abs(model.bxb - model.bxa),
      C: Math.abs(model.bxb - model.soxb),
      D: Math.abs(model.soxb - model.soxa),
      E: Math.abs(model.soyb - model.byb),
      F: Math.abs(model.bya - model.byb),
      G: Math.abs(model.soya - model.bya),
      H: Math.abs(model.soya - model.soyb),
      I: Math.abs(model.sixa - model.bxa),
      J: Math.abs(model.bxb - model.bxa),
      K: Math.abs(model.sixb - model.bxb),
      L: Math.abs(model.sixa - model.sixb),
      M: Math.abs(model.siyb - model.byb),
      N: Math.abs(model.byb - model.bya),
      O: Math.abs(model.bya - model.siya),
      P: Math.abs(model.siya - model.siyb)
    }
    switch (konstruktion) {
      case 1:
        return new ConstructionDimensions({
          A: allValues.A,
          B: allValues.B,
          C: allValues.C,
          D: allValues.D,
          E: allValues.E,
          F: allValues.F,
          G: allValues.G,
          H: allValues.H,
        })
      case 2:
        return new ConstructionDimensions(allValues)
      case 0:
      default:
        return new ConstructionDimensions({
          B: allValues.B,
          D: allValues.B,
          F: allValues.F,
          H: allValues.F,
        })
    }
  }

  toParameterModel(): AbbreviatedCoordinateData {
    return {
      soxa: 1000,
      bxa:  1000 + this.A,
      bxb:  1000 + this.A + this.B,
      soxb: 1000 + this.A + this.B - this.C,
      soya: 1000,
      bya:  1000 + this.G,
      byb:  1000 + this.F + this.G,
      soyb: 1000 + this.F + this.G + this.E,
      sixa: 1000 + this.A + this.I,
      sixb: 1000 + this.A + this.B + this.K,
      siyb: 1000 + this.F + this.G + this.M,
      siya: 1000 + this.F + this.G + this.M + this.O,
    }
  }

  get maxFuellungsBreite(): number {
    return Math.max(this.B, this.D, this.J, this.L)
  }

  get maxFuellungsHoehe(): number {
    return Math.max(this.F, this.H, this.N, this.P)
  }

  get minFuellungsBreite(): number {
    return Math.min(...[this.B, this.D, this.J, this.L].filter((v): boolean => v !== 0))
  }

  get minFuellungsHoehe(): number {
    return Math.min(...[this.F, this.H, this.N, this.P].filter((v): boolean => v !== 0))
  }
}
