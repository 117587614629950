import {Component, Input, OnDestroy} from '@angular/core'
import {AccordionComponent} from './accordion.component'

@Component({
  selector: 'accordion-group',
  templateUrl: './accordion-group.component.html'
})
export class AccordionGroupComponent implements OnDestroy {
  private _allowToggle: boolean = true
  private _isOpen = false
  @Input() heading: string
  @Input() visible: boolean | string = true

  constructor(private accordion: AccordionComponent) {
    this.accordion.addGroup(this)
  }

  getIcon(status: boolean): string {
    if (status) {
      return '<i class="fa fa-minus"></i>'
    } else {
      return '<i class="fa fa-plus"></i>'
    }
  }

  ngOnDestroy(): void {
    this.accordion.removeGroup(this)
  }

  toggleOpen(event: MouseEvent): void {
    event.preventDefault()
    this.isOpen = !this.isOpen
  }

  get allowToggle(): boolean | string {
    return this._allowToggle
  }

  @Input()
  set allowToggle(value: boolean | string) {
    this._allowToggle = value === 'true' || value === true
  }

  get isOpen(): boolean {
    return this._isOpen
  }

  @Input()
  set isOpen(value: boolean) {
    if (!this.allowToggle) {
      return
    }
    this._isOpen = value
    if (!value) {
      return
    }
    this.accordion.closeOthers(this)
    // scroll to currently active accordion
    setTimeout((): void => {
      const openAccordion = document.querySelector('.subNavigationContainer.active .panel.panel-open')
      if (openAccordion instanceof HTMLElement) {
        const parent = openAccordion.closest('.container-inner')
        parent.scroll(0, openAccordion.offsetTop - 10)
      }
    }, 100)
  }
}
