import {Injectable} from '@angular/core'
import {Observable, Subject} from 'rxjs'
import {filter, map} from 'rxjs/operators'

interface Event<T> {
  data?: T
  name: string
}

@Injectable()
export class EventBusService {
  private eventSubject: Subject<Event<unknown>> = new Subject<Event<unknown>>()

  on<T>(eventName: string): Observable<T> {
    return this.eventSubject.pipe(
      filter((event: Event<T>): event is Event<T> => event.name === eventName),
      map((event: Event<T>): T => event.data)
    )
  }

  publish<T>(eventName: string, data?: T): void {
    this.eventSubject.next({name: eventName, data})
  }
}
