import {ConstructionComponent, ConstructionComponentFactory} from './constructionComponent'
import {Injectable} from '@angular/core'
import {GlasaufbauFactory} from '../glassaufbau/glasaufbau'
import {ConstructionComponentType} from './construction-component-type.enum'

export class Tuer extends ConstructionComponent {
  constructor(data: Partial<ConstructionComponent>, glasaufbauFactory: GlasaufbauFactory) {
    super(data, glasaufbauFactory)
    this._objectType = ConstructionComponentType.Door
  }
}

@Injectable()
export class TuerFactory extends ConstructionComponentFactory<Tuer> {
  constructor(glasaufbauFactory: GlasaufbauFactory) {
    super(glasaufbauFactory, Tuer)
  }
}
