import {ColorBase} from './colorBase'
import {ColorType} from '../../../types'
import {Foil} from '../../api/model/color/foil.interface'

export class Folie extends ColorBase {
  FarbeAlternativ: number
  Hersteller: string
  ImageUrl: string
  IsDeckschichtMoeglich: boolean
  IsHolzdekor: boolean
  IsOrnamentrahmenMoeglich: boolean
  IsStruktur: boolean
  IsTiefzieh: boolean
  loaded: boolean
  objectType = 'Folie'
  Praegung: string
  PreviewImageUrl: string
  PulverAlternativ: number

  constructor(data?: Partial<Foil>) {
    super(data)
    this.FarbeAlternativ = data?.FarbeAlternativ
    this.Hersteller = data?.Hersteller
    this.ImageUrl = data?.ImageUrl
    this.IsDeckschichtMoeglich = data?.IsDeckschichtMoeglich
    this.IsHolzdekor = data?.IsHolzdekor
    this.IsOrnamentrahmenMoeglich = data?.IsOrnamentrahmenMoeglich
    this.IsStruktur = data?.IsStruktur
    this.IsTiefzieh = data?.IsTiefzieh
    this.Praegung = data?.Praegung
    this.PreviewImageUrl = data?.PreviewImageUrl
    this.PulverAlternativ = data?.PulverAlternativ
    this.loaded = false
  }

  getType(): ColorType {
    return 'folie'
  }
}
