import {Component, HostBinding} from '@angular/core'
import {AccordionGroupComponent} from './accordion-group.component'

@Component({
  selector: 'accordion',
  template: `
    <ng-content></ng-content>
  `
})
export class AccordionComponent {
  readonly groups: Array<AccordionGroupComponent> = []
  @HostBinding('class.panel-group')
  readonly hostStyleClass = true

  addGroup(group: AccordionGroupComponent): void {
    this.groups.push(group)
  }

  closeOthers(openGroup: AccordionGroupComponent): void {
    this.groups.forEach((group: AccordionGroupComponent): void => {
      if (group !== openGroup && group.allowToggle) {
        group.isOpen = false
      }
    })
  }

  removeGroup(group: AccordionGroupComponent): void {
    const index = this.groups.indexOf(group)
    if (index !== -1) {
      this.groups.splice(index, 1)
    }
  }
}

