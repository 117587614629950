import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

export interface DialogueModalData {
  message: string
  nostring: string
  title: string
  yesstring: string
}

@Component({
  templateUrl: './dialogue-modal.component.html',
  styleUrls: ['./dialogue-modal.component.scss']
})
export class DialogueModalComponent {
  hideCloseButton: boolean = false

  constructor(
    public dialogRef: MatDialogRef<DialogueModalComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: DialogueModalData
  ) {
    if (typeof this.dialogRef?.disableClose === 'boolean') {
      this.hideCloseButton = this.dialogRef?.disableClose
    }
  }
}
