<button
    (click)="previousButtonConfiguration.onClick()"
    [class.disable]="previousButtonConfiguration.isDisabled()"
>
    {{ previousButtonConfiguration.getDisplayString() | translate }}
</button>
<div (click)="toggleNavigationMenuOpen()" class="squares">
    <ng-container *ngFor='let menuEntryKey of this.configuredMenuEntries'>
        <div [class.active]="isActive(menuEntryKey)"></div>
    </ng-container>
</div>
<button
    (click)="nextButtonConfiguration.onClick()"
    [class.disable]="nextButtonConfiguration.isDisabled()"
    class="active"
>
    {{ nextButtonConfiguration.getDisplayString() | translate }}
</button>
