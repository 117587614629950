import {Material} from '../../model/material'
import {ConstructionComponentType} from '../../model/component/construction/construction-component-type.enum'
import {ModelLoadColorConfigurationRequestData} from './model-load-color-configuration-request-data.interface'
import {
  LoadRequestIdentifier
} from '../../../configurator/components/load-configuration-button/load-configuration-button.component'
import {Oeffnungsart} from '../../../types'



export class ModelLoadRequestData implements ModelLoadColorConfigurationRequestData {
  DekorFuellung: string
  DekorFuellungInnen: string
  DekorRahmen: string
  DekorRahmenInnen: string
  DinFuellung: number
  FarbeFuellung: string
  FarbeFuellungInnen: string
  FarbeRahmen: string
  FarbeRahmenInnen: string
  Id: string
  Konstruktion: number
  Material: Material
  Oeffnungsart: Oeffnungsart
  Position: number
  Profil: string
  TuerId: string
  Typ: ConstructionComponentType

  constructor(data?: Partial<ModelLoadRequestData>) {
    this.DekorFuellung = data?.DekorFuellung ?? ''
    this.DekorFuellungInnen = data?.DekorFuellungInnen ?? ''
    this.DekorRahmen = data?.DekorRahmen ?? ''
    this.DekorRahmenInnen = data?.DekorRahmenInnen ?? ''
    this.DinFuellung = data?.DinFuellung ?? 0
    this.FarbeFuellung = data?.FarbeFuellung ?? ''
    this.FarbeFuellungInnen = data?.FarbeFuellungInnen ?? ''
    this.FarbeRahmen = data?.FarbeRahmen ?? ''
    this.FarbeRahmenInnen = data?.FarbeRahmenInnen ?? ''
    this.Id = data?.Id ?? ''
    this.Konstruktion = data?.Konstruktion
    this.Material = data?.Material ?? Material.Alu
    this.Oeffnungsart = data?.Oeffnungsart ?? 'innen'
    if (typeof data?.Position === 'number' && !isNaN(data.Position)) {
      this.Position = data.Position
    }
    this.Profil = data?.Profil ?? ''
    if (typeof data?.TuerId === 'string') {
      this.TuerId = data.TuerId
    }
    this.Typ = data?.Typ ?? ConstructionComponentType.Door
  }

  static createFromParameterModel(
    modelId: string,
    componentType: ConstructionComponentType,
    modelMaterial: Material,
    profil: string,
    konstruktion: number,
    colorConf: ModelLoadColorConfigurationRequestData,
    oeffnungsart: Oeffnungsart,
    dinFuellung: number,
    loadRequestIdentifier?: LoadRequestIdentifier
  ): ModelLoadRequestData {
    return new ModelLoadRequestData({
      DinFuellung: dinFuellung,
      Id: modelId,
      Konstruktion: konstruktion,
      Material: modelMaterial,
      Oeffnungsart: oeffnungsart,
      Position: loadRequestIdentifier?.[1],
      Profil: profil,
      TuerId: loadRequestIdentifier?.[0],
      Typ: componentType,
      ...colorConf
    })
  }
}
