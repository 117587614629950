import {ComponentModel} from '../../component/model/component-model'
import {HttpService} from '../../../../http.service'
import {Inject, Injectable} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {ModelLoadRequestData} from '../../../api/model/model-load-request-data.dto'
import {GLASS_PROVIDER_TOKEN, GlassProvider} from '../glass/glass.type'
import {Tuer} from '../../component/construction/tuer'
import {Seitenteil} from '../../component/construction/seitenteil'
import {Oberlicht} from '../../component/construction/oberlicht'
import {FbsModelProvider} from './fbsModel.type'
import {ParameterModelConfiguration} from '../../modelConfiguration/parameterModelConfiguration'
import {Material} from '../../material'
import {TranslateService} from '../../../../translate'
import {ConfiguratorMode, minMax, Oeffnungsart} from '../../../../types'
import {ConfiguratorModeService} from '../../../service/configuratorMode.service'
import {ComponentSelectionService} from '../../../service/componentSelectionService'
import {ConstructionComponent} from '../../component/construction/constructionComponent'
import {FbsGlas} from '../../../../class'
import {ConstructionComponentType} from '../../component/construction/construction-component-type.enum'
import {CompactComponentModel} from '../../../api/model/compact-component-model.interface'
import {FbsModelTyp} from '../../component/model/component-model-type.enum'
import {ModelLoadResponse} from '../../../api/model/model-load-response.interface'
import {LoadRequestIdentifier} from '../../../../configurator/components/load-configuration-button/load-configuration-button.component'
import {ConstructionDimensionService} from '../../../service/construction-dimension.service'

const constructionMap = {
  0: 'Einsatz',
  1: 'Aufsatz einseitig',
  2: 'Aufsatz beidseitig'
}
const TRANSLATION_KEY = {
  REASON_CONSTRUCTION_IMPOSSIBLE_PREFIX: 'FbsModelService.DisabilityReason.ConstructionImpossible.Prefix',
  REASON_CONSTRUCTION_IMPOSSIBLE_SUFFIX: 'FbsModelService.DisabilityReason.ConstructionImpossible.Suffix',
  REASON_MIN_WIDTH_NOT_REACHED_PREFIX: 'FbsModelService.DisabilityReason.MinWidthNotMet.Prefix',
  REASON_MIN_WIDTH_NOT_REACHED_INFIX: 'FbsModelService.DisabilityReason.MinWidthNotMet.Infix',
  REASON_MIN_WIDTH_NOT_REACHED_SUFFIX: 'FbsModelService.DisabilityReason.MinWidthNotMet.Suffix',
  REASON_MAX_WIDTH_EXCEEDED_PREFIX: 'FbsModelService.DisabilityReason.MaxWidthExceeded.Prefix',
  REASON_MAX_WIDTH_EXCEEDED_INFIX: 'FbsModelService.DisabilityReason.MaxWidthExceeded.Infix',
  REASON_MAX_WIDTH_EXCEEDED_SUFFIX: 'FbsModelService.DisabilityReason.MaxWidthExceeded.Suffix',
  REASON_MIN_HEIGHT_NOT_REACHED_PREFIX: 'FbsModelService.DisabilityReason.MinHeightNotMet.Prefix',
  REASON_MIN_HEIGHT_NOT_REACHED_INFIX: 'FbsModelService.DisabilityReason.MinHeightNotMet.Infix',
  REASON_MIN_HEIGHT_NOT_REACHED_SUFFIX: 'FbsModelService.DisabilityReason.MinHeightNotMet.Suffix',
  REASON_MAX_HEIGHT_EXCEEDED_PREFIX: 'FbsModelService.DisabilityReason.MaxHeightExceeded.Prefix',
  REASON_MAX_HEIGHT_EXCEEDED_INFIX: 'FbsModelService.DisabilityReason.MaxHeightExceeded.Infix',
  REASON_MAX_HEIGHT_EXCEEDED_SUFFIX: 'FbsModelService.DisabilityReason.MaxHeightExceeded.Suffix'
} as const

@Injectable()
export class FbsModelService implements FbsModelProvider {
  private _fbsModel: ComponentModel
  private fbsModels: (CompactComponentModel & { disabilityReasons?: string[] })[]

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService,
    private logger: NGXLogger,
    @Inject(GLASS_PROVIDER_TOKEN) private glassProvider: GlassProvider,
    private configuratorMode: ConfiguratorModeService,
    private componentSelector: ComponentSelectionService,
    private constructionDimensionService: ConstructionDimensionService
  ) {
    glassProvider.getObservableGlasses().subscribe({next: (): void => this.updateGlasses()})
  }

  calcMinMaxValuesFBS(element: ConstructionComponent, model: CompactComponentModel, construction: number): minMax {
    let minWidth = 0
    let maxWidth = 3000
    let minHeight = 0
    let maxHeight = 3000
    if (element && model) {
      if (element.material === Material.Alu) {
        if (construction === 0) {
          minWidth = model.MinBreiteEinsatz
          maxWidth = model.MaxBreiteEinsatzAluminium
          minHeight = model.MinHoeheEinsatz
          maxHeight = model.MaxHoeheEinsatzAluminium
        } else if (construction === 1 || construction === 2) {
          minWidth = model.MinBreiteAufsatz
          maxWidth = model.MaxBreiteAufsatzAluminium
          minHeight = model.MinHoeheAufsatz
          maxHeight = model.MaxHoeheAufsatzAluminium
        }
      } else {
        if (construction === 0) {
          minWidth = model.MinBreiteEinsatz
          maxWidth = model.MaxBreiteEinsatzKunststoff
          minHeight = model.MinHoeheEinsatz
          maxHeight = model.MaxHoeheEinsatzKunststoff
        } else if (construction === 1 || construction === 2) {
          minWidth = model.MinBreiteAufsatz
          maxWidth = model.MaxBreiteAufsatzKunststoff
          minHeight = model.MinHoeheAufsatz
          maxHeight = model.MaxHoeheAufsatzKunststoff
        }
      }
    }
    // returns the smallest and larges values available in the model not konstruktion values are being regarded
    return {
      minWidth,
      maxWidth,
      minHeight,
      maxHeight
    }
  }

  filterModels(
    elementToFit: Tuer | Seitenteil | Oberlicht,
    construction: number | number[],
    modelSearchTerm = '',
    selectedCatalogueId?: number,
    models?: (CompactComponentModel & { disabilityReasons?: string[] })[]
  ): (CompactComponentModel & { disabilityReasons?: string[] })[] {
    models ??= this.fbsModels
    const constructions = typeof construction === 'number' ? [construction] : construction
    const elementDimensionRequirements = constructions.reduce<Record<number, minMax>>((accumulator, construct): Record<number, minMax> => {
      const constructionDimensions = this.constructionDimensionService.calculateConstructionDimensions(elementToFit, construct)
      accumulator[construct] =
        {
          minWidth: constructionDimensions.minFuellungsBreite,
          maxWidth: constructionDimensions.maxFuellungsBreite,
          minHeight: constructionDimensions.minFuellungsHoehe,
          maxHeight: constructionDimensions.maxFuellungsHoehe
        }
      return accumulator
    }, {})
    this.logger.trace('filterModels')
    return models.filter((model: CompactComponentModel & { disabilityReasons?: string[] }): boolean => {
      model.disabilityReasons = []
      // in TTK show only the fitting models for selected element type - i.e. tuer, seitenteil, oberlicht models
      if (this.configuratorMode.mode === ConfiguratorMode.TTK) {
        let modelTypeFits = true
        switch (this.componentSelector?.selectedComponent?.objectType) {
          case ConstructionComponentType.Door:
            modelTypeFits = model.Typ === FbsModelTyp.tuer
            break
          case ConstructionComponentType.SidePanel:
            modelTypeFits = model.Typ === FbsModelTyp.seitenteil
            break
          case ConstructionComponentType.Fanlight:
            modelTypeFits = model.Typ === FbsModelTyp.oberlicht
            break
          default:
            return true
        }
        // return false if modeltype does not fit
        // otherwise go on
        if (!modelTypeFits) {
          return false
        }
      }
      const modelAllowedConstructions = constructions.filter((construct): boolean =>
        (construct === 0 && model.IsEinsatzMoeglich)
        || (construct === 1 && model.IsAufsatzEinseitigMoeglich)
        || (construct === 2 && model.IsAufsatzBeidseitigMoeglich)
      )
      if (modelAllowedConstructions.length === 0) {
        model.disabilityReasons.push(
          this.translateService.translate(TRANSLATION_KEY.REASON_CONSTRUCTION_IMPOSSIBLE_PREFIX)
          + constructionMap[elementToFit.konstruktion]
          + this.translateService.translate(TRANSLATION_KEY.REASON_CONSTRUCTION_IMPOSSIBLE_SUFFIX)
        )
      }
      if (
        (selectedCatalogueId && selectedCatalogueId > 0 && (model.KatalogId !== selectedCatalogueId))
        || (
          modelSearchTerm.length > 0
          && !this.translateService.translate(model.Bezeichnung).toLowerCase().includes(modelSearchTerm.toLowerCase())
        )
      ) {
        return false
      }
      const dimensionAllowedConstructions = modelAllowedConstructions.filter((construct): boolean => {
        const dimensionBounds = this.calcMinMaxValuesFBS(elementToFit, model, construct)
        const dimensionRequirements = elementDimensionRequirements[construct]
        return dimensionRequirements.minWidth >= dimensionBounds.minWidth
          && dimensionRequirements.maxWidth <= dimensionBounds.maxWidth
          && dimensionRequirements.minHeight >= dimensionBounds.minHeight
          && dimensionRequirements.maxHeight <= dimensionBounds.maxHeight
      })
      if (modelAllowedConstructions.length !== 0 && dimensionAllowedConstructions.length === 0) {
        const errorConditionConstruction = modelAllowedConstructions.includes(elementToFit.konstruktion)
          ? elementToFit.konstruktion
          : modelAllowedConstructions[0]
        const fillingExtremes = this.calcMinMaxValuesFBS(elementToFit, model, errorConditionConstruction)
        const dimensionRequirements = elementDimensionRequirements[errorConditionConstruction]
        if (dimensionRequirements.minWidth < fillingExtremes.minWidth) {
          model.disabilityReasons.push(
            this.translateService.translate(TRANSLATION_KEY.REASON_MIN_WIDTH_NOT_REACHED_PREFIX)
            + fillingExtremes.minWidth
            + this.translateService.translate(TRANSLATION_KEY.REASON_MIN_WIDTH_NOT_REACHED_INFIX)
            + dimensionRequirements.minWidth
            + this.translateService.translate(TRANSLATION_KEY.REASON_MIN_WIDTH_NOT_REACHED_SUFFIX)
          )
        }
        if (dimensionRequirements.maxWidth > fillingExtremes.maxWidth) {
          model.disabilityReasons.push(
            this.translateService.translate(TRANSLATION_KEY.REASON_MAX_WIDTH_EXCEEDED_PREFIX)
            + fillingExtremes.maxWidth
            + this.translateService.translate(TRANSLATION_KEY.REASON_MAX_WIDTH_EXCEEDED_INFIX)
            + dimensionRequirements.maxWidth
            + this.translateService.translate(TRANSLATION_KEY.REASON_MAX_WIDTH_EXCEEDED_SUFFIX)
          )
        }
        if (dimensionRequirements.minHeight < fillingExtremes.minHeight) {
          model.disabilityReasons.push(
            this.translateService.translate(TRANSLATION_KEY.REASON_MIN_HEIGHT_NOT_REACHED_PREFIX)
            + fillingExtremes.minHeight
            + this.translateService.translate(TRANSLATION_KEY.REASON_MIN_HEIGHT_NOT_REACHED_INFIX)
            + dimensionRequirements.minHeight
            + this.translateService.translate(TRANSLATION_KEY.REASON_MIN_HEIGHT_NOT_REACHED_SUFFIX)
          )
        }
        if (dimensionRequirements.maxHeight > fillingExtremes.maxHeight) {
          model.disabilityReasons.push(
            this.translateService.translate(TRANSLATION_KEY.REASON_MAX_HEIGHT_EXCEEDED_PREFIX)
            + fillingExtremes.maxHeight
            + this.translateService.translate(TRANSLATION_KEY.REASON_MAX_HEIGHT_EXCEEDED_INFIX)
            + dimensionRequirements.maxHeight
            + this.translateService.translate(TRANSLATION_KEY.REASON_MAX_HEIGHT_EXCEEDED_SUFFIX)
          )
        }
      }
      return true
    })
  }

  loadFBSModels(): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.httpService?.getAllDoorModelsV2()?.subscribe({
        next: (data): void => {
          this.logger.trace(data)
          this.fbsModels = data?.Modelle ?? []
        },
        error: (error): void => {
          this.logger.error(error)
          reject()
        },
        complete: (): void => {
          resolve()
        }
      })
    })
  }

  loadModel(
    modelId: string,
    componentType: ConstructionComponentType,
    modelMaterial: Material,
    profil: string,
    konstruktion: number,
    parameterModel: ParameterModelConfiguration,
    oeffnungsart: Oeffnungsart,
    dinFuellung: number,
    loadRequestIdentifier?: LoadRequestIdentifier
  ): Promise<ComponentModel> {
    return new Promise<ComponentModel>((resolve, reject): void => {
      this.httpService
        .postDoorModelV2(
          ModelLoadRequestData.createFromParameterModel(
            modelId,
            componentType,
            modelMaterial,
            profil,
            konstruktion,
            parameterModel,
            oeffnungsart,
            dinFuellung,
            loadRequestIdentifier
          )
        ).subscribe({
          next: (data: Partial<ModelLoadResponse>): void => {
            resolve(new ComponentModel(data))
          },
          error: (error): void => {
            this.logger.error(error)
            reject()
          }
        })
    })
  }

  private updateGlasses(): void {
    if (this._fbsModel?.Glasscheiben && this.glassProvider.getAllGlasses().length > 0) {
      this._fbsModel.Glasscheiben =
        this._fbsModel.Glasscheiben.map((g1: FbsGlas): FbsGlas => this.glassProvider.findGlass(g1.Id) ?? g1)
    }
  }
}


