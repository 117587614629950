import {Inject, Injectable} from '@angular/core'
import WorkflowModule, {MassblattUpdateDebounceTimeMs} from './workflow.module'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {NGXLogger} from 'ngx-logger'
import {WorkflowExecution} from './workflow.types'
import WorkflowParser from './workflow-parser'
import {isSimpleConstruction} from '../classes/model/component/other/construction'
import {Material} from '../classes/model/material'
import {PackageType} from '../classes/model/component/other/paket'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'
import {GlasaufbauPositionShort} from '../types'
import {Glasaufbau} from '../classes/model/component/glassaufbau/glasaufbau'
import {MehrpreisEntry} from '../classes/model/component/extras/zubehoer/mehrpreisEntry'
import {ZubehoerAddonEntry} from '../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import {Dienstleistung} from '../classes/model/component/extras/mehrpreis/dienstleistung'
import {Massblatt} from '../classes/model/component/extras/massblatt'
import {ToastrService} from 'ngx-toastr'

@Injectable({providedIn: WorkflowModule})
export class WorkflowExecutionParser {
  private readonly ccmMassblattDebounceMap: WeakMap<ConfiguratorConfigurationModel, MassblattUpdateDebounceData>

  constructor(
    private logger: NGXLogger,
    @Inject(MassblattUpdateDebounceTimeMs) private readonly massblattUpdateDebounceTimeMs: number
  ) {
    this.ccmMassblattDebounceMap = new WeakMap()
  }

  private buildBasisExecution(selectorParts: string[]): WorkflowExecution<unknown> {
    if (selectorParts.length > 1) {
      this.logger.warn('Too many workflow execution child keys in "basis"!', selectorParts)
    }
    switch (selectorParts[0]?.toLowerCase()) {
      case 'klebeset':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: boolean): void => {
          ccm.selectedComponent.Klebeset = value
        }
      case 'klebesystem':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: number): void => {
          const adhesiveSystem = ccm.selectedComponent.model.Klebesysteme.find((system): boolean => system.Id === value)
          if (adhesiveSystem) {
            ccm.selectedComponent.Klebesystem = adhesiveSystem
          }
        }
      case 'konstruktion':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: number): void => {
          if (isSimpleConstruction(value)) {
            ccm.selectedComponent.konstruktion = value
          }
        }
      case 'konstruktionsvariante':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: string): void => {
          const constructionId = Number.parseInt(value, 10)
          ccm.selectedComponent.fbsKonstruktion = ccm.selectedComponent.model.Konstruktionen.find(
            (construction): boolean => construction.Id === constructionId
          )
        }
      case 'materialtürfüllung':
      case 'materialtuerfüllung':
      case 'materialtürfuellung':
      case 'materialtuerfuellung':
        return (ccm: ConfiguratorConfigurationModel, _,  __, ___, value: Material): void =>
          ccm.configuratedDoor.Components.forEach((c): void => {
            c.material = value
          })
      case 'materialtürsystem':
      case 'materialtuersystem':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: Material): void => {
          ccm.material = value
        }
      case 'seeklimatauglich':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: boolean): void => {
          ccm.configuratedDoor.Seeklimatauglich = value
        }
      case 'sicherheitspaket':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: string): void => {
          ccm.configuratedDoor.sicherheitsPaket = ccm.selectedComponent.model
            .getPackages({material: ccm.selectedComponent.material, type: PackageType.Security})
            .find((x): boolean => x.Key === value)
          ccm.selectedComponent.glasaufbau.setSicherheitsGlaeser()
        }
      case 'werksmontage':
        return (ccm: ConfiguratorConfigurationModel, _,  __, ___, value: boolean): void => {
          ccm.configuratedDoor.Werksmontage = value
        }
      case 'wärmeschutzpaket':
      case 'waermeschutzpaket':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: string): void => {
          ccm.configuratedDoor.waermeschutzPaket = ccm.selectedComponent.model
            .getPackages({material: ccm.selectedComponent.material, type: PackageType.ThermalInsulation})
            .find((x): boolean => x.Key === value)
          ccm.selectedComponent.glasaufbau.setWaermeschutzGlaeser()
        }
      case 'z-maß':
      case 'zmaß':
      case 'z-mass':
      case 'zmass':
        return (ccm: ConfiguratorConfigurationModel, _, __, ___, value: string): void => {
          ccm.configuratedDoor.Components.forEach((c): void => {
            c.Zmass = Number.parseFloat(value)
            ccm.zMassChanged.emit()
          })
        }
      default:
        this.logger.error('Unknown workflow execution key "' + selectorParts[0] + '" in "basis"!')
        return undefined
    }
  }

  public buildExecution(selector: string): WorkflowExecution<unknown> | undefined {
    const selectorParts = selector.split('.') as string[] & [string]
    const firstSelectorPartLc = selectorParts?.[0].toLowerCase()
    switch (firstSelectorPartLc) {
      case 'basis':
      case `${WorkflowParser.metaCharacters.not}basis`:
        return this.buildBasisExecution(selectorParts.slice(1))
      case 'gläser':
      case `${WorkflowParser.metaCharacters.not}gläser`:
      case 'glaeser':
      case `${WorkflowParser.metaCharacters.not}glaeser`:
        return this.buildGlassesAccessor(selectorParts.slice(1))
      case 'toast':
      case `${WorkflowParser.metaCharacters.not}toast`:
        return this.buildToastAccessor(selectorParts.slice(1))
    }
    if (
      firstSelectorPartLc?.startsWith('mehrpreise')
      || firstSelectorPartLc?.startsWith(`${WorkflowParser.metaCharacters.not}mehrpreise`)
    ) {
      return this.buildMehrpreiseExecution(selectorParts)
    }
    this.logger.error('Unknown workflow execution key "' + selectorParts[0] + '"!')
    return undefined
  }

  private buildGlassesAccessor(selectorParts: string[]): WorkflowExecution<unknown> {
    if (selectorParts.length > 1) {
      this.logger.warn('Too many workflow execution child keys in "gläser"!', selectorParts)
    }
    if (selectorParts.length < 1) {
      this.logger.error('Too few workflow execution child keys in "gläser"!', selectorParts)
      return undefined
    }
    if (selectorParts[0].toLowerCase() === 'anzahl') {
      return (ccm: ConfiguratorConfigurationModel, cdm: ConfiguratorDataModel, _, __, value: unknown): void => {
        const requestedAmountGlasses = parseInt(String(value), 10)
        if (requestedAmountGlasses !== 2 && requestedAmountGlasses !== 3 && requestedAmountGlasses !== 4) {
          this.logger.error(
            `Wrong value '${JSON.stringify(value)}' on key "gläser.anzahl", ` +
            'only the values \'2\', \'3\' and \'4\' are permitted!'
          )
          return
        }
        const selectorAmountMapping = {
          mitte1: 3,
          mitte2: 4
        } as const satisfies Record<Exclude<GlasaufbauPositionShort, 'aussen' | 'innen'>, Glasaufbau['numGlasses']>
        ccm.configuratedDoor.Components.forEach((component): void => {
          for (const selector in selectorAmountMapping) {
            if (selectorAmountMapping.hasOwnProperty(selector)) {
              if (selectorAmountMapping[selector] <= requestedAmountGlasses) {
                component?.glasaufbau
                  ?.setGlas(selector as keyof typeof selectorAmountMapping, cdm.getDefaultMiddleGlass())
              } else {
                component?.glasaufbau?.unsetGlas(selector as keyof typeof selectorAmountMapping)
              }
            }
          }
        })
      }
    }
    this.logger.error('Unknown workflow execution key "' + selectorParts[0] + '" in "gläser"!')
    return undefined
  }

  private buildMehrpreiseExecution(selectorParts: string[]): WorkflowExecution<unknown> {
    if (selectorParts.length < 2) {
      this.logger.error('Too few workflow execution child keys in "mehrpreise"!', selectorParts)
      return undefined
    }

    const childSelectorPartLc = selectorParts[1].toLowerCase()

    if (childSelectorPartLc === 'item') {
      const zuebehoerData = WorkflowParser.buildValidFilterMap(
        selectorParts[0],
        WorkflowParser.isSupportedMehrpreisKey,
        'mehrpeise',
        this.logger
      )
      const zubehoerFilterTyp = typeof zuebehoerData.Typ === 'string' ? zuebehoerData.Typ : undefined

      return (ccm, _, __, ___, value: unknown): void => {
        if (typeof value !== 'number') {
          this.logger.error(
            `Wrong value type '${typeof value}' in workflow execution "mehrpreise.item"!`,
            {selectorParts, value}
          )
          return
        }

        const zubehoer = ccm.selectedComponent.model.findZubehoer(value, zubehoerFilterTyp)
        if (typeof zubehoer === 'undefined') {
          this.logger.warn(
            `Could not find addon with id '${value}'`
            + (typeof zubehoerFilterTyp === 'undefined' ? '' : `and typ ${zubehoerFilterTyp}`)
            + 'in workflow execution "mehrpreise.item"',
            { selectorParts, zubehoerFilterTyp, value }
          )
          return
        }

        const zubehoerEntry = ccm.selectedComponent.getZubehoerEntry(zubehoer.Typ)
        if (zubehoerEntry?.Item && zubehoerEntry.Item.Id === zubehoer.Id) {
          return
        }

        ccm.selectedComponent.toggleZubehoer(zubehoer, true)
      }
    }

    if (!childSelectorPartLc.startsWith('dienstleistung')) {
      this.logger.error('Unknown workflow execution key "' + selectorParts[1] + '" in "mehrpreise"!', selectorParts)
      return undefined
    }

    const mehrpreisePredicates = WorkflowParser.buildValidPredicates<MehrpreisEntry>(
      selectorParts[0],
      WorkflowParser.isSupportedMehrpreisKey,
      'mehrpeise',
      this.logger
    )

    const mehrpreisAccessor = (ccm: ConfiguratorConfigurationModel): MehrpreisEntry[] =>
      mehrpreisePredicates.reduce<MehrpreisEntry[]>(
        (acc, curr): MehrpreisEntry[] => acc.filter(curr),
        ccm.selectedComponent.Mehrpreise
      )

    const addonPredicates = WorkflowParser.buildValidPredicates(
      selectorParts[1],
      WorkflowParser.isSupportedDienstleistungKey,
      'dienstleistung',
      this.logger
    )

    const dienstleistungAccessor =
        (ccm: ConfiguratorConfigurationModel): [MehrpreisEntry, Dienstleistung][] =>
          mehrpreisAccessor(ccm).flatMap((zubehoer: MehrpreisEntry): [MehrpreisEntry, Dienstleistung][] =>
            addonPredicates.reduce(
              (acc, curr): Dienstleistung[] => acc.filter(curr),
              ccm.selectedComponent.model.Mehrpreise.find((m): boolean => zubehoer.Typ === m.Typ)
                ?.Dienstleistungen ?? []
            ).map(
              (dienstleistung): [MehrpreisEntry, Dienstleistung] => [zubehoer, dienstleistung]
            )
          )

    if (selectorParts.length === 2) {
      return async (
        ccm: ConfiguratorConfigurationModel,
        _,
        __,
        ___,
        value: unknown
      ): Promise<void> => {
        let zubehoerEntry: MehrpreisEntry
        let updateMassblaetter = false
        value = WorkflowParser.castBool(value)
        if (mehrpreisAccessor(ccm).length === 0) {
          const zuebehoerData = WorkflowParser.buildValidFilterMap(
            selectorParts[0],
            WorkflowParser.isSupportedMehrpreisKey,
            'mehrpeise',
            this.logger
          )
          if (typeof zuebehoerData.Typ === 'string' && zuebehoerData.Typ !== '') {
            zubehoerEntry = ccm.selectedComponent.getOrCreateZubehoerEntry(zuebehoerData.Typ)
          }
        }
        dienstleistungAccessor(ccm).forEach(([zubehoer, dienstleistung]): void => {
          const setAddon = zubehoer.getAddon(dienstleistung.Typ)
          const isAddonSet = typeof setAddon !== 'undefined'
          if (isAddonSet && !value) {
            zubehoer.removeAddon(setAddon)
          } else if (!isAddonSet && value) {
            zubehoer.addAddon(ZubehoerAddonEntry.fromDienstleistung(dienstleistung))
            updateMassblaetter = true
          }
        })
        ccm.selectedComponent.clearZubehoerEntryIfEmpty(zubehoerEntry)
        if (updateMassblaetter) {
          await this.debounceMassblattUpdate(ccm)
        }
      }
    }

    if (
      !selectorParts[2].toLowerCase().startsWith('maßblatt')
      && !selectorParts[2].toLowerCase().startsWith('massblatt')
    ) {
      this.logger.error('Unknown workflow execution key "' + selectorParts[2] + '" in "mehrpreise.dienstleistung"!', selectorParts)
      return undefined
    }

    const massblattPredicates = WorkflowParser.buildValidPredicates(
      selectorParts[2],
      WorkflowParser.isSupportedMassblattKey,
      'massblatt',
      this.logger
    )

    const updateMassblatt = async (
      ccm: ConfiguratorConfigurationModel,
      values: Massblatt['Values']
    ): Promise<void> => {
      await Promise.all(
        dienstleistungAccessor(ccm).map(
          async ([zubehoer, dienstleistung]): Promise<void> => {
            const addon = zubehoer.getAddon(dienstleistung.Typ)
            const massblatt = massblattPredicates.reduce(
              (acc, curr): Massblatt[] => acc.filter(curr),
              dienstleistung.Massblaetter ?? []
            )?.[0]
            if (addon && massblatt) {
              addon.Massblatt = massblatt
              await this.debounceMassblattUpdate(ccm).then((): void => {
                if (Object.getOwnPropertyNames(values).length > 0) {
                  massblatt.updateValues(values)
                }
              })
            }
          }
        )
      )
    }

    if (selectorParts.length === 3) {
      const parseValues = (value: unknown): Massblatt['Values'] | false => {
        const values: Massblatt['Values'] = {}
        if (typeof value !== 'boolean') {
          let parsedValue: unknown
          if (typeof value === 'object') {
            parsedValue = value
          } else {
            if (typeof value !== 'string') {
              this.logger.error('Only json string values are permitted on key "mehrpreise[...].dienstleistung[...].massblatt[...]"')
              return false
            }
            try {
              parsedValue = JSON.parse(value)
            } catch (e) {
              this.logger.error('Only json string values are permitted on key "mehrpreise[...].dienstleistung[...].massblatt[...]"')
              return false
            }
          }
          if (typeof parsedValue === 'object') {
            for (const key in parsedValue) {
              if (parsedValue.hasOwnProperty(key)) {
                const numValue: unknown = parsedValue[key]
                if (typeof key === 'string' && typeof numValue === 'number') {
                  values[key] = numValue
                } else {
                  if (typeof key !== 'string') {
                    this.logger.warn(
                      `Wrong type '${typeof key}' for object key which should be 'string' on workflow key` +
                          '"mehrpreise[...].dienstleistung[...].massblatt[...]"',
                      {
                        key,
                        parsedObject: parsedValue,
                        rawValue: value
                      }
                    )
                  }
                  if (typeof numValue !== 'number') {
                    this.logger.warn(
                      `Wrong type '${typeof numValue}' for object value which should be 'number' on workflow` +
                          ' key "mehrpreise[...].dienstleistung[...].massblatt[...]"',
                      {
                        key,
                        value: numValue,
                        parsedObject: parsedValue,
                        rawValue: value
                      }
                    )
                  }
                }
              }
            }
          } else if (typeof parsedValue !== 'boolean') {
            this.logger.error(
              'Only json encoded object and boolean values are permitted on key ' +
                  '"mehrpreise[...].dienstleistung[...].massblatt[...]"'
            )
            return false
          }
        }
        return values
      }
      return (
        ccm: ConfiguratorConfigurationModel,
        _,
        __,
        ___,
        value: unknown
      ): Promise<void> => {
        const values: Massblatt['Values'] | false = parseValues(value)
        if (values !== false) {
          return updateMassblatt(ccm, values)
        }
      }
    }

    if (selectorParts.length > 4) {
      this.logger.warn('Too many workflow execution child keys in "mehrpreise"!', selectorParts)
    }
    return (
      ccm: ConfiguratorConfigurationModel,
      _,
      __,
      ___,
      value: unknown
    ): Promise<void> => {
      if (typeof value !== 'number') {
        this.logger.error(
          `Wrong type '${typeof value}' for object value which should be 'number' on workflow` +
                  ` key "mehrpreise[...].dienstleistung[...].massblatt[...].${selectorParts[3]}"`,
          {
            key: selectorParts[3],
            value,
          }
        )
        return undefined
      }

      return updateMassblatt(ccm, {[selectorParts[3]]: value})
    }
  }

  private buildToastAccessor(
    selectorParts: string[]
  ): WorkflowExecution<string | { Title: string } | { Message: string } | { Title: string; Message: string }> {
    if (selectorParts.length < 1) {
      this.logger.error('Too few workflow execution child keys in "toast"!', selectorParts)
      return undefined
    }
    if (selectorParts.length > 1) {
      this.logger.warn('Too many workflow execution child keys in "toast"!', selectorParts)
    }
    if ((['error', 'info', 'success', 'warning'] satisfies (keyof ToastrService)[] as string[]).includes(selectorParts[0])){
      this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "toast"!', selectorParts)
      return undefined
    }
    return (_, __, ___, ts, value): void => {
      const doToast = ts[selectorParts[0]] as ToastrService['error' | 'info' | 'success' | 'warning']
      if (typeof value === 'string') {
        value = { Message: value}
      }
      if (typeof value !== 'object' || value === null) {
        this.logger.error(
          `Wrong type '${typeof value}' for value which should be 'object' or 'string' on workflow key "toast.${selectorParts[0]}"`,
          {
            selectorParts,
            value,
          }
        )
        return undefined
      }
      if (!('Title' in value) && !('Message' in value)) {
        this.logger.error(
          `Missing properties 'Title' and 'Message' in value on workflow key "toast.${selectorParts[0]}"`,
          {
            selectorParts,
            value,
          }
        )
        return undefined
      }
      if ('Title' in value && typeof value.Title !== 'string') {
        this.logger.error(
          `Wrong type '${typeof value.Title}' for title which should be 'string' on workflow key "toast.${selectorParts[0]}"`,
          {
            selectorParts,
            value,
          }
        )
        return undefined
      }
      if ('Message' in value && typeof value.Message !== 'string') {
        this.logger.error(
          `Wrong type '${typeof value.Message}' for message which should be 'string' on workflow key "toast.${selectorParts[0]}"`,
          {
            selectorParts,
            value,
          }
        )
        return undefined
      }
      const title = 'Title' in value ? value.Title : undefined
      const message = 'Message' in value ? value.Message : undefined
      doToast(message, title)
    }
  }

  private debounceMassblattUpdate(ccm: ConfiguratorConfigurationModel): Promise<void> {
    const doUpdate = async (resolvers: MassblattUpdateDebounceData['resolver']): Promise<void> => {
      try {
        await ccm.updateMassblaetter()
      } finally {
        resolvers.forEach((resolve): void => resolve())
      }
    }
    let debounceData: MassblattUpdateDebounceData = {
      resolver: [],
      timerId: null
    }
    if (this.ccmMassblattDebounceMap.has(ccm)) {
      debounceData = this.ccmMassblattDebounceMap.get(ccm)
      clearTimeout(debounceData.timerId)
      this.ccmMassblattDebounceMap.delete(ccm)
    } else {
      this.ccmMassblattDebounceMap.set(ccm, debounceData)
    }
    if (this.massblattUpdateDebounceTimeMs >= 0) {
      debounceData.timerId = setTimeout(
        async (): Promise<void> => {
          const resolvers = this.ccmMassblattDebounceMap.get(ccm)?.resolver || debounceData.resolver || []
          this.ccmMassblattDebounceMap.delete(ccm)
          await doUpdate(resolvers)
        },
        this.massblattUpdateDebounceTimeMs
      )
      this.ccmMassblattDebounceMap.set(ccm, debounceData)
      return new Promise((resolve): void => {
        debounceData.resolver.push(resolve)
      })

    }
    return doUpdate(debounceData.resolver)
  }
}

type MassblattUpdateDebounceData<T = unknown> = {
  resolver: ((value?: T) => void)[]
  timerId: ReturnType<typeof setTimeout> | null
}
