import {NGXLogger} from 'ngx-logger'
import {Injectable} from '@angular/core'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {ParameterService} from '../classes/service/parameter/parameter.service'
import {WorkflowAccessor} from './workflow.types'
import {Material} from '../classes/model/material'
import {MehrpreisEntry} from '../classes/model/component/extras/zubehoer/mehrpreisEntry'
import {ZubehoerAddonEntry} from '../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import WorkflowParser from './workflow-parser'
import WorkflowModule from './workflow.module'
import {Zubehoer} from '../classes/model/component/extras/zubehoer/zubehoer'
import {Massblatt} from '../classes/model/component/extras/massblatt'
import {SimpleConstruction} from '../classes/model/component/other/construction'
import {ConstructionComponent} from '../classes/model/component/construction/constructionComponent'
import {ExtraParameter} from '../classes/service/parameter/extra-parameter'


@Injectable({providedIn: WorkflowModule})
export class WorkflowAccessorBuilder {
  constructor(
    private logger: NGXLogger,
  ) {
  }

  public buildAccessor(selector: string): WorkflowAccessor<unknown> | undefined {
    const selectorParts = selector.split('.') as [string, ...string[]]
    const firstSelectorPartLc = selectorParts?.[0].toLowerCase()
    switch (firstSelectorPartLc) {
      case 'basis':
      case `${WorkflowParser.metaCharacters.not}basis`:
        return this.buildBasisAccessor(selectorParts.slice(1))
      case 'deckschichten':
      case `${WorkflowParser.metaCharacters.not}deckschichten`:
        return this.buildCoverLayerAccessor(selectorParts.slice(1))
      case 'gläser':
      case `${WorkflowParser.metaCharacters.not}gläser`:
      case 'glaeser':
      case `${WorkflowParser.metaCharacters.not}glaeser`:
        return this.buildGlassesAccessor(selectorParts.slice(1))
      case 'parameter':
      case `${WorkflowParser.metaCharacters.not}parameter`:
        if (selectorParts[1].toLowerCase() === 'modelle') {
          return this.buildParameterModelAccessor(selectorParts.slice(2))
        }
        if (selectorParts[1].toLowerCase() === 'freie') {
          return this.buildParameterExtraAccessor(selectorParts.slice(2))
        } else {
          return this.buildParameterAccessor(selectorParts.slice(1))
        }
    }
    if (firstSelectorPartLc.startsWith('mehrpreise') || firstSelectorPartLc.startsWith(`${WorkflowParser.metaCharacters.not}mehrpreise`)) {
      return this.buildMehrpreiseAccessor(selectorParts)
    }
    this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '"!')
    return undefined
  }

  private buildBasisAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    if (selectorParts.length > 1) {
      this.logger.warn('Too many workflow selector child keys in "basis"!', selectorParts)
    }
    switch (selectorParts[0]?.toLowerCase()) {
      case 'klebeset':
        return (ccm: ConfiguratorConfigurationModel): boolean => ccm.selectedComponent.Klebeset
      case 'klebesystem':
        return (ccm: ConfiguratorConfigurationModel): number => ccm.selectedComponent.Klebesystem.Id
      case 'konstruktion':
        return (ccm: ConfiguratorConfigurationModel): 'E' | 'AE' | 'AB' => {
          switch (ccm.selectedComponent.konstruktion) {
            case SimpleConstruction.AufsatzEinseitig:
              return 'AE'
            case SimpleConstruction.AufsatzBeidseitig:
              return 'AB'
            case SimpleConstruction.Einsatz:
            default:
              return 'E'
          }
        }
      case 'konstruktionsvariante':
        return (ccm: ConfiguratorConfigurationModel): number => ccm.selectedComponent.fbsKonstruktion.Id
      case 'materialtürfüllung':
      case 'materialtuerfüllung':
      case 'materialtürfuellung':
      case 'materialtuerfuellung':
        return (ccm: ConfiguratorConfigurationModel): Material => ccm.selectedComponent.material
      case 'materialtürsystem':
      case 'materialtuersystem':
        return (ccm: ConfiguratorConfigurationModel): Material => ccm.configuratedDoor.profile.Material
      case 'seeklimatauglich':
        return (ccm: ConfiguratorConfigurationModel): boolean => ccm.configuratedDoor.Seeklimatauglich
      case 'sicherheitspaket':
        return (ccm: ConfiguratorConfigurationModel): string => ccm.configuratedDoor.sicherheitsPaket.Key
      case 'werksmontage':
        return (ccm: ConfiguratorConfigurationModel): boolean => ccm.configuratedDoor.Werksmontage
      case 'wärmeschutzpaket':
      case 'waermeschutzpaket':
        return (ccm: ConfiguratorConfigurationModel): string => ccm.configuratedDoor.waermeschutzPaket.Key
      case 'z-maß':
      case 'zmaß':
      case 'z-mass':
      case 'zmass':
        return (ccm: ConfiguratorConfigurationModel): number => ccm.configuratedDoor.selectedComponent.Zmass
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "basis"!')
        return undefined
    }
  }

  private buildCoverLayerAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    if (selectorParts.length > 2) {
      this.logger.warn('Too many workflow selector child keys in "deckschichten"!', selectorParts)
    }
    let side: keyof ConstructionComponent['Deckschichten']

    switch (selectorParts[0]?.toLowerCase()) {
      case 'innen':
        side = 'Inside'
        break
      case 'aussen':
      case 'außen':
        side = 'Outside'
        break
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "deckschichten"!')
        return undefined
    }

    switch (selectorParts[1]?.toLowerCase()) {
      case 'oberflächentyp':
      case 'oberflaechentyp':
        return (ccm: ConfiguratorConfigurationModel): string =>
          ccm.selectedComponent.Deckschichten[side].find((): true => true).OberflaechenTyp
      default:
        this.logger.error(`Unknown workflow selector key "${selectorParts[1]}" in "deckschichten.${selectorParts[0]}"!`)
        return undefined
    }
  }

  private buildGlassesAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    if (selectorParts.length > 1) {
      this.logger.warn('Too many workflow selector child keys in "gläser"!', selectorParts)
    }
    switch (selectorParts[0]?.toLowerCase()) {
      case 'anzahl':
        return (ccm: ConfiguratorConfigurationModel): number => ccm.selectedComponent.glasaufbau.numGlasses
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "gläser"!')
        return undefined
    }
  }

  private buildMehrpreiseAccessor(selectorParts: [string, ...string[]]): WorkflowAccessor<unknown> | undefined {
    const mehrpreisePredicates = WorkflowParser.buildValidPredicates<MehrpreisEntry>(
      selectorParts[0],
      WorkflowParser.isSupportedMehrpreisKey,
      'mehrpeise',
      this.logger
    )

    const mehrpreisAccessor = (ccm: ConfiguratorConfigurationModel): MehrpreisEntry[] =>
      mehrpreisePredicates.reduce<MehrpreisEntry[]>(
        (acc, curr): MehrpreisEntry[] => acc.filter(curr),
        ccm.selectedComponent.Mehrpreise
      )

    if (selectorParts.length === 1) {
      return mehrpreisAccessor
    }

    const childSelectorPartLc = selectorParts[1].toLowerCase()

    if (childSelectorPartLc === 'item') {
      return (ccm): Zubehoer => mehrpreisAccessor(ccm)?.[0]?.Item
    }

    if (!childSelectorPartLc.startsWith('dienstleistung')) {
      this.logger.error('Unknown workflow selector key "' + selectorParts[1] + '" in "mehrpreise"!', selectorParts)
      return undefined
    }

    const addonPredicates = WorkflowParser.buildValidPredicates(
      selectorParts[1],
      WorkflowParser.isSupportedDienstleistungKey,
      'dienstleistung',
      this.logger
    )

    const addonAccessor = (ccm: ConfiguratorConfigurationModel): ZubehoerAddonEntry[] =>
      addonPredicates.reduce(
        (acc, curr): ZubehoerAddonEntry[] => acc.filter(curr),
        mehrpreisAccessor(ccm).reduce(
          (acc, curr): ZubehoerAddonEntry[] => acc.concat(curr?.addons || []),
          [] as ZubehoerAddonEntry[]
        )
      )

    if (selectorParts.length === 2) {
      return addonAccessor
    }

    if (
      !selectorParts[2].toLowerCase().startsWith('maßblatt')
      && !selectorParts[2].toLowerCase().startsWith('massblatt')
    ) {
      this.logger.error('Unknown workflow selector key "' + selectorParts[2] + '" in "mehrpreise.dienstleistung"!', selectorParts)
      return undefined
    }

    const massblattPredicates = WorkflowParser.buildValidPredicates(
      selectorParts[2],
      WorkflowParser.isSupportedMassblattKey,
      'massblatt',
      this.logger
    )

    const massblattAccessor = (ccm: ConfiguratorConfigurationModel): Massblatt[] =>
      massblattPredicates.reduce(
        (acc, curr): Massblatt[] => acc.filter(curr),
        addonAccessor(ccm).reduce(
          (acc, curr): Massblatt[] =>
            acc.concat([curr?.Massblatt].filter((m): boolean => typeof m !== 'undefined')),
          [] as Massblatt[]
        )
      )

    if (selectorParts.length === 3) {
      return massblattAccessor
    }

    if (selectorParts.length > 4) {
      this.logger.warn('Too many workflow selector child keys in "mehrpreise"!', selectorParts)
    }
    return (ccm: ConfiguratorConfigurationModel): number =>
      massblattAccessor(ccm)
        .find((value): boolean => value.Values.hasOwnProperty(selectorParts[3]))
        ?.Values?.[selectorParts[3]]
  }

  private buildParameterAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    switch (selectorParts[0].toLowerCase()) {
      case 'profil':
        return (_, __, ps: ParameterService): string => ps.parameter.profil
      case 'software':
        return (_, __, ps: ParameterService): string => ps.parameter.software
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter"!')
        return undefined
    }
  }

  private buildParameterExtraAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    if (selectorParts.length < 2) {
      this.logger.error('Too few workflow selector child keys in "parameter.freie"!', selectorParts)
      return undefined
    } else if (selectorParts.length > 2) {
      this.logger.error('Too many workflow selectro child keys in "parameter.freie"!', selectorParts)
      return undefined
    }
    const typ: Extract<keyof ExtraParameter, 'booleanParameters' | 'numberParameters' | 'stringParameters'> | undefined = ({
      boolesch: 'booleanParameters',
      numerisch: 'numberParameters',
      textuell: 'stringParameters'
    } as const)[selectorParts[0]?.toLowerCase()]
    if (!typ) {
      this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.freie"!')
      return undefined
    }
    if (!selectorParts[1]?.match(/^\s*[+\-]?[0-9]+\s*$/)) {
      this.logger.error('Illegal format for "' + selectorParts[1] + '" in "parameter.freie.' + selectorParts[0] + '", expected integer!')
      return undefined
    }
    const id = Number.parseInt(selectorParts[1], 10)
    if (Number.isNaN(id) || !Number.isFinite(id)) {
      this.logger.error('Could not parse "' + selectorParts[1] + '" as integer in "parameter.freie.' + selectorParts[0] + '"!')
      return undefined
    }
    return (_, __, ps: ParameterService): unknown => ps.extraParameter[typ][id]
  }

  private buildParameterModelAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    switch (selectorParts[0]?.toLowerCase()) {
      case 'farbefüllung':
      case 'farbefuellung':
        return (_, __, ps: ParameterService): string => ps.modelle[0]?.FarbeFuellung
      case 'farbefüllunginnen':
      case 'farbefuellunginnen':
      case 'farbefüllung_innen':
      case 'farbefuellung_innen':
        return (_, __, ps: ParameterService): string => ps.modelle[0]?.FarbeFuellungInnen
      case 'farberahmen':
        return (_, __, ps: ParameterService): string => ps.modelle[0]?.FarbeRahmen
      case 'farberahmen_innen':
        return (_, __, ps: ParameterService): string => ps.modelle[0]?.FarbeRahmenInnen
      case 'fingerprint':
      case 'fingerprintfräsung':
      case 'fingerprintfraesung':
      case 'fingerprint_fräsung':
      case 'fingerprint_fraesung':
        return this.buildParameterModelFingerprintAccessor(selectorParts.slice(1))
      case 'griffposition':
      case 'griff_position':
        return this.buildParameterModelHandlePositionAccessor(selectorParts.slice(1))
      case 'griffverstärkung':
      case 'griffverstaerkung':
      case 'griff_verstärkung':
      case 'griff_verstaerkung':
        return this.buildParameterModelHandleStrengtheningAccessor(selectorParts.slice(1))
      case 'pz_position':
      case 'pzposition':
        return this.buildParameterModelPzPositionAccessor(selectorParts.slice(1))
      case 'seitenteilhöhenverteilung':
      case 'seitenteilhoehenverteilung':
      case 'sthöhenverteilung':
      case 'sthoehenverteilung':
      case 'st_höhen_verteilung':
      case 'st_hoehen_verteilung':
      case 'seitenteil_höhen_verteilung':
      case 'seitenteil_hoehen_verteilung':
        return this.buildParameterModelSidePanelHeightDistributionAccessor(selectorParts.slice(1))
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle"!')
        return undefined
    }
  }

  private buildParameterModelFingerprintAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 'b':
      case 'h':
      case 'r':
      case 't':
      case 'v':
        return (_, __, ps: ParameterService): number => ps.modelle[0]?.fingerprintFraesung?.[selector]
      case 'aktiv':
        return (_, __, ps: ParameterService): boolean => ps.modelle[0]?.fingerprintFraesung?.aktiv
      case undefined:
        return (_, __, ps: ParameterService): object => ps.modelle[0]?.fingerprintFraesung
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.fingerprint"!')
        return undefined
    }
  }

  private buildParameterModelHandlePositionAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 's':
      case 't':
        return (_, __, ps: ParameterService): number => ps.modelle[0]?.griffPosition?.[selector]
      case 'aktiv':
        return (_, __, ps: ParameterService): boolean => ps.modelle[0]?.griffPosition?.aktiv
      case undefined:
        return (_, __, ps: ParameterService): object => ps.modelle[0]?.griffPosition
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.griff_position"!')
        return undefined
    }
  }

  private buildParameterModelHandleStrengtheningAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 'ga':
      case 'gb':
      case 'gd':
        return (_, __, ps: ParameterService): number => ps.modelle[0]?.griffVerstaerkung?.[selector]
      case 'aktiv':
        return (_, __, ps: ParameterService): boolean => ps.modelle[0]?.griffVerstaerkung?.aktiv
      case undefined:
        return (_, __, ps: ParameterService): object => ps.modelle[0]?.griffVerstaerkung
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.griff_verstaerkung"!')
        return undefined
    }
  }

  private buildParameterModelPzPositionAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 'd':
      case 'v':
      case 'w':
        return (_, __, ps: ParameterService): number => ps.modelle[0]?.pzPosition?.[selector]
      case 'aktiv':
        return (_, __, ps: ParameterService): boolean => ps.modelle[0]?.pzPosition?.aktiv
      case 'typ':
        return (_, __, ps: ParameterService): string => ps.modelle[0]?.pzPosition?.typ
      case undefined:
        return (_, __, ps: ParameterService): object => ps.modelle[0]?.pzPosition
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.pz_position"!')
        return undefined
    }
  }

  private buildParameterModelSidePanelHeightDistributionAccessor(selectorParts: string[]): WorkflowAccessor<unknown> | undefined {
    const selector = selectorParts[0]?.toLowerCase()
    switch (selector) {
      case 'a':
      case 'b':
        return (_, __, ps: ParameterService): number => ps.modelle[0]?.seitenteilHoehenverteilung?.[selector]
      case 'aktiv':
        return (_, __, ps: ParameterService): boolean => ps.modelle[0]?.seitenteilHoehenverteilung?.aktiv
      case undefined:
        return (_, __, ps: ParameterService): object => ps.modelle[0]?.seitenteilHoehenverteilung
      default:
        this.logger.error('Unknown workflow selector key "' + selectorParts[0] + '" in "parameter.modelle.seitenteil_hoehen_verteilung"!')
        return undefined
    }
  }
}
