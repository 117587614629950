import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {FbsGlas, Motiv} from '../../../class'
import {StringUtil} from '../../../classes/util/stringUtil'
import {GLASS_PROVIDER_TOKEN, GlassProvider} from '../../../classes/model/dataProvider/glass/glass.type'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {GlassUpdateHelper} from '../../../classes/model/dataProvider/glass/glass-update-helper'
import {ModalService} from '../modal.service'
import {TranslateService} from 'src/app/translate'
import {GlasaufbauPositionShort} from '../../../types'

export type GlassModalMode = 'glass' | 'motiv'
export type GlassModalData = {
  mode: GlassModalMode
  position: GlasaufbauPositionShort
}
export type HasGlassOrMotiveChanged = boolean
const TRANSLATION_KEY = {
  MODAL_TITLE_GLASSES: 'GlassModal.Title.Glasses',
  MODAL_TITLE_MOTIVES: 'GlassModal.Title.Motives',
  SEARCH_LABEL: 'GlassModal.Input.Search.Label',
  SAVE_BUTTON: 'GlassModal.Button.Save.Label'
} as const

@Component({
  templateUrl: './glass-modal.component.html',
  styleUrls: ['./glass-modal.component.scss']
})
export class GlassModalComponent {
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected readonly glassUpdate: GlassUpdateHelper

  constructor(
    public dialogRef: MatDialogRef<GlassModalComponent, HasGlassOrMotiveChanged>,
    private componentSelection: ComponentSelectionService,
    private modalService: ModalService,
    protected translator: TranslateService,
    @Inject(MAT_DIALOG_DATA) data: GlassModalData,
    @Inject(GLASS_PROVIDER_TOKEN) private glassProvider: GlassProvider
  ) {
    this.glassUpdate = new GlassUpdateHelper(
      this.translator,
      this.componentSelection.selectedComponent,
      this.glassProvider.getGlassesForModel(this.componentSelection.selectedComponent.model),
      this.componentSelection.selectedComponent.model.Motive,
      data.position,
      data.mode
    )
    const selectedGlassId = this.glassUpdate.getCurrentGlasselementId()
    const selectedMotivId = this.glassUpdate.getCurrentMotivelementId()
    if (selectedGlassId > 0) {
      this.glassUpdate.selectedGlas = this.glassUpdate.glaeser.find((g: FbsGlas): boolean => g.Id === selectedGlassId)
    }
    if (selectedMotivId > 0) {
      this.glassUpdate.selectedMotiv = this.glassUpdate.motive.find((m: Motiv): boolean => m.Id === selectedMotivId)
    }
    this.glassUpdate.moveSelectedGlasToBeginning()
    this.glassUpdate.moveSelectedMotivToBeginning()
  }

  onConfirm(): void {
    this.dialogRef.close(this.glassUpdate.updateSelection())
  }

  showImageModal(item: FbsGlas | Motiv, e: Event): void {
    e.stopPropagation()
    this.modalService.showImageModal(
      item.Beschreibung,
      item.PreviewImageUrl,
      {panelClass: 'detailview'}
    )
  }
}
