import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {Konstruktion} from '../../../class'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ModalService} from '../modal.service'

const TRANSLATION_KEY = {
  CLOSE_BUTTON: 'ConstructionModal.Button.Close.Label'
} as const

@Component({
  templateUrl: './konstruktion-modal.component.html'
})
export class KonstruktionModalComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY

  constructor(
    private modalService: ModalService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    @Inject(MAT_DIALOG_DATA) public readonly konstruktion: Konstruktion
  ) {
  }

  showImageModalDialog(title: string, url: string): void {
    this.modalService.showImageModal(title, url)
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }
}
