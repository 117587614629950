import {RegexTreeObject} from './regex-tree-object.type'
import {RegexTreeArray} from './regex-tree-array.type'

export class RegexpTreeEvaluator {
  public exec(stringToMatch: string, regex: RegexTreeArray): RegExpExecArray | null {
    if (typeof regex === 'string') {
      regex = new RegExp(regex)
    }

    if (regex instanceof RegExp) {
      return regex.exec(stringToMatch)
    }
    if (regex && Array.isArray(regex)) {
      return regex.reduce<RegExpExecArray | null>(
        (res: RegExpExecArray | null, item: RegexTreeArray): RegExpExecArray =>
          (!!res) ? res : this.exec(stringToMatch, item)
        , null)
    }
    return null
  }

  public test(stringToMatch: string , regex: RegexTreeObject): boolean {
    if (typeof regex === 'string') {
      regex=new RegExp(regex)
    }

    if (regex instanceof RegExp) {
      return regex.test(stringToMatch)
    }
    if (typeof regex !== 'object') {
      return false
    }
    if ('and' in regex && Array.isArray(regex.and)) {
      return regex.and.every((item: RegexTreeObject): boolean => this.test(stringToMatch, item))
    }
    if ('or' in regex && Array.isArray(regex.or)) {
      return regex.or.some((item: RegexTreeObject): boolean => this.test(stringToMatch, item))
    }
    if ('not' in regex) {
      return !this.test(stringToMatch, regex.not)
    }
    return false
  }
}
